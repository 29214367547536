// shared items for listings, to DRY up the code
import { timeAgo } from '/src/services/helpers';

export default {

  metaInfo () {
    return {
      title: this.listing.title,
      meta: [
        { vmid: 'description', name: 'description', content: this.listing.body },
        { vmid: 'og:title', property: 'og:title', content: this.listing.title + ' - Partstash Classic Car Part Listings' }
      ]
    }
  },


  mounted: function() {
    window.scrollTo(0,0);
  },

	computed: {

    user: function() {
      return this.$store.state.user;
    },

		hasGallery: function() {
      if(this.listing.hasOwnProperty('gallery')) {
        if(this.listing.gallery.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    title: function() {
      if(!this.listing.title) {
        return false;
      } else {
        return this.listing.title.replaceAll('&amp;', '&');
      }

    },

    bgStyle: function() {
      if(this.listing) {
        if(this.hasGallery) {
          return "background-image: url(//partstash.imgix.net/" + this.listing.gallery[0].filename + "?auto=format&q=100&width=500)";
        } else {
          return '';
        }
      } else {
        return '';
      }
    },

    listing_date: function() {
      return new Date(this.listing.created_at).toLocaleDateString("en-US");
    },

    yourListing: function() {
      if(this.listing.user_id == this.$store.state.user.user_id) {
        return true;
      } else {
        return false;
      }
    },

    listing_id: function() {
      return this.listing._id;
    },
	}

}
