var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded && _vm.visible
    ? _c(
        "div",
        [
          _c("heading", {
            attrs: {
              heading: _vm.heading,
              "user-action": _vm.action,
              "user-label": "< Back",
            },
          }),
          _vm.loggedInUser.username && _vm.yours
            ? _c("admin-bar", { attrs: { item: _vm.entry, type: "event" } })
            : _vm._e(),
          _vm.hasGallery
            ? _c(
                "segment",
                { attrs: { transparent: "" } },
                [
                  _c("listing-gallery", {
                    attrs: { item: _vm.entry, bucket: "groups" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "segment",
            [
              _c("p", [_vm._v("Organizer:")]),
              _c("profile-widget", {
                attrs: { large: "", userId: _vm.entry.user_id },
              }),
              _c("br"),
              _c("badges", { attrs: { page: _vm.entry } }, [
                _vm.entry.is_private
                  ? _c("div", { staticClass: "badge private" }, [
                      _vm._v("PRIVATE"),
                    ])
                  : _vm._e(),
              ]),
              !_vm.entry.is_private
                ? _c("likes-and-comments-bar", {
                    attrs: { item: _vm.entry, type: "event", scroll: "" },
                    on: { "scroll-to-comments": _vm.scrollToComments },
                  })
                : _vm._e(),
            ],
            1
          ),
          !_vm.yours
            ? _c("segment", [
                _vm.loggedInUser
                  ? _c(
                      "div",
                      { staticClass: "btn-list" },
                      [
                        !_vm.attending
                          ? _c("btn", {
                              ref: "attendBtn",
                              attrs: { label: "Attend Event" },
                              on: {
                                click: function ($event) {
                                  return _vm.attendEvent()
                                },
                              },
                            })
                          : _c("btn", {
                              ref: "leaveBtn",
                              attrs: {
                                color: "red",
                                theme: "outline",
                                label: "Leave Event",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.leaveEvent()
                                },
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "big-card-wrap" },
            [
              _vm.loggedInUser
                ? _c("big-card", {
                    attrs: {
                      title: "Attendees",
                      theme: "dark",
                      width: "full",
                      icon: "users",
                      count: _vm.entry.attendees.length,
                    },
                    on: {
                      openPane: function ($event) {
                        return _vm.openPane("attendees")
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "segment",
            { attrs: { title: "Details" } },
            [
              _vm.entry.event_date
                ? _c("p", [_vm._v("When: " + _vm._s(_vm.eventDate))])
                : _vm._e(),
              _vm.entry.recurring_frequency
                ? _c("p", [
                    _vm._v(
                      "Recurring: " + _vm._s(_vm.entry.recurring_frequency)
                    ),
                  ])
                : _vm._e(),
              _vm.entry.location
                ? _c("p", [_vm._v("Location: " + _vm._s(_vm.entry.location))])
                : _vm._e(),
              _vm.entry.time
                ? _c("p", [_vm._v("Time: " + _vm._s(_vm.entry.time))])
                : _vm._e(),
              _vm.entry.url
                ? _c("btn", {
                    attrs: { href: _vm.entry.url, label: "Detail Link" },
                  })
                : _vm._e(),
              _vm.entry.location_url
                ? _c("btn", {
                    attrs: {
                      href: _vm.entry.location_url,
                      outside: "",
                      label: "Location Link",
                    },
                  })
                : _vm._e(),
              _c("div", { domProps: { innerHTML: _vm._s(_vm.entry.body) } }),
            ],
            1
          ),
          !_vm.entry.is_private
            ? _c(
                "segment",
                { ref: "comments", attrs: { id: "comments" } },
                [_c("comments", { attrs: { item: _vm.entry, type: "event" } })],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }