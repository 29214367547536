var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content narrow" }, [
    _c("br"),
    _c(
      "div",
      { staticClass: "card single pad centered" },
      [
        _c("h3", [_vm._v("Not Found")]),
        _c("p", [_vm._v("The page your looking for cannout be found.")]),
        _c("br"),
        _c("btn", { attrs: { label: "Go Home", to: "/" } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }