var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gallery-picker" },
    [
      !_vm.single
        ? _c("h3", [
            _c("span", [_vm._v("Images:  ")]),
            _c("span", [_vm._v(_vm._s(_vm.state.length))]),
            _c("span", { staticClass: "of" }, [_vm._v(" of")]),
            _c("span", [_vm._v(" 10")]),
          ])
        : _c("h3", [_vm._v("Image")]),
      _vm.showPicker
        ? _c("label", { attrs: { for: _vm.id } }, [
            _c(
              "div",
              { staticClass: "add" },
              [_c("plus-icon", { staticClass: "icon" })],
              1
            ),
            _c("div", { staticClass: "file-input-wrap" }, [
              _c("input", {
                ref: _vm.ref,
                staticClass: "file-input",
                attrs: {
                  required: _vm.required,
                  id: _vm.id,
                  type: "file",
                  accept: "image/*",
                  name: "images",
                  multiple: !_vm.single,
                },
                on: { input: _vm.onSelectFile },
              }),
            ]),
          ])
        : _vm._e(),
      _c("help-text", { attrs: { text: _vm.helpText } }),
      _vm.state.length
        ? _c(
            "div",
            { staticClass: "previews" },
            _vm._l(_vm.state, function (image, index) {
              return !image.remove
                ? _c("div", { key: index, staticClass: "preview" }, [
                    _c(
                      "div",
                      {
                        staticClass: "remove",
                        on: {
                          click: function ($event) {
                            return _vm.removeImage(index)
                          },
                        },
                      },
                      [_c("x-icon", { staticClass: "icon" })],
                      1
                    ),
                    image.path
                      ? _c("img", {
                          staticClass: "thumb",
                          attrs: { src: image.path },
                        })
                      : _vm._e(),
                    image.result
                      ? _c("img", {
                          staticClass: "thumb",
                          attrs: { src: image.result },
                        })
                      : _vm._e(),
                  ])
                : _vm._e()
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }