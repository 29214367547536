<template lang='pug'>

.content-rows

	//- LISTINGS
	.content(v-if='type == "listings"')

		.action(v-if='data.length')
			btn(to='/listing/add' label='New Listing')

		.action(v-else)
			btn(to='/listing/add' label='Create Your First Listing')

		div(v-if='data.length')
			row(
				v-for="item, index in data"
				:key="index"
				:item="item")

		div(v-else)
			p.none No listings yet

	.content(v-if='type == "wants"')

		.action(v-if='data.length')
			btn(to='/want/add' label='New Want')

		.action(v-else)
			btn(to='/want/add' label='Create Your First Want')

		div(v-if='data.length')
			row(
				v-for="item, index in data"
				:key="index"
				:item="item")

		div(v-else)
			p.none No wants yet

	//- GARAGE CARS
	.content(v-if='type == "garage"')

		.action(v-if='!noAdd')
			btn(to='/garage/car/add' label='New Car')

		div(v-if='data.length')
			row(
				v-for="item, index in data"
				:key="index"
				:item="item"
				customEntryType='garage')

		div(v-else)
			p.none No cars in your garage


	//- WATCHES
	.content(v-if='type == "watches"')
		div(v-if='data.length')
			row(
				v-for="item, index in data"
				:key="index"
				:item="item"
				type='watch'
				v-on:watch-remove='removeWatch')

		div(v-else)
			p.none no items in your watch list

	//- SEARCHES
	.content(v-if='type == "searches"')
		div(v-if='data.length')
			row(
				v-for="item, index in data"
				:key="index"
				:item="item"
				customEntryType='search')

		div(v-else)
			p.none no items in your saved searches

	//- FOLLOWERS
	.content(v-if='type == "followers"')
		div(v-if='data.length')
			UserRow(
				v-for="item, index in data"
				:key="index"
				:user="item")

		div(v-else)
			p.none No followers

	//- FOLLOWING
	.content(v-if='type == "following"')
		div(v-if='data.length')
			UserRow(
				v-for="item, index in data"
				:key="index"
				:user='item')

		div(v-else)
			p.none You're not following anyone


	//- Deleted LISTINGS
	.content(v-if='type == "deleted"')
		div(v-if='data.length')
			card(
				layout='horizontal'
				v-for="item, index in data"
				:key="index"
				:item="item"
				deleted)

		div(v-else)
			p.none Nothing Deleted

	//- Notices
	.content(v-if='type == "notices"')
		div(v-if='data.length')
			notice-row(
				v-for="item, index in data"
				:key="index"
				:index="index"
				:item="item")

		div(v-else)
			p.none No Notifications

	//- Events
	.content(v-if='type == "events"')
		.action
			btn(v-if='!noAdd' to='/event/add' label='New Event')

		div(v-if='data.length')
			row(
				v-for="item, index in data"
				:key="index"
				:index="index"
				:item="item")

		div(v-else)
			p.none No Events

	//- Timeline
	.content(v-if='type == "timeline"')
		.action(v-if='!noAdd')
			btn(to='/timeline/add' label='New Status')

		div(v-if='data.length')
			row(
				v-for="item, index in data"
				:key="index"
				:index="index"
				:item="item")

		div(v-else)
			p.none No Timeline Statuses

	//- Treasures
	.content(v-if='type == "treasures"')
		.action(v-if='!noAdd')
			btn(to='/treasure/add' label='New Treasure')

		div(v-if='data.length')
			row(
				v-for="item, index in data"
				:key="index"
				:index="index"
				:item="item")

		div(v-else)
			p.none No Cascade Consulting Listings

</template>

<script>
import UserRow from '../../components/globals/rows/user';
import NoticeRow from '../../components/globals/rows/notice';

export default {

	components: {
		UserRow,
		NoticeRow
	},

	props: {
		data: Array,
		type: String,
		noAdd: {
			type: Boolean,
			default: false
		}
	},

	data: function() {
		return { }
	},

	methods: {
		removeWatch: async function(id) {
			try {

				await axios.post(
					'/api/v1/user/watch/remove',
					{ listing_id: id },
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
							'Content-Type' : 'application/json'
						}
					});

					window.location.reload(); // TODO: Better reactive data
					// gtag('event', 'part was removed from watches');
					// alert('watch list updated'); // TODO: Swap for partstash alert system
					// this.$refs['card-watchlist'].classList.toggle('open');
					// window.scrollTo(0,0);

			}

			catch(err) {
				console.log('Something went wrong', err);
			}
		}
	}

}

</script>

<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.content-rows
	text-align left

.action
	margin-top 10px
	margin-bottom 10px

</style>
