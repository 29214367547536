<template lang='pug'>

div(v-if='loaded')
	heading(
		:heading='heading'
		:user-action='action'
		user-label='< Back')

	.car
		div {{ carHeading }}

	car-record-form(
		edit
		:car='car'
		:recordToEdit='record')

</template>

<script>
import CarRecordForm from '/src/components/garage/car-record-form';

export default {
	components: {
		CarRecordForm,
	},

	data() {
		return {
			record: {},
			car: {},
			loaded: false
		}
	},

	beforeMount: async function() {
		await this.fetchComponentData();
	},

	computed: {
		heading: function() {
			return 'EDIT record: ' + this.record.name;
		},

		carHeading: function() {
			return 'For car: ' + this.car.name;
		},

		action: function() {
			return '/garage/car/detail/' + this.car.internal_id;
		}
	},

	methods: {
		async fetchComponentData() {

			await fetch('/api/v1/garage/record/' + this.$route.params.recordId)
				.then(res => res.json())
				.then(record => {
					this.record = record;
				});

			await fetch('/api/v1/garage/detail/' + this.$route.params.id)
				.then(res => res.json())
				.then(car => {
					this.car = car;
				});

			this.loaded = true;
		}
	}
}

</script>


<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.car
	padding 0 30px


</style>
