<template lang="pug">

.grid
	slot

</template>

<script>


export default {
	name: 'grid',

}
</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.grid
	display flex
	align-items stretch
	flex-wrap wrap
	justify-content flex-start

>>> .card
	width: calc(33.33333% - 10px)
	margin 5px

	@media(max-width:tablet)
		width: calc(50% - 10px)


</style>
