var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c("div", { staticClass: "record-row" }, [
        _vm.removed
          ? _c("div", [
              _c("span", { staticClass: "red" }, [_vm._v("Record Deleted")]),
            ])
          : _c(
              "div",
              [
                _c("router-link", { attrs: { to: _vm.viewLink } }, [
                  _c("h3", { staticClass: "name" }, [
                    _vm._v(_vm._s(_vm.item.name)),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c("badges", { attrs: { page: _vm.item } }, [
                      _vm.item.is_private
                        ? _c("div", { staticClass: "badge private" }, [
                            _vm._v("PRIVATE"),
                          ])
                        : _vm._e(),
                    ]),
                    _c("time-ago", { attrs: { item: _vm.item } }),
                  ],
                  1
                ),
                !_vm.item.is_private
                  ? _c("likes-and-comments-bar", {
                      attrs: { item: _vm.item, type: "record" },
                    })
                  : _vm._e(),
                _c("div", { staticClass: "actions" }, [
                  _c(
                    "div",
                    { staticClass: "btn-list" },
                    [
                      _c("btn", {
                        attrs: {
                          size: "small",
                          to: _vm.viewLink,
                          label: "Details",
                        },
                      }),
                      _vm.loggedInUser && _vm.mine
                        ? _c("btn", {
                            attrs: {
                              color: "grey",
                              size: "small",
                              to: _vm.editLink,
                              label: "Edit",
                            },
                          })
                        : _vm._e(),
                      _vm.loggedInUser && _vm.mine
                        ? _c("btn", {
                            attrs: {
                              color: "red",
                              size: "small",
                              theme: "outline",
                              icon: "trash-2-icon",
                            },
                            on: {
                              clicked: function ($event) {
                                return _vm.mountPane("delete")
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }