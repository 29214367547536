var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content-rows" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "segment",
          { attrs: { full: "", light: "" } },
          [
            _c("h4", [_vm._v("Admins")]),
            _vm._l(_vm.admins, function (user, index) {
              return _c("user-row", { key: index, attrs: { userId: user } })
            }),
          ],
          2
        ),
        _c("segment", { attrs: { full: "", light: "" } }, [
          _c("h4", [_vm._v("Members")]),
          _vm.members.length > 0
            ? _c(
                "div",
                _vm._l(_vm.members, function (user, index) {
                  return _c("user-row", { key: index, attrs: { userId: user } })
                }),
                1
              )
            : _c("div", [
                _c("p", { staticClass: "none" }, [_vm._v("No Members")]),
              ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }