<template lang='pug'>

segment
  segment-header
    h1 New Message
    router-link.back(to='/garage') Back To Garage

  message-form(v-if='!loading') 

</template>

<script>
import MessageForm from '../../components/views/message-form';

export default {

  components: {
    MessageForm
  },

  data() {
    return {
      loading: true
    }
  },

  mounted: function () {
    this.loading = false;
    this.$store.state.view = 'New Message';
  },

}

</script>