var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("heading", { attrs: { heading: "Cascade Consulting" } }),
      _c(
        "div",
        { staticClass: "about" },
        [
          _c("profile-widget", {
            attrs: { large: "", userId: "01ece3e149e54ebca6bb78a1569ca852" },
          }),
          _c("p", [
            _vm._v(
              "We will help you search for, buy, and/or sell your next car. With 5+ years of automotive sales and restoration experience, we know what to look for and avoid in the car world. We constantly monitor the market on a wide array of used and new cars to keep up to date on market prices and what’s coming soon."
            ),
          ]),
          _c(
            "a",
            {
              staticClass: "btn",
              attrs: {
                href: "https://www.cascadeconsultingconcierge.com/",
                target: "blank",
              },
            },
            [_vm._v("Read More")]
          ),
        ],
        1
      ),
      _c("paginated-feed", { attrs: { type: "treasures", noAds: "" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }