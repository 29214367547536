var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "notice-wrap" },
    [
      _c(
        "pane",
        { ref: "feedback", attrs: { title: "Submit Feedback" } },
        [
          _c("form-text", {
            attrs: { label: "Name", name: "name" },
            model: {
              value: _vm.feedback.name,
              callback: function ($$v) {
                _vm.$set(_vm.feedback, "name", $$v)
              },
              expression: "feedback.name",
            },
          }),
          _c("form-textarea", {
            ref: "comment",
            attrs: { label: "Comment", name: "comment" },
            model: {
              value: _vm.feedback.comment,
              callback: function ($$v) {
                _vm.$set(_vm.feedback, "comment", $$v)
              },
              expression: "feedback.comment",
            },
          }),
          _c("btn", {
            attrs: { label: "Submit" },
            on: { click: _vm.submitFeedback },
          }),
        ],
        1
      ),
      _vm.loggedIn
        ? _c(
            "div",
            {
              staticClass: "feedback-notice",
              on: {
                click: function ($event) {
                  return _vm.openPane("feedback")
                },
              },
            },
            [
              _c("p", [_vm._v("This app is currently in user testing")]),
              _c("span", [_vm._v("Submit Feedback")]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }