<template lang='pug'>

mounted-pane(ref='pane' title='Your Groups' @close='close()')
	.content-rows(v-if='loaded')
		.content
			.action
				btn(to='/group/add' v-if='!public' label='New Group')

			segment(v-if='totalPending > 0' full light)
				h3 Membership Pending
				paginated-groups(type='pending' :user='user' :total='totalPending' rows status='pending')

			segment(v-if='totalOwner > 0' full light)
				h3 Groups You Created
				paginated-groups(type='created' :user='user' :total='totalOwner' rows)

			segment(v-if='totalAdmin > 0' full light)
				h3 Groups you're an admin of
				paginated-groups(type='admin' :user='user' :total='totalAdmin' rows)

			segment(v-if='totalMember > 0' full light)
				h3 Groups you're a member of
				paginated-groups(type='member' :user='user' :total='totalMember' rows)


</template>

<script>
import PaginatedGroups from '/src/components/garage/paginated-groups';

export default {

	components: { PaginatedGroups },

	props: {
		public: Boolean
	},

	data: function() {
		return {
			loaded: false,
			totalPending: 0,
			totalOwner: 0,
			totalMember: 0,
			totalAdmin: 0
		}
	},

	beforeMount: async function() {
		await this.fetchTotal('created');
		await this.fetchTotal('member');
		await this.fetchTotal('pending');
		await this.fetchTotal('admin');
	},

	mounted: async function() {
		this.loaded = true;
	},

	computed: {
		user: function() {
			return this.$store.state.user;
		}
	},

	methods: {
		fetchTotal: async function(type) {

			await fetch('/api/v1/group/' + type + '/0', {
				headers: {
						authorization: `Bearer ${JSON.parse(localStorage.token).value}`
					}
			}).then(res => res.json())
				.then(data => {
					if(type == 'pending') { this.totalPending = data.total; }
					if(type == 'created') { this.totalOwner = data.total; }
					if(type == 'member') { this.totalMember = data.total; }
					if(type == 'admin') { this.totalAdmin = data.total; }
				});
		}
	}

}

</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

</style>
