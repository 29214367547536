var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "content" },
            [_c("heading", { attrs: { heading: "All Listings" } })],
            1
          ),
          _c("featured-listings"),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("listings-filter", {
                on: { "set-listings": _vm.setListings },
              }),
              _c("listings-all", { ref: "listings" }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }