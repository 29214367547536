<template lang='pug'>

div
  
  //- If locked choice, then show this
  //- and hide the input
  .lock-show(v-if='choicePicked && lock')
    .choice.pill.blue.large
      span {{ choicePicked }}
      span.remove(v-if='!initial' @click='clear')
        x-icon(class='icon')
  
  form-text(
    @inputChanged='onInputChange($event)' 
    v-else 
    v-model='picking'
    :label='label')

  transition(name='fade')
    .autocomplete(v-show='showResults')
      .choice(v-for='result, index in results'
        @click='assign(result)')
        .pill.blue.large {{ result }}

</template>

<script>

export default {
  
  props: {
    data: Array,
    label: String,
    lock: Boolean,
    initial: Object
  },

  data: function() {
    return {
      results: [],
      picking: '',
      showResults: false,
      choicePicked: null,
    }
  },

  methods: {

    // Once chosen, then set the val
    assign: function(val) {
      this.clear();
      this.choicePicked = val;
      this.picking = val;
      this.$emit('selection', val);
    },

    // Do the autocomplete filtering
    filterMakeResults: function() {
      this.results = this.data.filter(result => result.toLowerCase().indexOf(this.picking.toLowerCase()) > -1);
    },

    onInputChange: function(e) {
      this.picking = e;

      if(e.length == 0) {
        this.clear();
      } else {
        this.filterMakeResults();
        this.showResults = true;
      }
    },

    clear: function() {
      this.picking = '';
      this.showResults = false;
      this.results = [];
      this.choicePicked = null;
      this.$emit('cleared', true);
    }
  }

}
</script>


<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.autocomplete
  position: absolute
  width: 100%
  margin: 0
  z-index: 500
  background #000
  color color-text
  border-top: none
  margin-top: -8px
  max-height 200px
  overflow scroll
  padding 15px
  border-bottom-left-radius 7px
  border-bottom-right-radius 7px

  .choice
    padding 8px
    cursor pointer
    display inline-block

  .remove
    cursor pointer

</style>