<template lang="pug">

.content.narrow
    
  h1 Ferruccio 
  //- input(type="number" ref='year')
  br
  br
  img(src="https://64.media.tumblr.com/40a1361ff65f6d61f0d785111a744192/tumblr_n6o9rsMZ931rpdptuo1_500.gifv")
  br
  br
  button(@click='processMakes') MAKES: PUNCH IT
  br
  br
  button(@click='processModels') MODELS: PUNCH IT
  //- br
  //- br
  //- button(@click='processTrims') TRIMS: PUNCH IT


</template>

<script>
import axios from 'axios';

export default {
  name: 'ferruccio',

  data: function() {
    return {}
  },

  methods: {
    async processMakes() {

      fetch('/api/v1/ferruccio', {
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.token).value}`
        }
      });
    },

    async processModels() {

      fetch('/api/v1/ferruccio/models', {
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.token).value}`
        }
      });
    }
  }
}
</script>

