<template lang="pug">

.content.narrow(v-if='loaded && isAdminUser')
  success-modal(
    ref='modalSuccess'
    title='Success'
    message='Ad Edited'
    noClose)

  loader(v-show='loadingScreen')

  router-link(to='/admin') Back

  h1 Edit Ad

  segment
    form-text(
      v-model='ad.title'
      label='Title'
      name='title')

  segment
    form-text(
      v-model='ad.description'
      label='Description'
      name='description')

  segment
    form-text(
      v-model='ad.btnText'
      label='Btn Text'
      name='btnText')


  segment
    form-text(
      v-model='ad.url'
      label='Btn URL'
      name='url')

  segment
    .input-group.checkbox
      input(type='checkbox'
        id='textDark'
        name='textDark'
        v-model='ad.dark_text')

      label(for='textDark')
        .box
        span Dark Text?

  segment
    uploader(
      ref='gallery'
      single
      v-on:master-batch='onSelect'
      v-if='dataLoaded'
      :initial='ad.image'
      :edit='edit')

  cta-wrap
    btn(
      color='green'
      @click='addAd'
      :disabled='addable == false'
      :label='buttonText')


</template>

<script>
import axios from 'axios';

export default {
  name: 'ad-edit',

  beforeMount: async function () {
    await fetch('/api/v1/advert/detail/' + this.$route.params.id)
    .then(res => res.json())
    .then(ad => {

      this.ad.title = ad.ad[0].title;
      this.ad.url = ad.ad[0].url;
      this.ad.description = ad.ad[0].description;
      this.ad.btnText = ad.ad[0].btnText;
      this.ad.url = ad.ad[0].url;
      this.ad.textDark = ad.ad[0].textDark;
      this.ad.image.filename = ad.ad[0].image.filename;


      this.$nextTick(function() {
        this.dataLoaded = true;
        this.loaded = true;
      });
    });
  },

  data: function() {
    return {
      loadingScreen: false,
      loaded: false,
      dataLoaded: false,
      edit: true,
      ad: {
        title: null,
        url: null,
        image: {
          filename: null,
          new: false,
          remove: false
        },
        description: null,
        btnText: null,
        url: null,
        textDark: false
      }
    }
  },

  computed: {
    isAdminUser: function() {
      if(_.isEmpty(this.$store.state.user)) {
        return false;
      } else {
        if(this.$store.state.user.accountType == 'admin') {
          return true;
        } else {
          window.location.href = '/';
        }
      }
    },

    addable: function() {
      if(this.ad.image && this.ad.url) {
        return true;
      } else {
        return false;
      }
    },

    buttonText: function() {
      return 'Add'
    }
  },

  methods: {
    onSelect(files) {
      this.ad.image = files;
    },

    addAd: async function() {

      this.loadingScreen = true;
      this.$refs.cta.classList.add('force-disabled');

      let formData = new FormData();

      formData.append('title', this.$sanitize(this.ad.title));
      formData.append('description', this.$sanitize(this.ad.description));
      formData.append('btn_text', this.$sanitize(this.ad.btn_text));
      formData.append('url', this.$sanitize(this.ad.url));

      // prepare file list
      formData.append('file', this.ad.image[0].file);

      console.log('formdata', formData);

      try {

        let submission = await axios.post(
          '/api/v1/advert/add',
          formData,
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'multipart/form-data'
            }
          });

        let res = submission.data;
        this.resetView();
        this.$refs.modalSuccess.setContent(res.internal_id, res.title);
        this.$refs.modalSuccess.openModal();
        this.loadingScreen = false;

      }

      catch(err) {
        console.log('Something went wrong', err);
      }

    },

    resetView: function() {
      window.scrollTo(0,0);
      this.ad.title = '';
      this.ad.description = '';
      this.ad.btnText = '';
      this.ad.url = '';
      this.ad.image = null;
      this.$refs.gallery.reset();
      this.loadingScreen = false;
      this.$refs.cta.classList.remove('force-disabled');
    }
  }

}
</script>


<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

</style>
