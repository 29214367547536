var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-advert", class: _vm.classes, style: _vm.adStyle },
    [
      _c("div", { staticClass: "shade" }),
      _c("div", { staticClass: "height-prop" }),
      _c("div", { staticClass: "content" }, [
        _vm.item.url
          ? _c(
              "a",
              {
                staticClass: "item",
                attrs: { href: _vm.item.url, target: "blank" },
              },
              [
                _vm.item.title
                  ? _c("h2", [_vm._v(_vm._s(_vm.item.title))])
                  : _vm._e(),
                _vm.item.headline
                  ? _c("h3", [_vm._v(_vm._s(_vm.item.headline))])
                  : _vm._e(),
                _vm.item.btn_text
                  ? _c("btn", { attrs: { label: _vm.item.btn_text } })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        !_vm.item.url
          ? _c("div", { staticClass: "item" }, [
              _vm.item.title
                ? _c("h2", [_vm._v(_vm._s(_vm.item.title))])
                : _vm._e(),
              _vm.item.headline
                ? _c("h3", [_vm._v(_vm._s(_vm.item.headline))])
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }