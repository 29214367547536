var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("div", { staticClass: "listings-container" }, [
        _c("div", { staticClass: "picker" }, [
          _c(
            "div",
            {
              ref: "sale",
              staticClass: "picker-item selected",
              on: {
                click: function ($event) {
                  return _vm.toggle("sale")
                },
              },
            },
            [_c("span", { staticClass: "title" }, [_vm._v("For Sale")])]
          ),
          _c(
            "div",
            {
              ref: "want",
              staticClass: "picker-item",
              on: {
                click: function ($event) {
                  return _vm.toggle("want")
                },
              },
            },
            [_c("span", { staticClass: "title" }, [_vm._v("Want To Buy")])]
          ),
        ]),
        _vm.section == "sale"
          ? _c("div", { staticClass: "listings listings" }, [
              _vm.listings.length
                ? _c(
                    "div",
                    { staticClass: "listing-wrap" },
                    [
                      _c(
                        "grid",
                        _vm._l(_vm.listings, function (item, index) {
                          return _c("card", {
                            key: index,
                            attrs: { item: item },
                          })
                        }),
                        1
                      ),
                      _vm.canLoadMoreListings
                        ? _c(
                            "div",
                            { staticClass: "loads-more" },
                            [
                              _c("btn", {
                                attrs: { label: "Load More" },
                                on: { click: _vm.loadMoreListings },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _c("div", { staticClass: "no-listings" }, [
                    _c("h2", [_vm._v("No Listings")]),
                  ]),
            ])
          : _vm._e(),
        _vm.section == "want"
          ? _c("div", { staticClass: "listings wants" }, [
              _vm.wants.length
                ? _c(
                    "div",
                    { staticClass: "listing-wrap" },
                    [
                      _c(
                        "grid",
                        _vm._l(_vm.wants, function (want, index) {
                          return _c("card", {
                            key: index,
                            attrs: { item: want },
                          })
                        }),
                        1
                      ),
                      _vm.canLoadMoreWants
                        ? _c(
                            "div",
                            { staticClass: "loads-more" },
                            [
                              _c("btn", {
                                attrs: { label: "Load More" },
                                on: { click: _vm.loadMoreWants },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _c("div", { staticClass: "no-listings" }, [
                    _c("h2", [_vm._v("No Want to buys")]),
                  ]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }