var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "option",
    {
      attrs: { "data-index": _vm.index },
      domProps: { value: _vm.optionValue },
    },
    [_vm._v(_vm._s(_vm.name))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }