var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user
    ? _c("div", { staticClass: "contact-prefs" }, [
        _c(
          "div",
          { staticClass: "btn-list" },
          [
            _vm.user.email && _vm.user.allowEmail
              ? _c("btn", { attrs: { href: _vm.emailLink, label: "Email" } })
              : _vm._e(),
            _vm.user.phone && _vm.user.allowPhone
              ? _c("btn", {
                  attrs: { href: _vm.textLink, label: "Text Message" },
                })
              : _vm._e(),
            _vm.user.phone && _vm.user.allowPhone
              ? _c("btn", { attrs: { href: _vm.phoneLink, label: "Call" } })
              : _vm._e(),
          ],
          1
        ),
        _vm.user.preferText
          ? _c("div", { staticClass: "notice" }, [
              _vm._v("Prefers Text Messages"),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }