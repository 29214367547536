<template lang='pug'>
.help-text {{ text }}
</template>

<script>

export default {
	props: {
		text: {
			type: String
		}
	}

}
</script>

<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'


.help-text
	margin 10px 0
	color rgba(white, .5)
	font-size 12px
	font-style italic

</style>