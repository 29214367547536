<template lang='pug'>

.when {{ formatDate }}

</template>

<script>
import { timeAgo } from '/src/services/helpers';

export default {
	name: 'when',
	props: {
		item: Object
	},

	computed: {
		formatDate: function() {
			return timeAgo(this.value);
		},

		value: function() {
			if(this.item.date) {
				return this.item.date;
			} else {
				return this.item.created_at;
			}
		}
	}
}

</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.when
	color lightblue
	font-style oblique
	margin 5px 0
	font-size 12px
	flex-shrink 0

</style>
