var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "badges", class: _vm.positionClasses }, [
    _vm.type == "region"
      ? _c(
          "div",
          [
            _vm._t("default"),
            _c("div", { staticClass: "badge", class: _vm.classes }, [
              _c("span", { domProps: { innerHTML: _vm._s(_vm.region) } }),
            ]),
          ],
          2
        )
      : _c(
          "div",
          [
            _vm._t("default"),
            _vm.isNew()
              ? _c("div", { staticClass: "badge new" }, [
                  _c("span", [_vm._v("Recently Added")]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "badge", class: _vm.classes }, [
              _c("span", { domProps: { innerHTML: _vm._s(_vm.page.type) } }),
            ]),
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }