<template lang="pug">

.delete-account
  modal(
    ref='modalDelete'
    v-on:pass='proceed()'
    typeToProceed,
    :stringMatch='user.email',
    :message='deleteMessage')

  p Warning: Deleting account will remove all user information, listings, etc.
  br

  btn(@click='deleteAccount()' color='red' type='outline' label='Delete Account')


</template>

<script>
import axios from 'axios';

export default {
  name: 'delete-account',
  
  props: {
    user: Object
  },

  data: function() {
    return {
      confirmDelete: false
    }
  },
  
  computed: {

    deleteMessage: function() {
      return 'Are you sure you want to delete your account? All listings will be deleted and will not be able to be restored.'
    }

  },
  
  methods: {
    deleteAccount: function() {
      this.$refs.modalDelete.openModal();
    },

    async proceed() {
  
      try {
        await axios.post(
          '/api/v1/user/delete', 
          { delete: true }, 
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          }
        );

        localStorage.removeItem('token');

        alert('Account Successfully Removed');

        this.$store.state.user = null;

        if (this.$route.path != '/') {
          this.$router.push('/');
        }

      } catch(err) {
        console.log('Something went wrong', err);
      }

    }
  }
}
</script>





