<template lang='pug'>
.card.signup-card

  img(src='/assets/logo-blue.svg')

  .message
    h3 Join Now
    p Create an account to contact this seller to get the part you need.

    .btn-list
      btn(to='/login' size='small' label='Login')
      btn(to='/register' size='small' label='Register')

</template>

<script>

export default {


  data: function() {
    return {
    }
	}
}

</script>

<style lang='stylus' scoped>

.signup-card
  overflow hidden
  position relative
  
  img
    position absolute
    top -50px
    left -120px
    height calc(100% + 100px)
    width auto
    transform rotate(10deg)

  .message
    padding 30px 30px 30px 300px

    h3
      font-family 'inter-bold'
      font-size 30px
      margin-bottom 5px

    p
      margin 0 0 20px 0

  @media(max-width:600px)
    img
      left -190px
      height 300px

    .message
      padding 20px 20px 20px 150px

  @media(max-width:400px)
    img
      left -250px
      
    .message
      padding 20px 0 20px 100px

      h3
        font-size 20px



</style>