var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row-timeline row-contents" },
    [
      _c("modal", {
        ref: "deleteModal",
        attrs: { message: "Confirm delete?" },
        on: { pass: _vm.proceedRemove },
      }),
      _c(
        "router-link",
        { staticClass: "img-area", attrs: { to: _vm.deepLink } },
        [
          _vm.item.gallery[0]
            ? _c("base-image", {
                attrs: { src: _vm.item.gallery[0].filename, bucket: "images" },
              })
            : _c("placeholder"),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "info-area" },
        [
          _c("div", {
            staticClass: "status",
            domProps: { innerHTML: _vm._s(_vm.item.status) },
          }),
          _c("likes-and-comments-bar", {
            attrs: { item: _vm.item, theme: "small", type: "timeline" },
          }),
          _vm.loggedInUser && _vm.mine
            ? _c("div", { staticClass: "actions" }, [
                _c(
                  "div",
                  { staticClass: "btn-list" },
                  [
                    _c("btn", {
                      attrs: {
                        color: "grey",
                        size: "small",
                        to: _vm.editLink,
                        label: "Edit",
                      },
                    }),
                    _c("btn", {
                      attrs: {
                        color: "red",
                        size: "small",
                        theme: "outline",
                        icon: "trash-2-icon",
                      },
                      on: {
                        clicked: function ($event) {
                          return _vm.remove()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }