<template lang="pug">

.card-group

	.image
		badges(:page='item' :region='region' absolute)
			.badge.type(v-if='typeBadge') {{ item.entry_type }}
		router-link(:to='deepLink')
			base-image(v-if='item.profile' :src='item.profile' bucket='groups')
			placeholder(v-else)
		profile-widget(:userId='item.owner' label='Admin')

	.info
		router-link(:to='deepLink')
			smart-title(:title='item.name')
		group-info(:item='item')
		group-metrics(:group='item')

</template>

<script>
import axios from 'axios';
import GroupMetrics from '/src/components/globals/groups/metrics'

export default {

	components: {
		GroupMetrics
	},

	props: {
		item: Object,
		typeBadge: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		deepLink: function() {
			return '/group/' + this.item.internal_id
		},
		created: function() {
			return new Date(this.item.created_at).toLocaleDateString("en-US");
		},
		region: function() {
			if(!this.item.region) {
				return false;
			}

			switch(this.item.region) {
				case 'nw':
					return 'NW';
				case 'sw':
					return 'SW';
				case 'mw':
					return 'MW';
				case 'ne':
					return 'NE';
				case 'se':
					return 'SE';
			}
		}
	},

	methods: {
		toggleActions: function () {
			if (!this.showActions) {
				this.showActions = true;
			} else {
				this.showActions = false;
			}
		},
		removeGroup: function () {
			this.$refs.deleteGroupModal.openModal();
		},

		async proceedRemoveGroup() {

			try {
				await axios.post(
					'/api/v1/group/delete',
					{
						internal_id: this.group.internal_id,
					},
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
							'Content-Type': 'application/json'
						}
					}
				);

				location.reload();

			} catch (err) {
				console.log('Something went wrong', err);
			}
		}
	}
}
</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.card-info p
	padding-top 5px
	font-style italic

.img-wrap
	position relative

</style>
