var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "no-content" },
    [
      _c("p", [_vm._v("Content Not Found")]),
      _c("btn", { attrs: { to: "/", label: "Go Home" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }