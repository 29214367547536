<template lang="pug">

div

  notice(
    v-if='notice'
    color='yellow'
    notice='Content has been flagged. A moderator will look into this and take action if nessesary.')
  
  btn(
    v-if='isLoggedIn'
    label='Flag as innapropriate' 
    icon='flag-icon'
    @click='flag' 
    color='yellow' 
    theme='outline' )

</template>

<script>
import axios from 'axios';

export default {
  
  name: 'error-message',

  props: {
    page: Object,
    type: String
  },

  data: function() {
    return {
      notice: false
    }
  },

  computed: {
    isLoggedIn: function() {
      return this.$store.state.user;
    }
  },

  methods: {
    flag: async function() {

      try {

        await axios.post(
          '/api/v1/common/flag', 
          { entryId: this.page.internal_id, type: this.type, title: this.page.title },
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          });

          // show the notice
          this.notice = true;
      }

      catch(err) {
        console.log('Something went wrong', err);
      }
    }
  }
}
</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

// common flag as inappropriate
.flag
  display flex
  color yellow
  align-items center
  
  span
    margin-left 5px
    cursor pointer

</style>