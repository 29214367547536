var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "metrics", class: _vm.classes }, [
    _c(
      "div",
      {
        staticClass: "metric",
        class: _vm.likeClasses,
        on: { click: _vm.likeThis },
      },
      [
        _c("heart-icon", { staticClass: "icon" }),
        _c("div", { staticClass: "count" }, [_vm._v(_vm._s(_vm.likes.length))]),
      ],
      1
    ),
    !_vm.scroll
      ? _c(
          "div",
          { staticClass: "metric", attrs: { to: _vm.deeplinkWithComments } },
          [
            _c("message-circle-icon", { staticClass: "icon" }),
            _c("div", { staticClass: "count" }, [
              _vm._v(_vm._s(_vm.commentLength)),
            ]),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "metric", on: { click: _vm.scrollToComments } },
          [
            _c("message-circle-icon", { staticClass: "icon" }),
            _c("span", [_vm._v(_vm._s(_vm.commentLength))]),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }