<template lang="pug">

  .advert-row
    modal(
      ref='deleteAdvertModal'
      v-on:pass='proceedRemoveAdvert'
      message='Confirm delete?')

    .image(v-if='ad.image.length')
      base-image(:src='ad.image[0].filename' bucket='images') 
    .text
      h3.title {{ ad.title }}
      p(v-if='ad.headline') {{ ad.headline }}

    .actions
      btn(color='grey' size='small' :to='editAdvertLink' label='edit')
      btn(color='red' theme='outline' icon='trash-2-icon' @click='removeAdvert')

</template>

<script>
import axios from 'axios';

export default {
  name: 'advert-row',
  props: {
    ad: Object
  },
  data: function() {
    return {}
  },
  computed: {
    editAdvertLink: function() {
      return '/ad/' + this.ad.internal_id + '/edit'
    }
  },
  methods: {
    removeAdvert: function() {
      this.$refs.deleteAdvertModal.openModal();
    },

    async proceedRemoveAdvert() {

      try {
        await axios.post(
          '/api/v1/advert/delete', 
          {
            internal_id: this.ad.internal_id,
            _id: this.ad._id
          }, 
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          }
        );

        location.reload();

      } catch(err) {
        console.log('Something went wrong', err);
      }
    },
  }
}
</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.advert-row
  position relative
  display block
  color white
  background color-card
  border-radius radius
  margin 10px 0
  padding 20px
  display flex
  align-items center

.actions
  display flex
  align-items center

  .btn
    margin-left 10px
  
.text
  flex-grow 1
  margin 0 10px

h3, p
  margin 0

.title
  margin-bottom 3px

</style>