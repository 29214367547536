var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "user-row" },
        [
          _c(
            "router-link",
            {
              staticClass: "link-wrap",
              attrs: { to: "/users/" + _vm.loadedUser.username },
            },
            [
              _c("div", { staticClass: "image" }, [
                this.loadedUser.profile
                  ? _c("div", { staticClass: "user", style: _vm.styles })
                  : _c("div", {
                      staticClass: "default",
                      staticStyle: {
                        "background-image": "url(/assets/logo.svg)",
                      },
                    }),
              ]),
              _c("div", { staticClass: "info" }, [
                _c("span", [_vm._v(_vm._s(_vm.loadedUser.username))]),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "btn-list" },
            [
              !_vm.noFollowUI
                ? _c(
                    "div",
                    [
                      !_vm.loggedInUser
                        ? _c("follow", {
                            attrs: { user: _vm.loadedUser, noUserName: "" },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._t("default"),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }