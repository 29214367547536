<template lang="pug">

.content(v-if='loaded')
  heading(heading='Wheels')
  wheels-all

</template>

<script>
import wheelsAll from '../components/wheels/all';

export default {
  
  components: {
    wheelsAll
  },

  metaInfo: {
    title: 'Wheels',
    meta: [
      { vmid: 'description', name: 'description', content: 'Wheel for sale, and wheels wanted.' },
      { vmid: 'og:title', property: 'og:title', content: 'Wheels - Partstash Classic Car Part Listings' },
    ]
  },
  
  mounted: function() {
    this.loaded = true;
  },
  
  data: function() {
    return {
      loaded: false
    }
  },

  methods: {
    setListings(url) {
      this.$refs.listings.setListings(url);
    }
  }
}
</script>


<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

</style>