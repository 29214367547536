<template lang='pug'>

.price(v-if='listing.price') ${{ numberWithCommas(listing.price) }}

</template>

<script>

export default {
	name: 'price',
	props: {
		listing: Object
	},

	methods: {
		numberWithCommas: function(x) {
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}
	}
}

</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.price
	display inline-block
	font-family font-bold
	padding 4px 10px
	border-radius 30px
	background #000
	z-index 10
	font-size 12px
	color #8BBF69

</style>
