var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loggedInUser
    ? _c(
        "form",
        {
          staticClass: "invite",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.inviteUser()
            },
          },
        },
        [
          _vm.messages.length
            ? _c(
                "div",
                { staticClass: "messages" },
                _vm._l(_vm.messages, function (message, index) {
                  return _c("span", { key: index }, [_vm._v(_vm._s(message))])
                }),
                0
              )
            : _vm._e(),
          _c("form-text", {
            attrs: {
              label: "Invite User via email",
              name: "email",
              type: "email",
              required: "",
            },
            model: {
              value: _vm.invite,
              callback: function ($$v) {
                _vm.invite = $$v
              },
              expression: "invite",
            },
          }),
          _c("btn", {
            attrs: { size: "small", type: "submit", label: "Invite" },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }