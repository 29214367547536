<template lang="pug">

.no-content
  p Content Not Found
  btn(to='/' label='Go Home')

</template>

<script>

export default {
  
  name: 'no-content',

}

</script>

<style lang='stylus' scoped>

.no-content
  text-align center
  padding 60px 10px
  color grey
  width 80%
  max-width 500px
  margin 80px auto
  border 1px solid light-grey
  border-radius 40px

  @media (max-width:mobile)
    padding-top 5px

    h2
      margin 40px auto
      padding 30px 10px

  p
    width 70%
    margin 40px auto 0 auto
    

</style>