var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "search-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.search.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "input-group combined" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.query,
                expression: "query",
              },
            ],
            ref: "searchInput",
            attrs: { id: "searchInput", type: "text", placeholder: "Search" },
            domProps: { value: _vm.query },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.query = $event.target.value
              },
            },
          }),
          _c("btn", {
            attrs: {
              type: "submit",
              theme: "inverted",
              size: "small",
              label: "Search",
            },
          }),
        ],
        1
      ),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "common" }, [
      _c("p", [_vm._v("Common Terms:")]),
      _c("p", { staticClass: "strong" }, [
        _vm._v("Porsche, Cylinder Head, etc..."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }