var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "overflow-wrap" }, [
    _vm.hasGallery
      ? _c("div", { staticClass: "bg-shade", style: _vm.bgStyle })
      : _vm._e(),
    _vm.loaded && _vm.listing
      ? _c("div", { staticClass: "want-banner" }, [
          _c("h3", [_vm._v("Want To Buy: " + _vm._s(_vm.title))]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "want-detail content narrow" }, [
      _vm.noWant
        ? _c(
            "div",
            { staticClass: "no-want" },
            [
              _c("p", [_vm._v("No Record Found")]),
              _c("btn", { attrs: { to: "/", label: "Go Home" } }),
            ],
            1
          )
        : _vm._e(),
      _vm.hidden
        ? _c("div", { staticClass: "hidden" }, [
            _vm.privateGroupListing
              ? _c("div", { staticClass: "private" }, [
                  _c("p", [_vm._v("This is a private listing for the group")]),
                  _c("a", { attrs: { href: _vm.groupUrl } }, [
                    _vm._v(_vm._s(_vm.groupName)),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.loaded && _vm.listing && !_vm.hidden
        ? _c(
            "div",
            [
              _vm.loaded && _vm.user.username
                ? _c(
                    "pane",
                    { ref: "paneMessage" },
                    [
                      _c("message-form", {
                        ref: "message",
                        attrs: { dataToFill: _vm.dataToFill, noChoice: "" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.user.username && _vm.yourListing
                ? _c("admin-bar", {
                    attrs: { item: _vm.listing, type: "want" },
                  })
                : _vm._e(),
              _c(
                "segment",
                { attrs: { transparent: "" } },
                [
                  _vm.hasGallery
                    ? _c("listing-gallery", { attrs: { item: _vm.listing } })
                    : _vm._e(),
                  _vm.privateGroupListing
                    ? _c("div", { staticClass: "private" }, [
                        _c("p", [
                          _vm._v("This is a private listing for the group"),
                        ]),
                        _c(
                          "a",
                          { attrs: { href: _vm.groupUrl } },
                          [
                            _c(
                              "btn",
                              { attrs: { color: "grey", size: "small" } },
                              [_vm._v(_vm._s(_vm.groupName))]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("likes-and-comments-bar", {
                    attrs: { item: _vm.listing, type: "want", scroll: "" },
                    on: { "scroll-to-comments": _vm.scrollToComments },
                  }),
                ],
                1
              ),
              _c(
                "segment",
                [
                  _c("h1", [_vm._v(_vm._s(_vm.title))]),
                  _c("time-ago", { attrs: { item: _vm.listing } }),
                  _c(
                    "segment-row",
                    [
                      _c("h3", [_vm._v("Willing to pay:")]),
                      _c("price", { attrs: { listing: _vm.listing } }),
                    ],
                    1
                  ),
                  _c("share", {
                    attrs: { id: _vm.listing.internal_id, type: "want" },
                  }),
                ],
                1
              ),
              _c(
                "segment",
                [
                  _c("profile-widget", {
                    attrs: { large: "", userId: _vm.listing.user_id },
                  }),
                ],
                1
              ),
              _vm.user.username && !_vm.yourListing
                ? _c(
                    "segment",
                    [
                      _c("h2", [_vm._v("User Actions")]),
                      _c(
                        "btn",
                        {
                          attrs: { size: "small" },
                          on: { click: _vm.sendMessage },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "Message " +
                                _vm._s(_vm.owner.username) +
                                " about this"
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.user.username && !_vm.yourListing
                ? _c("segment", [_c("signup-card")], 1)
                : _vm._e(),
              _vm.user
                ? _c(
                    "segment",
                    [
                      _c("h2", [_vm._v("Shipping/Pickup Info")]),
                      _c("segment-row", [
                        _c("h3", [_vm._v("Location")]),
                        _c("span", [_vm._v(_vm._s(_vm.user.cityState))]),
                      ]),
                      _vm.user.username
                        ? _c(
                            "segment-row",
                            [
                              _c("user-contact-prefs", {
                                attrs: { user: _vm.owner },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "segment",
                [
                  _vm.listing.partNumber
                    ? _c("segment-row", [
                        _c("h3", [_vm._v("Part Number")]),
                        _c("span", [_vm._v(_vm._s(_vm.listing.partNumber))]),
                      ])
                    : _vm._e(),
                  _vm.listing.body != "null"
                    ? _c("segment-row", [
                        _c("h3", [_vm._v("Additional Details")]),
                        _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.listing.body) },
                        }),
                      ])
                    : _vm._e(),
                  _c(
                    "segment-row",
                    [
                      _c("innapropriate", {
                        attrs: { page: _vm.listing, type: "want" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "segment",
                { ref: "comments", attrs: { id: "comments" } },
                [
                  _c("comments", {
                    attrs: { item: _vm.listing, type: "want" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }