<template lang='pug'>
segment

  loader(v-show='loading')

  segment-header
    h1 Create a new account

  form.form(
    ref='form'
    enctype='multipart/form-data'
    @submit.prevent='addUser')

    standard-message(v-if='error.length' type='error' :message='error')

    .loading-view(v-if='loading')

    form-text(
      label='Username'
      v-model="user.username"
      autocapitalize='off'
      name='username'
      required)

    form-text(
      label='First Name'
      v-model="user.firstName"
      name='firstname'
      required)

    form-text(
      label='Last Name'
      v-model="user.lastName"
      name='lastname'
      required)

    form-text(
      label='Password'
      v-model="user.password"
      name='password'
      type='password'
      required)

    form-text(
      label='Confirm Password'
      v-model="user.passwordConfirm"
      name='passwordConfirm'
      type='password'
      required)

    form-text(
      label='Email'
      v-model="user.email"
      name='email'
      autocapitalize='off'
      required)

    form-text(
      label='Zip Code'
      v-model="user.zip"
      name='zip'
      type='number'
      required)

    .input-group.gallery
      p Optional Profile Image
      p.small can be set up later in your garage

      .gallery-picker-wrap
        uploader(
          single
          type='profile'
          v-on:master-batch='onSelect')

    .actions
      button.cta.green(type='submit') Create Account
      br
      br
      p already have an account?
      btn(label='Login' size='small' color='light' to='/login')

</template>

<script>
import axios from 'axios';

export default {

  data() {
    return {
      error: '',
      user: {
        username: null,
        email: null,
        firstName: null,
        lastName: null,
        password: null,
        passwordConfirm: null,
        zip: null,
        profile: null
      },
      loading: false
    }
  },

  mounted: function() {
    this.$store.state.view = 'Register'
  },

  methods: {

    onSelect(files) {
      this.user.profile = files;
    },

    async addUser() {

      this.error = ''

      if(this.user.username.indexOf(' ') != -1) {
        this.error = 'no spaces in user name';
        return;
      }

      if(this.user.password.length < 5) {
        this.error = 'password must be at least 5 characters long';
        return;
      }

      if(this.user.password != this.user.passwordConfirm) {
        this.error = 'passwords must match';
        return;
      }

      if(this.user.email.indexOf('.') == -1 || this.user.email.indexOf('@') == -1) {
        this.error = 'please enter a valid email address';
        return;
      }

      this.loading = true;

      // make form data
      let formData = new FormData();
      formData.append('username', this.$sanitize(this.user.username));
      formData.append('email', this.$sanitize(this.user.email));
      formData.append('firstName', this.$sanitize(this.user.firstName));
      formData.append('lastName', this.$sanitize(this.user.lastName));
      formData.append('password', this.$sanitize(this.user.password));
      formData.append('zip', this.$sanitize(this.user.zip));

      if(this.user.profile) {
        // prepare file list
        let fileListArray = [];
        this.user.profile.forEach(function (item, index) {
          fileListArray.push(item.file);
        });

        // Create the file list
        for(var i = 0; i < fileListArray.length; i++) {
          formData.append('file', fileListArray[i]);
        }
      }


      try {

        let submission = await axios.post('/auth/signup', formData, {
          headers: { 'Content-Type' : 'multipart/form-data' }
        });

        this.setWithExpire('token', submission.data.token, 5);
        this.$store.state.user = this.user;
        setTimeout(() => {
          if (this.$route.path != '/garage') {
            this.$router.push('/garage');
          }
        }, 1000);
      }

      catch(err) {
        console.log('err', err.response.data.error);
        this.error = err.response.data.error;
        this.loading = false;
        window.scrollTo(0,0);
      }

    },

    setWithExpire(key, value, ttl) {
      var date = new Date();
      var futureStamp = date.setDate(date.getDate() + ttl);

      const item = {
        value: value,
        expiry: futureStamp,
      }
      localStorage.setItem(key, JSON.stringify(item));
    }
  },
  watch: {
    $route (to, from) {
			window.scrollTo(0,0);
		}
	}
}
</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'
@import '~/src/styles/forms.styl'
@import '~/src/styles/listing-forms.styl'

.input-group.select
  input-style()

</style>
