<template lang='pug'>

div(v-if='car && loaded')
  heading(
    :heading='heading'
    :user-action='action'
    user-label='< Back')

  car-record-form(new :car='car')

</template>

<script>
import CarRecordForm from '/src/components/garage/car-record-form';

export default {

  components: {
    CarRecordForm,
  },

	data() {
		return {
			car: null,
			loaded: false
		}
	},

	beforeMount: async function() {
    await this.fetchComponentData();
	},

	computed: {
		heading: function() {
			return 'Add Record for: ' + this.car.name;
		},
		action: function() {
			return '/garage/car/detail/' + this.car.internal_id
		}
	},

	methods: {
		async fetchComponentData() {

      this.car = {};

      await fetch('/api/v1/garage/detail/' + this.$route.params.id)
        .then(res => res.json())
        .then(car => {
          this.car = car;        
        });

			this.loaded = true;
		}
	}
}

</script>