<template lang='pug'>

.content-rows.message-rows

  .content
    h2 Inbox

    .action
      btn(to='/garage/message/add' label='New message')

    div(v-if='messages.length')
      message-row(
          v-for="message, index in messages" 
          :key="index" 
          :message="message"
          v-on:watch-remove='removeMessage')

    div(v-else)
      p.none No Messages in your inbox


  .content

    h2 Sent

    div(v-if='sent.length')
      message-row(
          v-for="message, index in sent" 
          :key="index" 
          sent
          :message="message"
          v-on:watch-remove='removeMessage')

    div(v-else)
      p.none No Sent Messages


</template>

<script>
import MessageRow from '../ui/message-row';

export default {

  components: {
    MessageRow
  },

  props: {
    messages: Array,
    sent: Array
  },

  data: function() {
    return { }
  },

  methods: {
    removeMessage() {
      console.log('remove message');
    }
  }
  
}

</script>

<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.content-rows
  text-align left

</style>