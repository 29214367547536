var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mounted-pane",
    {
      ref: "pane",
      attrs: { title: "Event Attendees" },
      on: {
        close: function ($event) {
          return _vm.close()
        },
      },
    },
    [
      _vm.loaded
        ? _c("div", { staticClass: "content-rows" }, [
            _c("div", { staticClass: "content" }, [
              _vm.page.attendees.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.page.attendees, function (user, index) {
                      return _c("user-row", {
                        key: index,
                        attrs: { userId: user },
                      })
                    }),
                    1
                  )
                : _c("div", [
                    _c("p", { staticClass: "none" }, [_vm._v("No Attendees")]),
                  ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }