var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "big-card",
      class: _vm.classes,
      on: { click: _vm.openPane },
    },
    [
      _c(
        "div",
        { staticClass: "wrap" },
        [
          _c("h2", [
            _c(
              "span",
              { staticClass: "icon-wrap" },
              [
                _vm.icon == "mail" ? _c("mail-icon") : _vm._e(),
                _vm.icon == "key" ? _c("key-icon") : _vm._e(),
                _vm.icon == "grid" ? _c("grid-icon") : _vm._e(),
                _vm.icon == "eye" ? _c("eye-icon") : _vm._e(),
                _vm.icon == "crosshair" ? _c("crosshair-icon") : _vm._e(),
                _vm.icon == "users" ? _c("users-icon") : _vm._e(),
                _vm.icon == "heart" ? _c("heart-icon") : _vm._e(),
                _vm.icon == "settings" ? _c("settings-icon") : _vm._e(),
                _vm.icon == "search" ? _c("search-icon") : _vm._e(),
                _vm.icon == "deleted" ? _c("x-icon") : _vm._e(),
                _vm.icon == "trash" ? _c("trash-2-icon") : _vm._e(),
                _vm.icon == "dollar" ? _c("dollar-sign-icon") : _vm._e(),
                _vm.icon == "alert" ? _c("alert-circle-icon") : _vm._e(),
                _vm.icon == "image" ? _c("image-icon") : _vm._e(),
                _vm.icon == "calendar" ? _c("calendar-icon") : _vm._e(),
              ],
              1
            ),
            _c("span", [_vm._v(_vm._s(_vm.title))]),
          ]),
          _vm.smallCount
            ? _c("span", { staticClass: "smallCount" }, [
                _vm._v(_vm._s(_vm.count)),
              ])
            : _vm._e(),
          !_vm.smallCount
            ? _c("span", { staticClass: "count" }, [_vm._v(_vm._s(_vm.count))])
            : _vm._e(),
          !_vm.noArrow
            ? _c(
                "div",
                { staticClass: "view" },
                [_c("corner-down-right-icon", { staticClass: "icon" })],
                1
              )
            : _vm._e(),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }