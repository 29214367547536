<template lang="pug">

.pane(:class='classes' :data-location='location')

  transition(name='fade')
    .overlay(v-show='open' @click='close')

  transition(:name='transitionType')
    .content(v-show='open')
      .pane-heading
        .close(v-if='!noClose && location == "right"' @click='close')
          arrow-left-icon(size='2x')

        .close.close-x(v-if='!noClose && location == "top"' @click='close')
          x-icon(size='2x')

        h2 {{ title }}

      .scrolling(ref='scroll')
        slot

</template>

<script>
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

export default {
  name: 'pane',
  props: {
    typeToProceed: Boolean,
    noClose: Boolean,
    title: String,
    blue: Boolean,
    location: {
      type: String,
      default: 'right'
    }
  },
  data: function() {
    return {
      open: false
    }
  },

  mounted: function() {
    disableBodyScroll(this.$refs.scroll);
    this.open = true;
  },

  computed: {
    classes: function() {
      if(this.blue) {
        return 'blue';
      }
    },
    transitionType: function() {
      if(this.location == 'right') {
        return 'pane'
      } else if(this.location == 'top') {
        return 'pane-top'
      }
    }
  },
  methods: {

    close() {
      enableBodyScroll(this.$refs.scroll);
      this.open = false;
      var scope = this;
      setTimeout(function() {
        scope.$el.remove();
      }, 500);
    },
    pass() {
      if(this.typeToProceed == false) {
        enableBodyScroll(this.$refs.scroll);
        this.close();
        this.$emit('pass');
        return;
      }
    }
  },

  watch: {
    $route() {
      this.close();
    }
  }
}
</script>


<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

pane-spacing = 20px

.pane

  .overlay
    expand()
    position fixed
    background rgba(#000, .8)
    backdrop-filter blur(6px)
    z-index 150

  .content
    position fixed
    background color-card-darker
    color color-text
    box-shadow()
    text-align center
    z-index 151
    margin 0


  .close-x
    position absolute
    right 20px
    top 25px

    @media (max-width:500px)
      top 10px

  &[data-location=right]
    .content
      text-align left
      top pane-spacing
      right pane-spacing
      width calc(100vw - 30px)
      height calc(100% - 40px)
      border-radius pane-spacing
      overflow hidden
      max-width 700px

      @media(max-width:mobile)
        top 0
        right 0
        left 0
        width 100vw
        height 100vh
        border-radius 0

  &[data-location=top]

    .overlay
      z-index 160 // above right pane if doubled up

    .content
      top pane-spacing
      right pane-spacing
      width calc(100vw - 40px)
      max-width 800px
      left 50%
      transform translateX(-50%)
      border-radius pane-spacing
      overflow hidden
      z-index 161 // above right pane if doubled up

      @media(max-width:mobile)
        top 0
        right 0
        left 0
        width 100vw
        border-radius 0
        transform translateX(0)

    .scrolling
      padding 0
      margin 0
      display flex
      align-items stretch
      justify-content flex-start
      flex-wrap wrap
      max-height 100vh
      overflow auto

      >>> .menu-link
        border none
        border-right 1px solid color-ui
        border-bottom 1px solid color-ui
        width calc(33.33333% - 0px)
        display flex
        justify-content center

        @media(max-width:tablet)
          width calc(50% - 0px)

        @media(max-width:mobile)
          width 100%

      >>> a:not('btn'), >>> .simulate-a
        width calc(25vw - 4px)
        height 180px
        display flex
        flex-wrap wrap
        justify-content center
        align-items center
        text-align center

        @media(max-width:700px)
          width calc(33vw - 3px)

        @media(max-width:500px)
          width calc(50vw - 2px)

        @media(max-width:mobile)
          width 100%
          height auto

          div
            display flex
            align-items center
            width 100%

            span
              flex-grow 1
              display block
              text-align left

        svg
         display block
         margin 0 auto 15px auto

         @media(max-width:mobile)
          margin 0 10px 0 0


  &.blue
    .content
      background primary

    .pane-heading
      background darken(primary, 30%)
      color white

  .pane-heading
    display flex
    align-items center
    padding 25px
    background color-bg
    color color-text

    h2
      font-size 24px
      flex-grow 1
      text-align left
      margin 0
      margin-left 5px

    @media (max-width:mobile)
      padding 12px

      h2
        font-size 20px

      >>> svg
        width 26px
        height auto
        margin-top 3px

  .scrolling
    max-height calc(100% - 80px)
    overflow auto
    margin-bottom 40px
    padding 0 0 35px 0

  .message
    max-width 600px
    margin 10px auto
    width 80%

  .string-match
    max-width 600px
    margin 0 auto
    width 80%

    p
      margin-bottom 10px

  .btn-list
    margin-top 20px
    display flex
    align-items center
    justify-content center

    &.long
      flex-wrap wrap

    .btn
      margin-right 10px
      margin-bottom 10px
      cursor pointer
      transition()

      &:hover
        transform scale(1.05)

  &.success
    .large
      font-size 2.5em

</style>
