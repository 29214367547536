<template lang="pug">

form.search-form(@submit.prevent='search')
  .input-group.combined
    input(ref='searchInput' id='searchInput' type='text' placeholder='Search' v-model='query')
    btn(type='submit' theme='inverted' size='small' label='Search')
  .common
    p Common Terms:
    p.strong Porsche, Cylinder Head, etc...

</template>

<script>

export default {
  name: 'search-form',

  props: {
    initial: String,
    focus: Boolean
  },

  data: function() {
    return {
      query: this.initial
    }
  },

  mounted: function() {
    if(this.focus) {
      this.$nextTick(() => {
				this.$refs.searchInput.focus();
			});
    }
  },

  methods: {
    search() {
      document.activeElement.blur();

			if(this.query.length) {
        this.$emit('closeSearch');
				this.$router.push('/search/' + this.query);
			} else {
				alert('enter a valid term to search');
			}
		}
  }
}
</script>

