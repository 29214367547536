<template lang='pug'>

.content.narrow

  pane(
    v-if='loaded'
    ref='paneMessage'
    v-on:pass='proceedMessage')
    message-form(:dataToFill='dataToFill' ref='reply')

  modal(
    ref='modalRemove'
    v-on:pass='proceedRemove'
    message='Confirm delete?')

  .no-listing(v-if='noMessage')
    p No Message Found
    btn(to='/' label='Go Home')

  .message(v-if='loaded && message')

    .top-messgae-area
      strong.pill.blue(v-if='sent') SENT
      segment-header
        h1 {{ message.title }}
        router-link.back(to='/garage') Back To Garage

    .meta
      p from: {{ message.from }}
      p on: {{ date }}

    .body
      h3(v-if='message.listing') Regarding Listing: {{ message.listing }}
      h3(v-if='message.car') Regarding Car: {{ message.car }}

      .wysiwyg(v-html='message.body')

    .btn-list
      btn(color='red' theme='outline' @click='removeMessage' label='Delete')
      btn(v-if='!sent' @click='reply' :label='messageFrom')
      

</template>

<script>
import axios from 'axios';
import MessageForm from '../../components/views/message-form';

export default {

  components: {
    MessageForm
  },

  beforeMount: async function () {
    await fetch('/api/v1/message/id/' + this.$route.params.id, {
      headers: {
        authorization: `Bearer ${JSON.parse(localStorage.token).value}`
      }
    })
      .then(res => res.json())
      .then(message => {

        if(message.noMessage) {
          this.noMessage = true;
        } else {
          this.message = message;
          this.dataToFill = message;
          this.loaded = true;

          this.$nextTick(function() {
            if(this.sent) {
              this.$store.state.view = 'Sent Message';
            } else {
              this.$store.state.view = 'Message';
            }
          });
        }
      });

    try {
      await axios.post(
        '/api/v1/message/inbox/read', 
        { message: this.message }, 
        {
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
            'Content-Type' : 'application/json'
          }
        }
      );
    } catch(err) {
      console.log('Something went wrong', err);
    } 
  },


  data() {
    return {
      loaded: false,
      message: false,
      noMessage: false,
      dataToFill: null,
      edactorConfig: {
        buttons: ['bold', 'italic', 'link', 'lists']
      },
    }
  },

  computed: {

    messageFrom: function() {
      return "Send message back to " + this.message.from;
    },

    endpoint: function() {
      if(this.sent) {
        return '/api/v1/message/sender/delete';
      } else {
        return '/api/v1/message/inbox/delete';
      }
    },

    sent() {
      
      if(this.$store.state.user.username == this.message.from) {
        return true;
      } else {
        return false;
      }
    },

    date: function() {
      return new Date(this.message.created_at).toLocaleDateString("en-US");
    }
    
  },

  methods: {

    reply() {
      this.$refs.reply.message.to = this.dataToFill.from;
      this.$refs.reply.recipient = this.dataToFill.from;

      this.$nextTick(function() {
        this.$refs.paneMessage.openPane();
      });
		},

    removeMessage() {
      this.$refs.modalRemove.openModal();
		},
    
		async proceedRemove() {
      try {
        await axios.post(
          this.endpoint, 
          { message: this.message }, 
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          }
        );

        this.$router.push('/garage');

      } catch(err) {
        console.log('Something went wrong', err);
      }

		},

    proceedMessage() {
      this.$router.push('/garage');
		},
  }
}

</script>

<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'

.body
  padding 40px
  border-radius 20px
  border 1px solid grey

.btn-list
  justify-content flex-end
  margin-top 20px

.top-messgae-area
  padding-top 30px

</style>