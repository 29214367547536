<template lang="pug">

.gallery
	image-placeholder

	div(v-if='item.gallery.length > 1')
		ssr-carousel(:slides-per-page='1' show-arrows)
			base-image(
				v-for='img, index in item.gallery'
				:key='index'
				:src='img.filename'
				:bucket='bucket')

	div(v-else)
		base-image(:src='singleFileName' :bucket='bucket')

</template>

<script>
import SsrCarousel from 'vue-ssr-carousel';

export default {
  name: 'listing-gallery',

components: { SsrCarousel },

	props: {
		item: Object,
		bucket: {
			type: String,
			default: null
		}
	},

	computed: {

		singleFileName: function () {
			return this.item.gallery[0].filename;
		}

	}

}
</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.gallery
	border-radius 20px
	overflow hidden
	position relative
	margin-bottom 20px

>>>
	.base-image
		z-index 1
		position relative

	.ssr-carousel-next-button, .ssr-carousel-back-button

		&:disabled
			opacity .3 !important

		@media(max-width:mobile)
			top auto
			bottom 0px

	.ssr-carousel-back-button
		@media(max-width:mobile)
			left auto
			right 60px

	.ssr-carousel-next-icon, .ssr-carousel-back-icon
		background primary
		opacity 1

		&:hover
			opacity 1


</style>
