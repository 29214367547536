var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mounted-pane",
    {
      ref: "pane",
      attrs: { title: "Your Groups" },
      on: {
        close: function ($event) {
          return _vm.close()
        },
      },
    },
    [
      _vm.loaded
        ? _c("div", { staticClass: "content-rows" }, [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "div",
                  { staticClass: "action" },
                  [
                    !_vm.public
                      ? _c("btn", {
                          attrs: { to: "/group/add", label: "New Group" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.totalPending > 0
                  ? _c(
                      "segment",
                      { attrs: { full: "", light: "" } },
                      [
                        _c("h3", [_vm._v("Membership Pending")]),
                        _c("paginated-groups", {
                          attrs: {
                            type: "pending",
                            user: _vm.user,
                            total: _vm.totalPending,
                            rows: "",
                            status: "pending",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.totalOwner > 0
                  ? _c(
                      "segment",
                      { attrs: { full: "", light: "" } },
                      [
                        _c("h3", [_vm._v("Groups You Created")]),
                        _c("paginated-groups", {
                          attrs: {
                            type: "created",
                            user: _vm.user,
                            total: _vm.totalOwner,
                            rows: "",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.totalAdmin > 0
                  ? _c(
                      "segment",
                      { attrs: { full: "", light: "" } },
                      [
                        _c("h3", [_vm._v("Groups you're an admin of")]),
                        _c("paginated-groups", {
                          attrs: {
                            type: "admin",
                            user: _vm.user,
                            total: _vm.totalAdmin,
                            rows: "",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.totalMember > 0
                  ? _c(
                      "segment",
                      { attrs: { full: "", light: "" } },
                      [
                        _c("h3", [_vm._v("Groups you're a member of")]),
                        _c("paginated-groups", {
                          attrs: {
                            type: "member",
                            user: _vm.user,
                            total: _vm.totalMember,
                            rows: "",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }