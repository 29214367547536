<template lang='pug'>
.garage.content.narrow(v-if='loaded')

	modal(
		ref='modalLogout'
		v-on:pass='proceedLogout'
		message='Logout?')

	modal-welcome(
		ref='modalWelcome'
		v-on:pass='proceedToGarage')

	//- pane(ref='messages' title='Your Messages')
	//-   message-rows(
	//-     :messages='messages'
	//-     :sent='sent')


	div
		heading(heading='Garage')
			router-link.public-profile(:to='profileDeeplink')
				span Public Profile
				external-link-icon(class='icon')

	.verify-email(v-if='!user.emailVerified')
		p Please verify your email address
		.btn-list
			btn(size='small' color='light' @click='resendEmailVerification') Resend
			btn(size='small' color='light' @click='reloadPage') Refresh if verified

	.big-cards(v-if='counts')

		//- Your Listings
		big-card(
			title='Notifications'
			theme='light'
			width='full'
			icon='alert'
			v-on:openPane='mountPane("notifications")')

			.dot(v-if='unreadNotices > 0')

		big-card(
			v-if='isAdminUser'
			title='Cascade Consulting'
			theme='lightblue'
			width='full'
			icon='eye'
			v-on:openPane='mountPane("treasures")')


		big-card(
			title='Your Timeline'
			theme='blue'
			width='full'
			icon='grid'
			v-on:openPane='mountPane("statuses")')

		//- Your Listings
		big-card(
			title='Your Listings'
			theme='blue'
			width='half'
			icon='grid'
			v-on:openPane='mountPane("listings")'
			:count='counts.listings')

		//- Your Listings
		big-card(
			title='Your Wants'
			theme='lightblue'
			width='half'
			icon='crosshair'
			v-on:openPane='mountPane("wants")'
			:count='counts.wants')

		//- Your Groups
		big-card(
			title='Your Groups'
			theme='dark'
			width='half'
			icon='users'
			v-on:openPane='mountPane("groups")'
			:count='counts.groupsCreated + counts.groupsMember + counts.groupsAdmin')

		//- Your Events
		big-card(
			title='Your Events'
			theme='dark'
			width='half'
			icon='calendar'
			v-on:openPane='mountPane("events")'
			:count='counts.events')

		//- Your Cars
		big-card(
			title='Your Cars'
			theme='dark'
			width='half'
			icon='key'
			v-on:openPane='mountPane("cars")'
			:count='counts.cars')

		//- Your Watch List
		big-card(
			title='Watchlist'
			theme='dark'
			width='half'
			icon='eye'
			v-on:openPane='mountPane("watches")'
			:count='user.watches.length')

		//- Followers
		big-card(
			title='Following'
			theme='dark'
			width='half'
			icon='heart'
			v-on:openPane='mountPane("following")'
			:count='user.following.length')

		//- Following
		big-card(
			title='Followers'
			theme='dark'
			width='half'
			icon='users'
			v-on:openPane='mountPane("followers")'
			:count='user.followers.length')

		//- //- Your Messages
		//- big-card(
		//-   title='Messages'
		//-   theme='light'
		//-   width='full'
		//-   icon='mail'
		//-   v-on:openPane='openPane("messages")')

		//- Your Saved Searches
		big-card(
			title='Saved Searches'
			theme='light'
			width='full'
			icon='search'
			v-on:openPane='mountPane("searches")'
			:count='user.savedSearches.length'
			smallCount)

		//- Account Settings
		big-card(
			title='Account Settings'
			theme='light'
			width='full'
			icon='settings'
			v-on:openPane='mountPane("settings")')

		//- Sold Listings
		big-card(
			title='Sold Listings'
			theme='green'
			width='half'
			icon='dollar'
			v-on:openPane='mountPane("sold")'
			:count='counts.sold'
			smallCount)

		//- Deleted Listings
		big-card(
			title='Deleted Listings'
			theme='red'
			width='half'
			icon='trash'
			v-on:openPane='mountPane("deleted")'
			:count='counts.deleted'
			smallCount)


	.bottom-actions
		h3(v-if='counts') {{ counts.sold }} items sold
		br
		br
		.btn-list
			btn(href='mailto:support@partstash.com' color='light' label='Contact Support')
			btn(@click='logout()' color='red' type='outline' label='Logout')

		invite

		p.version.bold Version 0.3.7
		p.version.date Last updated 5/31/23


</template>

<script>
import axios from 'axios';
import Invite from './ui/invite';
import ModalWelcome from './views/modal-welcome';
import ContentRows from './garage/content-rows';
import MessageRows from './garage/message-rows';
import PaginatedUserFeed from '/src/components/listing/paginated-user-feed';

// panes
import { mountOnBody } from '/src/services/helpers';
import PaneListings from '/src/components/panes/garage/listings';
import PaneWants from '/src/components/panes/garage/wants';
import PaneGroups from '/src/components/panes/garage/groups';
import PaneSold from '/src/components/panes/garage/sold';
import PaneDeleted from '/src/components/panes/garage/deleted';
import PaneSettings from '/src/components/panes/garage/settings';
import PaneFollowers from '/src/components/panes/garage/followers';
import PaneFollowing from '/src/components/panes/garage/following';
import PaneCars from '/src/components/panes/garage/cars';
import PaneWatches from '/src/components/panes/garage/watches';
import PaneSearches from '/src/components/panes/garage/searches';
import PaneNotifications from '/src/components/panes/garage/notifications';
import PaneEvents from '/src/components/panes/garage/events';
import PaneTreasures from '/src/components/panes/garage/treasures';
import PaneTimeline from '/src/components/panes/garage/timeline';

export default {
	components: {
		Invite,
		ModalWelcome,
		ContentRows,
		MessageRows,
		PaginatedUserFeed
	},
	data() {
		return {
			loggedIn: false,
			user: null,
			loaded: false,
			error: '',
			loading: false,
			listings: [],
			wants: [],
			// messages: [],
			sent: [],
			deleted: [],
			searches: [],
			groups: [],
			counts: null
		}
	},

	metaInfo: {
		title: 'Your Garage',
		meta: [
			{ vmid: 'description', name: 'description', content: 'Your garage, your place to manage all of your content.' },
			{ vmid: 'og:title', property: 'og:title', content: 'Garage - Partstash Classic Car Part Listings' },
		]
	},

	mounted: function() {
		if(this.$store.state.user) {
			this.loadUser();
			this.loggedIn = true;
		}
	},

	computed: {
		isAdminUser: function() {
			if(this.$store.state.user && this.$store.state.user.accountType == 'admin') {
				return true;
			} else {
				return false;
			}
		},

		profileDeeplink: function() {
			if(this.loggedIn) {
				return '/users/' + this.$store.state.user.username;
			}
		},

		unreadNotices: function() {
			let newNotices = this.$store.state.user.notices.filter(function(notice) {
				return notice.read == false;
			});

			return newNotices.length;
		}
	},

	methods: {

		loadUser() {
			fetch('/api/v1/user', {
				headers: {
					authorization: `Bearer ${JSON.parse(localStorage.token).value}`
				}
			}).then(res => res.json())
				.then(user => {
					this.user = user;
					this.searches = user.savedSearches;
					this.loaded = true;
					this.getUserCounts();

					// this.getUserMessages();
					// this.getUserSentMessages();

					this.$nextTick(function() {

						// un-comment this to force-show the welcome modal
						// this.$refs.modalWelcome.openModal();

						// only show the welcome modal on the first visit
						if(!user.welcomeMessage) {
							this.$refs.modalWelcome.openModal();
						}

					});

				});
		},

		// Get counts (like wants and listings)
		async getUserCounts() {

			fetch('/api/v1/user/counts', {
				headers: {
					authorization: `Bearer ${JSON.parse(localStorage.token).value}`
				}
			}).then(res => res.json())
				.then(counts => {
					this.counts = counts;
				});

		},

		// async getUserMessages() {

		//   fetch('/api/v1/message/user/messages', {
		//     headers: {
		//       authorization: `Bearer ${JSON.parse(localStorage.token).value}`
		//     }
		//   }).then(res => res.json())
		//     .then(messages => {
		//       this.messages = messages;
		//     });
		// },

		// async getUserSentMessages() {

		//   fetch('/api/v1/message/user/sent', {
		//     headers: {
		//       authorization: `Bearer ${JSON.parse(localStorage.token).value}`
		//     }
		//   }).then(res => res.json())
		//     .then(messages => {
		//       this.sent = messages;
		//     });
		// },

		async proceedToGarage() {
			try {
				await axios.post(
					'/api/v1/user/welcome-message-approved',
					{
						welcome: true
					},
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
							'Content-Type' : 'application/json'
						}
					}
				);


			} catch(err) {
				console.log('Something went wrong', err);
			}
		},

		logout() {
			this.$refs.modalLogout.openModal();
		},

		proceedLogout() {
			localStorage.removeItem('token');
			this.$store.state.user = {};
			this.$store.state.loggedIn = false;
			this.loggedIn = false;
			if (this.$route.path != '/') {
				this.$router.push('/');
			}
		},

		mountPane: async function(key) {

			let component = null;
			let data = {};

			switch (key) {
				case 'listings':
					component = PaneListings;
					data = { total: this.counts.listings }
					break;
				case 'wants':
					component = PaneWants;
					data = { total: this.counts.wants }
					break;
				case 'groups':
					component = PaneGroups;
					break;
				case 'sold':
					component = PaneSold;
					break;
				case 'deleted':
					component = PaneDeleted;
					break;
				case 'settings':
					component = PaneSettings;
					break;
				case 'cars':
					component = PaneCars;
					break;
				case 'watches':
					component = PaneWatches;
					data = { options: { watches: this.user.watches } }
					break;
				case 'followers':
					component = PaneFollowers;
					break;
				case 'following':
					component = PaneFollowing;
					break;
				case 'searches':
					component = PaneSearches;
					data = { data: this.searches }
					break;
				case 'notifications':
					component = PaneNotifications;
					data = { data: this.user.notices }
					break;
				case 'events':
					component = PaneEvents;
					break;
				case 'treasures':
					component = PaneTreasures;
					break;
				case 'statuses':
					component = PaneTimeline;
					break;

			}

			await mountOnBody(component, { parent: this, propsData: data })

		},

		resendEmailVerification: async function() {

			alert('Sending the verification email again');

			try {
				await axios.get(
					'/auth/verify/resend',
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`
						}
					}
				);
			} catch(err) {
				console.log('Something went wrong', err);
			}

		},

		reloadPage: function() {
			window.location.reload();
		}

	},
	watch: {
		// check for when vuex boots to determine if you're logged in
		'$store.state.user': function(to, from) {
			if(to.username) {

				if(!this.loggedIn) {
					this.loggedIn = true;
					this.loadUser();
				}

			} else {
				this.loggedIn = false;
			}
		}
	}
}
</script>


<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.dot
	width 20px
	height 20px
	background red
	border-radius 20px
	position absolute
	top -2px
	left -2px

.notice-labels
	p
		display flex
		align-items center

	.bold
		text-transform uppercase
		font-family font-bold
		margin-right 5px

.garage
	padding-bottom (gutter * 2)

.public-profile
	font-size 12px
	font-family font-bold
	display flex
	align-items center
	margin-right 15px
	color white
	position relative
	top 2px

	>>> .icon
		margin-left 5px
		height 20px
		width auto

.bottom-actions
	text-align center
	margin 30px 0 60px 0

	.btn-list
		justify-content center

.version
	font-family monospace

	&.bold
		font-weight bold
		margin-top 35px
		font-size 20px

	&.date
		font-size 12px
		font-style italic
		margin-top -14px
		letter-spacing .5px

.btn-list
	justify-content center


>>> .invite
	margin-left auto
	margin-right auto

.verify-email
	border 1px solid gold
	border-radius 7px
	margin 10px 20px
	padding 20px

	p
		margin 0 0 10px 0

	.btn-list
		justify-content flex-start



</style>
