<template lang="pug">

.comment(:class='classes' v-if='loaded')

  modal(
    ref='modalRemove'
    v-on:pass='proceedRemove'
    message='Are You Sure?')

  .removed(v-if='removed')
    p Comment Deleted

  profile-widget(ref='profile' :userId='commentUser.user_id')
  span.date {{ date(comment) }}
  p {{ comment.message }} 

  .voting.btn-list(v-if='!mine')
    div.up(@click='upvote' :class='upClasses')
      arrow-up-icon(class='icon')
      span.count(v-if='comment.upvotes.length') {{ comment.upvotes.length }}
    div.down(@click='downvote' :class='downClasses')
      arrow-down-icon(class='icon')
      span.count(v-if='comment.downvotes.length') {{ comment.downvotes.length }}
  
  .voting.btn-list.mine(v-else)
    div.up
      arrow-up-icon(class='icon')
      span.count(v-if='comment.upvotes.length') {{ comment.upvotes.length }}
    div.down
      arrow-down-icon(class='icon')
      span.count(v-if='comment.downvotes.length') {{ comment.downvotes.length }}
    btn(@click='remove') REMOVE

</template>

<script>
import axios from 'axios';

export default {
  name: 'comment',

  props: {
    item: Object
  },

  data: function () {
    return {
      removed: false,
      comment: null,
      loaded: false,
      commentUser: null
    }
  },

  mounted: function() {
    this.comment = this.item.comment; 
    this.commentUser = this.item.user;
    this.loaded = true;
  },

  computed: {

    isInUpvotes: function() {
      var userID = this.user.user_id;

      // see if you're in the upvotes
      if(this.comment.upvotes.indexOf(userID) != -1) {
        return true;
      } else {
        return false;
      }
    },

    isInDownvotes: function() {
      var userID = this.user.user_id;

      // see if you're in the upvotes
      if (this.comment.downvotes.indexOf(userID) != -1) {
        return true;
      } else {
        return false;
      }
    },

    upClasses: function() {
      if(this.isInUpvotes) { return 'active' }
    },

    downClasses: function() {
      if (this.isInDownvotes) { return 'active' }
    },
    
    user: function () {
      return this.$store.state.user;
    },

    mine: function() {
      if (this.commentUser.user_id == this.user.user_id) {
        return true;
      } else {
        return false;
      }
    },

    classes: function() {
      if (this.$store.state.user.username == this.commentUser.username) {
        return 'my-comment';
      }
    }
  },

  methods: {

    date: function (comment) {
      return new Date(comment.created_at).toLocaleDateString("en-US");
    },

    upvote: async function () {

      if(this.isInUpvotes) { return }

      try {

        var post = await axios.post(
          '/api/v1/comment/upvote',
          { comment_id: this.comment.internal_id },
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type': 'application/json'
            }
          }
        );

        this.comment.upvotes = post.data.doc.upvotes;
        this.comment.downvotes = post.data.doc.downvotes;

      } catch (err) {
        console.log('Something went wrong', err);
      }
    },

    downvote: async function () {

      if (this.isInDownvotes) { return }

      try {

        var post = await axios.post(
          '/api/v1/comment/downvote',
          { comment_id: this.comment.internal_id },
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type': 'application/json'
            }
          }
        );

        this.comment.upvotes = post.data.doc.upvotes;
        this.comment.downvotes = post.data.doc.downvotes;

      } catch (err) {
        console.log('Something went wrong', err);
      }
    },

    remove: function() {
      this.$refs.modalRemove.openModal();
    },

    proceedRemove: async function() {
      this.$refs.modalRemove.close();
      try {

        await axios.post(
          '/api/v1/comment/remove',
          { comment_id: this.comment.internal_id },
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type': 'application/json'
            }
          }
        );

        this.removed = true;

      } catch (err) {
        console.log('Something went wrong', err);
      }
    }
  }
}
</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.comment
  margin-bottom 20px 
  padding 10px
  background rgba(white, .05)
  border-radius radius
  position relative
  overflow hidden

  &.my-comment
    background rgba(green, .2)

>>> .profile-widget
  position relative

.date
  font-size 11px
  font-style italic
  display block
  padding-top 10px
  opacity .5

p
  margin 0
  padding 5px 0


.voting
  margin-top 10px

.up, .down
  padding 5px 10px
  border 1px solid white
  border-radius 5px
  display flex
  align-items center
  justify-content center
  cursor pointer
  margin-right 10px

  .mine &
    border none
    cursor default
    padding 5px

  .icon
    font-size 13px

  .count
    font-weight bold
    font-size 13px
    margin-left 4px

.up.active
  background green
  border 1px solid green
  cursor default

.down.active
  background red
  border 1px solid red
  cursor default

.removed
  expand()
  z-index 5
  background rgba(red, .5)
  color white
  display flex
  align-items center
  justify-content center

</style>



