<template lang="pug">

.gallery-picker

	h3(v-if='!single')
		span Images: &nbsp;
		span {{ state.length }}
		span.of &nbsp;of
		span &nbsp;10

	h3(v-else) Image

	//- Input Picker
	label(:for='id' v-if='showPicker')

		.add: plus-icon(class='icon')

		.file-input-wrap
			input(
				:required='required'
				:ref='ref'
				:id='id'
				type='file'
				accept="image/*"
				name='images'
				:multiple='!single'
				class='file-input',
				@input="onSelectFile")

	//- msg
	help-text(:text='helpText')

	//- Image Previews
	.previews(v-if='state.length')

		.preview(
			v-for='image, index in state'
			:key='index'
			v-if='!image.remove')

			.remove(@click='removeImage(index)')
				x-icon(class='icon')

			//- PATH (for loaded previews from s3)
			img.thumb(:src='image.path' v-if='image.path')

			//- FILE (for selected from picker)
			img.thumb(:src='image.result' v-if='image.result')


</template>

<script>

export default {
	name: 'gallery',

	props: {
		required: Boolean,
		initial: Array|String,
		single: Boolean,
		type: String,
		edit: Boolean,
		id: {
			type: String,
			default: 'file'
		},
		index: {
			type: String,
			default: '1'
		}

	},

	mounted: function () {
		var scope = this;
		setTimeout(function() {
			scope.setInitialMasterBatch();
		}, 2000);

	},

	data: function() {
		return {
			state: []
		}
	},

	computed: {
		ref: function() {
			return 'file-' + this.index;
		},

		helpText: function() {
			if(!this.single) {
				return 'Maximum 10 images';
			} else {
				return 'Single Image';
			}
		},

		showPicker: function() {
			if(this.single) {
				if(this.state.length < 1) {
					return true;
				} else {
					return false;
				}
			} else {
				if(this.state.length < 10) {
					return true;
				} else {
					return false;
				}
			}
		}
	},

	methods: {

		// Call this when you select NEW files from
		// the image picker, these are NOT uploaded to s3
		onSelectFile(e) {

			// try to prevent the reload
			e.preventDefault();

			// The selected files from the picker
			var files = e.target.files;

			// if single, clear batch
			if(this.single) {
				this.state = [];
			}

			// Check if total is > 10
			if(this.state.length + files.length > 10) {
				alert('only 10 images are allowed');
				return;
			}

			// Check if current selected is > 10
			if(files.length > 10) {
				alert('only 10 images are allowed');
				return;
			}

			// loop and show preview images
			for (let i = 0; i < files.length; i++) {
				let reader = new FileReader();
				let file = files[i];
				let rawFile = file;

				reader.onload = (file) => {

					// Add to master array
					this.state.push({
						new: true,
						remove: false,
						preview: true,
						result: file.target.result,
						file: rawFile,
						s3Path: null,
						filename: null
					});

				}

				reader.readAsDataURL(file);
			}

			// send the images array back to what called this
			this.$emit('master-batch', this.state, this.uniq);

		},

		removeImage(index) {

			if(this.single) {
				this.state = [];
				this.$emit('master-batch', this.state);
				return;
			}

			//find item in master batch by index
			var item = this.state[index];

			// if new, then just remove it?
			if(item.new) {
				this.state.splice(index, 1);
			}

			// If not, then change it's props
			item.remove = true;
			item.preview = false;

			this.$emit('master-batch', this.state);
		},

		reset() {
			this.state = [];
		},

		setInitialMasterBatch() {

			if(!this.initial) { return }

			if(Array.isArray(this.initial)) {
				var initialBatch = this.initial;
			} else {
				var initialBatch = [];
				initialBatch.push(this.initial);
			}

			var scope = this;

			initialBatch.forEach(function (item, index) {

				var subdir = ''
				if(scope.type == 'profile') { subdir = '-profiles' }
				if(scope.type == 'garage') { subdir = '-cars' }
				if(scope.type == 'group') { subdir = '-groups' }
				if(scope.type == 'gallery') { subdir = '-gallery' }
				if(scope.type == 'images') { subdir = '-images' }

				var previewImg = '';
				if(item.filename) {
					 previewImg = item.filename;
				} else {
					previewImg = item;
				}


				var img = '//partstash' + subdir + '.imgix.net/' + previewImg + '?auto=format&q=100&width=500';

				scope.state.push({
					new: false,
					remove: false,
					file: null,
					path: img,
					preview: true,
					filename: item
				});

			});
		}
	}
}

</script>

<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.add
	w = 100px
	// background-color rgba(white, .04)
	width w
	height w
	border-radius w
	flex-center()
	margin 0 auto

	>>> svg
		transform scale(2)

.of
	font-size 16px
	font-family font-main

label
	border-radius 20px
	display block
	padding 40px
	text-align center
	background-image url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='grey' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='3' stroke-linecap='round'/%3e%3c/svg%3e")
	background-color rgba(white, .04)
	cursor pointer

.base-image-input
	height 100px
	width 100px
	border-radius radius
	overflow hidden
	background-color #eee
	margin-left 30px

	.image-holder
		height 100px
		width 100px
		background-size cover


.file-input-wrap
	display none

.previews
	display flex
	flex-wrap wrap
	margin-top 20px

	.preview
		margin-right 20px
		margin-bottom 20px
		position relative
		width 120px
		height auto

	img
		width auto
		height auto
		max-width 120px
		border-radius 5px
		overflow hidden
		border 1px solid #000
		box-shadow 0 0 10px rgba(#000, .2)

.remove
	width 23px
	height 23px
	position absolute
	top -8px
	right -8px
	background red
	color #eee
	font-size 11px
	display flex
	align-items center
	justify-content center
	border-radius 9999px
	cursor pointer
	transition all .2s ease-out
	padding 3px
	box-shadow 0 0 10px rgba(#000, .2)

	&:hover
		color white
		background-color lighten(red, 10%)

</style>
