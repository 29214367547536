<template lang='pug'>

div(v-if='loaded')
  heading(:heading='heading')

  form-component(new cta='Event')

</template>

<script>
import FormComponent from './form';

export default {

  components: {
    FormComponent,
  },

	mounted: function() {
		this.loaded = true;
	},

  data() {
    return {
      loaded: false
    }
  },

  computed: {
    heading: function() {
      return 'New Event';
    }
  }
}

</script>
