import { render, staticRenderFns } from "./base-image.vue?vue&type=template&id=5a6486a4&scoped=true&lang=pug&"
import script from "./base-image.vue?vue&type=script&lang=js&"
export * from "./base-image.vue?vue&type=script&lang=js&"
import style0 from "./base-image.vue?vue&type=style&index=0&id=5a6486a4&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a6486a4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/matthewaebersold/dev/psg/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5a6486a4')) {
      api.createRecord('5a6486a4', component.options)
    } else {
      api.reload('5a6486a4', component.options)
    }
    module.hot.accept("./base-image.vue?vue&type=template&id=5a6486a4&scoped=true&lang=pug&", function () {
      api.rerender('5a6486a4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/globals/base-image.vue"
export default component.exports