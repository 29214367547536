<template lang='pug'>

mounted-pane(ref='pane' title='User Comments' @close='close()')
	.content-rows(v-if='loaded')
		.content
			user-comment(
				v-for='comment, index in comments' 
				:key='index'
				:comment='comment')

</template>

<script>
import UserComment from '/src/components/globals/user-comment';

export default {

	components: { UserComment },

  props: {
    userId: String
  },

	data: function() {
    return {
      loaded: false,
			comments: []
    }
  },

	mounted: async function() {
		await this.fetchData();
		this.loaded = true;
	},

	methods: {
		
		fetchData: function() {
			
			fetch('/api/v1/comment/getAllFromUser/' + this.userId)
      .then(res => res.json())
      .then(comments => {
        this.comments = comments.comments
      });
			
		}
	}
  
}

</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'



</style>