<template lang='pug'>

mounted-pane(ref='pane' title='🗑 Confirm Delete' location='top' @close='close()')
	.content-rows(v-if='loaded')
		.content
			p {{ message }}

			.btn-list
				btn(@click='confirm()' size='small' label='Confirm')
				btn(color='grey' @click='cancel()' size='small' label='Cancel')


</template>

<script>

export default {

	props: {
		message: String,
		parentEl: Object
	},

	data: function() {
		return {
			loaded: false
		}
	},

	mounted: async function() {
		this.loaded = true;
	},

	methods: {
		confirm: function() {
			this.parentEl.remove();
			this.close();
		},

		cancel: function() {
			this.close();
		},

		close: function() {
			this.$refs.pane.close();
		}

	}

}

</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.content
	padding-bottom 20px
	padding-left 20px
	padding-right 20px
	width 100%
	text-align left

</style>
