import Vue from 'vue';
import {
  ExternalLinkIcon,
  ChevronRightIcon,
  PlusIcon,
  SearchIcon,
  GridIcon,
  ToolIcon,
  HomeIcon,
  MoreHorizontalIcon,
  LogOutIcon,
  UserIcon,
  UsersIcon,
  BookmarkIcon,
  TargetIcon,
  MoreVerticalIcon,
  XIcon,
  ImageIcon,
  ChevronLeftIcon,
  ChevronDownIcon,
  FlagIcon,
  DollarSignIcon,
  MessageCircleIcon,
  HeartIcon,
  Trash2Icon,
  CornerDownRightIcon,
  MailIcon,
  KeyIcon,
  EyeIcon,
  EyeOffIcon,
  CrosshairIcon,
  SettingsIcon,
  ArrowUpIcon,
  ArrowLeftIcon,
  ArrowDownIcon,
  CheckIcon,
  AlertCircleIcon,
	CalendarIcon,
  FolderIcon } from 'vue-feather-icons';

Vue.component('alert-circle-icon', AlertCircleIcon);
Vue.component('arrow-up-icon', ArrowUpIcon);
Vue.component('arrow-down-icon', ArrowDownIcon);
Vue.component('arrow-left-icon', ArrowLeftIcon);
Vue.component('check-icon', CheckIcon);
Vue.component('corner-down-right-icon', CornerDownRightIcon);
Vue.component('mail-icon', MailIcon);
Vue.component('key-icon', KeyIcon);
Vue.component('eye-icon', EyeIcon);
Vue.component('eye-off-icon', EyeOffIcon);
Vue.component('crosshair-icon', CrosshairIcon);
Vue.component('settings-icon', SettingsIcon);
Vue.component('folder-icon', FolderIcon);
Vue.component('message-circle-icon', MessageCircleIcon);
Vue.component('heart-icon', HeartIcon);
Vue.component('x-icon', XIcon);
Vue.component('image-icon', ImageIcon);
Vue.component('chevron-left-icon', ChevronLeftIcon);
Vue.component('chevron-down-icon', ChevronDownIcon);
Vue.component('flag-icon', FlagIcon);
Vue.component('dollar-sign-icon', DollarSignIcon);
Vue.component('trash-2-icon', Trash2Icon);
Vue.component('external-link-icon', ExternalLinkIcon);
Vue.component('chevron-right-icon', ChevronRightIcon);
Vue.component('plus-icon', PlusIcon);
Vue.component('search-icon', SearchIcon);
Vue.component('grid-icon', GridIcon);
Vue.component('tool-icon', ToolIcon);
Vue.component('home-icon', HomeIcon);
Vue.component('more-horizontal-icon', MoreHorizontalIcon);
Vue.component('log-out-icon', LogOutIcon);
Vue.component('user-icon', UserIcon);
Vue.component('users-icon', UsersIcon);
Vue.component('bookmark-icon', BookmarkIcon);
Vue.component('target-icon', TargetIcon);
Vue.component('more-vertical-icon', MoreVerticalIcon);
Vue.component('calendar-icon', CalendarIcon);
