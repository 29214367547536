var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.to && !_vm.outside
        ? _c(
            "router-link",
            { staticClass: "btn", class: _vm.classes, attrs: { to: _vm.to } },
            [
              _vm.icon
                ? _c("btn-icon", { attrs: { icon: _vm.icon } })
                : _vm._e(),
              _vm._t("default"),
              _vm.label
                ? _c("span", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.label)),
                  ])
                : _vm._e(),
            ],
            2
          )
        : _vm.to && _vm.outside
        ? _c(
            "a",
            {
              staticClass: "btn",
              class: _vm.classes,
              attrs: {
                href: _vm.to,
                disabled: _vm.disabled,
                type: _vm.type,
                target: "blank",
              },
              on: { click: _vm.click },
            },
            [
              _vm.icon
                ? _c("btn-icon", { attrs: { icon: _vm.icon } })
                : _vm._e(),
              _vm._t("default"),
              _vm.label
                ? _c("span", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.label)),
                  ])
                : _vm._e(),
            ],
            2
          )
        : !_vm.to && !_vm.href
        ? _c(
            "div",
            {
              staticClass: "btn",
              class: _vm.classes,
              attrs: { disabled: _vm.disabled },
              on: { click: _vm.click },
            },
            [
              _vm.icon
                ? _c("btn-icon", { attrs: { icon: _vm.icon } })
                : _vm._e(),
              _vm._t("default"),
              _vm.label
                ? _c("span", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.label)),
                  ])
                : _vm._e(),
            ],
            2
          )
        : _vm.href
        ? _c(
            "a",
            {
              staticClass: "btn",
              class: _vm.classes,
              attrs: {
                target: "blank",
                href: _vm.href,
                disabled: _vm.disabled,
                type: _vm.type,
              },
              on: { click: _vm.click },
            },
            [
              _vm.icon
                ? _c("btn-icon", { attrs: { icon: _vm.icon } })
                : _vm._e(),
              _vm._t("default"),
              _vm.label
                ? _c("span", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.label)),
                  ])
                : _vm._e(),
            ],
            2
          )
        : _c(
            "button",
            {
              staticClass: "btn",
              class: _vm.classes,
              attrs: { disabled: _vm.disabled, type: _vm.type },
              on: { click: _vm.click },
            },
            [
              _vm.icon
                ? _c("btn-icon", { attrs: { icon: _vm.icon } })
                : _vm._e(),
              _vm._t("default"),
              _vm.label
                ? _c("span", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.label)),
                  ])
                : _vm._e(),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }