<template lang="pug">

div(v-if='loaded')
	div.feed-all(v-if='data.length > 0')
		card(v-for="item, index in data"
			:key="index"
			:item="item"
			typeBadge)

		.loads-more
			btn(@click='loadMore' v-if='canFetchMore' label='load more')

	div(v-else)
		.no No Data

</template>

<script>
import { fetchData } from '/src/services/helpers';

export default {

	name: 'feed-all',

	props: {
		endpoint: String
	},

	data() {
		return {
			data: [],
			loaded: false,
			index: 0,
			mounted: false,
			canFetchMore: true
		}
	},

	mounted: async function() {
		this.data = await fetchData(this.endpoint + '/' + this.index);
		this.loaded = true;

		// TODO: This is kinda ugly, but prevents the data
		// from being fetched twice on route change
		let scope = this;
		setTimeout(function() {
			scope.mounted = true;
		}, 1000);
	},

	computed: {
		loggedInUser: function() { return this.$store.state.user; },
	},

	methods: {
		loadMore: function() {
			this.index++;
			this.fetchData();
		},

		fetchData: async function() {
			let newData = await fetchData(this.endpoint + '/' + this.index);
			let data = this.data;

			// deal with the load more button
			// TODO: would be nice to pre-empt this
			if(newData.length <= 1) { this.canFetchMore = false; }

			// loop through new data and add it to the feed
			newData.forEach(function(item) {
				data.push(item);
			});

		}
	},

	watch: {

		'$store.state.user': async function(to, from) {

			if(this.mounted) {
				this.loaded = false;
				this.data = await fetchData(this.endpoint + '/0');
				this.loaded = true;
			}

		}
	}

}
</script>


<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.feed-all
	margin 10px auto
	width calc(100% - 20px)
	display flex
	align-items stretch
	flex-wrap wrap

	>>> .card
		width calc(50% - 20px)
		margin 10px

		@media(min-width:tablet-landscape)
			width calc(33.3333% - 20px)

	@media(max-width:500px)
		width 100%

		>>> .card
			width calc(50% - 10px)
			margin 5px

</style>
