<template lang='pug'>
.content.narrow
  loader(v-show='loading')

  segment
    segment-header
      h1 Login to Your Account

    form.form(
      ref='form'
      enctype='multipart/form-data'
      @submit.prevent='login')

      standard-message(v-if='error.length' type='error' :message='error')

      form-text(
        v-model='user.login'
        label='Email or Username'
        name='login'
        required
        autocomplete='username')

      form-text(
        v-model='user.password'
        label='Password'
        name='password'
        type='password'
        required
        autocomplete='current-password')

      .actions
        button.cta.green(type='submit') Login
        br
        br
        p Don't have an account?
        btn(label='Register' size='small' color='light' to='/register')

</template>

<script>
import axios from 'axios';

export default {

  data() {
    return {
      error: '',
      user: {
        login: null,
        password: null
      },
      loading: false
    }
  },

  mounted: function() {
    this.$store.state.view = 'Login'
  },

  computed: {
    token: function() {
      if(localStorage.token) {
        return JSON.parse(localStorage.token).value;
      } else {
        return '';
      }
    }
  },

  methods: {

    async login() {
      this.error = '';
      this.loading = true;

      var sanitizedUser = {
        login: this.$sanitize(this.user.login),
        password: this.$sanitize(this.user.password)
      }

      try {
        let submission = await axios.post('/auth/login', sanitizedUser);

        this.setWithExpire('token', submission.data.token, 5);

        // Set the user
        await fetch('/api/v1/user', {
          headers: {
            authorization: `Bearer ${this.token}`
          }
        }).then(res => res.json())
          .then(user => {
            this.$store.state.user = user;

            setTimeout(() => {
              this.loggingIn = false;
              if (this.$route.path != '/garage') {
                this.$router.push('/garage');
              }
            }, 1000);
          });
      }

      catch(err) {
        this.error = err.response.data.error;
        this.loading = false;
      }
    },
    setWithExpire(key, value, ttl) {
      var date = new Date();
      var futureStamp = date.setDate(date.getDate() + ttl);

      const item = {
        value: value,
        expiry: futureStamp,
      }
      localStorage.setItem(key, JSON.stringify(item));
    }
  },
  watch: {
    $route (to, from) {
			window.scrollTo(0,0);
		}
	}
}
</script>

<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'
@import '~/src/styles/forms.styl'
@import '~/src/styles/listing-forms.styl'

.input-group.select
  input-style()


</style>