var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content narrow" },
    [
      _vm.loaded
        ? _c(
            "pane",
            { ref: "paneMessage", on: { pass: _vm.proceedMessage } },
            [
              _c("message-form", {
                ref: "reply",
                attrs: { dataToFill: _vm.dataToFill },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("modal", {
        ref: "modalRemove",
        attrs: { message: "Confirm delete?" },
        on: { pass: _vm.proceedRemove },
      }),
      _vm.noMessage
        ? _c(
            "div",
            { staticClass: "no-listing" },
            [
              _c("p", [_vm._v("No Message Found")]),
              _c("btn", { attrs: { to: "/", label: "Go Home" } }),
            ],
            1
          )
        : _vm._e(),
      _vm.loaded && _vm.message
        ? _c("div", { staticClass: "message" }, [
            _c(
              "div",
              { staticClass: "top-messgae-area" },
              [
                _vm.sent
                  ? _c("strong", { staticClass: "pill blue" }, [_vm._v("SENT")])
                  : _vm._e(),
                _c(
                  "segment-header",
                  [
                    _c("h1", [_vm._v(_vm._s(_vm.message.title))]),
                    _c(
                      "router-link",
                      { staticClass: "back", attrs: { to: "/garage" } },
                      [_vm._v("Back To Garage")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "meta" }, [
              _c("p", [_vm._v("from: " + _vm._s(_vm.message.from))]),
              _c("p", [_vm._v("on: " + _vm._s(_vm.date))]),
            ]),
            _c("div", { staticClass: "body" }, [
              _vm.message.listing
                ? _c("h3", [
                    _vm._v("Regarding Listing: " + _vm._s(_vm.message.listing)),
                  ])
                : _vm._e(),
              _vm.message.car
                ? _c("h3", [
                    _vm._v("Regarding Car: " + _vm._s(_vm.message.car)),
                  ])
                : _vm._e(),
              _c("div", {
                staticClass: "wysiwyg",
                domProps: { innerHTML: _vm._s(_vm.message.body) },
              }),
            ]),
            _c(
              "div",
              { staticClass: "btn-list" },
              [
                _c("btn", {
                  attrs: { color: "red", theme: "outline", label: "Delete" },
                  on: { click: _vm.removeMessage },
                }),
                !_vm.sent
                  ? _c("btn", {
                      attrs: { label: _vm.messageFrom },
                      on: { click: _vm.reply },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }