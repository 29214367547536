import { render, staticRenderFns } from "./pane.vue?vue&type=template&id=62dc30fc&scoped=true&lang=pug&"
import script from "./pane.vue?vue&type=script&lang=js&"
export * from "./pane.vue?vue&type=script&lang=js&"
import style0 from "./pane.vue?vue&type=style&index=0&id=62dc30fc&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62dc30fc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/matthewaebersold/dev/psg/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('62dc30fc')) {
      api.createRecord('62dc30fc', component.options)
    } else {
      api.reload('62dc30fc', component.options)
    }
    module.hot.accept("./pane.vue?vue&type=template&id=62dc30fc&scoped=true&lang=pug&", function () {
      api.rerender('62dc30fc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/panes/pane.vue"
export default component.exports