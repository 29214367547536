var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comment" },
    [
      _c("div", {
        staticClass: "message",
        domProps: { innerHTML: _vm._s(_vm.comment.message) },
      }),
      _c("div", [_vm._v(_vm._s(_vm.comment.type))]),
      _c("time-ago", { attrs: { item: _vm.comment } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }