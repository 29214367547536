var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "settings-form" },
    [
      _c(
        "segment",
        { attrs: { title: "Update Username", light: "" } },
        [
          _c("form-text", {
            attrs: { label: "Username", name: "username" },
            model: {
              value: _vm.user.username,
              callback: function ($$v) {
                _vm.$set(_vm.user, "username", $$v)
              },
              expression: "user.username",
            },
          }),
          _c(
            "div",
            { staticClass: "actions" },
            [
              _c("btn", {
                ref: "btnUsername",
                attrs: { label: "Update Username" },
                on: { click: _vm.updateUsername },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "segment",
        { attrs: { title: "Update Email Address", light: "" } },
        [
          _c("form-text", {
            attrs: { label: "Email Address", name: "email" },
            model: {
              value: _vm.user.email,
              callback: function ($$v) {
                _vm.$set(_vm.user, "email", $$v)
              },
              expression: "user.email",
            },
          }),
          _c(
            "div",
            { staticClass: "actions" },
            [
              _c("btn", {
                ref: "btnEmail",
                attrs: { label: "Update Email Address" },
                on: { click: _vm.updateEmail },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "segment",
        { attrs: { title: "Update Password", light: "" } },
        [
          _c("form-text", {
            ref: "curpass",
            attrs: {
              type: "password",
              label: "Current Password",
              name: "password",
            },
          }),
          _c("form-text", {
            ref: "newpass",
            attrs: {
              type: "password",
              label: "New Password",
              name: "password",
            },
          }),
          _c(
            "div",
            { staticClass: "actions" },
            [
              _c("btn", {
                ref: "btnPassword",
                attrs: { label: "Update Password" },
                on: { click: _vm.updatePassword },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "segment",
        { attrs: { title: "Contact Preferences", light: "" } },
        [
          _vm.contactNotice
            ? _c("notice", {
                attrs: {
                  color: "green",
                  notice: "Contact Preferences Updated",
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "radio-wrap checkmark" }, [
            _c("label", { attrs: { for: "allowEmail" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.allowEmail,
                    expression: "user.allowEmail",
                  },
                ],
                ref: "allowEmail",
                attrs: {
                  type: "checkbox",
                  name: "allowEmail",
                  value: "allowEmail",
                  id: "allowEmail",
                },
                domProps: {
                  checked: Array.isArray(_vm.user.allowEmail)
                    ? _vm._i(_vm.user.allowEmail, "allowEmail") > -1
                    : _vm.user.allowEmail,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.user.allowEmail,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "allowEmail",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.user, "allowEmail", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.user,
                            "allowEmail",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.user, "allowEmail", $$c)
                    }
                  },
                },
              }),
              _c(
                "span",
                { staticClass: "box" },
                [
                  _c("check-icon", { staticClass: "icon" }),
                  _c("span", [_vm._v("Show email publically")]),
                ],
                1
              ),
            ]),
            _c("label", { attrs: { for: "allowPhone" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.allowPhone,
                    expression: "user.allowPhone",
                  },
                ],
                ref: "allowPhone",
                attrs: {
                  type: "checkbox",
                  name: "allowPhone",
                  value: "allowPhone",
                  id: "allowPhone",
                },
                domProps: {
                  checked: Array.isArray(_vm.user.allowPhone)
                    ? _vm._i(_vm.user.allowPhone, "allowPhone") > -1
                    : _vm.user.allowPhone,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.user.allowPhone,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "allowPhone",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.user, "allowPhone", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.user,
                            "allowPhone",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.user, "allowPhone", $$c)
                    }
                  },
                },
              }),
              _c(
                "span",
                { staticClass: "box" },
                [
                  _c("check-icon", { staticClass: "icon" }),
                  _c("span", [_vm._v("Show phone publically")]),
                ],
                1
              ),
            ]),
            _c("label", { attrs: { for: "preferText" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.preferText,
                    expression: "user.preferText",
                  },
                ],
                ref: "preferText",
                attrs: {
                  type: "checkbox",
                  name: "preferText",
                  value: "preferText",
                  id: "preferText",
                },
                domProps: {
                  checked: Array.isArray(_vm.user.preferText)
                    ? _vm._i(_vm.user.preferText, "preferText") > -1
                    : _vm.user.preferText,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.user.preferText,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "preferText",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.user, "preferText", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.user,
                            "preferText",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.user, "preferText", $$c)
                    }
                  },
                },
              }),
              _c(
                "span",
                { staticClass: "box" },
                [
                  _c("check-icon", { staticClass: "icon" }),
                  _c("span", [_vm._v("Prefer text messages")]),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "actions" },
            [
              _c("btn", {
                ref: "btnContactPrefs",
                attrs: { label: "Save Contact Preferences" },
                on: { click: _vm.updateContactPrefs },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "segment",
        { attrs: { title: "Notifications", light: "" } },
        [
          _c("p", [
            _vm._v("Recieve email notifications for the following actions"),
          ]),
          _vm.notificationNotice
            ? _c("notice", {
                attrs: {
                  color: "green",
                  notice: "notification Preferences Updated",
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "radio-wrap checkmark" }, [
            _c("label", { attrs: { for: "listingNew" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.notifications.listingNew,
                    expression: "user.notifications.listingNew",
                  },
                ],
                ref: "listingNew",
                attrs: {
                  type: "checkbox",
                  name: "listingNew",
                  value: "listingNew",
                  id: "listingNew",
                },
                domProps: {
                  checked: Array.isArray(_vm.user.notifications.listingNew)
                    ? _vm._i(_vm.user.notifications.listingNew, "listingNew") >
                      -1
                    : _vm.user.notifications.listingNew,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.user.notifications.listingNew,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "listingNew",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.user.notifications,
                            "listingNew",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.user.notifications,
                            "listingNew",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.user.notifications, "listingNew", $$c)
                    }
                  },
                },
              }),
              _c(
                "span",
                { staticClass: "box" },
                [
                  _c("check-icon", { staticClass: "icon" }),
                  _c("span", [_vm._v("Listing Created")]),
                ],
                1
              ),
            ]),
            _c("label", { attrs: { for: "listingUpdated" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.notifications.listingUpdated,
                    expression: "user.notifications.listingUpdated",
                  },
                ],
                ref: "listingUpdated",
                attrs: {
                  type: "checkbox",
                  name: "listingUpdated",
                  value: "listingUpdated",
                  id: "listingUpdated",
                },
                domProps: {
                  checked: Array.isArray(_vm.user.notifications.listingUpdated)
                    ? _vm._i(
                        _vm.user.notifications.listingUpdated,
                        "listingUpdated"
                      ) > -1
                    : _vm.user.notifications.listingUpdated,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.user.notifications.listingUpdated,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "listingUpdated",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.user.notifications,
                            "listingUpdated",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.user.notifications,
                            "listingUpdated",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.user.notifications, "listingUpdated", $$c)
                    }
                  },
                },
              }),
              _c(
                "span",
                { staticClass: "box" },
                [
                  _c("check-icon", { staticClass: "icon" }),
                  _c("span", [_vm._v("Listing Updated")]),
                ],
                1
              ),
            ]),
            _c("label", { attrs: { for: "wantNew" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.notifications.wantNew,
                    expression: "user.notifications.wantNew",
                  },
                ],
                ref: "wantNew",
                attrs: {
                  type: "checkbox",
                  name: "wantNew",
                  value: "wantNew",
                  id: "wantNew",
                },
                domProps: {
                  checked: Array.isArray(_vm.user.notifications.wantNew)
                    ? _vm._i(_vm.user.notifications.wantNew, "wantNew") > -1
                    : _vm.user.notifications.wantNew,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.user.notifications.wantNew,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "wantNew",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.user.notifications,
                            "wantNew",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.user.notifications,
                            "wantNew",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.user.notifications, "wantNew", $$c)
                    }
                  },
                },
              }),
              _c(
                "span",
                { staticClass: "box" },
                [
                  _c("check-icon", { staticClass: "icon" }),
                  _c("span", [_vm._v("Want-Ad Created")]),
                ],
                1
              ),
            ]),
            _c("label", { attrs: { for: "wantUpdated" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.notifications.wantUpdated,
                    expression: "user.notifications.wantUpdated",
                  },
                ],
                ref: "wantUpdated",
                attrs: {
                  type: "checkbox",
                  name: "wantUpdated",
                  value: "wantUpdated",
                  id: "wantUpdated",
                },
                domProps: {
                  checked: Array.isArray(_vm.user.notifications.wantUpdated)
                    ? _vm._i(
                        _vm.user.notifications.wantUpdated,
                        "wantUpdated"
                      ) > -1
                    : _vm.user.notifications.wantUpdated,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.user.notifications.wantUpdated,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "wantUpdated",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.user.notifications,
                            "wantUpdated",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.user.notifications,
                            "wantUpdated",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.user.notifications, "wantUpdated", $$c)
                    }
                  },
                },
              }),
              _c(
                "span",
                { staticClass: "box" },
                [
                  _c("check-icon", { staticClass: "icon" }),
                  _c("span", [_vm._v("Want-Ad Updated")]),
                ],
                1
              ),
            ]),
            _c("label", { attrs: { for: "carNew" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.notifications.carNew,
                    expression: "user.notifications.carNew",
                  },
                ],
                ref: "carNew",
                attrs: {
                  type: "checkbox",
                  name: "carNew",
                  value: "carNew",
                  id: "carNew",
                },
                domProps: {
                  checked: Array.isArray(_vm.user.notifications.carNew)
                    ? _vm._i(_vm.user.notifications.carNew, "carNew") > -1
                    : _vm.user.notifications.carNew,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.user.notifications.carNew,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "carNew",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.user.notifications,
                            "carNew",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.user.notifications,
                            "carNew",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.user.notifications, "carNew", $$c)
                    }
                  },
                },
              }),
              _c(
                "span",
                { staticClass: "box" },
                [
                  _c("check-icon", { staticClass: "icon" }),
                  _c("span", [_vm._v("Garage Car Created")]),
                ],
                1
              ),
            ]),
            _c("label", { attrs: { for: "carUpdated" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.notifications.carUpdated,
                    expression: "user.notifications.carUpdated",
                  },
                ],
                ref: "carUpdated",
                attrs: {
                  type: "checkbox",
                  name: "carUpdated",
                  value: "carUpdated",
                  id: "carUpdated",
                },
                domProps: {
                  checked: Array.isArray(_vm.user.notifications.carUpdated)
                    ? _vm._i(_vm.user.notifications.carUpdated, "carUpdated") >
                      -1
                    : _vm.user.notifications.carUpdated,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.user.notifications.carUpdated,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "carUpdated",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.user.notifications,
                            "carUpdated",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.user.notifications,
                            "carUpdated",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.user.notifications, "carUpdated", $$c)
                    }
                  },
                },
              }),
              _c(
                "span",
                { staticClass: "box" },
                [
                  _c("check-icon", { staticClass: "icon" }),
                  _c("span", [_vm._v("Garage Car Updated")]),
                ],
                1
              ),
            ]),
            _c("label", { attrs: { for: "settingsChanged" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.notifications.settingsChanged,
                    expression: "user.notifications.settingsChanged",
                  },
                ],
                ref: "settingsChanged",
                attrs: {
                  type: "checkbox",
                  name: "settingsChanged",
                  value: "settingsChanged",
                  id: "settingsChanged",
                },
                domProps: {
                  checked: Array.isArray(_vm.user.notifications.settingsChanged)
                    ? _vm._i(
                        _vm.user.notifications.settingsChanged,
                        "settingsChanged"
                      ) > -1
                    : _vm.user.notifications.settingsChanged,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.user.notifications.settingsChanged,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "settingsChanged",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.user.notifications,
                            "settingsChanged",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.user.notifications,
                            "settingsChanged",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.user.notifications, "settingsChanged", $$c)
                    }
                  },
                },
              }),
              _c(
                "span",
                { staticClass: "box" },
                [
                  _c("check-icon", { staticClass: "icon" }),
                  _c("span", [_vm._v("User Settings Changed")]),
                ],
                1
              ),
            ]),
            _c("label", { attrs: { for: "newFollow" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.notifications.newFollow,
                    expression: "user.notifications.newFollow",
                  },
                ],
                ref: "newFollow",
                attrs: {
                  type: "checkbox",
                  name: "newFollow",
                  value: "newFollow",
                  id: "newFollow",
                },
                domProps: {
                  checked: Array.isArray(_vm.user.notifications.newFollow)
                    ? _vm._i(_vm.user.notifications.newFollow, "newFollow") > -1
                    : _vm.user.notifications.newFollow,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.user.notifications.newFollow,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "newFollow",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.user.notifications,
                            "newFollow",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.user.notifications,
                            "newFollow",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.user.notifications, "newFollow", $$c)
                    }
                  },
                },
              }),
              _c(
                "span",
                { staticClass: "box" },
                [
                  _c("check-icon", { staticClass: "icon" }),
                  _c("span", [_vm._v("User Followed You")]),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "actions" },
            [
              _c("btn", {
                ref: "btnNotificationPrefs",
                attrs: { label: "Save Notification Preferences" },
                on: { click: _vm.updateNotifications },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "segment",
        { attrs: { title: "Banner/Background Image", light: "" } },
        [
          _vm.bannerImageNotice
            ? _c("notice", {
                attrs: { color: "green", notice: "Banner Updated" },
              })
            : _vm._e(),
          _vm.bannerErrorNotice
            ? _c("notice", {
                attrs: { color: "red", notice: "Something Went Wrong" },
              })
            : _vm._e(),
          _c("image-updater", {
            attrs: { id: "banner", type: "user", item: _vm.user },
            on: {
              updated: function ($event) {
                return _vm.bannerUpdated()
              },
              error: function ($event) {
                return _vm.bannerError()
              },
            },
          }),
        ],
        1
      ),
      _c(
        "segment",
        { attrs: { title: "Profile Image", light: "" } },
        [
          _vm.profileImageNotice
            ? _c("notice", {
                attrs: { color: "green", notice: "Profile Updated" },
              })
            : _vm._e(),
          _vm.profileErrorNotice
            ? _c("notice", {
                attrs: { color: "red", notice: "Something Went Wrong" },
              })
            : _vm._e(),
          _c("image-updater", {
            attrs: { id: "profile", type: "user", item: _vm.user },
            on: {
              updated: function ($event) {
                return _vm.profileUpdated()
              },
              error: function ($event) {
                return _vm.profileError()
              },
            },
          }),
        ],
        1
      ),
      _c(
        "segment",
        { attrs: { title: "Update Information", light: "" } },
        [
          _vm.userInfoNotice
            ? _c("notice", {
                attrs: { color: "green", notice: "User Info Updated" },
              })
            : _vm._e(),
          _c("form-text", {
            attrs: { label: "First Name", name: "firstName" },
            model: {
              value: _vm.userData.firstName,
              callback: function ($$v) {
                _vm.$set(_vm.userData, "firstName", $$v)
              },
              expression: "userData.firstName",
            },
          }),
          _c("form-text", {
            attrs: { label: "Last Name", name: "lastName" },
            model: {
              value: _vm.userData.lastName,
              callback: function ($$v) {
                _vm.$set(_vm.userData, "lastName", $$v)
              },
              expression: "userData.lastName",
            },
          }),
          _c("form-text", {
            attrs: { label: "Phone", name: "phone" },
            model: {
              value: _vm.userData.phone,
              callback: function ($$v) {
                _vm.$set(_vm.userData, "phone", $$v)
              },
              expression: "userData.phone",
            },
          }),
          _c("form-text", {
            attrs: { label: "Zip Code", type: "number", name: "zip" },
            model: {
              value: _vm.userData.zip,
              callback: function ($$v) {
                _vm.$set(_vm.userData, "zip", $$v)
              },
              expression: "userData.zip",
            },
          }),
          _c("form-textarea", {
            attrs: { label: "Public Bio", name: "bio" },
            model: {
              value: _vm.userData.bio,
              callback: function ($$v) {
                _vm.$set(_vm.userData, "bio", $$v)
              },
              expression: "userData.bio",
            },
          }),
          _c(
            "div",
            { staticClass: "actions" },
            [
              _c("btn", {
                ref: "btnUserInfo",
                attrs: { label: "Save User Information" },
                on: { click: _vm.updateUserInfo },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "segment",
        { attrs: { title: "Warning", light: "", danger: "" } },
        [_c("delete-account", { attrs: { user: _vm.user } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }