<template lang="pug">

.profile-card
  .label {{ label }}
  .val {{ value }}

</template>

<script>

export default {
  name: 'profile-card',

  props: {
    label: String,
    value: [String, Number]
  }

}
</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.profile-card
  background rgba(#101010, .85)
  padding 15px
  border-radius radius

.label
  text-transform uppercase
  font-size 11px
  letter-spacing 1px
  margin-bottom 5px

.val
  font-size 26px
  font-weight bold

</style>