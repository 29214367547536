var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-want" }, [
    _c(
      "div",
      { staticClass: "image" },
      [
        _c("badges", { attrs: { page: _vm.item, absolute: "" } }, [
          _vm.typeBadge
            ? _c("div", { staticClass: "badge type" }, [
                _vm._v(_vm._s(_vm.item.entry_type)),
              ])
            : _vm._e(),
        ]),
        _c(
          "router-link",
          { attrs: { to: _vm.deepLink } },
          [
            _vm.item.gallery[0]
              ? _c("base-image", {
                  attrs: { src: _vm.item.gallery[0].filename },
                })
              : _c("placeholder"),
          ],
          1
        ),
        _c("profile-widget", { attrs: { userId: _vm.item.user_id } }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "info" },
      [
        _c(
          "router-link",
          { staticClass: "card-info", attrs: { to: _vm.deepLink } },
          [_c("smart-title", { attrs: { title: _vm.item.title } })],
          1
        ),
        _c("car-info", { attrs: { item: _vm.item } }),
        _c("time-ago", { attrs: { item: _vm.item } }),
        _vm.private
          ? _c("span", { staticClass: "private" }, [
              _vm._v("Private Want for this group"),
            ])
          : _vm._e(),
        !_vm.private
          ? _c("likes-and-comments-bar", {
              attrs: { item: _vm.item, type: "want" },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }