var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("div", [
        _c(
          "div",
          { staticClass: "content" },
          [_c("heading", { attrs: { center: "", heading: _vm.heading } })],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }