<template lang="pug">

.heading-wrap(:class='classes')
	h1 {{ heading }}

	btn(
		v-if='user.username && userAction && userLabel'
		size='small'
		:to='userAction'
		:label='userLabel')

	slot

</template>

<script>


export default {
	name: 'main-heading',

	props: {
		heading: String,
		userAction: String,
		userLabel: String,
		center: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		user: function() {
			return this.$store.state.user;
		},
		classes: function() {
			if(this.center) { return 'center' }
		}
	}
}
</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.heading-wrap
	display flex
	align-items center
	padding gutter 20px
	max-width 1200px
	margin 0 auto

	@media(max-width:mobile)
		padding gutter 10px

	&.center
		text-align center
		padding-top 30px
		padding-bottom 30px
		justify-content center

		h1
			display inline-flex
			width auto
			flex-grow 0
			position relative

		h1:before, h1:after
			content ''
			height 2px
			position absolute
			width 30px
			display block
			top calc(50% - 1px)
			border-radius 4px

		h1:before
			left -45px
			background linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)

		h1:after
			right -45px
			background linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)

h1
	flex-grow 1
	margin 0

</style>
