<template lang='pug'>

.info-badge(v-if='show')
	span(v-if='item.year') {{ item.year }}
	span(v-if='item.make') {{ item.make }}
	span(v-if='item.model') {{ item.model }}
	span(v-if='item.generation') {{ item.generation }}
	span(v-if='item.generation_from') {{ item.generation_from }}
	span(v-if='item.generation_to') {{ item.generation_to }}

</template>

<script>

export default {
	name: 'car-info',
	props: {
		item: Object
	},

	computed: {
		show: function() {
			if(this.item.year || this.item.make || this.item.model) {
				return true;
			} else {
				return false;
			}
		}
	}
}

</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

</style>
