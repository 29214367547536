<template lang="pug">

.content.narrow(v-if='loaded && isAdminUser')
  success-modal(
    ref='modalSuccess'
    title='Success'
    message='Ad Created'
    noClose
    advert)

  loader(v-show='loadingScreen')

  router-link(to='/admin') Back

  h1 New Ad

  segment
    form-text(
      v-model='ad.title'
      label='Title'
      name='title'
      required)

  segment
    form-text(
      v-model='ad.description'
      label='Description'
      name='description')

  segment
    form-text(
      v-model='ad.btnText'
      label='Btn Text'
      name='btnText')

  segment
    form-text(
      v-model='ad.url'
      label='Btn URL'
      name='url')

  segment
    form-text(
      v-model='ad.position'
      label='Position (number - higher = more prominent)'
      type='number'
      name='position'
      required)

  segment
    .input-group.checkbox
      input(type='checkbox'
        id='textDark'
        name='textDark'
        v-model='ad.textDark')

      label(for='textDark')
        .box
        span Dark Text?

  segment
    uploader(
      ref='gallery'
      single
      v-on:master-batch='onSelect')

  cta-wrap
    btn(
      color='green'
      ref='cta'
      @click='addAd'
      :disabled='addable == false'
      v-html='buttonText')


</template>

<script>
import axios from 'axios';

export default {
  name: 'ad-new',

  mounted: function() {
    this.loaded = true;
  },

  data: function() {
    return {
      loadingScreen: false,
      loaded: false,
      ad: {
        title: null,
        url: null,
        image: null,
        description: null,
        btnText: null,
        url: null,
        textDark: false,
        position: null
      }
    }
  },

  computed: {

    isDarkText: function() {
      if(this.ad.textDark) {
        return 'yes';
      } else {
        return 'no';
      }
    },

    isAdminUser: function() {
      if(_.isEmpty(this.$store.state.user)) {
        return false;
      } else {
        if(this.$store.state.user.accountType == 'admin') {
          return true;
        } else {
          window.location.href = '/';
        }
      }
    },

    addable: function() {
      if(this.ad.image && this.ad.url) {
        return true;
      } else {
        return false;
      }
    },

    buttonText: function() {
      return 'Add'
    }
  },

  methods: {
    onSelect(files) {
      this.ad.image = files;
    },

    addAd: async function() {

      this.loadingScreen = true;
      this.$refs.cta.classList.add('force-disabled');

      let formData = new FormData();

      formData.append('title', this.$sanitize(this.ad.title));
      formData.append('description', this.$sanitize(this.ad.description));
      formData.append('btn_text', this.$sanitize(this.ad.btnText));
      formData.append('url', this.$sanitize(this.ad.url));

      formData.append('darkText', this.isDarkText);

      // prepare file list
      formData.append('file', this.ad.image[0].file);

      try {

        let submission = await axios.post(
          '/api/v1/advert/add',
          formData,
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'multipart/form-data'
            }
          });

        let res = submission.data;
        this.resetView();
        this.$refs.modalSuccess.setContent(res.internal_id, res.title);
        this.$refs.modalSuccess.openModal();
        this.loadingScreen = false;

      }

      catch(err) {
        console.log('Something went wrong', err);
      }

    },

    resetView: function() {
      window.scrollTo(0,0);
      this.ad.title = '';
      this.ad.description = '';
      this.ad.btnText = '';
      this.ad.url = '';
      this.ad.image = null;
      this.$refs.gallery.reset();
      this.loadingScreen = false;
      this.$refs.cta.classList.remove('force-disabled');
    }
  }

}
</script>


<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

a
  color white

</style>
