<template lang="pug">

.modal-simple.welcome

  transition(name='fade')
    .overlay(v-show='open' @click='close')
  
  transition(name='slide')
    .content(v-show='open' ref='scroll')
      .close(@click='close')
        x-icon(class='icon')

      .flex-wrap
        logo
        h1 Welcome to Partstash
        hr
        p Some good next steps would be to:

        ul
          li Create your cars, so listings are easier and your feed can be populated with relevant listings
          li Follow some people to see their listings in your feed
          li Set up a profile photo
          li List some parts for sale
          li List some things you want to buy

        .btn-list
          btn(color='green' @click='pass' label='GOTCHA')
      
</template>

<script>
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

export default {
  name: 'modal-welcome',
 
  props: {
    message: String
  },
  
  data: function() {
    return {
      open: false
    }
  },
  
  methods: {

    openModal() {
      disableBodyScroll(this.$refs.scroll);
      this.open = true;
    },
    
    close() {
      enableBodyScroll(this.$refs.scroll);
      this.open = false;
      this.$emit('pass');      
    },

    pass() {
      this.close();
      this.$emit('pass');
    }

  }
}
</script>

<style lang='stylus' scoped>

h1
  width 100%

hr
  width 100px
  margin 0 auto

.logo
  padding 20px
  width 100px
  height 100px
  margin-bottom 20px
  border-radius 100px
  display flex
  align-items center
  justify-content center
  position relative
  background blue
  margin 10px auto

  >>> img
    width 60px
    height auto
    display block
    position relative
    left -3px
    top 2px

ul
  width 80%
  margin 10px auto

li
  text-align left
  margin-bottom 8px
  

</style>

