var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.theOne
    ? _c("div", { staticClass: "content narrow" }, [
        _c("h1", [_vm._v("NEO")]),
        _c("p", [_vm._v("Root user controls")]),
        _c("br"),
        _c("br"),
        _c("div", { staticClass: "btn", on: { click: _vm.addAdmins } }, [
          _vm._v("Add Admins"),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }