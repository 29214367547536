var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "overflow-wrap" }, [
    _vm.hasGallery
      ? _c("div", { staticClass: "bg-shade", style: _vm.bgStyle })
      : _vm._e(),
    _c(
      "div",
      { staticClass: "car-detail content narrow" },
      [
        _vm.loaded && _vm.page && !_vm.hideForPrivacy
          ? _c(
              "div",
              [
                _vm.loggedInUser && _vm.yourCar
                  ? _c("admin-bar", { attrs: { item: _vm.page, type: "car" } })
                  : _vm._e(),
                _c(
                  "segment",
                  { attrs: { transparent: "" } },
                  [
                    _vm.hasGallery
                      ? _c("listing-gallery", {
                          attrs: { item: _vm.page, bucket: "cars" },
                        })
                      : _vm._e(),
                    _vm.loggedInUser && _vm.yourCar
                      ? _c("badges", { attrs: { page: _vm.page } }, [
                          _c("div", { staticClass: "badge want" }, [
                            _vm._v("YOUR CAR"),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "segment",
                  [
                    _c("h1", [_vm._v(_vm._s(_vm.page.name))]),
                    _c("profile-widget", {
                      attrs: { large: "", userId: _vm.page.owner },
                    }),
                    _c("likes-and-comments-bar", {
                      attrs: { item: _vm.page, type: "car", scroll: "" },
                      on: { "scroll-to-comments": _vm.scrollToComments },
                    }),
                    _vm.loggedInUser && _vm.yourCar && _vm.page.is_private
                      ? _c("div", { staticClass: "pill blue large" }, [
                          _c("span", [
                            _vm._v("This is private, only you can see this"),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "segment",
                  { attrs: { title: "Car Information" } },
                  [
                    _c("car-info", { attrs: { item: _vm.page } }),
                    _c("br"),
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.page.body) },
                    }),
                    _c("br"),
                    _c("innapropriate", {
                      attrs: { page: _vm.page, type: "car" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "big-card-wrap" },
                  [
                    _vm.loggedInUser
                      ? _c("big-card", {
                          attrs: {
                            title: "Records",
                            theme: "dark",
                            width: "full",
                            icon: "grid",
                          },
                          on: {
                            openPane: function ($event) {
                              return _vm.mountPane("records")
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "segment",
                  { ref: "comments", attrs: { id: "comments" } },
                  [_c("comments", { attrs: { item: _vm.page, type: "car" } })],
                  1
                ),
                _c("segment", { attrs: { title: "Related Listings" } }, [
                  _vm.relatedListings.length > 0
                    ? _c(
                        "div",
                        _vm._l(_vm.relatedListings, function (row, index) {
                          return _c("row", { key: index, attrs: { item: row } })
                        }),
                        1
                      )
                    : _c("p", [_vm._v("No Related listings for this car")]),
                ]),
                _c("segment", { attrs: { title: "Related Want Ads" } }, [
                  _vm.relatedWants.length > 0
                    ? _c(
                        "div",
                        _vm._l(_vm.relatedWants, function (row, index) {
                          return _c("row", { key: index, attrs: { item: row } })
                        }),
                        1
                      )
                    : _c("p", [_vm._v("No Related want-ads for this car")]),
                ]),
                _c("segment", { attrs: { title: "Timeline" } }, [
                  _vm.relatedTimelines.length > 0
                    ? _c(
                        "div",
                        _vm._l(_vm.relatedTimelines, function (row, index) {
                          return _c("row", { key: index, attrs: { item: row } })
                        }),
                        1
                      )
                    : _c("p", [_vm._v("No status updates for this car")]),
                ]),
                _vm.relatedCars.length > 0
                  ? _c(
                      "segment",
                      { attrs: { title: _vm.otherCarsByUsername } },
                      _vm._l(_vm.relatedCars, function (item, index) {
                        return _c("row", { key: index, attrs: { item: item } })
                      }),
                      1
                    )
                  : _vm._e(),
                _c("segment", { attrs: { title: _vm.otherMakes } }, [
                  _vm.relatedMakes.length > 0
                    ? _c(
                        "div",
                        _vm._l(_vm.relatedMakes, function (row, index) {
                          return _c("row", {
                            key: index,
                            attrs: { item: row, showProfile: "" },
                          })
                        }),
                        1
                      )
                    : _c("p", [_vm._v("No Related Cars")]),
                ]),
              ],
              1
            )
          : _c("no-content"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }