var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "segment", class: _vm.classes }, [
    _vm.title ? _c("h3", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
    _c("div", [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }