var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "message-row" },
    [
      _c("modal", {
        ref: "modalRemove",
        attrs: { message: "Confirm delete?" },
        on: { pass: _vm.proceedRemove },
      }),
      _c(
        "div",
        { staticClass: "info-wrap" },
        [
          !_vm.sent && !_vm.message.read
            ? _c("div", { staticClass: "new" })
            : _vm._e(),
          _c(
            "router-link",
            {
              staticClass: "info",
              attrs: { to: "/garage/message/" + _vm.message.internal_id },
            },
            [
              _c("h3", [_vm._v(_vm._s(_vm.message.title))]),
              _vm.sent
                ? _c("p", { staticClass: "to-from" }, [
                    _vm._v("To: " + _vm._s(_vm.message.to)),
                  ])
                : _c("p", { staticClass: "to-from" }, [
                    _vm._v("From: " + _vm._s(_vm.message.from)),
                  ]),
              _c("p", { staticClass: "date" }, [_vm._v(_vm._s(_vm.date))]),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "listing-admin" }, [
        _c(
          "div",
          { staticClass: "btn-list" },
          [
            _c("btn", {
              attrs: {
                color: "red",
                theme: "outline",
                size: "small",
                icon: "trash-2-icon",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.removeMessage.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }