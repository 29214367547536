<template lang="pug">

.profile-widget(v-if='loaded' :class='classes')
	router-link.profile(:to='profileLink')
		.profile-image(:style='profile_styles')
	router-link.span.name(:to='profileLink')
		span.short(v-if='short') {{ truncate(name) }}
		span(v-else) {{ name }}

	.actions(v-if='large')
		follow(v-if='!loggedInUser' :user='user' noUserName)
		btn(size='small' color='grey' :to='profileLink' label='View Profile')

</template>

<script>
import axios from 'axios';

export default {
	name: 'profile-widget',

	mounted: function() {
		this.fetchUserData();
	},

	props: {
		userId: String,
		large: Boolean,
		loggedIn: Boolean,
		label: String,
		short: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			user: null,
			loaded: false
		}
	},


	computed: {

		name: function() {
			if(this.label) {
				return this.label + ': ' + this.user.username
			} else {
				return this.user.username;
			}
		},

		profileLink: function() {
			return '/users/' + this.user.username
		},

		profile_styles: function() {
			if(this.user.profile) {
				return 'background-image: url("//partstash-profiles.imgix.net/' + this.user.profile + '?auto=format&q=100&width=500");';
			} else {
				return 'background-image: url(/assets/img/default-dark.jpg);';
			}
		},

		isFollowing: function() {
			if(this.$store.state.user.following.indexOf(this.user.user_id) != -1) {
				return true;
			} else {
				return false;
			}
		},

		loggedInUser: function() {
			if(this.$store.state.user.user_id == this.userId) {
				return true;
			} else {
				return false;
			}
		},

		classes: function() {
			if(this.large) { return 'large' }
		}
	},

	methods: {

		truncate: function(name) {
			return name.substring(0, 10)  + '...'
		},

		fetchUserData: async function() {

			this.user = null;
			this.loaded = false;
			await fetch('/api/v1/profile/id/' + this.userId)
				.then(res => res.json())
				.then(user => {
					this.user = user;
				});
			this.loaded = true;
		},

		follow: async function() {
			try {

				await axios.post(
					'/api/v1/user/follow',
					{ user_id: this.userId },
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
							'Content-Type' : 'application/json'
						}
					});
					window.location.reload();
			}

			catch(err) {
				console.log('Something went wrong', err);
			}
		},
		unfollow: async function() {
			try {

				await axios.post(
					'/api/v1/user/unfollow',
					{ user_id: this.userId },
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
							'Content-Type' : 'application/json'
						}
					});
					window.location.reload();
			}

			catch(err) {
				console.log('Something went wrong', err);
			}
		}
	}

}
</script>


<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.profile-widget
	position relative
	display flex
	align-items center
	z-index 3
	font-size 12px
	color white

	.name
		display inline-block
		margin-left 5px
		position relative
		color white !important
		text-shadow 0 0 2px rgba(black, .5)

	.profile
		position relative
		width 36px
		height 36px
		border-radius 100px
		overflow hidden
		background #333
		box-shadow 0 0 10px rgba(black, .3)

		.profile-image
			position absolute
			top 0
			left 0
			width 100%
			height 100%
			background-size cover
			background-position center center


	&.large
		position relative
		font-size 14px
		color black

		.profile
			width 45px
			height 45px
			border-radius radius
			box-shadow none

		.name
			color white !important
			margin-left gutter
			margin-rirght gutter
			font-size 16px
			font-family font-semibold
			flex-grow 1
			text-shadow none

			@media (max-width 500px)
				display block

		.actions
			display flex
			align-items center
			margin 7px 0

		.btn
			display inline-flex




</style>
