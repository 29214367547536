var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "settings-form" },
    [
      _c(
        "segment",
        { attrs: { title: "Group Information", light: "" } },
        [
          _vm.infoNotice
            ? _c("notice", {
                attrs: { color: "green", notice: "Group Info Updated" },
              })
            : _vm._e(),
          _vm.infoErrorNotice
            ? _c("notice", {
                attrs: { color: "red", notice: "Something Went Wrong" },
              })
            : _vm._e(),
          _c("form-textarea", {
            attrs: { label: "Description", name: "description" },
            model: {
              value: _vm.groupData.description,
              callback: function ($$v) {
                _vm.$set(_vm.groupData, "description", $$v)
              },
              expression: "groupData.description",
            },
          }),
          _c(
            "div",
            { staticClass: "btn-list" },
            [
              _c("btn", {
                ref: "btnGroupInfo",
                attrs: { label: "Save" },
                on: { click: _vm.updateGroupInfo },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "segment",
        { attrs: { title: "Banner/Background Image", light: "" } },
        [
          _vm.bannerNotice
            ? _c("notice", {
                attrs: { color: "green", notice: "Group Info Updated" },
              })
            : _vm._e(),
          _c("image-updater", {
            attrs: {
              documentId: _vm.id,
              id: "banner",
              type: "group",
              item: _vm.page,
            },
          }),
        ],
        1
      ),
      _c(
        "segment",
        { attrs: { title: "Profile Image", light: "" } },
        [
          _vm.profileNotice
            ? _c("notice", {
                attrs: { color: "green", notice: "Group Info Updated" },
              })
            : _vm._e(),
          _c("image-updater", {
            attrs: {
              documentId: _vm.id,
              id: "profile",
              type: "group",
              item: _vm.page,
            },
          }),
        ],
        1
      ),
      _c(
        "segment",
        { attrs: { title: "Delete Group", light: "", danger: "" } },
        [
          _c("p", [_vm._v("Type the name of the group here to delete it")]),
          _c("p", [
            _vm._v(
              'NOTE: All listings private to this group will placed in the user\'s "deleted listings" section, and the user will be notified'
            ),
          ]),
          _vm.error.length
            ? _c("standard-message", {
                attrs: { type: "error", message: _vm.error },
              })
            : _vm._e(),
          _c("form-text", {
            ref: "delete",
            attrs: { name: "delete", placeholder: _vm.page.name },
            model: {
              value: _vm.deleteText,
              callback: function ($$v) {
                _vm.deleteText = $$v
              },
              expression: "deleteText",
            },
          }),
          _c(
            "div",
            { staticClass: "btn-list" },
            [
              !_vm.showForceDeleteButton
                ? _c("btn", {
                    attrs: { label: "Delete" },
                    on: { click: _vm.deleteGroup },
                  })
                : _vm._e(),
              _vm.showForceDeleteButton
                ? _c("btn", {
                    attrs: { color: "red", label: "Confirm Deletion" },
                    on: { click: _vm.forceDelete },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }