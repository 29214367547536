<template lang="pug">

.admin-bar
  modal(
    ref='modalDelete'
    v-on:pass='proceedRemove'
    message='Confirm delete?')

  modal(
    ref='modalForceDelete'
    v-on:pass='proceedForceRemove'
    message='Confirm fully delete?')

  modal(
    ref='modalSold'
    v-on:pass='proceedSold'
    message='Mark as sold?')

  .btn-list

    btn(
      color='grey'
      size='small'
      v-if='!item.deleted'
      :to='editLink'
      label='Edit')

    btn(
      color='grey'
      size='small'
      v-if='canRestore'
      @click='restoreListing'
      label='Restore')

    btn(
      size='small'
      color='green'
      theme='outline'
      v-if='canBeSold && type == "listing"'
      @click='sellListing()'
      icon='dollar-sign-icon'
      label='Mark Sold')

    btn(
      color='yellow'
      theme='outline'
      size='small'
      v-if='item.sold && type == "listing"'
      @click='unsellListing()'
      icon='dollar-sign-icon'
      label='Remove Sold')

    btn(
      color='red'
      theme='outline'
      size='small'
      icon='trash-2-icon'
      v-if='!item.deleted'
      @click='removeListing()')

    btn(
      color='red'
      size='small'
      theme='outline'
      v-if='item.deleted'
      @click='forceRemoveListing()'
      label='Force Delete')

</template>

<script>
import axios from 'axios';

export default {
  name: 'admin-bar',

  props: {
    item: Object,
    type: {
      type: String,
      default: 'listing'
    }
  },

  data: function() {
    return { }
  },

  computed: {
    editLink: function() {
      return '/' + this.type + '/' + this.item.internal_id + '/edit';
    },
    canBeSold: function() {
      if(this.item.deleted || this.item.sold) {
        return false;
      } else {
        return true;
      }
    },
    canRestore: function() {
      if(this.item.deleted || this.item.sold) {
        return true;
      } else {
        return false;
      }
    },

    endpoint: function() {
			return this.type
    }
  },

  methods: {

    removeListing: function() {
      this.$refs.modalDelete.openModal();
    },

    forceRemoveListing: function() {
      this.$refs.modalForceDelete.openModal();
    },

    sellListing: function() {
      this.$refs.modalSold.openModal();
    },


    // restore the listing
    async restoreListing() {
      try {
        await axios.post(
          '/api/v1/' + this.endpoint + '/restore',
          {
            internal_id: this.item.internal_id,
            _id: this.item._id
          },
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          }
        );

        // If listing detail route, then go to garage
        if(this.$route.path.indexOf('/listing/') != -1) {
          this.$router.push('/garage');
        } else {
          location.reload();
        }

      } catch(err) {
        console.log('Something went wrong', err);
      }
    },

    closeModal() {
      this.confirmRemove = false;
      this.confirmArchive = false;
      this.confirmSold = false;
    },

    // delete the listing
    async proceedRemove() {
      try {
        await axios.post(
          '/api/v1/' + this.endpoint + '/delete',
          {
            internal_id: this.item.internal_id,
            _id: this.item._id
          },
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          }
        );

        // If listing detail route, then go to garage
        if(this.$route.path.indexOf('/listing/') != -1) {
          this.$router.push('/garage');
        } else {
          location.reload();
        }

      } catch(err) {
        console.log('Something went wrong', err);
      }
    },


    // force remove (like don't put it in deleted)
    async proceedForceRemove() {

      try {
        await axios.post(
          '/api/v1/' + this.endpoint + '/force-delete',
          {
            internal_id: this.item.internal_id,
            _id: this.item._id
          },
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          }
        );

        // If listing detail route, then go to garage
        if(this.$route.path.indexOf('/' + this.endpoint + '/') != -1) {
          this.$router.push('/garage');
        } else {
          location.reload();
        }

      } catch(err) {
        console.log('Something went wrong', err);
      }
    },


    // sold
    async proceedSold() {

      try {
        await axios.post(
          '/api/v1/' + this.endpoint + '/sold',
          {
            internal_id: this.item.internal_id,
            _id: this.item._id
          },
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          }
        );

        // If listing detail route, then go to garage
        if(this.$route.path.indexOf('/listing/') != -1) {
          this.$router.push('/garage');
        } else {
          location.reload();
        }

      } catch(err) {
        console.log('Something went wrong', err);
      }
    },


    // un-sell
    async unsellListing() {

      try {
        await axios.post(
          '/api/v1/' + this.endpoint + '/unsold',
          {
            internal_id: this.item.internal_id,
            _id: this.item._id
          },
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          }
        );

        // If listing detail route, then go to garage
        if(this.$route.path.indexOf('/listing/') != -1) {
          this.$router.push('/garage');
        } else {
          location.reload();
        }

      } catch(err) {
        console.log('Something went wrong', err);
      }
    }

  }
}
</script>


<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.admin-bar
  background rgba(#000, .6)
  padding 9px 12px 5px 12px
  border-radius 4px
  width calc(100% - 16px)
  margin 8px

</style>
