<template lang='pug'>

option(:data-index='index' :value='optionValue') {{ name }}

</template>

<script>


export default {

	props: {
		item: Object,
		index: Number,
		typeID: String
	},

	computed: {
		optionValue() {
			return `${this.typeID}:${this.item.id_car_make}:${this.item.name}:${this.item.id_car_model}:${this.item.year_begin}:${this.item.year_end}`;
		},
		optionName() {
			return this.item.name;
		},
		optionIndex() {
			return this.index
		},
		name() {
			let name = this.item.name;
			if(this.item.year_begin && this.item.year_end) {
				return `${name} (${this.item.year_begin} - ${this.item.year_end})`
			} else {
				return name;
			}
		}
	}

}
</script>
