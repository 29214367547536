var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "listing",
    [
      _c("heading", {
        attrs: {
          heading: "Events",
          "user-action": "/event/add",
          "user-label": "Create a new event",
        },
      }),
      _c("paginated-feed", { attrs: { type: "scoped-events", scope: "all" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }