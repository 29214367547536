var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("div", { staticClass: "info-badge" }, [
        _vm.item.year ? _c("span", [_vm._v(_vm._s(_vm.item.year))]) : _vm._e(),
        _vm.item.make ? _c("span", [_vm._v(_vm._s(_vm.item.make))]) : _vm._e(),
        _vm.item.model
          ? _c("span", [_vm._v(_vm._s(_vm.item.model))])
          : _vm._e(),
        _vm.item.generation
          ? _c("span", [_vm._v(_vm._s(_vm.item.generation))])
          : _vm._e(),
        _vm.item.generation_from
          ? _c("span", [_vm._v(_vm._s(_vm.item.generation_from))])
          : _vm._e(),
        _vm.item.generation_to
          ? _c("span", [_vm._v(_vm._s(_vm.item.generation_to))])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }