var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "advert-row" },
    [
      _c("modal", {
        ref: "deleteAdvertModal",
        attrs: { message: "Confirm delete?" },
        on: { pass: _vm.proceedRemoveAdvert },
      }),
      _vm.ad.image.length
        ? _c(
            "div",
            { staticClass: "image" },
            [
              _c("base-image", {
                attrs: { src: _vm.ad.image[0].filename, bucket: "images" },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "text" }, [
        _c("h3", { staticClass: "title" }, [_vm._v(_vm._s(_vm.ad.title))]),
        _vm.ad.headline ? _c("p", [_vm._v(_vm._s(_vm.ad.headline))]) : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "actions" },
        [
          _c("btn", {
            attrs: {
              color: "grey",
              size: "small",
              to: _vm.editAdvertLink,
              label: "edit",
            },
          }),
          _c("btn", {
            attrs: { color: "red", theme: "outline", icon: "trash-2-icon" },
            on: { click: _vm.removeAdvert },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }