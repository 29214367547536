<template lang="pug">

.content(v-if='loaded')

  heading(:heading='computedTitle')

  .picker
    .picker-item.selected(ref='sale' @click='toggle("sale")')
      span.count {{ listingsTotal }}
      span.title For Sale
    .picker-item(ref='want' @click='toggle("want")')
      span.count {{ wantsTotal }}
      span.title Want To Buy

  .listings(v-if='section == "sale"')
    grid(v-if='filteredListings.length')
      card(:item='item' v-for="item, index in filteredListings" :key='index')
    div(v-else)
      h3 No matches for this filter
    .loads-more(v-if='canLoadMoreListings')
      btn(@click='loadMoreListings' label='Load More')


  .wants(v-if='section == "want"')
    grid(v-if='filteredWants.length')
      card(:item="item" v-for="want, index in filteredWants" :key='index')
    div(v-else)
      h3 No matches for this filter

    .loads-more(v-if='canLoadMoreWants')
      btn(@click='loadMoreWants' label='Load More')

</template>

<script>

export default {

  name: 'home',

  beforeMount: function() {
    this.getFilteredListings()
  },

  mounted: function() {
    this.loaded = true;
    this.$store.state.view = 'Filtered Listings';
  },

  data: function() {
    return {
      section: 'sale',
      url: '',
      loaded: false,
      filteredListings: [],
      filteredWants: [],
      listingsIndex: 0,
      listingsTotal: 0,
      wantsIndex: 0,
      wantsTotal: 0,
      filteredListing: {
        year_from: '',
        year_to: '',
        make: '',
        model: ''
      }
    }
  },

  computed: {
    computedTitle: function() {
      let label = '';

      if(this.filteredListing.year_from.length > 0 && this.filteredListing.year_from != 'any') {
        label += this.filteredListing.year_from + ' ';
      }

      if(this.filteredListing.year_to.length > 0 && this.filteredListing.year_to != 'any') {
        label += '/ ' + this.filteredListing.year_to + ' ';
      }

      if(this.filteredListing.make.length > 0 && this.filteredListing.make != 'any') {
        label += this.filteredListing.make + ' ';
      }

      if(this.filteredListing.model.length > 0 && this.filteredListing.model != 'any') {
        label += this.filteredListing.model + ' ';
      }

      return label;
    },
    canLoadMoreListings: function() {
      if(this.filteredListings.length < this.listingsTotal) {
        return true;
      } else {
        return false;
      }
    },
    canLoadMoreWants: function() {
      if(this.filteredWants.length < this.wantsTotal) {
        return true;
      } else {
        return false;
      }
    }
  },

  methods: {
    toggle(section) {
      if(section == this.section) {
        return;
      } else {
        this.section = section;
        this.$refs['sale'].classList.remove('selected');
        this.$refs['want'].classList.remove('selected');
        this.$refs[section].classList.add('selected');
      }
    },

    setListings(listings) {
      this.$refs.listings.setListings(listings);
    },

    buildSearchURL: function() {
      let make = 'any', model = 'any', year_from = 'any', year_to = 'any';

      if(this.filteredListing.year_to.length > 0) {
         year_to = this.filteredListing.year_to;
      } else if(this.filteredListing.year_from.length > 0) {
        year_to = this.filteredListing.year_from;
      }

      if(this.filteredListing.year_from.length > 0) {
         year_from = this.filteredListing.year_from;
      }

      if(this.filteredListing.make.length > 0) {
        make = this.filteredListing.make;
      }

      if(this.filteredListing.model.length > 0) {
        model = this.filteredListing.model;
      }

      return 'filter/' + year_from + '/' + year_to + '/' + make + '/' + model

    },

    getFilteredListings: function() {

      this.filteredListing.year_from = this.$route.params.year_from
      this.filteredListing.year_to = this.$route.params.year_to
      this.filteredListing.make = this.$route.params.make
      this.filteredListing.model = this.$route.params.model

      this.url = this.buildSearchURL();

      this.fetchListings();
      this.fetchWants();
    },

    fetchListings: async function() {
      await fetch('/api/v1/listing/' + this.url + '/' + this.listingsIndex + '/showAds')
      .then(res => res.json())
      .then(filteredListings => {
        this.listingsTotal = filteredListings.total;
        this.filteredListings.push(...filteredListings.listings);
      });
    },

    fetchWants: async function() {
      await fetch('/api/v1/want/' + this.url + '/' + this.wantsIndex + '/showAds')
      .then(res => res.json())
      .then(filteredWants => {
        this.wantsTotal = filteredWants.total;
        this.filteredWants.push(...filteredWants.wants);
      });
    },

    loadMoreListings() {
      this.listingsIndex++;
      this.fetchListings();
    },

    loadMoreWants() {
      this.wantsIndex++;
      this.fetchWants();
    }

  },

  watch: {
    $route (to, from) {
      this.filteredWants = [];
      this.filteredListings = [];
      this.listingsIndex = 0;
      this.listingsTotal = 0;
      this.wantsIndex = 0;
      this.wantsTotal = 0;
      this.section = 'sale';
      this.getFilteredListings();
		}
  }
}
</script>


<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

</style>
