<template lang='pug'>

label(:class='classes')

  span {{ label }}
  
  .input-wrap
    i(v-if='currency') $
    input(
      :required='required'
      :type='type'
      :name='name'
      :value='value'
      :placeholder='placeholder'
      ref='input'
      @input='emitInput($event)'
      :autocapitalize='autocapitalize'
      :autocomplete='autocomplete'
      v-model='state')

</template>

<script>


export default {
  
  props: {
    label: String,
    name: String,
    value: [String, Number],
    autocomplete: String,
    placeholder: String,
    large: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    required: {
      type: Boolean,
      default: false
    },
    currency: {
      type: Boolean,
      default: false
    },
    autocapitalize: {
      type: String,
      default: 'off'
    }
  },

  data: function() {
    return {
      state: this.value
    }
  },

  computed: {
    classes: function() {
      let classes = []
      if(this.large) { classes.push('large') }
      if(this.currency) { classes.push('currency') }
      return classes;
    }
  },
  
  methods: {
    emitInput: function(e) {
      this.$emit("inputChanged", this.$refs.input.value);
    }
  },

  watch: {
    value() {
      this.state = this.value;
    },
    state() {
      this.$emit('input', this.state);
    }
  }
  
}

</script>

<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

label
  display block
  input-style()
  margin 20px 0

  &.large
    input, select
      padding 18px
      font-size 22px

  &.currency
    input
      padding-left 40px

  &.inline
    display flex
    align-items center

    input
      margin-left 15px

.input-wrap
  position relative

i
  font-style inherit
  font-family font-main
  color #444
  position absolute
  font-size 24px
  left 18px
  top 50%
  transform translateY(-50%)


</style>