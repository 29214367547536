var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "heading-wrap", class: _vm.classes },
    [
      _c("h1", [_vm._v(_vm._s(_vm.heading))]),
      _vm.user.username && _vm.userAction && _vm.userLabel
        ? _c("btn", {
            attrs: { size: "small", to: _vm.userAction, label: _vm.userLabel },
          })
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }