// globally registered components
import Vue from 'vue'

import Logo from './components/globals/logo';
import Btn from './components/globals/btn';
import Card from './components/globals/cards/card';
import Row from './components/globals/rows/row';
import Grid from './components/globals/grid';
import Segment from './components/globals/segment/segment';
import SegmentRow from './components/globals/segment/row';
import SegmentHeader from './components/globals/segment/header';
import Placeholder from './components/globals/placeholder';
import BaseImage from './components/globals/base-image';
import LikesAndCommentsBar from './components/globals/likes-and-comments-bar';
import ProfileWidget from './components/globals/profile-widget';
import Modal from './components/globals/modals/simple';
import SuccessModal from './components/globals/modals/success';
import Loader from './components/globals/loader';
import FormText from './components/forms/form-text';
import FormTextarea from './components/forms/form-textarea';
import StandardMessage from './components/globals/messages/standard';
import ImagePlaceholder from './components/globals/images/placeholder';
import Pane from './components/panes/pane';
import MountedPane from './components/panes/mounted-pane';
import Notice from './components/globals/notice';
import Follow from './components/globals/follow';
import HelpText from './components/globals/help-text';
import Spacer from './components/globals/spacer';
import Heading from './components/globals/heading';
import FeaturedListings from './components/globals/featured-listings';
import SmartTitle from '/src/components/globals/cards/smart-title';
import BigCard from '/src/components/globals/cards/big';
import CarInfo from '/src/components/globals/info/car';
import EventInfo from '/src/components/globals/info/event';
import GroupInfo from '/src/components/globals/info/group';
import TimeAgo from '/src/components/globals/info/when';
import Listing from '/src/components/listing/listing';
import PaginatedFeed from '/src/components/listing/paginated-feed';
import Price from '/src/components/globals/price';
import Badges from '/src/components/ui/badges';
import Uploader from '/src/components/globals/gallery/uploader';
import CtaWrap from '/src/components/globals/cta-wrap';

Vue.component('badges', Badges);
Vue.component('price', Price);
Vue.component('logo', Logo);
Vue.component('btn', Btn);
Vue.component('card', Card);
Vue.component('row', Row);
Vue.component('base-image', BaseImage);
Vue.component('grid', Grid);
Vue.component('segment', Segment);
Vue.component('segment-header', SegmentHeader);
Vue.component('segment-row', SegmentRow);
Vue.component('placeholder', Placeholder);
Vue.component('likes-and-comments-bar', LikesAndCommentsBar);
Vue.component('profile-widget', ProfileWidget);
Vue.component('modal', Modal);
Vue.component('success-modal', SuccessModal);
Vue.component('loader', Loader);
Vue.component('form-text', FormText);
Vue.component('form-textarea', FormTextarea);
Vue.component('standard-message', StandardMessage);
Vue.component('image-placeholder', ImagePlaceholder);
Vue.component('pane', Pane);
Vue.component('mounted-pane', MountedPane);
Vue.component('notice', Notice);
Vue.component('car-info', CarInfo);
Vue.component('event-info', EventInfo);
Vue.component('group-info', GroupInfo);
Vue.component('time-ago', TimeAgo);
Vue.component('follow', Follow);
Vue.component('help-text', HelpText);
Vue.component('spacer', Spacer);
Vue.component('heading', Heading);
Vue.component('featured-listings', FeaturedListings);
Vue.component('smart-title', SmartTitle);
Vue.component('big-card', BigCard);
Vue.component('listing', Listing);
Vue.component('paginated-feed', PaginatedFeed);
Vue.component('uploader', Uploader);
Vue.component('cta-wrap', CtaWrap);
