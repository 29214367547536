var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { class: _vm.positionClasses },
    [
      _c("pane", { ref: "menu", attrs: { title: "Menu", location: "right" } }, [
        _c(
          "div",
          {
            staticClass: "menu-link",
            on: {
              click: function ($event) {
                return _vm.closePane("menu")
              },
            },
          },
          [
            _vm.loggedIn
              ? _c(
                  "router-link",
                  { staticClass: "top", attrs: { to: "/garage" } },
                  [_c("profile-img"), _c("span", [_vm._v("Garage")])],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.loggedIn
          ? _c(
              "div",
              {
                staticClass: "menu-link has-more",
                class: _vm.dropClasses,
                on: {
                  click: function ($event) {
                    return _vm.toggleDrop()
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "simulate-a" },
                  [
                    _c(
                      "div",
                      { staticClass: "top" },
                      [
                        _c("bookmark-icon", { staticClass: "icon" }),
                        _c("span", [_vm._v("Saved Searches")]),
                      ],
                      1
                    ),
                    _c("chevron-right-icon", { staticClass: "icon" }),
                  ],
                  1
                ),
                _vm.dropOpen
                  ? _c(
                      "ul",
                      { staticClass: "drop" },
                      _vm._l(_vm.savedSearches, function (search, index2) {
                        return _vm.savedSearches
                          ? _c(
                              "li",
                              {
                                key: index2 + 10,
                                on: {
                                  click: function ($event) {
                                    return _vm.closePane("menu")
                                  },
                                },
                              },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "category",
                                    attrs: { to: "/" + search.url },
                                  },
                                  [_vm._v(_vm._s(search.label))]
                                ),
                              ],
                              1
                            )
                          : _vm._e()
                      }),
                      0
                    )
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        !_vm.loggedIn
          ? _c(
              "div",
              {
                staticClass: "menu-link",
                on: {
                  click: function ($event) {
                    return _vm.closePane("menu")
                  },
                },
              },
              [
                _c(
                  "router-link",
                  { staticClass: "top", attrs: { to: "/register" } },
                  [_c("span", [_vm._v("Register")])]
                ),
              ],
              1
            )
          : _vm._e(),
        !_vm.loggedIn
          ? _c(
              "div",
              {
                staticClass: "menu-link",
                on: {
                  click: function ($event) {
                    return _vm.closePane("menu")
                  },
                },
              },
              [
                _c(
                  "router-link",
                  { staticClass: "top", attrs: { to: "/login" } },
                  [_c("span", [_vm._v("Login")])]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.loggedIn
          ? _c(
              "div",
              {
                staticClass: "menu-link",
                on: {
                  click: function ($event) {
                    return _vm.closePane("menu")
                  },
                },
              },
              [
                _c(
                  "router-link",
                  { staticClass: "top", attrs: { to: "/listing/add" } },
                  [
                    _c("plus-icon", { staticClass: "icon" }),
                    _c("span", [_vm._v("NEW LISTING")]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.loggedIn
          ? _c(
              "div",
              {
                staticClass: "menu-link",
                on: {
                  click: function ($event) {
                    return _vm.closePane("menu")
                  },
                },
              },
              [
                _c(
                  "router-link",
                  { staticClass: "top", attrs: { to: _vm.profileDeeplink } },
                  [
                    _c("user-icon", { staticClass: "icon" }),
                    _c("span", [_vm._v("Your Public Profile")]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "menu-link",
            on: {
              click: function ($event) {
                return _vm.closePane("menu")
              },
            },
          },
          [
            _c(
              "router-link",
              { staticClass: "top", attrs: { to: "/users" } },
              [
                _c("search-icon", { staticClass: "icon" }),
                _c("span", [_vm._v("Search Users")]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "menu-link",
            on: {
              click: function ($event) {
                return _vm.closePane("menu")
              },
            },
          },
          [
            _c(
              "a",
              {
                staticClass: "top",
                attrs: { href: "https://drive.partstash.com", target: "blank" },
              },
              [_c("span", [_vm._v("drive.partstash.com")])]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "menu-link",
            on: {
              click: function ($event) {
                return _vm.closePane("menu")
              },
            },
          },
          [
            _c(
              "router-link",
              { staticClass: "top", attrs: { to: "/cascade-consulting" } },
              [_c("span", [_vm._v("Cascade Consulting")])]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "menu-link",
            on: {
              click: function ($event) {
                return _vm.closePane("menu")
              },
            },
          },
          [
            _c(
              "a",
              {
                staticClass: "top",
                attrs: {
                  href: "https://drive.partstash.com/pages/faq",
                  target: "blank",
                },
              },
              [_c("span", [_vm._v("FAQ")])]
            ),
          ]
        ),
        _vm.isAdminUser
          ? _c(
              "div",
              {
                staticClass: "menu-link",
                on: {
                  click: function ($event) {
                    return _vm.closePane("menu")
                  },
                },
              },
              [
                _c(
                  "router-link",
                  { staticClass: "top", attrs: { to: "/admin" } },
                  [_c("span", [_vm._v("Admin")])]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.theOne
          ? _c(
              "div",
              {
                staticClass: "menu-link",
                on: {
                  click: function ($event) {
                    return _vm.closePane("menu")
                  },
                },
              },
              [
                _c(
                  "router-link",
                  { staticClass: "top", attrs: { to: "/neo" } },
                  [_c("img", { attrs: { src: "/assets/img/wr.jpg" } })]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.loggedIn
          ? _c(
              "div",
              {
                staticClass: "menu-link",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.logout.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "simulate-a" },
                  [
                    _c("log-out-icon", { staticClass: "icon" }),
                    _c("span", [_vm._v("Log Out")]),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ]),
      _c(
        "pane",
        { ref: "partstash", attrs: { title: "Partstash", location: "top" } },
        [
          _c(
            "div",
            { staticClass: "menu-link" },
            [
              _c("router-link", { attrs: { to: "/" } }, [
                _c(
                  "div",
                  [
                    _c("home-icon", { staticClass: "icon" }),
                    _c("span", [_vm._v("Recent")]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "menu-link" },
            [
              _c("router-link", { attrs: { to: "/listings/all" } }, [
                _c(
                  "div",
                  [
                    _c("grid-icon", { staticClass: "icon" }),
                    _c("span", [_vm._v("All Listings")]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "menu-link" },
            [
              _c("router-link", { attrs: { to: "/wheels" } }, [
                _c(
                  "div",
                  [
                    _c("target-icon", { staticClass: "icon" }),
                    _c("span", [_vm._v("Wheels")]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "menu-link" },
            [
              _c("router-link", { attrs: { to: "/groups" } }, [
                _c(
                  "div",
                  [
                    _c("users-icon", { staticClass: "icon" }),
                    _c("span", [_vm._v("Groups")]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "menu-link" },
            [
              _c("router-link", { attrs: { to: "/events" } }, [
                _c(
                  "div",
                  [
                    _c("grid-icon", { staticClass: "icon" }),
                    _c("span", [_vm._v("Events")]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "menu-link" },
            [
              _c("router-link", { attrs: { to: "/cars" } }, [
                _c(
                  "div",
                  [
                    _c("grid-icon", { staticClass: "icon" }),
                    _c("span", [_vm._v("User Cars")]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "menu-link" },
            [
              _vm.loggedIn
                ? _c("router-link", { attrs: { to: "/garage" } }, [
                    _c(
                      "div",
                      [
                        _c("tool-icon", { staticClass: "icon" }),
                        _c("span", [_vm._v("Garage")]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "menu-link", on: { click: _vm.openSearch } },
            [
              _c("div", { staticClass: "simulate-a" }, [
                _c(
                  "div",
                  [
                    _c("search-icon", { staticClass: "icon" }),
                    _c("span", [_vm._v("Search")]),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _vm._l(_vm.savedSearches, function (search, index2) {
            return _vm.loggedIn
              ? _c(
                  "div",
                  { key: index2 + 10, staticClass: "menu-link" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "category",
                        attrs: { to: "/" + search.url },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("bookmark-icon", { staticClass: "icon" }),
                            _c("span", [_vm._v(_vm._s(search.label))]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e()
          }),
        ],
        2
      ),
      _c("pane", { ref: "new", attrs: { title: "New", blue: "" } }, [
        _c(
          "div",
          {
            staticClass: "menu-link",
            on: {
              click: function ($event) {
                return _vm.closePane("new")
              },
            },
          },
          [
            _c("router-link", { attrs: { to: "/timeline/add" } }, [
              _vm._v("New Status"),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "menu-link",
            on: {
              click: function ($event) {
                return _vm.closePane("new")
              },
            },
          },
          [
            _c("router-link", { attrs: { to: "/listing/add" } }, [
              _vm._v("New Listing"),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "menu-link",
            on: {
              click: function ($event) {
                return _vm.closePane("new")
              },
            },
          },
          [
            _c("router-link", { attrs: { to: "/want/add" } }, [
              _vm._v("New Want"),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "menu-link",
            on: {
              click: function ($event) {
                return _vm.closePane("new")
              },
            },
          },
          [
            _c("router-link", { attrs: { to: "/group/add" } }, [
              _vm._v("New Group"),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "menu-link",
            on: {
              click: function ($event) {
                return _vm.closePane("new")
              },
            },
          },
          [
            _c("router-link", { attrs: { to: "/garage/car/add" } }, [
              _vm._v("New Garage Car"),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "menu-link",
            on: {
              click: function ($event) {
                return _vm.closePane("new")
              },
            },
          },
          [
            _c("router-link", { attrs: { to: "/event/add" } }, [
              _vm._v("New Event"),
            ]),
          ],
          1
        ),
        _vm.isAdminUser
          ? _c(
              "div",
              {
                staticClass: "menu-link",
                on: {
                  click: function ($event) {
                    return _vm.closePane("new")
                  },
                },
              },
              [
                _c("router-link", { attrs: { to: "/treasure/add" } }, [
                  _vm._v("New Treasure"),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "shade", class: _vm.shadeClasses }),
      _c("div", { staticClass: "width-restrict" }, [
        _c(
          "div",
          {
            staticClass: "logo",
            on: {
              click: function ($event) {
                return _vm.openPane("partstash")
              },
            },
          },
          [_c("logo", { attrs: { color: "#ffffff", size: "50" } })],
          1
        ),
        _c(
          "div",
          { staticClass: "nav" },
          [
            _c("router-link", { attrs: { to: "/" } }, [
              _c("span", [_vm._v("Recent")]),
            ]),
            _c("router-link", { attrs: { to: "/listings/all" } }, [
              _c("span", [_vm._v("Listings")]),
            ]),
            _c("router-link", { attrs: { to: "/wheels" } }, [
              _c("span", [_vm._v("Wheels")]),
            ]),
            _c("router-link", { attrs: { to: "/groups" } }, [
              _c("span", [_vm._v("Groups")]),
            ]),
            _c("router-link", { attrs: { to: "/events" } }, [
              _c("span", [_vm._v("Events")]),
            ]),
            _c("router-link", { attrs: { to: "/cars" } }, [
              _c("span", [_vm._v("User Cars")]),
            ]),
            _c("search-icon", {
              staticClass: "search icon",
              on: { click: _vm.openSearch },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "right" },
          [
            _vm.loggedIn
              ? _c(
                  "div",
                  {
                    staticClass: "new",
                    on: {
                      click: function ($event) {
                        return _vm.openPane("new")
                      },
                    },
                  },
                  [_c("plus-icon", { staticClass: "icon" })],
                  1
                )
              : _vm._e(),
            _c(
              "router-link",
              { staticClass: "profile", attrs: { to: "/garage" } },
              [
                _vm.newMessages
                  ? _c("div", { staticClass: "newMessageBtn" })
                  : _vm._e(),
                _c("profile-img"),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "menu-toggle",
                on: {
                  click: function ($event) {
                    return _vm.openPane("menu")
                  },
                },
              },
              [_c("more-vertical-icon", { staticClass: "icon" })],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }