var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mounted-pane",
    {
      ref: "pane",
      attrs: { title: "You Follow" },
      on: {
        close: function ($event) {
          return _vm.close()
        },
      },
    },
    [
      _vm.loaded
        ? _c("div", { staticClass: "content-rows" }, [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("content-rows", {
                  attrs: { data: _vm.data, type: "following" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }