import { render, staticRenderFns } from "./notice.vue?vue&type=template&id=0345ff27&scoped=true&lang=pug&"
import script from "./notice.vue?vue&type=script&lang=js&"
export * from "./notice.vue?vue&type=script&lang=js&"
import style0 from "./notice.vue?vue&type=style&index=0&id=0345ff27&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0345ff27",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/matthewaebersold/dev/psg/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0345ff27')) {
      api.createRecord('0345ff27', component.options)
    } else {
      api.reload('0345ff27', component.options)
    }
    module.hot.accept("./notice.vue?vue&type=template&id=0345ff27&scoped=true&lang=pug&", function () {
      api.rerender('0345ff27', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/globals/notice.vue"
export default component.exports