var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gallery" },
    [
      _c("image-placeholder"),
      _vm.item.gallery.length > 1
        ? _c(
            "div",
            [
              _c(
                "ssr-carousel",
                { attrs: { "slides-per-page": 1, "show-arrows": "" } },
                _vm._l(_vm.item.gallery, function (img, index) {
                  return _c("base-image", {
                    key: index,
                    attrs: { src: img.filename, bucket: _vm.bucket },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c("base-image", {
                attrs: { src: _vm.singleFileName, bucket: _vm.bucket },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }