var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal-simple welcome" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.open,
              expression: "open",
            },
          ],
          staticClass: "overlay",
          on: { click: _vm.close },
        }),
      ]),
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.open,
                expression: "open",
              },
            ],
            ref: "scroll",
            staticClass: "content",
          },
          [
            _c(
              "div",
              { staticClass: "close", on: { click: _vm.close } },
              [_c("x-icon", { staticClass: "icon" })],
              1
            ),
            _c(
              "div",
              { staticClass: "flex-wrap" },
              [
                _c("logo"),
                _c("h1", [_vm._v("Welcome to Partstash")]),
                _c("hr"),
                _c("p", [_vm._v("Some good next steps would be to:")]),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      "Create your cars, so listings are easier and your feed can be populated with relevant listings"
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "Follow some people to see their listings in your feed"
                    ),
                  ]),
                  _c("li", [_vm._v("Set up a profile photo")]),
                  _c("li", [_vm._v("List some parts for sale")]),
                  _c("li", [_vm._v("List some things you want to buy")]),
                ]),
                _c(
                  "div",
                  { staticClass: "btn-list" },
                  [
                    _c("btn", {
                      attrs: { color: "green", label: "GOTCHA" },
                      on: { click: _vm.pass },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }