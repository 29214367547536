<template lang='pug'>

.user-row(v-if='loaded')
	router-link.link-wrap(:to='"/users/" + loadedUser.username')

		.image
			.user(v-if='this.loadedUser.profile' :style='styles')
			.default(v-else style='background-image: url(/assets/logo.svg);')

		.info
			span {{ loadedUser.username }}

	.btn-list
		div(v-if='!noFollowUI')
			follow(v-if='!loggedInUser' :user='loadedUser' noUserName)
		slot

</template>

<script>

export default {
	props: {
		user: Object,
		userId: String,
		status: String,
		noFollowUI: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			loaded: false,
			loadedUser: null
		}
	},

	mounted: async function() {
		if(this.userId) {
			await this.fetchUser();
		} else {
			this.loadedUser = this.user;
			this.loaded = true;
		}
	},

	computed: {
		styles: function() {
			return 'background-image: url("//partstash-profiles.imgix.net/' + this.loadedUser.profile + '?auto=format&q=100&width=500");'
		},

		loggedInUser: function() {
			if(this.$store.state.user.user_id == this.userId) {
				return true;
			} else {
				return false;
			}
		}
	},

	methods: {
		fetchUser: async function() {
			await fetch('/api/v1/profile/id/' + this.userId)
				.then(res => res.json())
				.then(user => {
					this.loadedUser = user;
					this.loaded = true;
				});
		}
	},

	watch: {
		'userId' (to, from) {
			this.fetchUser();
		}
	}
}

</script>


<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.user-row
	display flex
	flex-wrap wrap
	align-items center
	position relative
	margin 15px 0
	padding 0 0 15px 0
	border-bottom 1px solid #303030

.link-wrap
	color white
	display flex
	align-items center
	position relative

.image
	width 60px
	height 60px
	margin-right 10px
	border-radius 10px
	overflow hidden
	position relative
	background #000

	.user, .default
		expand()
		background-size cover
		background-position center center

	.default
		opacity .1
		transform scale(3)


.link-wrap
	flex-grow 1

.btn-list
	margin 10px 0


</style>
