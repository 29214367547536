<template lang='pug'>

div(v-if='loaded && visible')
	heading(
		:heading='heading'
		:user-action='action'
		user-label='< Back')

	admin-bar(
		:item='entry' v-if='loggedInUser.username && yours'
		type='event')

	segment(transparent v-if='hasGallery')
		listing-gallery(
			:item='entry'
			bucket='groups')

	segment
		p Organizer:
		profile-widget(large :userId='entry.user_id')

		br

		badges(:page='entry')
			.badge.private(v-if='entry.is_private') PRIVATE
		likes-and-comments-bar(
			v-if='!entry.is_private'
			:item='entry'
			type='event'
			scroll
			v-on:scroll-to-comments='scrollToComments')

	segment(v-if='!yours')

		.btn-list(v-if='loggedInUser')

			btn(v-if='!attending'
				ref='attendBtn'
				@click='attendEvent()'
				label='Attend Event')

			btn(v-else
				color='red'
				theme='outline'
				ref='leaveBtn'
				@click='leaveEvent()'
				label='Leave Event')

	.big-card-wrap
		big-card(
			title='Attendees'
			theme='dark'
			width='full'
			icon='users'
			v-if='loggedInUser'
			v-on:openPane='openPane("attendees")'
			:count='entry.attendees.length')

	segment(title='Details')
		p(v-if='entry.event_date') When: {{ eventDate }}
		p(v-if='entry.recurring_frequency') Recurring: {{ entry.recurring_frequency }}
		p(v-if='entry.location') Location: {{ entry.location }}
		p(v-if='entry.time') Time: {{ entry.time }}
		btn(:href='entry.url' v-if='entry.url' label='Detail Link')
		btn(:href='entry.location_url' outside v-if='entry.location_url' label='Location Link')

		div(v-html='entry.body')

	segment(id='comments' ref='comments' v-if='!entry.is_private')
		comments(:item='entry' type='event')

</template>

<script>
import axios from 'axios';
import ListingGallery from '/src/components/globals/gallery/gallery';
import Comments from '/src/components/ui/comments';
import AdminBar from '/src/components/globals/admin-bar';
import { smartTitle, fetchData, date, mountOnBody } from '/src/services/helpers';
import PaneAttendees from '/src/components/panes/event/attendees';

export default {

	components: { ListingGallery, Comments, AdminBar },

	data() {
		return {
			entry: {},
			userGroups: null,
			loaded: false
		}
	},

	beforeMount: async function() {
		this.entry = await fetchData('event/detail/' + this.$route.params.id);
		// this.userGroups = await fetchData('event/userGroups/' + this.$route.params.id);
		this.loaded = true;
	},

	computed: {

		hasGallery: function() {
			if(this.entry.hasOwnProperty('gallery')) {
				if(this.entry.gallery.length > 0) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		},

		heading: function() {
			return smartTitle(this.entry.title);
		},

		eventDate: function() {
			if(this.entry.event_date) {
				return date(this.entry.event_date);
			} else {
				return null;
			}
		},

		action: function() {
			return '/events'
		},

		loggedInUser: function() {
			return this.$store.state.user;
		},

		yours: function() {
			if(this.loggedInUser.user_id == this.entry.user_id) {
				return true;
			} else {
				return false;
			}
		},

		attending: function() {
			if(this.entry.attendees.indexOf(this.loggedInUser.user_id) != -1) {
				return true;
			} else {
				return false;
			}
		},

		visible: function() {
			if(this.entry.scope.indexOf('all') == -1) {

				// TODO: This is low pri, because the user
				// would have to manually go to this event
				// it wouldn't be linkable anywhere

				// this event is only scoped to a group
				// check if there's a match in the group IDS
				// between entry.scope && this.userGroups
				// return true, else return false

			} else {
				return true;
			}
		}
	},

	methods: {
		scrollToComments: function() {
			this.$refs.comments.$el.scrollIntoView({behavior: "smooth"});
		},

		openPane: async function(key) {
			if(key == 'attendees') {
				await mountOnBody(PaneAttendees, { parent: this, propsData: {
					page: this.entry
				} })
			}
		},

		attendEvent: async function() {
			this.$refs.attendBtn.setLoading();

			try {

				let submission = await axios.post(
					'/api/v1/event/attendees/add',
					{
						eventId: this.entry.internal_id
					},
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
							'Content-Type' : 'application/json'
						}
					}
				);

				if(submission.data.attendees) {
					gtag('event', 'event attended');
					this.$refs.attendBtn.removeLoading();
					this.entry.attendees = submission.data.attendees;

					// TODO: notify the organizer?

				}


			} catch(err) {
				gtag('event', this.id + ' attending failed');
				console.log('Something went wrong', err);
				this.$refs.attendBtn.removeLoading();
			}
		},

		leaveEvent: async function() {
			this.$refs.leaveBtn.setLoading();

			try {

				let submission = await axios.post(
					'/api/v1/event/attendees/remove',
					{
						eventId: this.entry.internal_id
					},
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
							'Content-Type' : 'application/json'
						}
					}
				);

				if(submission.data.attendees) {
					gtag('event', 'event left');
					this.$refs.leaveBtn.removeLoading();
					this.entry.attendees = submission.data.attendees;

					// TODO: notify the organizer?

				}


			} catch(err) {
				gtag('event', this.id + ' leaving failed');
				console.log('Something went wrong', err);
				this.$refs.leaveBtn.removeLoading();
			}
		}
	}
}

</script>


<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.car
	margin-bottom 10px

</style>
