var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("label", { class: _vm.classes }, [
    _c("span", [_vm._v(_vm._s(_vm.label))]),
    _c("div", { staticClass: "input-wrap" }, [
      _vm.currency ? _c("i", [_vm._v("$")]) : _vm._e(),
      _vm.type === "checkbox"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.state,
                expression: "state",
              },
            ],
            ref: "input",
            attrs: {
              required: _vm.required,
              name: _vm.name,
              placeholder: _vm.placeholder,
              autocapitalize: _vm.autocapitalize,
              autocomplete: _vm.autocomplete,
              type: "checkbox",
            },
            domProps: {
              value: _vm.value,
              checked: Array.isArray(_vm.state)
                ? _vm._i(_vm.state, _vm.value) > -1
                : _vm.state,
            },
            on: {
              input: function ($event) {
                return _vm.emitInput($event)
              },
              change: function ($event) {
                var $$a = _vm.state,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = _vm.value,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.state = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.state = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.state = $$c
                }
              },
            },
          })
        : _vm.type === "radio"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.state,
                expression: "state",
              },
            ],
            ref: "input",
            attrs: {
              required: _vm.required,
              name: _vm.name,
              placeholder: _vm.placeholder,
              autocapitalize: _vm.autocapitalize,
              autocomplete: _vm.autocomplete,
              type: "radio",
            },
            domProps: {
              value: _vm.value,
              checked: _vm._q(_vm.state, _vm.value),
            },
            on: {
              input: function ($event) {
                return _vm.emitInput($event)
              },
              change: function ($event) {
                _vm.state = _vm.value
              },
            },
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.state,
                expression: "state",
              },
            ],
            ref: "input",
            attrs: {
              required: _vm.required,
              name: _vm.name,
              placeholder: _vm.placeholder,
              autocapitalize: _vm.autocapitalize,
              autocomplete: _vm.autocomplete,
              type: _vm.type,
            },
            domProps: { value: _vm.value, value: _vm.state },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.state = $event.target.value
                },
                function ($event) {
                  return _vm.emitInput($event)
                },
              ],
            },
          }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }