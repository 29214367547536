var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "overflow-wrap" }, [
    _c(
      "div",
      { staticClass: "group-detail content" },
      [
        !_vm.page
          ? _c("no-content")
          : _c(
              "div",
              [
                _vm.member || _vm.admin
                  ? _c("segment", [
                      _c("div", { staticClass: "group-subnav" }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.scrollToItem("groupDetails")
                              },
                            },
                          },
                          [_vm._v("Details")]
                        ),
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.scrollToItem("groupComments")
                              },
                            },
                          },
                          [_vm._v("Discussion")]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("segment", [
                  _c("div", {
                    staticClass: "banner",
                    style: _vm.banner_styles,
                  }),
                  _c(
                    "div",
                    { staticClass: "profile" },
                    [
                      _c("base-image", {
                        attrs: { src: _vm.page.profile, bucket: "groups" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "info" },
                    [
                      _c("smart-title", { attrs: { title: _vm.page.name } }),
                      _c("group-metrics", { attrs: { group: _vm.page } }),
                      _c("group-info", { attrs: { item: _vm.page } }),
                      _vm.page.region
                        ? _c(
                            "div",
                            { staticClass: "region" },
                            [
                              _c("span", [_vm._v("Region")]),
                              _c("badges", {
                                attrs: { page: _vm.page, region: _vm.region },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("likes-and-comments-bar", {
                        attrs: { item: _vm.page, type: "group", scroll: "" },
                        on: { "scroll-to-comments": _vm.scrollToComments },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "big-card-wrap" },
                  [
                    _vm.loggedInUser
                      ? _c("big-card", {
                          attrs: {
                            title: "Members",
                            theme: "dark",
                            width: "half",
                            icon: "users",
                            count: _vm.totalGroupCount,
                          },
                          on: {
                            openPane: function ($event) {
                              return _vm.openPane("members")
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.admin || _vm.member
                      ? _c("big-card", {
                          attrs: {
                            title: "Timeline",
                            theme: "dark",
                            width: "half",
                            icon: "grid",
                          },
                          on: {
                            openPane: function ($event) {
                              return _vm.openPane("timeline")
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.admin || _vm.member
                      ? _c("big-card", {
                          attrs: {
                            title: "Events",
                            theme: "dark",
                            width: "full",
                            icon: "grid",
                          },
                          on: {
                            openPane: function ($event) {
                              return _vm.openPane("events")
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.admin || _vm.member
                      ? _c("big-card", {
                          attrs: {
                            title: "Member Cars",
                            theme: "dark",
                            width: "full",
                            icon: "grid",
                          },
                          on: {
                            openPane: function ($event) {
                              return _vm.openPane("memberCars")
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.member || _vm.admin
                      ? _c("big-card", {
                          attrs: {
                            title: "Group Listings",
                            theme: "blue",
                            width: "half",
                            icon: "grid",
                            count: _vm.listingCount,
                          },
                          on: {
                            openPane: function ($event) {
                              return _vm.openPane("listings")
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.member || _vm.admin
                      ? _c("big-card", {
                          attrs: {
                            title: "Group Want-Ads",
                            theme: "lightblue",
                            width: "half",
                            icon: "grid",
                            count: _vm.wantCount,
                          },
                          on: {
                            openPane: function ($event) {
                              return _vm.openPane("wants")
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.admin
                      ? _c("big-card", {
                          attrs: {
                            title: "Admin: Settings",
                            theme: "light",
                            width: "full",
                            icon: "settings",
                          },
                          on: {
                            openPane: function ($event) {
                              return _vm.openPane("settings")
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.admin
                      ? _c("big-card", {
                          attrs: {
                            title: "Admin: Manage Members",
                            theme: "light",
                            width: "full",
                            icon: "settings",
                          },
                          on: {
                            openPane: function ($event) {
                              return _vm.openPane("manageMembers")
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                !_vm.admin
                  ? _c("segment", [
                      _vm.loggedInUser
                        ? _c(
                            "div",
                            { staticClass: "btn-list" },
                            [
                              !_vm.member && !_vm.pending
                                ? _c("btn", {
                                    ref: "joinButton",
                                    attrs: { label: "Join Group" },
                                    on: { click: _vm.joinGroup },
                                  })
                                : _vm._e(),
                              _vm.pending && !_vm.member
                                ? _c(
                                    "div",
                                    [
                                      _c("div", { staticClass: "pending" }, [
                                        _vm._v("Pending Approval"),
                                      ]),
                                      _c("btn", {
                                        ref: "cancelButton",
                                        attrs: {
                                          color: "yellow",
                                          label: "Cancel",
                                        },
                                        on: { click: _vm.cancelPending },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.pending && _vm.member
                                ? _c("btn", {
                                    ref: "leaveButton",
                                    attrs: {
                                      color: "red",
                                      label: "Leave Group",
                                    },
                                    on: { click: _vm.leaveGroup },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _c("div", [
                            _c("h3", [
                              _vm._v(
                                "Login or Register to view and join this group"
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "btn-list" },
                              [
                                _c("btn", {
                                  attrs: {
                                    size: "small",
                                    to: "/login",
                                    label: "Login",
                                  },
                                }),
                                _c("btn", {
                                  attrs: {
                                    size: "small",
                                    to: "/register",
                                    label: "Register",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                    ])
                  : _vm._e(),
                _c(
                  "segment",
                  { ref: "groupDetails" },
                  [
                    _c("h2", [_vm._v("Details")]),
                    _vm.page.description !== "null"
                      ? _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.page.description) },
                        })
                      : _vm._e(),
                    _c("innapropriate", {
                      attrs: { page: _vm.page, type: "group" },
                    }),
                  ],
                  1
                ),
                _vm.member || _vm.admin
                  ? _c(
                      "segment",
                      { ref: "groupComments" },
                      [
                        _c("comments", {
                          attrs: { item: _vm.page, type: "group" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.page.tags.length
                  ? _c("segment", { attrs: { title: "Tags" } }, [
                      _c(
                        "div",
                        { staticClass: "tags" },
                        _vm._l(_vm.page.tags, function (tag, index) {
                          return _c("div", { key: index, staticClass: "tag" }, [
                            _vm._v(_vm._s(tag)),
                          ])
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "segment",
                  {
                    staticClass: "other-groups",
                    attrs: { title: "Other Groups" },
                  },
                  [
                    _c("paginated-feed", {
                      attrs: {
                        type: "group",
                        hide: _vm.id,
                        noAds: "",
                        small: "",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }