var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "content" },
        [
          _c("heading", { attrs: { heading: _vm.computedTitle } }),
          _c("div", { staticClass: "picker" }, [
            _c(
              "div",
              {
                ref: "sale",
                staticClass: "picker-item selected",
                on: {
                  click: function ($event) {
                    return _vm.toggle("sale")
                  },
                },
              },
              [
                _c("span", { staticClass: "count" }, [
                  _vm._v(_vm._s(_vm.listingsTotal)),
                ]),
                _c("span", { staticClass: "title" }, [_vm._v("For Sale")]),
              ]
            ),
            _c(
              "div",
              {
                ref: "want",
                staticClass: "picker-item",
                on: {
                  click: function ($event) {
                    return _vm.toggle("want")
                  },
                },
              },
              [
                _c("span", { staticClass: "count" }, [
                  _vm._v(_vm._s(_vm.wantsTotal)),
                ]),
                _c("span", { staticClass: "title" }, [_vm._v("Want To Buy")]),
              ]
            ),
          ]),
          _vm.section == "sale"
            ? _c(
                "div",
                { staticClass: "listings" },
                [
                  _vm.filteredListings.length
                    ? _c(
                        "grid",
                        _vm._l(_vm.filteredListings, function (item, index) {
                          return _c("card", {
                            key: index,
                            attrs: { item: item },
                          })
                        }),
                        1
                      )
                    : _c("div", [
                        _c("h3", [_vm._v("No matches for this filter")]),
                      ]),
                  _vm.canLoadMoreListings
                    ? _c(
                        "div",
                        { staticClass: "loads-more" },
                        [
                          _c("btn", {
                            attrs: { label: "Load More" },
                            on: { click: _vm.loadMoreListings },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.section == "want"
            ? _c(
                "div",
                { staticClass: "wants" },
                [
                  _vm.filteredWants.length
                    ? _c(
                        "grid",
                        _vm._l(_vm.filteredWants, function (want, index) {
                          return _c("card", {
                            key: index,
                            attrs: { item: _vm.item },
                          })
                        }),
                        1
                      )
                    : _c("div", [
                        _c("h3", [_vm._v("No matches for this filter")]),
                      ]),
                  _vm.canLoadMoreWants
                    ? _c(
                        "div",
                        { staticClass: "loads-more" },
                        [
                          _c("btn", {
                            attrs: { label: "Load More" },
                            on: { click: _vm.loadMoreWants },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }