var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "listings top" },
        [
          _vm.listings.length
            ? _c(
                "grid",
                _vm._l(_vm.listings, function (item, index) {
                  return _c("card", { key: index, attrs: { item: item } })
                }),
                1
              )
            : _c("div", { staticClass: "no-listings" }, [
                _c("h2", [_vm._v("No Listings")]),
              ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }