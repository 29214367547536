var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "overflow-wrap" }, [
    _c("div", { staticClass: "bg-shade", style: _vm.bgStyle }),
    _vm.loaded
      ? _c(
          "div",
          { staticClass: "content" },
          [
            _c("div", { staticClass: "banner", style: _vm.banner_styles }),
            _vm.loggedInUser
              ? _c(
                  "pane",
                  { ref: "paneMessage" },
                  [
                    _c("message-form", {
                      attrs: { dataToFill: _vm.dataToFill },
                      on: { closePane: _vm.closePane },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.noUser
              ? _c(
                  "div",
                  { staticClass: "no-listing" },
                  [
                    _c("p", [_vm._v("No User Found")]),
                    _c("btn", { attrs: { to: "/", label: "Go Home" } }),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.noUser
              ? _c(
                  "div",
                  { staticClass: "z-wrap" },
                  [
                    _c("div", { staticClass: "profile-heading" }, [
                      _c("div", { staticClass: "col" }, [
                        _c("div", {
                          staticClass: "profile-image",
                          style: _vm.profile_styles,
                        }),
                      ]),
                      _c("div", { staticClass: "col info" }, [
                        _c("h1", [_vm._v(_vm._s(_vm.user.username))]),
                        _c(
                          "div",
                          { staticClass: "card-wrap" },
                          [
                            _c("profile-card", {
                              staticClass: "long",
                              attrs: { label: "Member Since", value: _vm.date },
                            }),
                            _c("profile-card", {
                              attrs: {
                                label: "Member Number",
                                value: _vm.user.number,
                              },
                            }),
                            _c("profile-card", {
                              attrs: {
                                label: "Account Type",
                                value: _vm.user.accountType,
                              },
                            }),
                            _c("profile-card", {
                              attrs: {
                                label: "Listings",
                                value: _vm.user.listings.length,
                              },
                            }),
                            _c("profile-card", {
                              attrs: {
                                label: "Wants",
                                value: _vm.user.wants.length,
                              },
                            }),
                            _c("profile-card", {
                              attrs: {
                                label: "Followers",
                                value: _vm.user.followers.length,
                              },
                            }),
                            _c("profile-card", {
                              attrs: {
                                label: "Following",
                                value: _vm.user.following.length,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm.loggedInUser && !_vm.yourProfile
                      ? _c("segment", [
                          _c("h3", [_vm._v("Actions")]),
                          _c(
                            "div",
                            { staticClass: "btn-list" },
                            [
                              _c("follow", { attrs: { user: _vm.user } }),
                              _vm.user.email && _vm.user.allowEmail
                                ? _c(
                                    "btn",
                                    {
                                      attrs: { color: "lignt", size: "small" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Email " + _vm._s(_vm.user.username)
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.user.phone && _vm.user.allowPhone
                                ? _c(
                                    "btn",
                                    {
                                      attrs: { color: "light", size: "small" },
                                    },
                                    [
                                      _c("span", [
                                        _c("span", [
                                          _vm._v(
                                            "Text " + _vm._s(_vm.user.username)
                                          ),
                                        ]),
                                        _vm.user.preferText
                                          ? _c("span", [_vm._v("(preferred)")])
                                          : _vm._e(),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.user.phone && _vm.user.allowPhone
                                ? _c(
                                    "btn",
                                    {
                                      attrs: { color: "light", size: "small" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Call " + _vm._s(_vm.user.username)
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.user.bio && _vm.user.bio != "undefined"
                      ? _c("segment", [
                          _c("h3", [_vm._v("Bio")]),
                          _c("div", {
                            domProps: { innerHTML: _vm._s(_vm.user.bio) },
                          }),
                        ])
                      : _vm._e(),
                    _vm.showUserContactPrefs
                      ? _c(
                          "segment",
                          [
                            _c("h2", [_vm._v("Contact Information")]),
                            _c("user-contact-prefs", {
                              attrs: { user: _vm.user },
                            }),
                            _c("h3", [_vm._v("Location")]),
                            _c("span", [_vm._v(_vm._s(_vm.user.cityState))]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "big-card-wrap" },
                      [
                        _c("big-card", {
                          attrs: {
                            title: "Timeline",
                            theme: "dark",
                            icon: "grid",
                          },
                          on: {
                            openPane: function ($event) {
                              return _vm.mountPane("timeline")
                            },
                          },
                        }),
                        _c("big-card", {
                          attrs: {
                            title: "Comment History",
                            theme: "dark",
                            icon: "users",
                          },
                          on: {
                            openPane: function ($event) {
                              return _vm.mountPane("comments")
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.user.listings.length
                      ? _c(
                          "segment",
                          [
                            _c("h2", [_vm._v("Listings")]),
                            _c("paginated-user-feed", {
                              attrs: {
                                user: _vm.user,
                                path: "listings",
                                btn: "",
                                public: "",
                                row: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.user.wants.length
                      ? _c(
                          "segment",
                          [
                            _c("h2", [_vm._v("Wants")]),
                            _c("paginated-user-feed", {
                              attrs: {
                                user: _vm.user,
                                path: "wants",
                                btn: "",
                                public: "",
                                row: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.user.garage.length
                      ? _c("segment", [
                          _c("h2", [
                            _vm._v(
                              "Garage (" + _vm._s(_vm.user.garage.length) + ")"
                            ),
                          ]),
                          _vm.loggedInUser &&
                          _vm.loggedInUser.user_id == _vm.user.user_id
                            ? _c(
                                "div",
                                { staticClass: "action" },
                                [
                                  _c("btn", {
                                    attrs: {
                                      to: "/garage/car/add",
                                      label: "New Car",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "rows" },
                            _vm._l(_vm.user.garage, function (item, index) {
                              return _c("row", {
                                key: index,
                                attrs: { item: item, public: "" },
                              })
                            }),
                            1
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }