<template lang='pug'>

.settings-form

  segment(title='Update Username' light)

    form-text(
      v-model='user.username'
      label='Username'
      name='username')

    .actions
      btn(@click='updateUsername' ref='btnUsername' label='Update Username')

  segment(title='Update Email Address' light)

    form-text(
      v-model='user.email'
      label='Email Address'
      name='email')

    .actions
      btn(ref='btnEmail' @click='updateEmail' label='Update Email Address')

  segment(title='Update Password' light)

    form-text(
      type='password'
      label='Current Password'
      name='password'
      ref='curpass')

    form-text(
      type='password'
      label='New Password'
      name='password'
      ref='newpass')

    .actions
      btn(@click='updatePassword' ref='btnPassword' label='Update Password')

  segment(title='Contact Preferences' light)

    notice(
      v-if='contactNotice'
      color='green'
      notice='Contact Preferences Updated')

    .radio-wrap.checkmark

      label(for='allowEmail')

        input(
          type='checkbox'
          v-model="user.allowEmail"
          name='allowEmail'
          value='allowEmail'
          ref='allowEmail'
          id='allowEmail')

        span.box
          check-icon(class='icon')
          span Show email publically

      label(for='allowPhone')

        input(
          type='checkbox'
          v-model="user.allowPhone"
          name='allowPhone'
          value='allowPhone'
          ref='allowPhone'
          id='allowPhone')

        span.box
          check-icon(class='icon')
          span Show phone publically

      label(for='preferText')

        input(
          type='checkbox'
          v-model="user.preferText"
          name='preferText'
          value='preferText'
          ref='preferText'
          id='preferText')

        span.box
          check-icon(class='icon')
          span Prefer text messages

    .actions
      btn(@click='updateContactPrefs' ref='btnContactPrefs' label='Save Contact Preferences')

  segment(title='Notifications' light)
    p Recieve email notifications for the following actions

    notice(
      v-if='notificationNotice'
      color='green'
      notice='notification Preferences Updated')

    .radio-wrap.checkmark

      label(for='listingNew')

        input(
          type='checkbox'
          v-model="user.notifications.listingNew"
          name='listingNew'
          value='listingNew'
          ref='listingNew'
          id='listingNew')

        span.box
          check-icon(class='icon')
          span Listing Created

      label(for='listingUpdated')

        input(
          type='checkbox'
          v-model="user.notifications.listingUpdated"
          name='listingUpdated'
          value='listingUpdated'
          ref='listingUpdated'
          id='listingUpdated')

        span.box
          check-icon(class='icon')
          span Listing Updated

      label(for='wantNew')

        input(
          type='checkbox'
          v-model="user.notifications.wantNew"
          name='wantNew'
          value='wantNew'
          ref='wantNew'
          id='wantNew')

        span.box
          check-icon(class='icon')
          span Want-Ad Created

      label(for='wantUpdated')

        input(
          type='checkbox'
          v-model="user.notifications.wantUpdated"
          name='wantUpdated'
          value='wantUpdated'
          ref='wantUpdated'
          id='wantUpdated')

        span.box
          check-icon(class='icon')
          span Want-Ad Updated

      label(for='carNew')

        input(
          type='checkbox'
          v-model="user.notifications.carNew"
          name='carNew'
          value='carNew'
          ref='carNew'
          id='carNew')

        span.box
          check-icon(class='icon')
          span Garage Car Created

      label(for='carUpdated')

        input(
          type='checkbox'
          v-model="user.notifications.carUpdated"
          name='carUpdated'
          value='carUpdated'
          ref='carUpdated'
          id='carUpdated')

        span.box
          check-icon(class='icon')
          span Garage Car Updated

      label(for='settingsChanged')

        input(
          type='checkbox'
          v-model="user.notifications.settingsChanged"
          name='settingsChanged'
          value='settingsChanged'
          ref='settingsChanged'
          id='settingsChanged')

        span.box
          check-icon(class='icon')
          span User Settings Changed


      label(for='newFollow')

        input(
          type='checkbox'
          v-model="user.notifications.newFollow"
          name='newFollow'
          value='newFollow'
          ref='newFollow'
          id='newFollow')

        span.box
          check-icon(class='icon')
          span User Followed You

      //- label(for='messageRecieved')

      //-   input(
      //-     type='checkbox'
      //-     v-model="user.notifications.messageRecieved"
      //-     name='messageRecieved'
      //-     value='messageRecieved'
      //-     ref='messageRecieved'
      //-     id='messageRecieved')

      //-   span.box
      //-     check-icon(class='icon')
      //-     span New Message Recieved


    .actions
      btn(@click='updateNotifications' ref='btnNotificationPrefs' label='Save Notification Preferences')

  segment(title='Banner/Background Image' light)

    notice(
      v-if='bannerImageNotice'
      color='green'
      notice='Banner Updated')

    notice(
      v-if='bannerErrorNotice'
      color='red'
      notice='Something Went Wrong')

    image-updater(
      id='banner'
      type='user'
      v-on:updated='bannerUpdated()'
      v-on:error='bannerError()'
      :item='user')

  segment(title='Profile Image' light)

    notice(
      v-if='profileImageNotice'
      color='green'
      notice='Profile Updated')

    notice(
      v-if='profileErrorNotice'
      color='red'
      notice='Something Went Wrong')

    image-updater(
      id='profile'
      type='user'
      v-on:updated='profileUpdated()'
      v-on:error='profileError()'
      :item='user')

  segment(title='Update Information' light)

    notice(
      v-if='userInfoNotice'
      color='green'
      notice='User Info Updated')

    form-text(
      v-model='userData.firstName'
      label='First Name'
      name='firstName')

    form-text(
      v-model='userData.lastName'
      label='Last Name'
      name='lastName')

    form-text(
      v-model='userData.phone'
      label='Phone'
      name='phone')

    form-text(
      v-model='userData.zip'
      label='Zip Code'
      type='number'
      name='zip')

    form-textarea(
      v-model='userData.bio'
      label='Public Bio'
      name='bio')

    .actions
      btn(@click='updateUserInfo' ref='btnUserInfo' label='Save User Information')

  segment(title='Warning' light danger)
    delete-account(:user='user')

</template>

<script>
import axios from 'axios';
import DeleteAccount from '../delete-account';
import ImageUpdater from '/src/components/forms/image-updater';

export default {

  components: {
    DeleteAccount,
    ImageUpdater
  },

  props: {
    user: Object
  },

  data: function() {
    return {
      userData: {
        firstName: null,
        lastName: null,
        phone: null,
        zip: null,
        bio: null
      },
      contactNotice: false,
      notificationNotice: false,
      userInfoNotice: false,
      profileImageNotice: false,
      profileErrorNotice: false,
      bannerImageNotice: false,
      bannerErrorNotice: false
    }
  },

  mounted: function() {
    this.userData.firstName = this.user.firstName;
    this.userData.lastName = this.user.lastName;
    this.userData.bio = this.user.bio;
    this.userData.zip = this.user.zip;
    this.userData.phone = this.user.phone;
  },

  methods: {


    async updateUserInfo() {

      if(this.processing) { return; }
      this.processing = true;
      this.$refs.btnUserInfo.setLoading();

      try {
        await axios.post(
          '/api/v1/user',
          {
            firstName: this.$sanitize(this.userData.firstName),
            lastName: this.$sanitize(this.userData.lastName),
            phone: this.$sanitize(this.userData.phone),
            zip: this.$sanitize(this.userData.zip),
            bio: this.$sanitize(this.userData.bio)
          },
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          }
        );

        gtag('event', 'user info updated');
        this.processing = false;
        this.$refs.btnUserInfo.removeLoading();
        this.userInfoNotice = true;

      } catch(err) {
        gtag('event', 'user info failed');
        console.log('Something went wrong', err);
        this.processing = false;
        this.$refs.btnUserInfo.removeLoading();
      }
    },

    async updateContactPrefs() {

      if(this.processing) { return; }
      this.processing = true;
      this.$refs.btnContactPrefs.setLoading();

      var allowEmail = false;
      if(this.$refs.allowEmail.checked) {
        allowEmail = true;
      }

      var allowPhone = false;
      if(this.$refs.allowPhone.checked) {
        allowPhone = true;
      }

      var preferText = false;
      if(this.$refs.preferText.checked) {
        preferText = true;
      }

      var payload = {
        allowEmail: allowEmail,
        allowPhone: allowPhone,
        preferText: preferText
      }

      try {
        await axios.post(
          '/api/v1/user/contact-prefs',
          payload,
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          }
        );

        gtag('event', 'contact preferences updated');
        this.contactNotice = true;
        this.processing = false;
        this.$refs.btnContactPrefs.removeLoading();

      } catch(err) {
        gtag('event', 'contact preferences failed');
        console.log('Something went wrong', err);
        this.processing = false;
        this.$refs.btnContactPrefs.removeLoading();
      }

    },

    async updateNotifications() {

      if(this.processing) { return; }
      this.processing = true;
      this.$refs.btnNotificationPrefs.setLoading();

      var listingNew = false;
      if(this.$refs.listingNew.checked) {
        listingNew = true;
      }

      var listingUpdated = false;
      if(this.$refs.listingUpdated.checked) {
        listingUpdated = true;
      }

      var wantNew = false;
      if(this.$refs.wantNew.checked) {
        wantNew = true;
      }

      var wantUpdated = false;
      if(this.$refs.wantUpdated.checked) {
        wantUpdated = true;
      }

      var carNew = false;
      if(this.$refs.carNew.checked) {
        carNew = true;
      }

      var carUpdated = false;
      if(this.$refs.carUpdated.checked) {
        carUpdated = true;
      }

      var settingsChanged = false;
      if(this.$refs.settingsChanged.checked) {
        settingsChanged = true;
      }

      var newFollow = false;
      if(this.$refs.newFollow.checked) {
        newFollow = true;
      }

      var payload = {
        listingNew: listingNew,
        listingUpdated: listingUpdated,
        wantNew: wantNew,
        wantUpdated: wantUpdated,
        carNew: carNew,
        carUpdated: carUpdated,
        settingsChanged: settingsChanged,
        newFollow: newFollow
      }

      try {
        await axios.post(
          '/api/v1/user/notification-prefs',
          payload,
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          }
        );

        gtag('event', 'notification preferences updated');
        this.notificationNotice = true;
        this.processing = false;
        this.$refs.btnNotificationPrefs.removeLoading();

      } catch(err) {
        gtag('event', 'notification preferences failed');
        console.log('Something went wrong', err);
        this.processing = false;
        this.$refs.btnNotificationPrefs.removeLoading();
      }

    },

    async updateUsername() {
      if(this.processing) { return; }
      this.processing = true;

      this.$refs.btnUsername.setLoading();

      var sanitizedUsername = this.$sanitize(this.user.username);

      try {
        var submission = await axios.post(
          '/api/v1/user/update-username',
          { username: sanitizedUsername },
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          }
        );

        if(submission.data.success) {
          gtag('event', 'username updated');
          alert('username updated');
          this.$refs.btnUsername.removeLoading();
          this.processing = false;
        } else {
          alert('username already exists, please try again');
          this.$refs.btnUsername.removeLoading();
          this.processing = false;
        }

      } catch(err) {
        gtag('event', 'username update failed');
        console.log('Something went wrong', err);
        this.$refs.btnUsername.removeLoading();
        this.processing = false;
      }
    },

    async updatePassword() {

      // check values before this
      var curpass = this.$sanitize(this.$refs.curpass.value);
      var newpass = this.$sanitize(this.$refs.newpass.value);

      if(curpass == newpass) {
        alert('New password must be different');
        return;
      }

      if(newpass.length < 5) {
        alert('New password must be more than 5 characters');
        return;
      }

      if(this.processing) { return; }
      this.processing = true;

      this.$refs.btnPassword.setLoading();

      try {
        var submission = await axios.post(
          '/auth/update-password',
          {
            curpass: curpass,
            newpass: newpass
          },
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          }
        );

        if(submission.data.success) {
          gtag('event', 'password updated');
          alert('password updated');
          this.$refs.btnPassword.removeLoading();
          this.processing = false;
        } else {
          alert('username already exists, please try again');
          this.$refs.btnPassword.removeLoading();
          this.processing = false;
        }

      } catch(err) {
        gtag('event', 'password update failed');
        console.log('Something went wrong', err);
        this.$refs.btnPassword.removeLoading();
        this.processing = false;
      }
    },

    async updateEmail() {
      if(this.processing) { return; }
      this.processing = true;

      this.$refs.btnEmail.setLoading();

      var sanitizedEmail = this.$sanitize(this.user.email);

      try {
        var submission = await axios.post(
          '/api/v1/user/update-email',
          { email: sanitizedEmail },
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          }
        );

        if(submission.data.success) {
          alert('email address updated');
          this.$refs.btnEmail.removeLoading();
          this.processing = false;
        } else {
          alert('email already exists, please try again');
          this.$refs.btnEmail.removeLoading();
          this.processing = false;
        }

      } catch(err) {
        console.log('Something went wrong', err);
        this.$refs.btnEmail.removeLoading();
        this.processing = false;
      }
    },

    bannerUpdated: function() {
      this.bannerImageNotice = true;
    },

    bannerError: function() {
      this.bannerErrorNotice = true;
    },

    profileUpdated: function() {
      this.profileImageNotice = true;
    },

    profileError: function() {
      this.profileErrorNotice = true;
    }
  }
}

</script>

<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'
@import '~/src/styles/forms.styl'
@import '~/src/styles/listing-forms.styl'

.input-group.select
  input-style()


</style>
