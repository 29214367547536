<template lang="pug">

.listings.top(v-if='loaded')
  grid(v-if='listings.length')
    card(:item='item' v-for="item, index in listings" :key='index')

  .no-listings(v-else)
    h2 No Listings

</template>

<script>

export default {
  name: 'listings-category',

  beforeMount: async function () {
    await this.fetchListings();
    await this.setViewName();
    this.loaded = true;
  },
  data: function() {
    return {
      listings: [],
      loaded: false
    }
  },
  methods: {
    fetchListings: function() {
      this.listings = [];
      fetch('/api/v1/listing/listings/category/' + this.$route.params.category)
      .then(res => res.json())
      .then(listings => {
        this.listings = listings;
      });
    },

    setViewName: function() {
      this.$store.state.view = 'Category: ' + this.$route.params.category.toUpperCase();
    }
  },
  watch: {
    $route (to, from) {
			this.fetchListings();
      this.setViewName();
		}
	}
}
</script>
