var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "comment", class: _vm.classes },
        [
          _c("modal", {
            ref: "modalRemove",
            attrs: { message: "Are You Sure?" },
            on: { pass: _vm.proceedRemove },
          }),
          _vm.removed
            ? _c("div", { staticClass: "removed" }, [
                _c("p", [_vm._v("Comment Deleted")]),
              ])
            : _vm._e(),
          _c("profile-widget", {
            ref: "profile",
            attrs: { userId: _vm.commentUser.user_id },
          }),
          _c("span", { staticClass: "date" }, [
            _vm._v(_vm._s(_vm.date(_vm.comment))),
          ]),
          _c("p", [_vm._v(_vm._s(_vm.comment.message) + " ")]),
          !_vm.mine
            ? _c("div", { staticClass: "voting btn-list" }, [
                _c(
                  "div",
                  {
                    staticClass: "up",
                    class: _vm.upClasses,
                    on: { click: _vm.upvote },
                  },
                  [
                    _c("arrow-up-icon", { staticClass: "icon" }),
                    _vm.comment.upvotes.length
                      ? _c("span", { staticClass: "count" }, [
                          _vm._v(_vm._s(_vm.comment.upvotes.length)),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "down",
                    class: _vm.downClasses,
                    on: { click: _vm.downvote },
                  },
                  [
                    _c("arrow-down-icon", { staticClass: "icon" }),
                    _vm.comment.downvotes.length
                      ? _c("span", { staticClass: "count" }, [
                          _vm._v(_vm._s(_vm.comment.downvotes.length)),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _c(
                "div",
                { staticClass: "voting btn-list mine" },
                [
                  _c(
                    "div",
                    { staticClass: "up" },
                    [
                      _c("arrow-up-icon", { staticClass: "icon" }),
                      _vm.comment.upvotes.length
                        ? _c("span", { staticClass: "count" }, [
                            _vm._v(_vm._s(_vm.comment.upvotes.length)),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "down" },
                    [
                      _c("arrow-down-icon", { staticClass: "icon" }),
                      _vm.comment.downvotes.length
                        ? _c("span", { staticClass: "count" }, [
                            _vm._v(_vm._s(_vm.comment.downvotes.length)),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("btn", { on: { click: _vm.remove } }, [_vm._v("REMOVE")]),
                ],
                1
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }