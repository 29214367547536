<template lang='pug'>

mounted-pane(ref='pane' title='Your Cars' @close='close()')
	.content-rows(v-if='loaded')
		.content
			content-rows(:data='data' type='garage')

</template>

<script>
import { fetchData } from '/src/services/helpers'
import ContentRows from '/src/components/garage/content-rows';

export default {

	components: { ContentRows },

	data: function() {
    return {
      loaded: false,
			data: []
    }
  },

	mounted: async function() {
		this.data = await fetchData('garage')
		this.loaded = true;
	}

}

</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

</style>
