<template lang="pug">

.notices
  notice(v-if='listing.sold' color='green' notice='This listing is marked as sold')
  notice(v-if='listing.deleted' color='red' notice='This listing is deleted, and will be removed soon')

</template>

<script>

export default {
  name: 'listing-notices',
  props: {
    listing: Object
  },
  data: function() {
    return {}
  }
}
</script>



