<template lang="pug">

.metrics(:class='classes')

	.metric(@click='likeThis' :class='likeClasses')
		heart-icon(class='icon')
		.count {{ likes.length }}

	.metric(:to='deeplinkWithComments' v-if='!scroll')
		message-circle-icon(class='icon')
		.count {{ commentLength }}

	.metric(v-else @click='scrollToComments')
		message-circle-icon(class='icon')
		span {{ commentLength }}


</template>

<script>
import axios from 'axios';

export default {
	name: 'likes-and-comments-bar',

	props: {
		item: Object,
		theme: String,
		type: String,
		scroll: Boolean
	},

	beforeMount: async function() {

		await fetch('/api/v1/comment/getAll/' + this.item.internal_id)
			.then(res => res.json())
			.then(comments => {
				this.commentLength = comments.comments
			});
	},

	data: function() {
		return {
			isLiked: false,
			likes: [],
			commentLength: 0
		}
	},

	mounted: function() {
		this.likes = this.item.likes;
	},

	computed: {

		classes: function() {
			if(this.theme == 'small') {
				return 'small';
			}
		},

		likeClasses: function() {
			if(this.isLiked || this.youLikeThis) {
				return 'liked';
			}
		},

		youLikeThis: function() {
			if(this.likes.indexOf(this.$store.state.user.user_id) != -1) {
				return true;
			} else {
				return false;
			}
		},

		deeplinkWithComments: function() {
			if(this.type == 'listing') {
				return '/listing/' + this.item.internal_id + '#comments'
			} else if(this.type == 'want') {
				return '/want/' + this.item.internal_id + '#comments'
			} else if(this.type == 'car') {
				return '/garage/car/detail/' + this.item.internal_id + '#comments'
			} else if(this.type == 'group') {
				return '/garage/car/group/' + this.item.internal_id + '#comments'
			} else if(this.type == 'record') {
				return '#comments'
			} else if(this.type == 'event') {
				return '/event/' + this.item.internal_id + '#comments'
			} else if(this.type == 'timeline') {
				return '/timeline/' + this.item.internal_id + '#comments'
			}
		}

	},
	methods: {
		scrollToComments: function() {
			this.$emit('scroll-to-comments');
		},

		likeThis: async function() {
			if(this.youLikeThis) {
				this.submitUnlike()
			} else {
				this.submitLike();
			}
		},

		submitLike: async function() {
			if(!this.$store.state.user) {
				 return;
			}

			try {
				var likeMe = await axios.post(
					'/api/v1/like',
					{
						id: this.item.internal_id,
						type: this.type,
						user: this.$store.state.user.user_id
					},
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
							'Content-Type' : 'application/json'
						}
					}
				);

				this.isLiked = true;
				this.likes = likeMe.data.likes;

			} catch(err) {
				console.log('The like failed... Something went wrong', err);
			}
		},

		submitUnlike: async function() {
			try {
				var likeMe = await axios.post(
					'/api/v1/like/unlike',
					{
						id: this.item.internal_id,
						type: this.type,
						user: this.$store.state.user.user_id
					},
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
							'Content-Type' : 'application/json'
						}
					}
				);

				this.isLiked = false;
				this.likes = likeMe.data.likes;

			} catch(err) {
				console.log('The like failed... Something went wrong', err);
			}
		}
	}

}
</script>


<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'
@import '~/src/styles/metrics.styl'

</style>


