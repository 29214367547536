


<template lang='pug'>

.big-card(:class='classes' @click='openPane')
  .wrap
    h2
      span.icon-wrap
        mail-icon(v-if='icon == "mail"')
        key-icon(v-if='icon == "key"')
        grid-icon(v-if='icon == "grid"')
        eye-icon(v-if='icon == "eye"')
        crosshair-icon(v-if='icon == "crosshair"')
        users-icon(v-if='icon == "users"')
        heart-icon(v-if='icon == "heart"')
        settings-icon(v-if='icon == "settings"')
        search-icon(v-if='icon == "search"')
        x-icon(v-if='icon == "deleted"')
        trash-2-icon(v-if='icon == "trash"')
        dollar-sign-icon(v-if='icon == "dollar"')
        alert-circle-icon(v-if='icon == "alert"')
        image-icon(v-if='icon == "image"')
        calendar-icon(v-if='icon == "calendar"')
      span {{ title }}
    span(v-if='smallCount').smallCount {{ count }}
    span.count(v-if='!smallCount') {{ count }}
    .view(v-if='!noArrow')
      corner-down-right-icon(class='icon')
    slot

</template>

<script>

export default {

  props: {
    title: String,
    theme: String,
    width: String,
    icon: String,
    count: Number,
    smallCount: Boolean,
    noArrow: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes: function() {
      return this.theme + ' ' + this.width
    }
  },

   methods: {
     openPane: function() {
       this.$emit('openPane');
     }
   }

}

</script>

<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.big-card
  padding 20px
  border-radius 30px
  box-shadow()
  position relative
  margin 10px
  cursor pointer
  flex-grow 1

  @media (max-width:mobile)
    margin 5px
    border-radius 10px
    padding 15px

  &.half
    width calc(50% - 20px)

    @media (max-width:mobile)
      width calc(50% - 10px)

  &.full
    width calc(100% - 20px)

    @media (max-width:mobile)
      width calc(100% - 10px)

  &.light
    background #292929
    color color-text

  &.green
    background green
    color white

  &.red
    background red
    color white

  &.blue
    background #60A3FB
    color black

  &.lightblue
    background #05244C
    color white

  &.dark
    background #101010
    color white

  h2
    margin 0

    @media (max-width:mobile)
      font-size 15px

  .smallCount
    display inline-block
    margin-top 5px
    padding 5px 10px
    background rgba(0, 0, 0, .2)
    border-radius 10px

  .count
    font-size 80px

    @media (max-width:mobile)
      font-size 40px
      display block
      margin-top 5px

  .view
    position absolute
    bottom 20px
    right 20px

    @media (max-width:mobile)
      display none

  h2
    display flex
    align-items center
    margin-right 40px

    @media (max-width:mobile)
      margin-right 0

    span
      display inline-block

    .icon-wrap
      position relative
      display inline-block
      margin-right 6px
      top 3px

</style>
