var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("div", { staticClass: "notice-row" }, [
        !_vm.notice.read
          ? _c("div", { staticClass: "unread" }, [_vm._v("Unread")])
          : _vm._e(),
        _c("div", { staticClass: "msg" }, [
          _vm.notice.emoji
            ? _c("div", { staticClass: "emoji" }, [
                _vm._v(_vm._s(_vm.notice.emoji)),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "msg-content" },
            [
              _vm.notice.message
                ? _c("div", [_vm._v(_vm._s(_vm.notice.message))])
                : _vm._e(),
              _vm.notice.note
                ? _c("div", [_vm._v(_vm._s(_vm.notice.note))])
                : _vm._e(),
              _c("time-ago", { attrs: { item: _vm.notice } }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "btn-list" },
          [
            _c("btn", {
              attrs: {
                color: "red",
                size: "small",
                theme: "outline",
                icon: "trash-2-icon",
              },
              on: {
                clicked: function ($event) {
                  return _vm.remove()
                },
              },
            }),
            !_vm.notice.read
              ? _c("btn", {
                  attrs: {
                    size: "small",
                    theme: "outline",
                    color: "yellow",
                    label: "Mark as Read",
                  },
                  on: { click: _vm.markRead },
                })
              : _vm._e(),
            _vm.notice.url
              ? _c("btn", {
                  attrs: {
                    to: _vm.notice.url,
                    outside: "",
                    theme: "outline",
                    color: "green",
                    size: "small",
                    label: "View",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }