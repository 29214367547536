var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded && _vm.visible
    ? _c(
        "div",
        [
          _vm.loggedInUser.username && _vm.yours
            ? _c("admin-bar", { attrs: { item: _vm.entry, type: "timeline" } })
            : _vm._e(),
          _c("segment", [
            _c("div", {
              staticClass: "status",
              domProps: { innerHTML: _vm._s(_vm.entry.status) },
            }),
          ]),
          _vm.hasGallery
            ? _c(
                "segment",
                { attrs: { transparent: "" } },
                [
                  _c("listing-gallery", {
                    attrs: { item: _vm.entry, bucket: "images" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "segment",
            [
              _c("profile-widget", {
                attrs: { large: "", userId: _vm.entry.user_id },
              }),
              _c("br"),
              _c("badges", { attrs: { page: _vm.entry } }, [
                _vm.entry.is_private
                  ? _c("div", { staticClass: "badge private" }, [
                      _vm._v("PRIVATE"),
                    ])
                  : _vm._e(),
              ]),
              !_vm.entry.is_private
                ? _c("likes-and-comments-bar", {
                    attrs: { item: _vm.entry, type: "timeline", scroll: "" },
                    on: { "scroll-to-comments": _vm.scrollToComments },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("segment", { attrs: { title: "Details" } }, [
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.entry.description) },
            }),
          ]),
          _c("segment", { attrs: { title: "Car" } }, [
            _vm.entry.carId && _vm.entry.carId != "null"
              ? _c(
                  "div",
                  [
                    _c("row", {
                      attrs: { item: _vm.car, customEntryType: "garage" },
                    }),
                  ],
                  1
                )
              : _c("div", { staticClass: "unique-car" }, [
                  _vm.entry.year
                    ? _c("span", [_vm._v(_vm._s(_vm.entry.year))])
                    : _vm._e(),
                  _vm.entry.make
                    ? _c("span", [_vm._v(_vm._s(_vm.entry.make))])
                    : _vm._e(),
                  _vm.entry.model
                    ? _c("span", [_vm._v(_vm._s(_vm.entry.model))])
                    : _vm._e(),
                  _vm.entry.trim
                    ? _c("span", [_vm._v(_vm._s(_vm.entry.trim))])
                    : _vm._e(),
                ]),
          ]),
          !_vm.entry.is_private
            ? _c(
                "segment",
                { ref: "comments", attrs: { id: "comments" } },
                [
                  _c("comments", {
                    attrs: { item: _vm.entry, type: "timeline" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }