var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("div", [
        _vm.data.length > 0
          ? _c(
              "div",
              { staticClass: "feed-all" },
              [
                _vm._l(_vm.data, function (item, index) {
                  return _c("card", {
                    key: index,
                    attrs: { item: item, typeBadge: "" },
                  })
                }),
                _c(
                  "div",
                  { staticClass: "loads-more" },
                  [
                    _vm.canFetchMore
                      ? _c("btn", {
                          attrs: { label: "load more" },
                          on: { click: _vm.loadMore },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              2
            )
          : _c("div", [_c("div", { staticClass: "no" }, [_vm._v("No Data")])]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }