<template lang="pug">

.content.narrow(v-if='theOne')

	h1 NEO
	p Root user controls
	//- br
	//- br
	//- img(src="https://media1.giphy.com/media/mSXSC0vivvygw/giphy.gif")
	//- br
	//- br
	//- //- .btn(@click='addNotification') Add Notification field to all users
	//- br
	//- br
	//- //- .btn(@click='addNotifications') Add Notification properties to all users
	//- br
	//- br
	//- .btn(@click='addEmailVerificationKeys') Add Email Verification Keys
	//- br
	//- br
	//- .btn(@click='sendMeVerification') Send Me Verification
	//- br
	//- br
	//- .btn(@click='setListingType') Set Listing Type
	//- br
	//- br
	//- .btn(@click='setWantType') Set Want Type
	//- br
	//- br
	//- .btn(@click='setCarType') Set Car Type
	//- br
	//- br
	//- .btn(@click='setAdType') Set Ad Type

	//- br
	//- br
	//- .btn(@click='setListingFlagged') Set listing Flagged

	//- br
	//- br
	//- .btn(@click='setWantFlagged') Set Want Flagged

	//- br
	//- br
	//- .btn(@click='setGarageFlagged') Set Garage Flagged

	//- br
	//- br
	//- .btn(@click='setUserBannerImage') Set Banner image

	//- br
	//- br
	//- .btn(@click='setListingScope') Listing Scope

	//- br
	//- br
	//- .btn(@click='setWantScope') Want Scope

	//- br
	//- br
	//- .btn(@click='commentVotes') Comment Votes

	//- br
	//- br
	//- .btn(@click='listingFeatured') Listing Featured

	//- br
	//- br
	//- .btn(@click='wantFeatured') Want Featured

	//- br
	//- br
	//- .btn(@click='groupFeatured') Group Featured

	//- br
	//- br
	//- .btn(@click='addNotices') Add Notices

	br
	br
	.btn(@click='addAdmins') Add Admins


</template>

<script>
import axios from 'axios';

export default {
	name: 'neo',

	data: function() {
		return {}
	},

	mounted: function() {
		if(!this.theOne) {
			window.location.href = '/';
		}
	},

	computed: {
		theOne: function() {
			if(this.$store.state.user.root == 'the-one') {
				return true;
			} else {
				return false;
			}
		}
	},

	methods: {
		async addNotifications() {
			try {
				await axios.get(
					'/api/v1/neo/add-notifications',
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`
						}
					}
				);

				alert('done');
			} catch(err) {
				console.log('Something went wrong', err);
			}
		},

		async addAdmins() {
			try {
				await axios.get(
					'/api/v1/neo/add-admins',
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`
						}
					}
				);

				alert('done');
			} catch(err) {
				console.log('Something went wrong', err);
			}
		},

		async addNotices() {
			try {
				await axios.get(
					'/api/v1/neo/add-notices',
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`
						}
					}
				);

				alert('done');
			} catch(err) {
				console.log('Something went wrong', err);
			}
		},

		async addNotification() {
			try {
				await axios.get(
					'/api/v1/neo/add-notification',
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`
						}
					}
				);
				alert('done');
			} catch(err) {
				console.log('Something went wrong', err);
			}
		},

		async setListingType() {
			try {
				await axios.get(
					'/api/v1/neo/set-listing-type',
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`
						}
					}
				);
				alert('done');
			} catch(err) {
				console.log('Something went wrong', err);
			}
		},

		async setWantType() {
			try {
				await axios.get(
					'/api/v1/neo/set-want-type',
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`
						}
					}
				);
				alert('done');
			} catch(err) {
				console.log('Something went wrong', err);
			}
		},

		async setCarType() {
			try {
				await axios.get(
					'/api/v1/neo/set-car-type',
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`
						}
					}
				);
				alert('done');
			} catch(err) {
				console.log('Something went wrong', err);
			}
		},

		async setUserBannerImage() {
			try {
				await axios.get(
					'/api/v1/neo/set-user-banner-image',
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`
						}
					}
				);
				alert('done');
			} catch(err) {
				console.log('Something went wrong', err);
			}
		},

		async setAdType() {
			try {
				await axios.get(
					'/api/v1/neo/set-ad-type',
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`
						}
					}
				);
				alert('done');
			} catch(err) {
				console.log('Something went wrong', err);
			}
		},

		async addEmailVerificationKeys() {
			try {
				await axios.get(
					'/api/v1/neo/add-verification-key',
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`
						}
					}
				);
				alert('done');
			} catch(err) {
				console.log('Something went wrong', err);
			}
		},

		async sendMeVerification() {
			try {
				await axios.get(
					'/api/v1/neo/test-verification',
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`
						}
					}
				);
				alert('done');
			} catch(err) {
				console.log('Something went wrong', err);
			}
		},

		async commentVotes() {
			try {
				await axios.get(
					'/api/v1/neo/comment-votes',
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`
						}
					}
				);
				alert('done');
			} catch (err) {
				console.log('Something went wrong', err);
			}
		},


		async setListingFlagged() {
			try {
				await axios.get(
					'/api/v1/neo/set-listing-scope',
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`
						}
					}
				);
				alert('done');
			} catch(err) {
				console.log('Something went wrong', err);
			}
		},

		async setListingScope() {
			try {
				await axios.get(
					'/api/v1/neo/set-listing-scope',
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`
						}
					}
				);
				alert('done');
			} catch(err) {
				console.log('Something went wrong', err);
			}
		},

		async setWantScope() {
			try {
				await axios.get(
					'/api/v1/neo/set-want-scope',
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`
						}
					}
				);
				alert('done');
			} catch(err) {
				console.log('Something went wrong', err);
			}
		},


		async setListingFlagged() {
			try {
				await axios.get(
					'/api/v1/neo/set-listing-flagged',
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`
						}
					}
				);
				alert('done');
			} catch(err) {
				console.log('Something went wrong', err);
			}
		},

		async setWantFlagged() {
			try {
				await axios.get(
					'/api/v1/neo/set-want-flagged',
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`
						}
					}
				);
				alert('done');
			} catch(err) {
				console.log('Something went wrong', err);
			}
		},

		async setGarageFlagged() {
			try {
				await axios.get(
					'/api/v1/neo/set-garage-flagged',
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`
						}
					}
				);
				alert('done');
			} catch(err) {
				console.log('Something went wrong', err);
			}
		},

		async listingFeatured() {
			try {
				await axios.get(
					'/api/v1/neo/listing-featured',
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`
						}
					}
				);
				alert('done');
			} catch(err) {
				console.log('Something went wrong', err);
			}
		},
		async wantFeatured() {
			try {
				await axios.get(
					'/api/v1/neo/want-featured',
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`
						}
					}
				);
				alert('done');
			} catch(err) {
				console.log('Something went wrong', err);
			}
		},
		async groupFeatured() {
			try {
				await axios.get(
					'/api/v1/neo/group-featured',
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`
						}
					}
				);
				alert('done');
			} catch(err) {
				console.log('Something went wrong', err);
			}
		}

	}
}
</script>

