var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        [
          _c("heading", {
            attrs: {
              heading: _vm.heading,
              "user-action": _vm.action,
              "user-label": "< Back",
            },
          }),
          _c("div", { staticClass: "car" }, [
            _c("div", [_vm._v(_vm._s(_vm.carHeading))]),
          ]),
          _c("car-record-form", {
            attrs: { edit: "", car: _vm.car, recordToEdit: _vm.record },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }