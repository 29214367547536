<template lang="pug">

.notice-wrap

  pane(ref='feedback' title='Submit Feedback')
    
    form-text(
      v-model='feedback.name'
      label='Name' 
      name='name')

    form-textarea(
      label='Comment'
      name='comment'
      ref='comment'
      v-model='feedback.comment')

    btn(@click='submitFeedback' label='Submit')

  .feedback-notice(@click='openPane("feedback")' v-if='loggedIn')
    p This app is currently in user testing
    span Submit Feedback

</template>

<script>
import axios from 'axios';

export default {
  name: 'app-notice',

  data: function() {
    return {
      loggedIn: false,
      feedback: {
        name: '',
        comment: ''
      }
    }
  },

  methods: {

    openPane: function(key) {
      this.$refs[key].openPane();
    },

    closePane: function(key) {
      this.$refs[key].close();
    },

    submitFeedback: async function() {
      
      this.error = '';
      this.loading = true;


      try {

        let submission = await axios.post(
          '/api/v1/user/feedback', 
          {
            name: this.feedback.name,
            comment: this.feedback.comment
          },
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          });

        alert('Thank you for the feedback!');
        this.closePane("feedback");
        this.feedback.name = '';
        this.feedback.comment = '';
        this.$refs.comment.value = '';
      }

      catch(err) {
        alert('Something went wrong', err);
        console.log('Something went wrong', err);
      }

    }
  },

  watch: {
    // check for when vuex boots to determine if you're logged in
    '$store.state.user': function(to, from) {
      if(to.username) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }
    }
  }
}

</script>


<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.notice-wrap
  position fixed
  top 0
  left 0
  width 100%
  z-index header-z + 1

>>> .scrolling
  width 80%
  margin 0 auto
  text-align left

.feedback-notice
  background yellow
  color black
  height 28px
  display flex
  align-items center
  justify-content center
  cursor pointer
  height notice-h
  position fixed
  top 0
  left 0
  width 100%
  z-index header-z

  p, span
    font-size 13px
    text-transform uppercase
    position relative

  span
    font-weight bold
    text-decoration underline
    display inline-block
    margin 0 20px

  @media (max-width:mobile)
    p
      display none


</style>
