<template lang='pug'>

.image-updater(v-if='loaded')
  uploader(
    :id='id'
    single
    :type='type'
    v-on:master-batch='onSelect'
    :initial='initial')

  btn(@click='update' ref='btn' :label='label')

</template>

<script>
import axios from 'axios';

export default {

  props: {
    id: String,
    type: String,
    item: Object,
    documentId: String
  },

  data() {
    return {
      loaded: false,
      gallery: [],
      to_remove: []
    }
  },

  mounted: function() {
    this.loaded = true;
  },

  computed: {
    label: function() {
      if(this.id == 'banner') { return "Update Banner Image" }
      if(this.id == 'profile') { return "Update Profile Image" }
    },

    initial: function() {
      if(this.item) {
        if(this.id == 'banner') {
          return this.item.banner_image;
        } else if(this.id == 'profile') {
          return this.item.profile;
        }
      }
    }
  },

  methods: {

    onSelect(files) {

      // if profile or banner
      if(this.id == 'profile') {
        this.to_remove = [
          this.item.profile
        ]
      } else if(this.id == 'banner') {
        this.to_remove = [
          this.item.banner_image
        ]
      }

      this.gallery = files;
    },

    update: async function() {
      this.$refs.btn.setLoading();

      let formData = new FormData();
      formData.append('to_remove', this.to_remove);

      // prepare file list
      let fileListArray = [];
      this.gallery.forEach(function (img, index) {
        fileListArray.push(img.file);
      });

      // Create the file list
      for(var i = 0; i < fileListArray.length; i++) {
        formData.append('file', fileListArray[i]);
      }

      try {

        if(this.type == 'group') {
          var endpoint = '/api/v1/' + this.type + '/update-' + this.id + '/' + this.documentId;
        } else {
          var endpoint = '/api/v1/' + this.type + '/update-' + this.id;
        }


        var submission = await axios.post(
          endpoint,
          formData,
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          }
        );

        if(submission.data.success) {
          gtag('event', 'updated');
          this.$emit('updated');
          this.$refs.btn.removeLoading();
          this.processing = false;
        }

      } catch(err) {
        gtag('event', this.id + ' image update failed');
        console.log('Something went wrong', err);
        this.$emit('error');
        this.$refs.btn.removeLoading();
        this.processing = false;
      }

    }
  }

}

</script>

<style lang='stylus' scoped>

</style>
