<template lang='pug'>

mounted-pane(ref='pane' :title='computedTitle' noClose location='top' @close='close()')
	.content-rows(v-if='loaded')
		.content
			p {{ message }}

			.btn-list
				btn(:to='viewLink' size='small' label='View' v-if='viewLink')
				btn(size='small' label='+ Another' @click='another()')
				btn(:to='backLink' size='small' label='Back' v-if='backLink')


</template>

<script>

export default {

	props: {
		title: String,
		backLink: String,
		viewLink: String,
		message: String,
		parentEl: Object
	},

	data: function() {
		return {
			loaded: false
		}
	},

	mounted: async function() {
		this.loaded = true;
	},

	computed: {
		computedTitle: function() {
			return '⚡️ ' + this.title + ' Updated';
		}
	},

	methods: {
		another: function() {
			this.parentEl.reset();
			this.close();
		},

		close: function() {
			this.$refs.pane.close();
		}
	},

	watch: {
    $route (to, from) {
			this.close();
		}
	}

}

</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.content
	padding-bottom 20px
	padding-left 20px
	padding-right 20px
	width 100%
	text-align left

</style>
