var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "segment",
    [
      _c(
        "segment-header",
        [
          _c("h1", [_vm._v("New Message")]),
          _c("router-link", { staticClass: "back", attrs: { to: "/garage" } }, [
            _vm._v("Back To Garage"),
          ]),
        ],
        1
      ),
      !_vm.loading ? _c("message-form") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }