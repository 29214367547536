var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "info-badge members" }, [
      _c("span", [_vm._v("Members")]),
      _c("span", [_vm._v(_vm._s(_vm.item.members.length))]),
    ]),
    _c("div", { staticClass: "info-badge established" }, [
      _c("span", [_vm._v("Established")]),
      _c("span", [_vm._v(_vm._s(_vm.created))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }