<template lang="pug">

.row(:class='classes')
	row-listing(:item='item' v-if='entryType == "listing"' :status='status' :showProfile='showProfile' :forceDelete='forceDelete')
	row-treasure(:item='item' v-if='entryType == "treasure"' :status='status' :showProfile='showProfile' :forceDelete='forceDelete')
	row-want(:item='item' v-if='entryType == "want"' :status='status' :showProfile='showProfile')
	row-garage(:item='item' v-if='entryType == "garage"' :status='status' :showProfile='showProfile')
	row-group(:item='item' v-if='entryType == "group"' :status='status' :showProfile='showProfile')
	row-search(:item='item' v-if='entryType == "search"' :status='status' :showProfile='showProfile')
	row-record(:item='item' v-if='entryType == "record"' :status='status' :showProfile='showProfile')
	row-timeline(:item='item' v-if='entryType == "timeline"' :status='status' :showProfile='showProfile')
	row-event(:item='item' v-if='entryType == "event"' :status='status' :showProfile='showProfile')

</template>

<script>
import RowGarage from './garage';
import RowGroup from './group';
import RowListing from './listing';
import RowWant from './want';
import RowSearch from './search';
import RowRecord from './record';
import RowTreasure from './treasure';
import RowTimeline from './timeline';
import RowEvent from './event';

export default {
	name: 'row',

	components: {
		RowGarage,
		RowListing,
		RowWant,
		RowGroup,
		RowSearch,
		RowRecord,
		RowTreasure,
		RowTimeline,
		RowEvent
	},

	props: {
		item: Object,
		customEntryType: String,
		status: String,
		layout: {
			type: String,
			default: 'row'
		},
		showProfile: {
			type: Boolean,
			default: false
		},
		forceDelete: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		entryType: function() {
			return this.customEntryType || this.item.entry_type;
		},
		classes: function() {
			return 'layout-' + this.layout
		}
	}
}
</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.row
	margin 20px 0
	padding 20px 0 0 0
	border-top 1px solid bdr
	position relative

	&:first-child
		border-top none

>>>

	h2
		font-size 22px
		margin-bottom 4px
		color color-text
		max-width 500px

		@media(max-width:mobile)
			font-size 18px

	.actions
		margin-top 5px

	.row-contents
		display flex
		color white
		align-items center
		width 100%
		position relative

	.img-area
		width 150px
		border-radius 20px
		overflow hidden
		margin-right 30px
		flex-shrink 0
		position relative

		&:after
			content ''
			height 90px
			width 100%
			position absolute
			bottom 0
			left 0
			display block
			pointer-events none
			background linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%)


		.image-placeholder
			aspect-ratio 3/2.5
			position relative

			img
				position absolute
				width 200%
				height 200%
				margin-top -60px
				margin-left -110px

		@media(max-width:mobile-small)
			width 85px
			border-radius 10px
			margin-right 14px

	.info-area
		width calc(100% - 180px)

		@media(max-width:mobile-small)
			width calc(100% - 100px)

	@media(max-width:mobile-small)
		.row-contents
			flex-wrap wrap
			align-items flex-start

		.base-image
			width 100%

>>> .profile-widget
	position absolute !important
	bottom 0
	left 0
	padding 5px 10px
	z-index 1

	.profile
		width 20px !important
		height 20px !important


</style>
