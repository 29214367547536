<template lang='pug'>

.share(v-if='loaded')

	.iframe-wrap
		iframe(:src='src' width='450' height='35' style='border:none;overflow:hidden' scrolling='no' frameborder='0' allowfullscreen='true' allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share')

	.tweet
		a.twitter-share-button(href='https://twitter.com/share?ref_src=twsrc%5Etfw' data-show-count='false') Tweet
		
</template>

<script>


export default {

	props: {
		id: String,
    type: String
	},

	data: function() {
    return {
      loaded: false
    }
  },

	mounted: function() {
		this.loaded = true;
	},

	computed: {
		url: function () {
			return "https://partstash.com/#/" + this.type + "/" + this.id
		},
		src: function() {
			return "https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fpartstash.com%2F%23%2F" + this.type + "%2F" + this.id +"&width=450&layout=standard&action=like&size=small&share=true&height=35&appId=1791128187766810"
		}
	}
}

</script>

<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.share
	margin 20px 0
	display flex
	align-items center

	.iframe-wrap
		margin-right 10px
		height 24px
		width 115px
		overflow hidden

	.tweet
		a
			position relative
			top -4px
			background lightblue
			color white
			font-size 10px
			padding 3px 10px
			border-radius 100px
	
</style>