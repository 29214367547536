var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mounted-pane",
    {
      ref: "pane",
      attrs: { title: "Car Records" },
      on: {
        close: function ($event) {
          return _vm.close()
        },
      },
    },
    [
      _vm.loaded
        ? _c("div", { staticClass: "content-rows" }, [
            _c(
              "div",
              { staticClass: "content" },
              [
                _vm.loggedInUser && _vm.mine
                  ? _c("btn", {
                      attrs: { to: _vm.formUrl, label: "Add Record" },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "records" },
                  _vm._l(_vm.records, function (record, index) {
                    return _c(
                      "row",
                      {
                        key: index,
                        attrs: { item: record, customEntryType: "record" },
                      },
                      [_vm._v(_vm._s(record.name))]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }