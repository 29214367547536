<template lang="pug">

span.follow(v-if='loggedInUser')
	btn(theme='outline' color='light' size='small' @click='follow' v-if='!isFollowing')
		span Follow
		span(v-if='!noUserName') {{ user.username }}

	btn(theme='outline' color='red' size='small' @click='unfollow' v-else)
		span Unfollow
		span(v-if='!noUserName') {{ user.username }}

</template>

<script>
import axios from 'axios';

export default {
  name: 'follow-widget',

	props: {
    user: Object,
		noUserName: Boolean
  },

  computed: {
    loggedInUser: function() {
      return this.$store.state.user;
    },

    isFollowing: function () {
      if(!this.loggedInUser) { return; }
      if(this.loggedInUser.following.find(el => el == this.user.user_id)) { return true; } 
      else { return false; }
    },
  },

  methods: {

    follow: async function() {
      try {

        var req = await axios.post(
          '/api/v1/user/follow', 
          { user_id: this.user.user_id },
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          });

					// set the data to show this change immediately
					var following = req.data.following;
					this.$store.state.user.following = following;
          
      }

      catch(err) {
        console.log('Something went wrong', err);
      }
    },

    unfollow: async function() {
      try {

        var req = await axios.post(
          '/api/v1/user/unfollow', 
          { user_id: this.user.user_id },
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          });

					// set the data to show this change immediately
					var following = req.data.following;
					this.$store.state.user.following = following;

      }

      catch(err) {
        console.log('Something went wrong', err);
      }
    }
  }
}
</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.follow
	display flex
	align-items center

</style>