<template lang="pug">

.logo
	img(src='/assets/logo.svg')

</template>

<script>

export default {
  name: 'logo',
  props: {
    message: String
  },
  data: function() {
    return {}
  }
}
</script>

<style lang='stylus' scoped>

.logo
  background transparent
  padding 9px
  cursor pointer

</style>



