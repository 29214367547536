var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "segment-header" },
    [
      _c("div", { staticClass: "slot" }, [_vm._t("default")], 2),
      _vm.expandable
        ? _c("chevron-down-icon", { staticClass: "icon" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }