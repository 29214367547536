var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.choicePicked && _vm.lock
        ? _c("div", { staticClass: "lock-show" }, [
            _c("div", { staticClass: "choice pill blue large" }, [
              _c("span", [_vm._v(_vm._s(_vm.choicePicked))]),
              !_vm.initial
                ? _c(
                    "span",
                    { staticClass: "remove", on: { click: _vm.clear } },
                    [_c("x-icon", { staticClass: "icon" })],
                    1
                  )
                : _vm._e(),
            ]),
          ])
        : _c("form-text", {
            attrs: { label: _vm.label },
            on: {
              inputChanged: function ($event) {
                return _vm.onInputChange($event)
              },
            },
            model: {
              value: _vm.picking,
              callback: function ($$v) {
                _vm.picking = $$v
              },
              expression: "picking",
            },
          }),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showResults,
                expression: "showResults",
              },
            ],
            staticClass: "autocomplete",
          },
          _vm._l(_vm.results, function (result, index) {
            return _c(
              "div",
              {
                staticClass: "choice",
                on: {
                  click: function ($event) {
                    return _vm.assign(result)
                  },
                },
              },
              [
                _c("div", { staticClass: "pill blue large" }, [
                  _vm._v(_vm._s(result)),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }