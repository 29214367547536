var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "profile" }, [
    _c("div", { staticClass: "profile-image", style: _vm.profile_styles }),
    _vm.user && _vm.notifications
      ? _c("div", { staticClass: "notifications" })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }