<template lang="pug">

.modal-simple.success

  transition(name='fade')
    .overlay(v-show='open' @click='checkToClose')
  
  transition(name='slide')  
    .content(v-show='open' ref='scroll' id='successModalScroll')
      .close(v-if='!noClose' @click='close')
        x-icon(class='icon')

      .message
        h2 
          span {{ title }} - {{ message }}
        
        .pill.blue.xl(v-if='viewTitle' @click='goView') {{ viewTitle }}
      
      .btn-list

        btn(color='light' v-if='!advert' @click='goView' label='View')
        btn(color='green' v-if='!advert' @click='close' label='+ Another')
        btn(v-if='!advert' @click='goGarage' label='Your Garage')
        btn(@click='goAdmin' v-if='advert' label='Back to Admin')

</template>

<script>
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

export default {
  name: 'success-modal',

  props: {
    id: String,
    want: Boolean,
    group: Boolean,
    title: String,
    message: String,
    listing: Boolean,
    garage: Boolean,
    noView: Boolean,
    noClose: Boolean,
    advert: Boolean
  },
  data: function() {
    return {
      open: false,
      viewId: '',
      viewTitle: '',
    }
  },
  methods: {
    openModal() {
      disableBodyScroll(this.$refs.scroll);
      this.open = true;
    },
    goHome() {
      this.resetScroll();
      this.$router.push('/');
    },
    goView(id) {
      this.resetScroll();

      var base = '/listing/';

      if(this.want) {
        var base = '/want/';
      }

      if(this.group) {
        var base = '/group/';
      }

      if(this.garage) {
        var base = '/garage/car/detail/';
      }

      this.$router.push(base + this.viewId);
    },
    goGarage() {
      this.resetScroll();
      this.$router.push('/garage');
    },
    goAdmin() {
      this.resetScroll();
      this.$router.push('/admin');
    },
    setContent(id, title) {
      this.viewId = id;
      this.viewTitle = title;
    },
    close() {
      this.resetScroll();
      this.open = false;
      this.$emit('close');   
    },
    resetScroll() {
      enableBodyScroll(this.$refs.scroll);
    },
    checkToClose() {
      if(!this.noClose) {
        this.close();
      }
    }
  }
}
</script>

<style lang='stylus' scoped>

.pill
  cursor pointer

</style>