var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card signup-card" }, [
    _c("img", { attrs: { src: "/assets/logo-blue.svg" } }),
    _c("div", { staticClass: "message" }, [
      _c("h3", [_vm._v("Join Now")]),
      _c("p", [
        _vm._v(
          "Create an account to contact this seller to get the part you need."
        ),
      ]),
      _c(
        "div",
        { staticClass: "btn-list" },
        [
          _c("btn", { attrs: { to: "/login", size: "small", label: "Login" } }),
          _c("btn", {
            attrs: { to: "/register", size: "small", label: "Register" },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }