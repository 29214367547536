<template lang='pug'>

.users.content.narrow(v-if='loaded')
  heading(heading='All Users')

  segment
    form-text(
      label='Search For a User'
      name='user'
      v-on:inputChanged='onInputChange($event)')

    .autocomplete(v-show='showResults')
      UserRow(
        v-for='user, index in results' 
        :user='user' 
        :key='index')

  segment
    .main-list
      UserRow(
        v-for='user, index in paginatedUsers' 
        :key='index' 
        :user="user")

    .loads-more-wrap(v-if='canLoadMore')
      btn(@click='loadMore' label='Load More')

  segment
    invite
        
</template>

<script>
import UserRow from '../../components/globals/rows/user';
import Invite from '../../components/ui/invite';

export default {
  
  components: {
    UserRow,
    Invite
  },

  data() {
    return {
      users: [],
      paginatedUsers: [],
      results: [],
      loaded: false,
      picking: null,
      showResults: false,
      choicePicked: null,
      index: 0
    }
  },

  beforeMount: async function() {

    await fetch('/api/v1/profile/all')
      .then(res => res.json())
      .then(users => {
        this.users = users;
      });

    this.fetchPaginatedUsers();
  },

  mounted: function() {
    this.$store.state.view = 'Search Users';
    this.loaded = true;
  },

  computed: {
    query: function() {
      return this.$route.params.query;
    },

    canLoadMore: function() {
      if(this.paginatedUsers.length < this.users.length) {
        return true;
      } else {
        return false;
      }
    }
  },

  methods: {

    // fetch paginated users
    fetchPaginatedUsers: async function() {
      await fetch('/api/v1/profile/all/' + this.index)
      .then(res => res.json())
      .then(data => {
        this.paginatedUsers.push(...data);
      });
    },

    loadMore: function() {
      this.index++;
      this.fetchPaginatedUsers();
    },

    // Once chosen, then set the val
    assign: function(val) {
      this.clear();
      this.choicePicked = val;
      this.picking = val;
      this.$emit('selection', val);
    },

    // Do the autocomplete filtering
    filterMakeResults: function() {
      this.results = this.users.filter(result => result.username.toLowerCase().indexOf(this.picking.toLowerCase()) > -1);
    },

    onInputChange: function(e) {
      this.picking = e;
      if(this.picking.length == 0) {
        this.clear();
      } else {
        this.filterMakeResults();
        this.showResults = true;
      }
    },

    clear: function() {
      this.picking = null;
      this.showResults = false;
      this.results = [];
      this.choicePicked = null;
      this.$emit('cleared', true);
    }

  }

}
</script>


<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.main-list, .loads-more-wrap
  margin-top 30px

.autocomplete
  padding 5px 15px
  margin 20px 0
  background #000
  border-radius 10px

</style>