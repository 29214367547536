<template lang="pug">

div
	h3.hide-desktop {{ mobileTitle }}
	h3.hide-mobile {{ desktopTitle }}

</template>

<script>
import { trimTitle } from '/src/services/helpers';

export default {
	props: {
		title: String
	},

	computed: {
		mobileTitle: function() {
			return trimTitle(this.title, 30);
		},
		desktopTitle: function() {
			return trimTitle(this.title, 20);
		}
	}
}
</script>

<style lang='stylus' scoped>

h3
	color white
	margin-bottom 7px

</style>
