<template lang='pug'>

mounted-pane(ref='pane' title='Car Records' @close='close()')
  .content-rows(v-if='loaded')
    .content
      btn(:to='formUrl' v-if='loggedInUser && mine' label='Add Record')

      .records
        row(
          v-for='record, index in records'
          :key='index'
          :item='record'
          customEntryType='record') {{ record.name }}


</template>

<script>

export default {

  props: {
    car: Object,
    owner: Boolean
  },

  data: function() {
    return {
      loaded: false,
      records: []
    }
  },

  beforeMount: async function() {
    await fetch('/api/v1/garage/records/' + this.car.internal_id)
      .then(res => res.json())
      .then(records => {
        this.records = records;
      });
  },

  mounted: async function() {
    this.loaded = true;
  },

  computed: {
    formUrl: function() {
      return '/garage/car/detail/' + this.car.internal_id + '/records/new'
    },

		loggedInUser: function() {
			return this.$store.state.user;
		},

		mine: function() {
			if(this.loggedInUser && this.car.owner == this.loggedInUser.user_id) {
				return true;
			} else {
				return false;
			}
		}
  }

}

</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.records
  margin-top 30px

</style>
