var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.data.length
      ? _c(
          "div",
          _vm._l(_vm.data, function (item, index) {
            return _vm.rows
              ? _c("row", {
                  key: index,
                  attrs: { item: item, status: _vm.status },
                })
              : _vm._l(_vm.data, function (item, index) {
                  return _c("card", { key: index, attrs: { item: item } })
                })
          }),
          1
        )
      : _c("div", [
          _c("p", { staticClass: "none" }, [_vm._v("No Groups yet")]),
        ]),
    _vm.canLoadMore
      ? _c(
          "div",
          { staticClass: "loads-more" },
          [
            _c("btn", {
              attrs: { label: "Load More" },
              on: { click: _vm.loadMore },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }