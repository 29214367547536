var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "profile-widget", class: _vm.classes },
        [
          _c(
            "router-link",
            { staticClass: "profile", attrs: { to: _vm.profileLink } },
            [
              _c("div", {
                staticClass: "profile-image",
                style: _vm.profile_styles,
              }),
            ]
          ),
          _c(
            "router-link",
            { staticClass: "span name", attrs: { to: _vm.profileLink } },
            [
              _vm.short
                ? _c("span", { staticClass: "short" }, [
                    _vm._v(_vm._s(_vm.truncate(_vm.name))),
                  ])
                : _c("span", [_vm._v(_vm._s(_vm.name))]),
            ]
          ),
          _vm.large
            ? _c(
                "div",
                { staticClass: "actions" },
                [
                  !_vm.loggedInUser
                    ? _c("follow", {
                        attrs: { user: _vm.user, noUserName: "" },
                      })
                    : _vm._e(),
                  _c("btn", {
                    attrs: {
                      size: "small",
                      color: "grey",
                      to: _vm.profileLink,
                      label: "View Profile",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }