var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "metrics" }, [
    _c("div", { staticClass: "metric metric-listings" }, [
      _c("div", { staticClass: "label" }, [_vm._v("Listings")]),
      _c("div", { staticClass: "count" }, [_vm._v(_vm._s(_vm.listingCount))]),
    ]),
    _c("div", { staticClass: "metric metric-wants" }, [
      _c("div", { staticClass: "label" }, [_vm._v("Wants")]),
      _c("div", { staticClass: "count" }, [_vm._v(_vm._s(_vm.wantCount))]),
    ]),
    _c(
      "div",
      { staticClass: "metric metric-members" },
      [
        _c("users-icon", { staticClass: "icon" }),
        _c("div", { staticClass: "count" }, [_vm._v(_vm._s(_vm.memberCount))]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "metric metric-likes" },
      [
        _c("heart-icon", { staticClass: "icon" }),
        _c("div", { staticClass: "count" }, [_vm._v(_vm._s(_vm.likeCount))]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "metric metric-comments" },
      [
        _c("message-circle-icon", { staticClass: "icon" }),
        _c("div", { staticClass: "count" }, [_vm._v(_vm._s(_vm.commentCount))]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }