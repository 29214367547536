var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "overflow-wrap" }, [
    _vm.hasGallery
      ? _c("div", { staticClass: "bg-shade", style: _vm.bgStyle })
      : _vm._e(),
    _c("div", { staticClass: "listing-detail content narrow" }, [
      _vm.noListing
        ? _c(
            "div",
            { staticClass: "no-listing" },
            [
              _c("p", [_vm._v("No Listing Found")]),
              _c("btn", { attrs: { to: "/", label: "Go Home" } }),
            ],
            1
          )
        : _vm._e(),
      _vm.hidden
        ? _c("div", { staticClass: "hidden" }, [
            _vm.privateGroupListing
              ? _c("div", { staticClass: "private" }, [
                  _c("p", [_vm._v("This is a private want-ad for the group")]),
                  _c("a", { attrs: { href: _vm.groupUrl } }, [
                    _vm._v(_vm._s(_vm.groupName)),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.loaded && _vm.listing && !_vm.hidden
        ? _c(
            "div",
            [
              _vm.loaded && _vm.user.username
                ? _c(
                    "pane",
                    { ref: "paneMessage" },
                    [
                      _c("message-form", {
                        ref: "message",
                        attrs: { dataToFill: _vm.dataToFill, noChoice: "" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("listing-notices", { attrs: { listing: _vm.listing } }),
              _vm.user.username && _vm.yourListing
                ? _c("admin-bar", { attrs: { item: _vm.listing } })
                : _vm._e(),
              _c(
                "segment",
                { attrs: { transparent: "" } },
                [
                  _vm.hasGallery
                    ? _c("listing-gallery", { attrs: { item: _vm.listing } })
                    : _vm._e(),
                  _c("badges", { attrs: { page: _vm.listing } }),
                  _vm.privateGroupListing
                    ? _c("div", { staticClass: "private" }, [
                        _c("p", [
                          _vm._v("This is a private want-ad for the group"),
                        ]),
                        _c(
                          "a",
                          { attrs: { href: _vm.groupUrl } },
                          [
                            _c(
                              "btn",
                              { attrs: { color: "grey", size: "small" } },
                              [_vm._v(_vm._s(_vm.groupName))]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("likes-and-comments-bar", {
                    attrs: { item: _vm.listing, type: "listing", scroll: "" },
                    on: { "scroll-to-comments": _vm.scrollToComments },
                  }),
                ],
                1
              ),
              _c(
                "segment",
                [
                  _c("h1", [_vm._v(_vm._s(_vm.title))]),
                  _c("time-ago", { attrs: { item: _vm.listing } }),
                  _c("price", { attrs: { listing: _vm.listing } }),
                  _c(
                    "div",
                    { staticClass: "watchers" },
                    [
                      _vm.peopleWatching < 1
                        ? _c("eye-off-icon", { staticClass: "off icon" })
                        : _c("eye-icon", { staticClass: "on icon" }),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.peopleWatching) +
                            " " +
                            _vm._s(_vm.watchLabel) +
                            " Watching This"
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("share", {
                    attrs: { id: _vm.listing.internal_id, type: "listing" },
                  }),
                ],
                1
              ),
              _c(
                "segment",
                [
                  _c("profile-widget", {
                    attrs: { large: "", userId: _vm.listing.user_id },
                  }),
                ],
                1
              ),
              _vm.user.username && !_vm.yourListing
                ? _c("segment", [
                    _c("h2", [_vm._v("User Actions")]),
                    _c(
                      "div",
                      { staticClass: "btn-list" },
                      [
                        _vm.isWatched
                          ? _c("btn", {
                              attrs: {
                                theme: "light",
                                size: "small",
                                label: "On your watchlist! Remove",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.removeWatch()
                                },
                              },
                            })
                          : _c("btn", {
                              attrs: {
                                size: "small",
                                label: "Watch This Listing",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.watch()
                                },
                              },
                            }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              !_vm.user.username && !_vm.yourListing
                ? _c("segment", [_c("signup-card")], 1)
                : _vm._e(),
              _vm.user
                ? _c(
                    "segment",
                    [
                      _c("h2", [_vm._v("Shipping / Pickup Options")]),
                      _c("segment-row", [
                        _c("h3", [_vm._v("Location")]),
                        _c("span", [_vm._v(_vm._s(_vm.user.cityState))]),
                      ]),
                      _c("segment-row", [
                        _c("h3", [_vm._v("Shipping")]),
                        _c("span", [_vm._v(_vm._s(_vm.shipping))]),
                      ]),
                      _c("segment-row", [
                        _vm.user.username
                          ? _c(
                              "div",
                              [
                                _c("user-contact-prefs", {
                                  attrs: { user: _vm.owner },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "segment",
                [
                  _c(
                    "segment-row",
                    [
                      _c("h3", [_vm._v("For")]),
                      _vm.noCar
                        ? _c("p", [_vm._v("Multiple Fitments")])
                        : _c("car-info", { attrs: { item: _vm.listing } }),
                    ],
                    1
                  ),
                  _vm.listing.mileage
                    ? _c("segment-row", [
                        _c("h3", [_vm._v("Mileage")]),
                        _c("span", [_vm._v(_vm._s(_vm.listing.mileage))]),
                      ])
                    : _vm._e(),
                  _vm.listing.vin
                    ? _c("segment-row", [
                        _c("h3", [_vm._v("VIN")]),
                        _c("span", [_vm._v(_vm._s(_vm.listing.vin))]),
                      ])
                    : _vm._e(),
                  _vm.listing.partNumber
                    ? _c("segment-row", [
                        _c("h3", [_vm._v("Part Number")]),
                        _c("span", [_vm._v(_vm._s(_vm.listing.partNumber))]),
                      ])
                    : _vm._e(),
                  _vm.listing.condition
                    ? _c(
                        "segment-row",
                        [
                          _c("h3", [_vm._v("condition")]),
                          _c("condition", {
                            attrs: { condition: _vm.listing.condition },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("segment-row", [
                    _c("h3", [_vm._v("Listed On")]),
                    _c("span", [_vm._v(_vm._s(_vm.listing_date))]),
                  ]),
                  _vm.listing.body != "null"
                    ? _c("segment-row", [
                        _c("h3", [_vm._v("Additional Details")]),
                        _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.listing.body) },
                        }),
                      ])
                    : _vm._e(),
                  _vm.listing.tags.length
                    ? _c("segment-row", [
                        _c("h3", [_vm._v("Tags")]),
                        _c(
                          "div",
                          { staticClass: "tags" },
                          _vm._l(_vm.listing.tags, function (tag, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "tag" },
                              [_vm._v(_vm._s(tag))]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "segment-row",
                    [
                      _c("innapropriate", {
                        attrs: { page: _vm.listing, type: "listing" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "segment",
                { ref: "comments", attrs: { id: "comments" } },
                [
                  _c("comments", {
                    attrs: { item: _vm.listing, type: "listing" },
                  }),
                ],
                1
              ),
              _vm.related && _vm.related.length > 1
                ? _c(
                    "segment",
                    [
                      _c("h2", { staticClass: "more-title" }, [
                        _vm._v(
                          "Other " +
                            _vm._s(_vm.listing.make) +
                            " listings on Partstash"
                        ),
                      ]),
                      _vm._l(_vm.related, function (row, index) {
                        return row._id != _vm.listing._id
                          ? _c("row", { key: index, attrs: { item: row } })
                          : _vm._e()
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.owner && _vm.owner.listings.length > 1
                ? _c(
                    "segment",
                    [
                      _c("h2", { staticClass: "more-title" }, [
                        _vm._v(
                          "Other listings by " + _vm._s(_vm.owner.username)
                        ),
                      ]),
                      _vm._l(_vm.owner.listings, function (row, index) {
                        return row._id != _vm.listing._id && index < 4
                          ? _c("row", { key: index, attrs: { item: row } })
                          : _vm._e()
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }