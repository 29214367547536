var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.processed
    ? _c(
        "div",
        { staticClass: "content narrow" },
        [
          _c("h2", [_vm._v("Verifying email address")]),
          _c("p", [
            _vm._v(
              "If you're seeing this message then it's likely you've opened the link in a different browser, where you're not logged in."
            ),
          ]),
          _vm._m(0),
          _c("p", [_vm._v("If that fails, please contact support")]),
          _c("btn", { attrs: { to: "/login", label: "Log In Again" } }),
          _c("p", [_vm._v("or")]),
          _c("btn", {
            attrs: {
              href: "mailto:support@partstash.com",
              label: "Contact Support",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v("Either open the link in a browser you've logged in at"),
      ]),
      _c("li", [
        _vm._v("Or login here and then click the link in your email again"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }