<template lang="pug">

.notice-bar(:class='classes') {{ notice }}

</template>

<script>

export default {
  name: 'notice',
  props: {
    color: String,
    notice: String
  },
  data: function() {
    return {}
  },
  computed: {
    classes: function() {
      return this.color
    }
  }
}
</script>



<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'


.notice-bar
  padding 10px
  border-radius radius
  margin 10px 0
  color black

  &.green
    background green
    color white

  &.yellow
    background yellow

  &.red
    background red
    color white

</style>

