<template lang='pug'>

.record-row(v-if='visible')

	div(v-if='removed')
		span.red Record Deleted

	div(v-else)
		router-link(:to='viewLink')
			h3.name {{ item.name }}
		.flex
			badges(:page='item')
				.badge.private(v-if='item.is_private') PRIVATE
			time-ago(:item='item')

		likes-and-comments-bar(
			v-if='!item.is_private'
			:item='item'
			type='record')

		//- action buttons
		.actions

			.btn-list

				btn(
					size='small'
					:to='viewLink'
					label='Details')

				btn(
					v-if='loggedInUser && mine'
					color='grey'
					size='small'
					:to='editLink'
					label='Edit')

				btn(
					v-if='loggedInUser && mine'
					color='red'
					size='small'
					theme='outline'
					@clicked='mountPane("delete")'
					icon='trash-2-icon')

</template>

<script>
import axios from 'axios';
import { mountOnBody } from '/src/services/helpers';
import PaneDelete from '/src/components/panes/modal/delete';

export default {

	props: {
		item: Object,
		status: String
	},

	data: function() {
		return {
			removed: false
		}
	},

	computed: {
		loggedInUser() {
			return this.$store.state.user;
		},

		mine() {
			if(this.loggedInUser.user_id == this.item.user_id) {
				return true;
			} else {
				return false;
			}
		},

		viewLink() {
			return '/garage/car/detail/' + this.item.car_id + '/records/' + this.item.internal_id;
		},

		editLink() {
			return '/garage/car/detail/' + this.item.car_id + '/records/edit/' + this.item.internal_id;
		},

		visible() {
			if(!this.item.is_private) {
				return true;
			} else {
				if(this.item.user_id == this.loggedInUser.user_id) {
					return true;
				} else {
					return false;
				}
			}
		}
	},

	methods: {


		async mountPane(key) {
			if(key == 'delete') {
				await mountOnBody(PaneDelete, { parent: this, propsData: {
					message: 'Delete ' + this.item.name + "?",
					parentEl: this
				} })
			}
		},

		remove: async function() {
			try {
				await axios.post(
					'/api/v1/garage/record/delete',
					{
						internal_id: this.item.internal_id,
						_id: this.item._id
					},
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
							'Content-Type': 'application/json'
						}
					}
				);

				this.removed = true;

			} catch (err) {
				console.log('Something went wrong', err);
			}
		}
	}
}

</script>


<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'


.name
	margin 0 0 10px 0

.flex
	display flex
	align-items center

	div
		margin-right 10px

	.when
		margin-top -2px

.red
	display block
	color red
	padding 10px

a
	color white

</style>
