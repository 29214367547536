<template lang="pug">

.listings-filter

  .title(@click='toggle' v-if='!opened')
    span FILTER LISTINGS
    .collapsed-label(v-if='selection && !opened' @click='clearFilter')
      p {{ computedFilter }}  (clear)

  .close(@click='toggle')
    x-icon(v-if='opened' class='icon')
    chevron-down-icon(v-else class='icon')

  transition(name='slide')
    .flex-content(v-show='opened')

      notice(
        v-if='searchNotice'
        color='green'
        notice='Search Saved to Garage')

      notice(
        v-if='yearNotice'
        color='yellow'
        notice='Year Must Be Greater')

      notice(
        v-if='duplicateNotice'
        color='yellow'
        notice='Already In Your Searches')

      .col.year
        h3 YEARS

        form-text(
          label='From'
          placeholder='any'
          type='number'
          v-model='filteredListing.year_from')

        form-text(
          label='To (optional)'
          placeholder='any'
          type='number'
          v-model='filteredListing.year_to')

      .col.car
        autocomplete-input(
          ref='make'
          label='Make'
          :data='makes'
          v-if='makes.length > 0'
          v-on:selection='setMake($event)'
          v-on:cleared='clearMake()')

        autocomplete-input(
          ref='model'
          v-if='models.length > 0'
          label='Model'
          :data='models'
          v-on:selection='setModel($event)'
          v-on:cleared='clearModel()')

        form-text(v-else label='Model')

      .col.cta
        .btn-list
          btn(color='white' size='small' theme='inverted' :disabled='searchable == false' @click='clearFilter' label='Clear')
          btn(color='white' size='small' :disabled='searchable == false' @click='filteredListings' label='Search')
          btn(color='white' size='small' theme='inverted' :disabled='searchable == false' v-if='isUser' @click='saveSearch()' label='Save Search')
          p(v-else) Login to save this search



</template>

<script>
import axios from 'axios';
import AutocompleteInput from '../ui/autocomplete-input.vue';

export default {

  name: 'listings-filter',

  components: {
    AutocompleteInput
  },

  beforeMount: async function () {
    await this.getMakes();
  },

  mounted: function() {
    this.loaded = true;
  },

  data: function() {
    return {
      opened: false,
      makes: [],
      models: [],
      loaded: false,
      selection: false,
      searchNotice: false,
      yearNotice: false,
      duplicateNotice: false,
      filteredListing: {
        year_from: '',
        year_to: '',
        make: '',
        model: ''
      }
    }
  },

  computed: {
    isUser: function() {
      if(_.isEmpty(this.$store.state.user)) {
        return false;
      } else {
        return true;
      }
    },
    searchable: function() {
      if(this.filteredListing.year_from.length > 0 || this.filteredListing.make.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    disabledClass: function() {
      if(this.filteredListing.make.length == 0) {
        return 'disabled';
      } else {
        return '';
      }
    },
    computedFilter: function() {
      var yearRange = this.filteredListing.year_from;
      if(this.filteredListing.year_to.length) {
        var yearTo = ' - ' + this.filteredListing.year_to;
        yearRange += yearTo;
      }
      return yearRange + ' ' + this.filteredListing.make + ' ' + this.filteredListing.model;
    }
  },

  methods: {

    buildSearchLabel: function() {

      let label = '';

      if(this.filteredListing.year_from.length > 0) {
        label += this.filteredListing.year_from + ' ';
      }

      if(this.filteredListing.year_to.length > 0) {
        label += '/ ' + this.filteredListing.year_to + ' ';
      }

      if(this.filteredListing.make.length > 0) {
        label += this.filteredListing.make + ' ';
      }

      if(this.filteredListing.model.length > 0) {
        label += this.filteredListing.model + ' ';
      }

      return label;

    },

    buildSearchURL: function() {
      let make = 'any', model = 'any', year_from = 'any', year_to = 'any';

      if(this.filteredListing.year_to.length > 0) {
         year_to = this.filteredListing.year_to;
      } else if(this.filteredListing.year_from.length > 0) {
        year_to = this.filteredListing.year_from;
      }

      if(this.filteredListing.year_from.length > 0) {
         year_from = this.filteredListing.year_from;
      }

      if(this.filteredListing.make.length > 0) {
        make = this.filteredListing.make;
      }

      if(this.filteredListing.model.length > 0) {
        model = this.filteredListing.model;
      }

      return 'filter/' + year_from + '/' + year_to + '/' + make + '/' + model

    },

    saveSearch: async function() {

      this.yearNotice = false;
      this.duplicateNotice = false;

      try {
        let save = await axios.post(
          '/api/v1/user/search/save', {
            label: this.buildSearchLabel(),
            url: this.buildSearchURL()
          },
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          }
        );

        if(save.data.success) {
          this.searchNotice = true;
        } else if(save.data.duplicate) {
          this.duplicateNotice = true;
        }

      } catch(err) {
        console.log('Something went wrong', err);
      }
    },

    filteredListings: function() {

      if(this.filteredListing.year_to.length && parseInt(this.filteredListing.year_from) >= parseInt(this.filteredListing.year_to)) {
        this.yearNotice = true;
        return;
      }

      this.toggle();
      this.$emit('set-listings', this.buildSearchURL());
      window.scrollTo(0,0);
    },

    async getMakes() {
      await fetch('/api/v1/car/makes')
        .then(res => res.json())
        .then(makes => {
          this.makes = makes;
        });
    },

    async getModels() {

      // Clear any there already
      this.models = [];

      await fetch('/api/v1/car/make/' + this.filteredListing.make).then(res => res.json())
        .then(models => {
          this.models = models;
        });
    },

    setMake: function(val) {
      this.filteredListing.make = val;
      this.getModels();
    },

    clearMake: function() {
      this.filteredListing.make = '';
    },

    setModel: function(val) {
      this.filteredListing.model = val;
    },

    clearModel: function() {
      this.filteredListing.model = '';
    },

    clearFilter: function() {
      this.selection = false;
      this.$refs.make.$refs.input.clear();
      this.$refs.model.$refs.input.clear();
      this.models = [];
      this.filteredListing.year_from = '';
      this.filteredListing.year_to = '';
      this.filteredListing.make = '';
      this.filteredListing.model = '';
      this.clearNotices();

      this.$emit('set-listings', '/filter/any/any/any/any');
      this.selection = true;

    },

    toggle: function() {
      this.clearNotices();

      if(this.opened) {
        this.opened = false;
      } else {
        this.opened = true;
      }
    },

    clearNotices: function() {
      this.yearNotice = false;
      this.searchNotice = false;
      this.duplicateNotice = false;
    }

  }
}
</script>


<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.listings-filter
  background rgba(white, .08)
  padding 20px
  position relative
  z-index 10
  margin 15px auto
  transition all .2s ease-out
  max-width 1200px
  width calc(100% - 20px)
  border-radius radius

  @media(max-width:mobile)
    padding 15px

h3
  margin 0
  color white
  margin-bottom 10px
  font-size 30px
  font-family 'inter-bold'

  @media (max-width:mobile-landscape)
    font-size 20px

  @media (max-width:mobile-landscape)
    font-size 16px

.title
  width calc(100% - 40px)
  margin-left 0
  color white
  cursor pointer

  span
    display block
    font-size 15px
    font-family 'inter-bold'

.close
  padding 10px
  position absolute
  top 0px
  right 20px
  cursor pointer

  @media (max-width:mobile-landscape)
    top 0
    right 0

  >>> svg
    width 20px
    height 30px
    color white

.collapsed-label
  margin-top 10px
  padding 7px 11px
  border-radius 4px
  background rgba(white, .2)
  display inline-block
  position relative

  p
    margin 0
    font-size 14px
    font-family 'inter-bold'
    text-transform uppercase

.flex-content
  display flex
  align-items stretch
  flex-wrap wrap
  max-width 900px
  margin 0 auto
  padding 30px 0

  @media (max-width:mobile-landscape)
    padding 0

  >>> label
    color white

  .col
    width 100%
    margin 0

    &.year
      .input-group
        width 140px
        display inline-block
        margin-right 10px

    &.cta

      .btn
        margin-right 15px

  .col.car
    >>> label
      font-size 30px
      font-family 'inter-bold'
      text-transform uppercase

      @media (max-width:mobile-landscape)
        font-size 20px

      @media (max-width:mobile)
        font-size 16px

    .input-group
      margin 0 0 20px 0

  @media (min-width:mobile-landscape)
    flex-wrap nowrap

    .col
      border-left 1px solid rgba(black, .2)

      &.year
        width 160px
        padding-right 30px

        .input-group
          display block
          margin-right 0

      &.car
        padding 0 30px
        flex-grow 1

      &.cta
        padding-left 30px
        display flex
        width 160px
        flex-shrink 0
        align-items center

        .btn
          display block
          margin 10px 0
          width 100%
          text-align center

      &:first-child
        border-left none


input, select, >>> input, >>> select
  border 1px solid white !important
  color white
  margin 5px 0

  &::placeholder
    color white


</style>

