var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content-rows message-rows" }, [
    _c("div", { staticClass: "content" }, [
      _c("h2", [_vm._v("Inbox")]),
      _c(
        "div",
        { staticClass: "action" },
        [
          _c("btn", {
            attrs: { to: "/garage/message/add", label: "New message" },
          }),
        ],
        1
      ),
      _vm.messages.length
        ? _c(
            "div",
            _vm._l(_vm.messages, function (message, index) {
              return _c("message-row", {
                key: index,
                attrs: { message: message },
                on: { "watch-remove": _vm.removeMessage },
              })
            }),
            1
          )
        : _c("div", [
            _c("p", { staticClass: "none" }, [
              _vm._v("No Messages in your inbox"),
            ]),
          ]),
    ]),
    _c("div", { staticClass: "content" }, [
      _c("h2", [_vm._v("Sent")]),
      _vm.sent.length
        ? _c(
            "div",
            _vm._l(_vm.sent, function (message, index) {
              return _c("message-row", {
                key: index,
                attrs: { sent: "", message: message },
                on: { "watch-remove": _vm.removeMessage },
              })
            }),
            1
          )
        : _c("div", [
            _c("p", { staticClass: "none" }, [_vm._v("No Sent Messages")]),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }