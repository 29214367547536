<template lang='pug'>

mounted-pane(ref='pane' title='Wants' @close='close()')
	.content-rows(v-if='loaded')
		.content
			paginated-feed(type='scoped-wants' :scope='id' noAds rows)

</template>

<script>
import PaginatedFeed from '/src/components/listing/paginated-feed';

export default {

	components: { PaginatedFeed },

	props: {
		id: String
	},

	data: function() {
		return {
			loaded: false
		}
	},

	mounted: async function() {
		this.loaded = true;
	}

}

</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

</style>
