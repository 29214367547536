var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card", class: _vm.classes },
    [
      _vm.entryType == "listing"
        ? _c("card-listing", {
            attrs: { item: _vm.item, typeBadge: _vm.typeBadge },
          })
        : _vm._e(),
      _vm.entryType == "want"
        ? _c("card-want", {
            attrs: { item: _vm.item, typeBadge: _vm.typeBadge },
          })
        : _vm._e(),
      _vm.entryType == "group"
        ? _c("card-group", {
            attrs: { item: _vm.item, typeBadge: _vm.typeBadge },
          })
        : _vm._e(),
      _vm.entryType == "advert"
        ? _c("card-ad", { attrs: { item: _vm.item, typeBadge: _vm.typeBadge } })
        : _vm._e(),
      _vm.entryType == "garage"
        ? _c("card-garage", {
            attrs: { item: _vm.item, typeBadge: _vm.typeBadge },
          })
        : _vm._e(),
      _vm.entryType == "event"
        ? _c("card-event", {
            attrs: { item: _vm.item, typeBadge: _vm.typeBadge },
          })
        : _vm._e(),
      _vm.entryType == "timeline"
        ? _c("card-timeline", {
            attrs: { item: _vm.item, typeBadge: _vm.typeBadge },
          })
        : _vm._e(),
      _vm.entryType == "treasure"
        ? _c("card-treasure", {
            attrs: { item: _vm.item, typeBadge: _vm.typeBadge },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }