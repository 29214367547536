<template lang="pug">

.modal-simple

  transition(name='fade')
    .overlay(v-show='open' @click='close')
  
  transition(name='slide')
    .content(v-show='open' ref='scroll')
      .close(v-if='!noClose' @click='close')
        x-icon(class='icon')
      
      .flex-wrap
        slot
        .message 
          .wrap {{ message }}
        .string-match(v-if='typeToProceed == true')
          .input-group.pad-top
            p.red 
              span Type your email address to confirm

            input(type='text' ref='matcher' value='' :placeholder='stringMatch')
            
        .btn-list(v-if='!noChoices')
          btn(size='large' color='grey' @click='close' icon='x-icon')
          btn(size='large' color='green' @click='pass' icon='check-icon')

        
</template>

<script>
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

export default {
  name: 'modal',


  props: {
    message: String,
    typeToProceed: Boolean,
    stringMatch: String,
    noClose: Boolean,
    noChoices: Boolean
  },
  data: function() {
    return {
      open: false
    }
  },
  methods: {
    openModal() {
      disableBodyScroll(this.$refs.scroll);
      this.open = true;
    },
    close() {

      if(this.noClose) return;

      enableBodyScroll(this.$refs.scroll);
      this.open = false;
      this.$emit('close');      
    },
    pass() {

      if(this.typeToProceed == false) {
        enableBodyScroll(this.$refs.scroll);
        this.$emit('pass');
        return;
      }

      if(this.typeToProceed == true && this.$refs.matcher.value == this.stringMatch) {
        enableBodyScroll(this.$refs.scroll);
        this.$emit('pass');
      } else {
        alert('does not match');
      }
      
      
    }
  }
}
</script>

