var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "pane",
      class: _vm.classes,
      attrs: { "data-location": _vm.location },
    },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.open,
              expression: "open",
            },
          ],
          staticClass: "overlay",
          on: { click: _vm.close },
        }),
      ]),
      _c("transition", { attrs: { name: _vm.transitionType } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.open,
                expression: "open",
              },
            ],
            staticClass: "content",
          },
          [
            _c("div", { staticClass: "pane-heading" }, [
              !_vm.noClose && _vm.location == "right"
                ? _c(
                    "div",
                    { staticClass: "close", on: { click: _vm.close } },
                    [_c("arrow-left-icon", { attrs: { size: "2x" } })],
                    1
                  )
                : _vm._e(),
              !_vm.noClose && _vm.location == "top"
                ? _c(
                    "div",
                    { staticClass: "close close-x", on: { click: _vm.close } },
                    [_c("x-icon", { attrs: { size: "2x" } })],
                    1
                  )
                : _vm._e(),
              _c("h2", [_vm._v(_vm._s(_vm.title))]),
            ]),
            _c(
              "div",
              { ref: "scroll", staticClass: "scrolling" },
              [_vm._t("default")],
              2
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }