var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal-simple success" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.open,
              expression: "open",
            },
          ],
          staticClass: "overlay",
          on: { click: _vm.checkToClose },
        }),
      ]),
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.open,
                expression: "open",
              },
            ],
            ref: "scroll",
            staticClass: "content",
            attrs: { id: "successModalScroll" },
          },
          [
            !_vm.noClose
              ? _c(
                  "div",
                  { staticClass: "close", on: { click: _vm.close } },
                  [_c("x-icon", { staticClass: "icon" })],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "message" }, [
              _c("h2", [
                _c("span", [
                  _vm._v(_vm._s(_vm.title) + " - " + _vm._s(_vm.message)),
                ]),
              ]),
              _vm.viewTitle
                ? _c(
                    "div",
                    { staticClass: "pill blue xl", on: { click: _vm.goView } },
                    [_vm._v(_vm._s(_vm.viewTitle))]
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "btn-list" },
              [
                !_vm.advert
                  ? _c("btn", {
                      attrs: { color: "light", label: "View" },
                      on: { click: _vm.goView },
                    })
                  : _vm._e(),
                !_vm.advert
                  ? _c("btn", {
                      attrs: { color: "green", label: "+ Another" },
                      on: { click: _vm.close },
                    })
                  : _vm._e(),
                !_vm.advert
                  ? _c("btn", {
                      attrs: { label: "Your Garage" },
                      on: { click: _vm.goGarage },
                    })
                  : _vm._e(),
                _vm.advert
                  ? _c("btn", {
                      attrs: { label: "Back to Admin" },
                      on: { click: _vm.goAdmin },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }