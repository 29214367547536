var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row-group" }, [
    _vm.status == "pending"
      ? _c(
          "div",
          { staticClass: "row-contents pending" },
          [
            _c(
              "router-link",
              { attrs: { to: "/group/" + _vm.item.internal_id } },
              [_c("smart-title", { attrs: { title: _vm.item.name } })],
              1
            ),
            _c("p", [_vm._v("Pending")]),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "row-contents" },
          [
            _c(
              "router-link",
              {
                staticClass: "img-area",
                attrs: { to: "/group/" + _vm.item.internal_id },
              },
              [
                _vm.item.profile
                  ? _c("base-image", {
                      attrs: { src: _vm.item.profile, bucket: "groups" },
                    })
                  : _c("placeholder"),
                _c("profile-widget", {
                  attrs: { userId: _vm.item.owner, short: "" },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "info-area" },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/group/" + _vm.item.internal_id } },
                  [
                    _c("smart-title", { attrs: { title: _vm.item.name } }),
                    _c("group-metrics", { attrs: { group: _vm.item } }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }