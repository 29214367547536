

<template lang='pug'>

.message(:class='classes') {{ message }}

</template>

<script>

export default {

  props: {
    message: String,
    type: {
      type: String,
      default: 'generic'
    }
  },

  computed: {
    classes: function() {
      return [
        "type-" + this.type
      ]
    }
  }
  
}
</script>

<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'


.message
  padding 10px
  margin 10px 0
  border-radius 4px
  font-weight bold
  font-size 14px
  letter-spacing .5px

  &.type-error
    background red

  &.type-generic
    background #666

</style>
