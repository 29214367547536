<template lang="pug">

.content.narrow(v-if='loaded && isAdminUser')
  h1 Admin

  hr

  h2 Ads
  btn(to='/ad/add' label='Add new Ad')

  div(v-if='ads.length > 0')

    div(v-for='ad, index in ads', :index='index')
      advert-row(:ad='ad')
  
  div(v-else)
    p.none No Ads Uploaded
    

  hr

  h2 Users not in good standing

  div(v-if='badUsers.length > 0')

    div(v-for='user, index in badUsers', :index='index')
      user-row(:user='user')
  
  div(v-else)
    p.none No Users in bad standing

  hr

  h2 Flagged listings

  div(v-if='flaggedListings.length > 0')

    div(v-for='listing, index in flaggedListings', :index='index')
      row(row :item='listing' admin)
      btn(color='yellow' theme='outline' label='unflag' @click='unflag(listing.internal_id, "listing")')
  
  div(v-else)
    p.none No Flagged Listings

  hr

  h2 Flagged Wants

  div(v-if='flaggedWants.length > 0')

    div(v-for='listing, index in flaggedWants', :index='index')
      row(row :item='listing' want admin)
      btn(color='yellow' theme='outline' label='unflag' @click='unflag(listing.internal_id, "want")')
  
  div(v-else)
    p.none No Flagged Wants

  hr

  h2 Flagged Cars

  div(v-if='flaggedCars.length > 0')

    row(
      layout='horizontal'
      v-for="car, index in flaggedCars" 
      :key="index" 
      :item="car"
      customEntryType='garage')
  
  div(v-else)
    p.none No Flagged Cars



  
</template>

<script>
import axios from 'axios';
import AdvertRow from '../../components/ui/advert-row';
import UserRow from '../../components/globals/rows/user';

export default {
  name: 'admin',

  components: {
    AdvertRow,
    UserRow
  },

  beforeMount: async function() {
    await this.fetchData('advert/ads');
    await this.fetchData('listing/flagged');
    await this.fetchData('want/flagged');
    await this.fetchData('garage/flagged');
    await this.fetchData('user/standings/bad');
    this.loaded = true;
  },

  data: function() {
    return {
      loaded: false,
      ads: [],
      badUsers: [],
      flaggedListings: [],
      flaggedWants: [],
      flaggedCars: [],
    }
  },

  computed: {
    isAdminUser: function() {
      if(_.isEmpty(this.$store.state.user)) {
        return false;
      } else {
        if(this.$store.state.user.accountType == 'admin') {
          return true;
        } else {
          window.location.href = '/';
        }
      }
    }
  },

  methods: {

    fetchData: async function(endpoint) {

      fetch('/api/v1/' + endpoint, {
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.token).value}`
        }
      }).then(res => res.json())
        .then(response => {
          if(endpoint == 'advert/ads') { this.ads = response }
          if(endpoint == 'listing/flagged') { this.flaggedListings = response }
          if(endpoint == 'want/flagged') { this.flaggedWants = response }
          if(endpoint == 'garage/flagged') { this.flaggedCars = response }
          if(endpoint == 'user/standings/bad') { this.badUsers = response }
        });
    },


    unflag: async function(id, type) {
      try {

        await axios.post(
          '/api/v1/common/unflag', 
          { entryId: id, type: type },
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          });

          window.location.reload()
      }

      catch(err) {
        console.log('Something went wrong', err);
      }
    }
  }

}
</script>


<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'


</style>