var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded && _vm.isAdminUser
    ? _c(
        "div",
        { staticClass: "content narrow" },
        [
          _c("h1", [_vm._v("Admin")]),
          _c("hr"),
          _c("h2", [_vm._v("Ads")]),
          _c("btn", { attrs: { to: "/ad/add", label: "Add new Ad" } }),
          _vm.ads.length > 0
            ? _c(
                "div",
                _vm._l(_vm.ads, function (ad, index) {
                  return _c(
                    "div",
                    { attrs: { index: index } },
                    [_c("advert-row", { attrs: { ad: ad } })],
                    1
                  )
                }),
                0
              )
            : _c("div", [
                _c("p", { staticClass: "none" }, [_vm._v("No Ads Uploaded")]),
              ]),
          _c("hr"),
          _c("h2", [_vm._v("Users not in good standing")]),
          _vm.badUsers.length > 0
            ? _c(
                "div",
                _vm._l(_vm.badUsers, function (user, index) {
                  return _c(
                    "div",
                    { attrs: { index: index } },
                    [_c("user-row", { attrs: { user: user } })],
                    1
                  )
                }),
                0
              )
            : _c("div", [
                _c("p", { staticClass: "none" }, [
                  _vm._v("No Users in bad standing"),
                ]),
              ]),
          _c("hr"),
          _c("h2", [_vm._v("Flagged listings")]),
          _vm.flaggedListings.length > 0
            ? _c(
                "div",
                _vm._l(_vm.flaggedListings, function (listing, index) {
                  return _c(
                    "div",
                    { attrs: { index: index } },
                    [
                      _c("row", {
                        attrs: { row: "", item: listing, admin: "" },
                      }),
                      _c("btn", {
                        attrs: {
                          color: "yellow",
                          theme: "outline",
                          label: "unflag",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.unflag(listing.internal_id, "listing")
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _c("div", [
                _c("p", { staticClass: "none" }, [
                  _vm._v("No Flagged Listings"),
                ]),
              ]),
          _c("hr"),
          _c("h2", [_vm._v("Flagged Wants")]),
          _vm.flaggedWants.length > 0
            ? _c(
                "div",
                _vm._l(_vm.flaggedWants, function (listing, index) {
                  return _c(
                    "div",
                    { attrs: { index: index } },
                    [
                      _c("row", {
                        attrs: { row: "", item: listing, want: "", admin: "" },
                      }),
                      _c("btn", {
                        attrs: {
                          color: "yellow",
                          theme: "outline",
                          label: "unflag",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.unflag(listing.internal_id, "want")
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _c("div", [
                _c("p", { staticClass: "none" }, [_vm._v("No Flagged Wants")]),
              ]),
          _c("hr"),
          _c("h2", [_vm._v("Flagged Cars")]),
          _vm.flaggedCars.length > 0
            ? _c(
                "div",
                _vm._l(_vm.flaggedCars, function (car, index) {
                  return _c("row", {
                    key: index,
                    attrs: {
                      layout: "horizontal",
                      item: car,
                      customEntryType: "garage",
                    },
                  })
                }),
                1
              )
            : _c("div", [
                _c("p", { staticClass: "none" }, [_vm._v("No Flagged Cars")]),
              ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }