<template lang='pug'>

.comment
	div.message(v-html='comment.message')
	div {{ comment.type }}
	time-ago(:item='comment')

</template>

<script>
export default {

	props: {
		comment: Object
	}

}

</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.comment
	margin-top 15px
	padding-bottom 15px
	border-bottom 1px solid rgba(white, .1)
	display flex
	align-items center

.message
	flex-grow 1

</style>
