<template lang='pug'>

.message-row

  modal(
    ref='modalRemove'
    v-on:pass='proceedRemove'
    message='Confirm delete?')

  .info-wrap
    .new(v-if='!sent && !message.read')
    router-link.info(:to='"/garage/message/" + message.internal_id ')
      h3 {{ message.title }}
      p.to-from(v-if='sent') To: {{ message.to }}
      p.to-from(v-else) From: {{ message.from }}
      p.date {{ date }}

  .listing-admin
    .btn-list
      btn(
        color='red'
        theme='outline'
        size='small'
        @click.prevent='removeMessage'
        icon='trash-2-icon')


</template>

<script>
import axios from 'axios';

export default {
  props: {
    message: Object,
    sent: Boolean
  },
  
  data: function() {
    return { }
  },

  computed: {
    
    endpoint: function() {
      if(this.sent) {
        return '/api/v1/message/sender/delete';
      } else {
        return '/api/v1/message/inbox/delete';
      }
    },

    date: function() {
      return new Date(this.message.created_at).toLocaleDateString("en-US");
    }
    
  },
  
  
  methods: {
    removeMessage: function() {
      this.$refs.modalRemove.openModal();
    },

    async proceedRemove() {

      try {
        await axios.post(
          this.endpoint, 
          { message: this.message }, 
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          }
        );

        location.reload();

      } catch(err) {
        console.log('Something went wrong', err);
      }
    },
  }
}
</script>


<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'

.message-row
  background white
  margin 15px 0
  border-radius 5px
  padding 15px
  display flex
  align-items center
  box-shadow()

.info
  flex-grow 1
  color black

  h3
    margin 0 0 5px 0
    font-size 20px

  .to-from
    padding 6px 10px
    background #efefef
    border-radius 3px
    display inline-flex
    align-items center
    margin 0 0 5px 0
    font-size 12px
    color grey

  .date
    font-size 12px
    color grey
    margin 0 0 0 10px
    display inline-block

    @media (max-width:mobile)
      margin 5px 0 0 0
      display block

.info-wrap
  display flex
  align-items center
  flex-grow 1

.new
  circle = 10px
  width circle
  height circle
  border-radius circle
  background red
  display inline-block
  margin-right 10px

</style>