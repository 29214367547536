<template lang="pug">

.cta-wrap
  .width-restrict
    slot

</template>

<script>


export default {
  name: 'cta-wrap'
}
</script>

<style lang='stylus' scoped>

.cta-wrap
  position fixed
  bottom 0
  left 0
  width 100%
  z-index 89
  background #000
  color color-text
  box-shadow()
  padding 8px
  text-align right

  .width-restrict
    width 90%
    max-width 900px
    margin 0 auto

.btn
  padding 10px 30px
  border-radius 50px
  border none
  background green
  color white

  &:disabled
    opacity .3

</style>
