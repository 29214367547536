<template lang="pug">

.loader
  .loading-logo
  p LOADING
    
</template>

<script>


export default {
  name: 'loader',
  
  data: function() {
    return { }
  }
}
</script>

