var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "form",
        {
          staticClass: "record-form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.formComplete.apply(null, arguments)
            },
          },
        },
        [
          _c("loader", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loadingScreen,
                expression: "loadingScreen",
              },
            ],
          }),
          _c(
            "segment",
            [
              _c("form-text", {
                attrs: {
                  value: _vm.entry.status,
                  label: "Status",
                  name: "body",
                },
                model: {
                  value: _vm.entry.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.entry, "status", $$v)
                  },
                  expression: "entry.status",
                },
              }),
              _c("div", { staticClass: "radio-wrap checkmark" }, [
                _c("label", { attrs: { for: "is_private" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.entry.is_private,
                        expression: "entry.is_private",
                      },
                    ],
                    ref: "is_private",
                    attrs: {
                      type: "checkbox",
                      name: "is_private",
                      value: "is_private",
                      id: "is_private",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.entry.is_private)
                        ? _vm._i(_vm.entry.is_private, "is_private") > -1
                        : _vm.entry.is_private,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.entry.is_private,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = "is_private",
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.entry,
                                "is_private",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.entry,
                                "is_private",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.entry, "is_private", $$c)
                        }
                      },
                    },
                  }),
                  _c(
                    "span",
                    { staticClass: "box" },
                    [
                      _c("check-icon", { staticClass: "icon" }),
                      _c("span", [_vm._v("Only Visible to Followers")]),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "segment",
            [
              _c("uploader", {
                ref: "imagePicker",
                attrs: {
                  initial: _vm.entry.gallery,
                  edit: _vm.edit,
                  type: "images",
                },
                on: { "master-batch": _vm.onImageSelect },
              }),
            ],
            1
          ),
          _c(
            "segment",
            [
              _c("form-textarea", {
                attrs: {
                  value: _vm.entry.description,
                  label: "Description",
                  name: "description",
                },
                model: {
                  value: _vm.entry.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.entry, "description", $$v)
                  },
                  expression: "entry.description",
                },
              }),
            ],
            1
          ),
          _c("segment", { attrs: { title: "Visibility" } }, [
            _c(
              "div",
              { ref: "scopes", staticClass: "radio-wrap" },
              [
                _c("label", { attrs: { for: "visiblityAll" } }, [
                  _c("input", {
                    ref: "visiblityAll",
                    attrs: {
                      type: "checkbox",
                      id: "visiblityAll",
                      name: "all",
                      "data-val": "all",
                      checked: "",
                    },
                  }),
                  _c("span", { staticClass: "box" }, [
                    _c("span", [_vm._v("Public")]),
                  ]),
                ]),
                _vm._l(_vm.groups, function (group, index) {
                  return _c(
                    "label",
                    { key: index, attrs: { for: group.internal_id } },
                    [
                      _c("input", {
                        ref: group.internal_id,
                        refInFor: true,
                        attrs: {
                          type: "checkbox",
                          id: group.internal_id,
                          "data-val": group.internal_id,
                          name: _vm.visibilityName(group),
                        },
                        domProps: { checked: _vm.isInGroup(group) },
                      }),
                      _c("span", { staticClass: "box" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.groupTitle(group.name))),
                        ]),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
          _c("segment", { attrs: { title: "Type" } }, [
            _c(
              "div",
              { staticClass: "radio-wrap" },
              _vm._l(_vm.types, function (type, index) {
                return _c("label", { key: index, attrs: { for: type } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.entry.type,
                        expression: "entry.type",
                      },
                    ],
                    attrs: { type: "radio", name: "recordType", id: type },
                    domProps: {
                      value: type,
                      checked: _vm._q(_vm.entry.type, type),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.entry, "type", type)
                      },
                    },
                  }),
                  _c("span", { staticClass: "box" }, [
                    _c("span", [_vm._v(_vm._s(type))]),
                  ]),
                ])
              }),
              0
            ),
          ]),
          _c(
            "segment",
            { attrs: { title: "Car (optional)" } },
            [
              _c("car-picker", {
                ref: "carPicker",
                on: { onChange: _vm.updateCar },
              }),
            ],
            1
          ),
          _c("cta-wrap", [
            _c("button", {
              ref: "cta",
              staticClass: "btn green cta",
              attrs: { type: "submit", disabled: _vm.addable == false },
              domProps: { innerHTML: _vm._s(_vm.btnText) },
            }),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }