var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "condition" }, [
    _c("div", { staticClass: "condition-label" }, [
      _c("span", { staticClass: "rating", class: _vm.conditionClasses }, [
        _vm._v(_vm._s(_vm.condition)),
      ]),
      _c("span", [_vm._v(_vm._s(_vm.conditionLabel))]),
    ]),
    _c("div", { staticClass: "legend" }, [
      _vm._v("1 = total restoration, 5 = like new"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }