<template lang='pug'>

mounted-pane(ref='pane' title='Admin: Manage Members' @close='close()')
	.content-rows(v-if='loaded')
		.content
			manage-members(:page='page' v-on:resetData='resetData')

</template>

<script>
import ManageMembers from '/src/views/group/manage-members';

export default {

	components: { ManageMembers },

  props: {
    page: Object,
		parent: Object
  },

	data: function() {
    return {
      loaded: false
    }
  },

	mounted: async function() {
		this.loaded = true;
	},

	methods: {
		resetData: function(data) {
			this.parent.resetData(data);
    }
	}
  
}

</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'



</style>