var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "group-form" },
    [
      _c("success-modal", {
        ref: "modalSuccess",
        attrs: {
          title: "New Group Created",
          message: "Your group is now live.",
          group: "",
          noClose: "",
        },
      }),
      _vm.error
        ? _c("standard-message", {
            attrs: { type: "error", message: _vm.error },
          })
        : _vm._e(),
      _c(
        "form",
        {
          ref: "form",
          staticClass: "form bottom-margin",
          attrs: { enctype: "multipart/form-data" },
          on: { submit: _vm.formSubmit },
        },
        [
          _c(
            "segment",
            { attrs: { title: "Profile Image" } },
            [
              _c("uploader", {
                ref: "imagePicker",
                attrs: { type: "group", single: "" },
                on: { "master-batch": _vm.onSelect },
              }),
            ],
            1
          ),
          _c(
            "segment",
            { attrs: { title: "Information" } },
            [
              _c("form-text", {
                attrs: { label: "Group Title", name: "title", required: "" },
                model: {
                  value: _vm.group.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.group, "title", $$v)
                  },
                  expression: "group.title",
                },
              }),
            ],
            1
          ),
          _c(
            "segment",
            { attrs: { title: "Description" } },
            [
              _c("form-textarea", {
                ref: "description",
                attrs: { name: "description" },
                model: {
                  value: _vm.group.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.group, "description", $$v)
                  },
                  expression: "group.description",
                },
              }),
            ],
            1
          ),
          _c("segment", { attrs: { title: "Region" } }, [
            _c(
              "div",
              { staticClass: "input-group select" },
              [
                _c("chevron-down-icon", { staticClass: "icon" }),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.group.region,
                        expression: "group.region",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.group,
                          "region",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v("Pick A Region"),
                    ]),
                    _vm._l(_vm.regions, function (region, index) {
                      return _c("option", { domProps: { value: region.val } }, [
                        _vm._v(_vm._s(region.label)),
                      ])
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c("cta-wrap", [
            _c("button", {
              ref: "cta",
              staticClass: "green cta",
              attrs: { type: "submit", disabled: _vm.addable == false },
              domProps: { innerHTML: _vm._s(_vm.btnText) },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }