var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content narrow" },
    [
      _c("loader", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
      }),
      _c(
        "segment",
        [
          _c("segment-header", [_c("h1", [_vm._v("Login to Your Account")])]),
          _c(
            "form",
            {
              ref: "form",
              staticClass: "form",
              attrs: { enctype: "multipart/form-data" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.login.apply(null, arguments)
                },
              },
            },
            [
              _vm.error.length
                ? _c("standard-message", {
                    attrs: { type: "error", message: _vm.error },
                  })
                : _vm._e(),
              _c("form-text", {
                attrs: {
                  label: "Email or Username",
                  name: "login",
                  required: "",
                  autocomplete: "username",
                },
                model: {
                  value: _vm.user.login,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "login", $$v)
                  },
                  expression: "user.login",
                },
              }),
              _c("form-text", {
                attrs: {
                  label: "Password",
                  name: "password",
                  type: "password",
                  required: "",
                  autocomplete: "current-password",
                },
                model: {
                  value: _vm.user.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "password", $$v)
                  },
                  expression: "user.password",
                },
              }),
              _c(
                "div",
                { staticClass: "actions" },
                [
                  _c(
                    "button",
                    { staticClass: "cta green", attrs: { type: "submit" } },
                    [_vm._v("Login")]
                  ),
                  _c("br"),
                  _c("br"),
                  _c("p", [_vm._v("Don't have an account?")]),
                  _c("btn", {
                    attrs: {
                      label: "Register",
                      size: "small",
                      color: "light",
                      to: "/register",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }