var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "garage content narrow" },
        [
          _c("modal", {
            ref: "modalLogout",
            attrs: { message: "Logout?" },
            on: { pass: _vm.proceedLogout },
          }),
          _c("modal-welcome", {
            ref: "modalWelcome",
            on: { pass: _vm.proceedToGarage },
          }),
          _c(
            "div",
            [
              _c(
                "heading",
                { attrs: { heading: "Garage" } },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "public-profile",
                      attrs: { to: _vm.profileDeeplink },
                    },
                    [
                      _c("span", [_vm._v("Public Profile")]),
                      _c("external-link-icon", { staticClass: "icon" }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.user.emailVerified
            ? _c("div", { staticClass: "verify-email" }, [
                _c("p", [_vm._v("Please verify your email address")]),
                _c(
                  "div",
                  { staticClass: "btn-list" },
                  [
                    _c(
                      "btn",
                      {
                        attrs: { size: "small", color: "light" },
                        on: { click: _vm.resendEmailVerification },
                      },
                      [_vm._v("Resend")]
                    ),
                    _c(
                      "btn",
                      {
                        attrs: { size: "small", color: "light" },
                        on: { click: _vm.reloadPage },
                      },
                      [_vm._v("Refresh if verified")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.counts
            ? _c(
                "div",
                { staticClass: "big-cards" },
                [
                  _c(
                    "big-card",
                    {
                      attrs: {
                        title: "Notifications",
                        theme: "light",
                        width: "full",
                        icon: "alert",
                      },
                      on: {
                        openPane: function ($event) {
                          return _vm.mountPane("notifications")
                        },
                      },
                    },
                    [
                      _vm.unreadNotices > 0
                        ? _c("div", { staticClass: "dot" })
                        : _vm._e(),
                    ]
                  ),
                  _vm.isAdminUser
                    ? _c("big-card", {
                        attrs: {
                          title: "Cascade Consulting",
                          theme: "lightblue",
                          width: "full",
                          icon: "eye",
                        },
                        on: {
                          openPane: function ($event) {
                            return _vm.mountPane("treasures")
                          },
                        },
                      })
                    : _vm._e(),
                  _c("big-card", {
                    attrs: {
                      title: "Your Timeline",
                      theme: "blue",
                      width: "full",
                      icon: "grid",
                    },
                    on: {
                      openPane: function ($event) {
                        return _vm.mountPane("statuses")
                      },
                    },
                  }),
                  _c("big-card", {
                    attrs: {
                      title: "Your Listings",
                      theme: "blue",
                      width: "half",
                      icon: "grid",
                      count: _vm.counts.listings,
                    },
                    on: {
                      openPane: function ($event) {
                        return _vm.mountPane("listings")
                      },
                    },
                  }),
                  _c("big-card", {
                    attrs: {
                      title: "Your Wants",
                      theme: "lightblue",
                      width: "half",
                      icon: "crosshair",
                      count: _vm.counts.wants,
                    },
                    on: {
                      openPane: function ($event) {
                        return _vm.mountPane("wants")
                      },
                    },
                  }),
                  _c("big-card", {
                    attrs: {
                      title: "Your Groups",
                      theme: "dark",
                      width: "half",
                      icon: "users",
                      count:
                        _vm.counts.groupsCreated +
                        _vm.counts.groupsMember +
                        _vm.counts.groupsAdmin,
                    },
                    on: {
                      openPane: function ($event) {
                        return _vm.mountPane("groups")
                      },
                    },
                  }),
                  _c("big-card", {
                    attrs: {
                      title: "Your Events",
                      theme: "dark",
                      width: "half",
                      icon: "calendar",
                      count: _vm.counts.events,
                    },
                    on: {
                      openPane: function ($event) {
                        return _vm.mountPane("events")
                      },
                    },
                  }),
                  _c("big-card", {
                    attrs: {
                      title: "Your Cars",
                      theme: "dark",
                      width: "half",
                      icon: "key",
                      count: _vm.counts.cars,
                    },
                    on: {
                      openPane: function ($event) {
                        return _vm.mountPane("cars")
                      },
                    },
                  }),
                  _c("big-card", {
                    attrs: {
                      title: "Watchlist",
                      theme: "dark",
                      width: "half",
                      icon: "eye",
                      count: _vm.user.watches.length,
                    },
                    on: {
                      openPane: function ($event) {
                        return _vm.mountPane("watches")
                      },
                    },
                  }),
                  _c("big-card", {
                    attrs: {
                      title: "Following",
                      theme: "dark",
                      width: "half",
                      icon: "heart",
                      count: _vm.user.following.length,
                    },
                    on: {
                      openPane: function ($event) {
                        return _vm.mountPane("following")
                      },
                    },
                  }),
                  _c("big-card", {
                    attrs: {
                      title: "Followers",
                      theme: "dark",
                      width: "half",
                      icon: "users",
                      count: _vm.user.followers.length,
                    },
                    on: {
                      openPane: function ($event) {
                        return _vm.mountPane("followers")
                      },
                    },
                  }),
                  _c("big-card", {
                    attrs: {
                      title: "Saved Searches",
                      theme: "light",
                      width: "full",
                      icon: "search",
                      count: _vm.user.savedSearches.length,
                      smallCount: "",
                    },
                    on: {
                      openPane: function ($event) {
                        return _vm.mountPane("searches")
                      },
                    },
                  }),
                  _c("big-card", {
                    attrs: {
                      title: "Account Settings",
                      theme: "light",
                      width: "full",
                      icon: "settings",
                    },
                    on: {
                      openPane: function ($event) {
                        return _vm.mountPane("settings")
                      },
                    },
                  }),
                  _c("big-card", {
                    attrs: {
                      title: "Sold Listings",
                      theme: "green",
                      width: "half",
                      icon: "dollar",
                      count: _vm.counts.sold,
                      smallCount: "",
                    },
                    on: {
                      openPane: function ($event) {
                        return _vm.mountPane("sold")
                      },
                    },
                  }),
                  _c("big-card", {
                    attrs: {
                      title: "Deleted Listings",
                      theme: "red",
                      width: "half",
                      icon: "trash",
                      count: _vm.counts.deleted,
                      smallCount: "",
                    },
                    on: {
                      openPane: function ($event) {
                        return _vm.mountPane("deleted")
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "bottom-actions" },
            [
              _vm.counts
                ? _c("h3", [_vm._v(_vm._s(_vm.counts.sold) + " items sold")])
                : _vm._e(),
              _c("br"),
              _c("br"),
              _c(
                "div",
                { staticClass: "btn-list" },
                [
                  _c("btn", {
                    attrs: {
                      href: "mailto:support@partstash.com",
                      color: "light",
                      label: "Contact Support",
                    },
                  }),
                  _c("btn", {
                    attrs: { color: "red", type: "outline", label: "Logout" },
                    on: {
                      click: function ($event) {
                        return _vm.logout()
                      },
                    },
                  }),
                ],
                1
              ),
              _c("invite"),
              _c("p", { staticClass: "version bold" }, [
                _vm._v("Version 0.3.7"),
              ]),
              _c("p", { staticClass: "version date" }, [
                _vm._v("Last updated 5/31/23"),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }