var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loggedInUser
    ? _c(
        "span",
        { staticClass: "follow" },
        [
          !_vm.isFollowing
            ? _c(
                "btn",
                {
                  attrs: { theme: "outline", color: "light", size: "small" },
                  on: { click: _vm.follow },
                },
                [
                  _c("span", [_vm._v("Follow")]),
                  !_vm.noUserName
                    ? _c("span", [_vm._v(_vm._s(_vm.user.username))])
                    : _vm._e(),
                ]
              )
            : _c(
                "btn",
                {
                  attrs: { theme: "outline", color: "red", size: "small" },
                  on: { click: _vm.unfollow },
                },
                [
                  _c("span", [_vm._v("Unfollow")]),
                  !_vm.noUserName
                    ? _c("span", [_vm._v(_vm._s(_vm.user.username))])
                    : _vm._e(),
                ]
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }