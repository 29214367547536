// Views (vue compoent for each)
import LoginView from './components/login';
import GarageView from './components/garage';
import RegisterView from './components/register';
import Home from './views/home';
import All from './views/all';
import Wheels from './views/wheels';
import FilteredView from './views/filtered';
import FeedViewCategory from './components/listings/category';
import FeedViewMake from './components/listings/make';
import FeedViewTag from './components/listings/tag';
import AddListingView from './views/listings/add';
import EditListingView from './views/listings/edit';
import AddWantView from './views/wants/add';
import EditWantView from './views/wants/edit';
import AddCarView from './views/garage/add-car';
import AddMessageView from './views/garage/add-message';
import MessageView from './views/garage/message';
import EditCarView from './views/garage/edit-car';
import DetailView from './views/listings/detail';
import WantDetailView from './views/wants/detail';
import CarView from './views/garage/car';
import UserProfileView from './views/user/profile';
import SearchResultsView from './views/search';
import NotFoundView from './components/notfound';
import UsersView from './views/user/users';
import VerifyView from './views/user/verify';
import AdminView from './views/admin/admin';
import AddAdvertView from './views/admin/advert-new';
import EditAdvertView from './views/admin/advert-edit';

// Groups
import GroupsView from './views/groups';
import GroupDetailView from './views/group/group';
import AddGroupView from './views/group/add';

import AddRecordView from './views/garage/records/add-record';
import EditRecordView from './views/garage/records/edit-record';
import RecordView from './views/garage/records/record';

// Timeline
import AddTimelineView from './views/timeline/add';
import EditTimelineView from './views/timeline/edit';
import TimelineView from './views/timeline/detail';

// Events
import AddEventView from './views/event/add';
import EditEventView from './views/event/edit';
import EventView from './views/event/detail';
import EventListingView from './views/event/listing';

// Treasures
import AddTreasureView from './views/treasure/add';
import EditTreasureView from './views/treasure/edit';
import TreasureView from './views/treasure/detail';
import TreasureListingView from './views/treasure/listing';

// make
import MakeView from './views/make/index';
import MakesView from './views/make/all';

// car listing
import CarsView from './views/cars/listing'

import Neo from './neo/neo'
// import Enzo from './enzo/enzo';
import Ferruccio from './ferruccio/ferruccio';


function redirectToGarage(to, from, next) {
	if (localStorage.token) { next('/garage'); }
	else { next(); }
};

function isLoggedIn(to, from, next) {
	if (localStorage.token) { next(); }
	else { next('/login') }
};

function isRootUser(to, from, next) {
	if (localStorage.token) { next(); }
	else { next('/login') }
};

function logoutAndGoToHome(to, from, next) {
	localStorage.removeItem("token");
	next('/');
};

// define routes, specific to general
const routes = [

	// Garage
	{
		path: '/garage',
		name: 'garage',
		component: GarageView,
		beforeEnter: isLoggedIn
	},

	// Admin
	{
		path: '/admin',
		name: 'admin',
		component: AdminView,
		beforeEnter: isLoggedIn
	},

	{
		path: '/ad/add',
		name: 'advert-add',
		component: AddAdvertView,
		beforeEnter: isLoggedIn
	},

	{
		path: '/ad/:id/edit',
		name: 'advert-edit',
		component: EditAdvertView,
		beforeEnter: isLoggedIn
	},

	// Listing views
	{
		path: '/listing/add',
		name: 'add-listing',
		component: AddListingView
	},

	{
		path: '/listing/:id/edit',
		name: 'edit-listing',
		component: EditListingView
	},

	// Want views
	{
		path: '/want/add',
		name: 'add-want',
		component: AddWantView
	},

	{
		path: '/want/:id/edit',
		name: 'edit-want',
		component: EditWantView
	},

	// Car views
	{
		path: '/garage/car/detail/:id',
		name: 'car',
		component: CarView
	},

	{
		path: '/garage/car/add',
		name: 'add-car',
		component: AddCarView,
		beforeEnter: isLoggedIn
	},

	{
		path: '/car/:id/edit',
		name: 'edit-car',
		component: EditCarView,
		beforeEnter: isLoggedIn
	},

	{
		path: '/garage/message/add',
		name: 'add-message',
		component: AddMessageView,
		beforeEnter: isLoggedIn
	},

	{
		path: '/garage/message/:id',
		name: 'message',
		component: MessageView,
		beforeEnter: isLoggedIn
	},

	{
		path: '/garage/car/detail/:id/records/new',
		name: 'add-record',
		component: AddRecordView,
		beforeEnter: isLoggedIn
	},

	{
		path: '/garage/car/detail/:id/records/edit/:recordId',
		name: 'edit-record',
		component: EditRecordView,
		beforeEnter: isLoggedIn
	},

	{
		path: '/garage/car/detail/:id/records/:recordId',
		name: 'record',
		component: RecordView,
		beforeEnter: isLoggedIn
	},

	// Search Users
	{
		path: '/users',
		name: 'users',
		component: UsersView
	},

	// login
	{
		path: '/login',
		name: 'login',
		component: LoginView,
		beforeEnter: redirectToGarage
	},

	// register
	{
		path: '/register',
		name: 'register',
		component: RegisterView,
		beforeEnter: redirectToGarage
	},

	// verify
	{
		path: '/verify/:key',
		name: 'verify',
		component: VerifyView
	},


	// logout
	{
		path: '/logout',
		name: 'logout',
		beforeEnter: logoutAndGoToHome
	},


	// listing deatil view
	{
		path: '/listing/:id',
		name: 'listing',
		component: DetailView,
	},

	// want deatil view
	{
		path: '/want/:id',
		name: 'want',
		component: WantDetailView,
	},


	// profile view
	{
		path: '/users/:name',
		name: 'profile',
		component: UserProfileView,
	},

	// search results view
	{
		path: '/search/:query',
		name: 'search',
		component: SearchResultsView,
	},

	// home listings
	{
		path: '/',
		name: 'home',
		component: Home
	},

	{
		path: '/listings/all',
		name: 'all',
		component: All
	},

	{
		path: '/wheels',
		name: 'wheels',
		component: Wheels
	},

	{
		path: '/listings/make/:make',
		name: 'listings-make',
		component: FeedViewMake
	},

	{
		path: '/listings/category/:category',
		name: 'listings-category',
		component: FeedViewCategory
	},

	{
		path: '/listings/tag/:tag',
		name: 'listings-tag',
		component: FeedViewTag
	},

	{
		path: '/filter/:year_from/:year_to/:make/:model',
		name: 'filtered-view',
		component: FilteredView
	},

	// GROUPS
	{
		path: '/groups',
		name: 'groups',
		component: GroupsView
	},

	{
		path: '/group/add',
		name: 'add-group',
		component: AddGroupView,
		beforeEnter: isLoggedIn
	},

	{
		path: '/group/:id',
		name: 'group',
		component: GroupDetailView,
	},




	// EVENTS
	{
		path: '/events',
		name: 'events',
		component: EventListingView
	},

	{
		path: '/event/add',
		name: 'event-add',
		component: AddEventView,
		beforeEnter: isLoggedIn
	},

	{
		path: '/event/:id/edit',
		name: 'event-edit',
		component: EditEventView,
		beforeEnter: isLoggedIn
	},

	{
		path: '/event/:id',
		name: 'event-detail',
		component: EventView
	},


	// TREASURE
	{
		path: '/cascade-consulting',
		name: 'cc',
		component: TreasureListingView
	},

	{
		path: '/treasure/add',
		name: 'treasure-add',
		component: AddTreasureView,
		beforeEnter: isLoggedIn
	},

	{
		path: '/treasure/:id/edit',
		name: 'treasure-edit',
		component: EditTreasureView,
		beforeEnter: isLoggedIn
	},

	{
		path: '/cascade-consulting/:id',
		name: 'cc-detail',
		component: TreasureView
	},





	// Timeline
	{
		path: '/timeline/add',
		name: 'timeline-add',
		component: AddTimelineView,
		beforeEnter: isLoggedIn
	},

	{
		path: '/timeline/:id/edit',
		name: 'timeline-edit',
		component: EditTimelineView,
		beforeEnter: isLoggedIn
	},

	{
		path: '/timeline/:id',
		name: 'timeline-detail',
		component: TimelineView
	},


	// MAKE
	{
		path: '/makes',
		name: 'makes',
		component: MakesView
	},

	{
		path: '/make/:make',
		name: 'make',
		component: MakeView
	},

	{
		path: '/cars',
		name: 'user_cars',
		component: CarsView
	},



	// ADMIN STUFF
	{
		path: '/neo',
		name: 'neo',
		component: Neo,
		beforeEnter: isRootUser
	},

	// {
	//   path: '/enzo',
	//   name: 'enzo',
	//   component: Enzo,
	//   beforeEnter: isLoggedIn
	// },

	{
		path: '/ferruccio',
		name: 'ferruccio',
		component: Ferruccio,
		beforeEnter: isLoggedIn
	},

	// Not found view
	{
		path: '*',
		name: '404',
		component: NotFoundView
	}

];

export default routes;
