<template lang='pug'>

.message-form
  modal(
    ref='modalSuccess'
    v-on:pass='back'
    message='Your Message has been sent.')

  loader(v-show='loadingScreen')

  segment

    .choice.pill.blue.large(v-if='dataToFill && recipient') {{ recipient }}

  segment


    autocomplete-input(
      v-if='users.length && !dataToFill'
      lock
      ref='users'
      label='Search For User'
      :data='users'
      v-on:selection='assignUser'
      v-on:cleared='removeRecipient')

    h4.regarding-reply(v-if='dataToFill && message.listing') Regarding Listing: {{ message.listing }}
    h4.regarding-reply(v-if='dataToFill && message.car') Regarding Car: {{ message.car }}

    .regarding(v-if='recipientData.listings.length > 0 || recipientData.garage.length > 0')
      div(v-if='recipientData.listings.length > 0')
        label Regarding Listing:
        .input-group.select
          chevron-down-icon(class='icon')
          select(v-model='message.listing')
            option(value='')
            option(v-for='listing, index in recipientData.listings') {{ listing.title }}

      div(v-if='recipientData.garage.length > 0')
        label Regarding Car:
        .input-group.select
          chevron-down-icon(class='icon')
          select(v-model='message.car')
            option(value='')
            option(v-for='car, index in recipientData.garage') {{ car.name }} ({{ car.year }} {{ car.make }} {{ car.model }})

    segment
      form-text(
        v-if='dataToFill.reply == false'
        v-model='message.subject'
        label='Subject'
        name='subject')

      form-text(
        v-if='!dataToFill || !dataToFill.title'
        v-model='message.subject'
        label='Subject'
        name='subject')

    segment

      form-textarea(
        v-model='message.body'
        ref='body'
        label='Message'
        name='message')

    segment

    .btn-list.right
      btn(
        color='green'
        v-if='dataToFill'
        ref='cta'
        @click='sendMessage'
        :disabled='sendable == false'
        label='Send Message')

  cta-wrap(v-if='!dataToFill')

    btn(
      color='green'
      ref='cta'
      @click='sendMessage'
      :disabled='sendable == false'
      label='Send Message')

</template>

<script>
import axios from 'axios';
import gtag from 'ga-gtag';
import AutocompleteInput from '../ui/autocomplete-input.vue';

export default {

  components: {
    AutocompleteInput
  },

  props: {
    dataToFill: Object,
    noChoice: Boolean
  },

  data() {
    return {
      recipient: false,
      recipientData: {
        username: null,
        listings: [],
        garage: []
      },
      showResults: false,
      results: [],
      loading: true,
      users: [],
      redactorConfig: {
        buttons: ['bold', 'italic', 'link', 'lists']
      },
      loadingScreen: false,
      message: {
        internal_id: null,
        title: null,
        body: null,
        to: null,
        from: null,
        listing: null,
        car: null
      }
    }
  },

  beforeMount: async function () {
    await fetch('/api/v1/message/users', {
      headers: {
          authorization: `Bearer ${JSON.parse(localStorage.token).value}`
        }
    }).then(res => res.json())
      .then(users => {
        this.users = users;
      });
  },

  mounted: async function() {

    // Hydrate things if this is a reply
    if(this.dataToFill) {

      if(this.dataToFill.to) {
        this.recipient = this.dataToFill.to;
        this.message.to = this.dataToFill.to;


        if(!this.noChoice) {
          await fetch('/api/v1/message/recipient/' + this.recipient, {
            headers: {
                authorization: `Bearer ${JSON.parse(localStorage.token).value}`
              }
          }).then(res => res.json())
            .then(recipientData => {
              this.recipientData = recipientData.user;
            });
        }

      }

      if(this.dataToFill.listing) {
        this.message.listing = this.dataToFill.listing;
      }

      if(this.dataToFill.car) {
        this.message.car = this.dataToFill.car;
      }

      if(this.dataToFill.reply) {
        if(this.dataToFill.title.indexOf('Re:') != -1) {
          this.message.title = this.dataToFill.title;
        } else {
          this.message.title = 'Re: ' + this.dataToFill.title;
        }
      }

    }
    this.loading = false;
  },

  computed: {
    sendable: function() {
      if(this.message.title && this.message.body && this.message.to) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {

    back: function() {
      this.$emit('closePane');
    },

    assignUser: async function(username) {
      this.message.to = username;
      this.recipient = username;
      this.showResults = false;
      this.results = [];

      await fetch('/api/v1/message/recipient/' + this.recipient, {
        headers: {
            authorization: `Bearer ${JSON.parse(localStorage.token).value}`
          }
      }).then(res => res.json())
        .then(recipientData => {
          this.recipientData = recipientData.user;
        });
    },


    sendMessage: async function() {
      gtag('event', 'message-sent');

      this.loadingScreen = true;
      this.$refs.cta.classList.add('force-disabled');

      var sanitizedMessage = this.$sanitize(this.message);

      try {

        await axios.post(
          '/api/v1/message/new',
          { message: sanitizedMessage },
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          });

        this.$refs.modalSuccess.openModal();
        this.resetView();
        this.loadingScreen = false;

      }

      catch(err) {
        alert('Something went wrong', err);
        console.log('Something went wrong', err);
      }

    },

    removeRecipient() {
      this.recipient = false;
      this.recipientData.username = null;
      this.recipientData.listings = [];
      this.recipientData.garage = [];
    },

    resetView() {
      window.scrollTo(0,0);
      this.recipient = false;
      this.recipientData.username = null;
      this.recipientData.garage = [];
      this.recipientData.listings = [];
      this.message.title = null
      this.message.internal_id = null;
      this.message.body = null;
      this.$refs.body.value = '';
      this.message.to = null;
      this.message.from = null;
      this.message.listing = null;
      this.message.car = true;
      this.$refs.cta.classList.remove('force-disabled');
    }
  }
}

</script>

<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'

.message-form
  text-align left

.autocomplete
  position: absolute
  width: 100%
  margin: 0
  z-index: 500
  background #000
  color color-text
  border: 1px solid black
  border-top: none
  margin-top: -5px
  max-height 200px
  overflow scroll

  .user
    padding 5px 15px
    margin-bottom 5px

.to
  display flex
  align-items center
  margin-bottom 20px

  h3
    margin-right 5px

.regarding-reply
  margin 0 15px 15px 0
  background #f0f0f0
  padding 10px 15px
  border-radius radius
  display inline-block

.regarding
  padding 20px
  margin 20px 10px 10px 10px
  background #eee
  border-radius radius

</style>
