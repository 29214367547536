var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content narrow" }, [
    _c("h1", [_vm._v("Ferruccio ")]),
    _c("br"),
    _c("br"),
    _c("img", {
      attrs: {
        src: "https://64.media.tumblr.com/40a1361ff65f6d61f0d785111a744192/tumblr_n6o9rsMZ931rpdptuo1_500.gifv",
      },
    }),
    _c("br"),
    _c("br"),
    _c("button", { on: { click: _vm.processMakes } }, [
      _vm._v("MAKES: PUNCH IT"),
    ]),
    _c("br"),
    _c("br"),
    _c("button", { on: { click: _vm.processModels } }, [
      _vm._v("MODELS: PUNCH IT"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }