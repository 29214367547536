import _ from 'lodash';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueRouter from 'vue-router';
import vhCheck from 'vh-check';
import VueSanitize from 'vue-sanitize';

// regulate vh
vhCheck()

// styles
import './styles/partstash.styl'

// Redactor
import './library/redactor/redactor.min.js';
import './library/redactor/vue-redactor.js';

// Components & Icons
import './components';
import './icons';

// vue globals (vuex and router)
Vue.use(VueRouter);
Vue.use(VueSanitize);
Vue.use(VueMeta);

import store from './store.js';
import routes from './routes';

// Vue Router
const router = new VueRouter({
  // history: true,
  routes: routes
});


// check if token, if so, try to get the user info
// else, clear the token if there's an issue
if (localStorage.token) {
  fetch('/api/v1/user', {
    headers: {
      authorization: `Bearer ${JSON.parse(localStorage.token).value}`
    }
  }).then(res => res.json())
    .then(user => {
      
      if(user) {
        store.state.user = user;
        store.state.loggedIn = true;
      } else {
        localStorage.removeItem('token');
        store.state.loggedIn = false;
      }

    });
}


// top level data & Components
import MainHeader from './components/header'
import Search from './components/search'
import AppNotice from './components/app-notice'
import Btn from './components/globals/btn'
import { clearAllBodyScrollLocks } from 'body-scroll-lock'

new Vue({
  el: '#partstash',
  router: router,
  store: store,
  metaInfo: {
    title: 'Feed',
    titleTemplate: '%s | Partstash: Classic Car Part Listings',
    meta: [
      { vmid: 'description', name: 'description', content: 'Partstash is the home for classic and hard to find cars and parts.' },
      { vmid: 'og:title', property: 'og:title', content: 'Partstash Classic Car Part Listings' },
      { vmid: 'og:image', property: 'og:image', content: 'https://drive.partstash.com/imgs/ps.jpg' }
    ]
  },
  components: {
    MainHeader,
    Search,
    Redactor,
    AppNotice,
    Btn
  },
  mounted: function() {

    this.checkLocalStorageToken();

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

  },
  
  watch: {
    '$route': function (to, from) {
      clearAllBodyScrollLocks();
      this.checkLocalStorageToken();
    }
  },
  methods: {
    checkLocalStorageToken: function() {

      // log out and clear token if it's past it's expiration
      if (localStorage.token) {
        var stamp = new Date().getTime();
        var expiry = JSON.parse(localStorage.token).expiry;
        if(stamp > expiry) {
          localStorage.removeItem('token');
          this.$store.state.loggedIn =  false;
          this.$store.state.user = {};

          // go home if you're not already
          // this solves the redundant navigation
          // error when trying to login with 
          // an expired jwt token
          if (this.$route.path != '/') {
            this.$router.push('/');
          }
        }
      }
    }
  }

});


