var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.notice
        ? _c("notice", {
            attrs: {
              color: "yellow",
              notice:
                "Content has been flagged. A moderator will look into this and take action if nessesary.",
            },
          })
        : _vm._e(),
      _vm.isLoggedIn
        ? _c("btn", {
            attrs: {
              label: "Flag as innapropriate",
              icon: "flag-icon",
              color: "yellow",
              theme: "outline",
            },
            on: { click: _vm.flag },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }