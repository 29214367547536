var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "users content narrow" },
        [
          _c("heading", { attrs: { heading: "All Users" } }),
          _c(
            "segment",
            [
              _c("form-text", {
                attrs: { label: "Search For a User", name: "user" },
                on: {
                  inputChanged: function ($event) {
                    return _vm.onInputChange($event)
                  },
                },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showResults,
                      expression: "showResults",
                    },
                  ],
                  staticClass: "autocomplete",
                },
                _vm._l(_vm.results, function (user, index) {
                  return _c("UserRow", { key: index, attrs: { user: user } })
                }),
                1
              ),
            ],
            1
          ),
          _c("segment", [
            _c(
              "div",
              { staticClass: "main-list" },
              _vm._l(_vm.paginatedUsers, function (user, index) {
                return _c("UserRow", { key: index, attrs: { user: user } })
              }),
              1
            ),
            _vm.canLoadMore
              ? _c(
                  "div",
                  { staticClass: "loads-more-wrap" },
                  [
                    _c("btn", {
                      attrs: { label: "Load More" },
                      on: { click: _vm.loadMore },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("segment", [_c("invite")], 1),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }