<template lang="pug">

.feed
	feed-all(endpoint='feed/all')

</template>

<script>
import FeedAll from './feed-all';

export default {

	components: { FeedAll },

	name: 'feed',

	computed: {
		heading: function() {
			if(this.loggedInUser.username) {
				return 'Feed: ' + this.loggedInUser.username;
			} else {
				return 'Feed';
			}
		},

		loggedInUser: function() { return this.$store.state.user; }
	}

}
</script>


<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

</style>
