<template lang='pug'>

mounted-pane(ref='pane' title='Notifications' @close='close()')
	.content-rows(v-if='loaded')
		.content
			content-rows(:data='data' type='notices')

</template>

<script>
import ContentRows from '/src/components/garage/content-rows';

export default {

	components: { ContentRows },

  props: {
    data: Array
  },

	data: function() {
    return {
      loaded: false
    }
  },

	mounted: async function() {
		this.loaded = true;
	}
  
}

</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

</style>