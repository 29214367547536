var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "segment",
    [
      _c("loader", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
      }),
      _c("segment-header", [_c("h1", [_vm._v("Create a new account")])]),
      _c(
        "form",
        {
          ref: "form",
          staticClass: "form",
          attrs: { enctype: "multipart/form-data" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.addUser.apply(null, arguments)
            },
          },
        },
        [
          _vm.error.length
            ? _c("standard-message", {
                attrs: { type: "error", message: _vm.error },
              })
            : _vm._e(),
          _vm.loading ? _c("div", { staticClass: "loading-view" }) : _vm._e(),
          _c("form-text", {
            attrs: {
              label: "Username",
              autocapitalize: "off",
              name: "username",
              required: "",
            },
            model: {
              value: _vm.user.username,
              callback: function ($$v) {
                _vm.$set(_vm.user, "username", $$v)
              },
              expression: "user.username",
            },
          }),
          _c("form-text", {
            attrs: { label: "First Name", name: "firstname", required: "" },
            model: {
              value: _vm.user.firstName,
              callback: function ($$v) {
                _vm.$set(_vm.user, "firstName", $$v)
              },
              expression: "user.firstName",
            },
          }),
          _c("form-text", {
            attrs: { label: "Last Name", name: "lastname", required: "" },
            model: {
              value: _vm.user.lastName,
              callback: function ($$v) {
                _vm.$set(_vm.user, "lastName", $$v)
              },
              expression: "user.lastName",
            },
          }),
          _c("form-text", {
            attrs: {
              label: "Password",
              name: "password",
              type: "password",
              required: "",
            },
            model: {
              value: _vm.user.password,
              callback: function ($$v) {
                _vm.$set(_vm.user, "password", $$v)
              },
              expression: "user.password",
            },
          }),
          _c("form-text", {
            attrs: {
              label: "Confirm Password",
              name: "passwordConfirm",
              type: "password",
              required: "",
            },
            model: {
              value: _vm.user.passwordConfirm,
              callback: function ($$v) {
                _vm.$set(_vm.user, "passwordConfirm", $$v)
              },
              expression: "user.passwordConfirm",
            },
          }),
          _c("form-text", {
            attrs: {
              label: "Email",
              name: "email",
              autocapitalize: "off",
              required: "",
            },
            model: {
              value: _vm.user.email,
              callback: function ($$v) {
                _vm.$set(_vm.user, "email", $$v)
              },
              expression: "user.email",
            },
          }),
          _c("form-text", {
            attrs: {
              label: "Zip Code",
              name: "zip",
              type: "number",
              required: "",
            },
            model: {
              value: _vm.user.zip,
              callback: function ($$v) {
                _vm.$set(_vm.user, "zip", $$v)
              },
              expression: "user.zip",
            },
          }),
          _c("div", { staticClass: "input-group gallery" }, [
            _c("p", [_vm._v("Optional Profile Image")]),
            _c("p", { staticClass: "small" }, [
              _vm._v("can be set up later in your garage"),
            ]),
            _c(
              "div",
              { staticClass: "gallery-picker-wrap" },
              [
                _c("uploader", {
                  attrs: { single: "", type: "profile" },
                  on: { "master-batch": _vm.onSelect },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "actions" },
            [
              _c(
                "button",
                { staticClass: "cta green", attrs: { type: "submit" } },
                [_vm._v("Create Account")]
              ),
              _c("br"),
              _c("br"),
              _c("p", [_vm._v("already have an account?")]),
              _c("btn", {
                attrs: {
                  label: "Login",
                  size: "small",
                  color: "light",
                  to: "/login",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }