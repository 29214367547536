var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "icon" },
    [
      _vm.icon == "flag-icon"
        ? _c("flag-icon", { staticClass: "icon" })
        : _vm._e(),
      _vm.icon == "trash-2-icon"
        ? _c("trash-2-icon", { staticClass: "icon" })
        : _vm._e(),
      _vm.icon == "image-icon"
        ? _c("image-icon", { staticClass: "icon" })
        : _vm._e(),
      _vm.icon == "check-icon"
        ? _c("check-icon", { staticClass: "icon" })
        : _vm._e(),
      _vm.icon == "chevron-left-icon"
        ? _c("chevron-left-icon", { staticClass: "icon" })
        : _vm._e(),
      _vm.icon == "chevron-right-icon"
        ? _c("chevron-right-icon", { staticClass: "icon" })
        : _vm._e(),
      _vm.icon == "dollar-sign-icon"
        ? _c("dollar-sign-icon", { staticClass: "icon" })
        : _vm._e(),
      _vm.icon == "x-icon" ? _c("x-icon", { staticClass: "icon" }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }