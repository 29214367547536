var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.state,
          expression: "state",
        },
      ],
      on: {
        change: [
          function ($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function (o) {
                return o.selected
              })
              .map(function (o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.state = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          },
          _vm.onChange,
        ],
      },
    },
    [
      _c("option", { attrs: { value: "undefined" } }, [
        _vm._v(_vm._s(_vm.fullLabel)),
      ]),
      _vm._l(_vm.items, function (item, index) {
        return _c("car-creator-option", {
          key: index,
          attrs: { item: item, typeID: _vm.typeID, index: index },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }