var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "feed-items" }, [
    _vm.feed.length
      ? _c(
          "div",
          [
            _vm.rows
              ? _c(
                  "div",
                  _vm._l(_vm.feed, function (item, index) {
                    return _c("row", { key: index, attrs: { item: item } })
                  }),
                  1
                )
              : _c(
                  "grid",
                  _vm._l(_vm.feed, function (item, index) {
                    return _c("card", {
                      key: index,
                      attrs: { item: item, small: _vm.small },
                    })
                  }),
                  1
                ),
            _vm.canLoadMore
              ? _c(
                  "div",
                  { staticClass: "loads-more" },
                  [
                    _c("btn", {
                      attrs: { label: "Load More" },
                      on: { click: _vm.loadMoreListings },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _c("div", { staticClass: "no-listings" }, [
          _c("h2", [_vm._v("Nothing to show")]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }