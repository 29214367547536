<template lang="pug">

.image-placeholder
  img(src='/assets/logo.svg')

</template>

<script>
export default {
  name: 'image-placeholder',
}
</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.image-placeholder
  expand()
  overflow hidden
  background-color color-bg

  img
    position absolute
    width 200%
    height auto
    margin-top -350px
    margin-left -350px
    opacity .07

</style>