var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-timeline" }, [
    _c(
      "div",
      { staticClass: "image" },
      [
        _c("badges", { attrs: { page: _vm.item, absolute: "" } }, [
          _vm.typeBadge
            ? _c("div", { staticClass: "badge type" }, [
                _vm._v(_vm._s(_vm.item.entry_type)),
              ])
            : _vm._e(),
        ]),
        _c(
          "router-link",
          { attrs: { to: _vm.deepLink } },
          [
            _vm.item.gallery[0]
              ? _c("base-image", {
                  attrs: {
                    src: _vm.item.gallery[0].filename,
                    bucket: "images",
                  },
                })
              : _c("placeholder"),
          ],
          1
        ),
        _c("profile-widget", { attrs: { userId: _vm.item.user_id } }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "info" },
      [
        _c("div", {
          staticClass: "status",
          domProps: { innerHTML: _vm._s(_vm.item.status) },
        }),
        _c("likes-and-comments-bar", {
          attrs: { item: _vm.item, theme: "small", type: "timeline" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }