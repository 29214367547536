<template lang='pug'>

form.record-form(@submit.prevent='formComplete' v-if='loaded')

	loader(v-show='loadingScreen')

	segment(title='Details')

		form-text(
			v-model='entry.title'
			label='Name'
			name='title')

	segment(title='Type')

		.radio-wrap

			//- label
			label(
				v-for='type, index in types'
				:key='index'
				:for='type')

				//- input
				input(
					type='radio'
					v-model="entry.type"
					name='recordType'
					:value='type'
					:id='type')

				//- styled elements
				span.box
					span {{ type }}

	segment(title='Event Visibility')

		.radio-wrap(ref='scopes')

			label(for='visiblityAll')

				input(
					type='checkbox'
					id='visiblityAll'
					ref='visiblityAll'
					name='all'
					data-val='all'
					checked)

				//- styled elements
				span.box
					span Public

			label(
					v-for='group, index in groups'
					:key='index'
					:for='group.internal_id')

				input(
					type='checkbox'
					:checked='isInGroup(group)'
					:id='group.internal_id'
					:ref='group.internal_id'
					:data-val='group.internal_id'
					:name='visibilityName(group)')

				span.box
					span {{ groupTitle(group.name) }}


	segment(title='Images')
		uploader(
			ref='imagePicker'
			v-on:master-batch='onImageSelect'
			:initial='entry.gallery'
			:edit='edit'
			type='group')

	segment
		form-text(
			type='date'
			v-model='entry.event_date'
			label='Event Date'
			name='date')

		div and/or

		form-text(
			v-model='entry.recurring_frequency'
			label='Recurring Frequency'
			name='recurring_frequency')

		form-text(
			v-model='entry.url'
			label='Optional Link'
			name='url')

		form-text(
			v-model='entry.location'
			label='Optional Locaiton'
			name='location')

		form-text(
			v-model='entry.location_url'
			label='Optional Location Link'
			name='location_url')

		form-text(
			v-model='entry.time'
			label='Event Time'
			name='time')

		form-textarea(
			v-model='entry.body'
			:value='entry.body'
			label='Details'
			name='body')

	//- CTA BAR------------------------------------------
	cta-wrap
		button.btn.green.cta(
			ref='cta'
			type='submit'
			:disabled='addable == false'
			v-html='btnText')


</template>

<script>
import axios from 'axios';
import { trimTitle, mountOnBody, smartTitle } from '/src/services/helpers';
import PaneCreate from '/src/components/panes/modal/create';
import PaneEdit from '/src/components/panes/modal/edit';

export default {

	props: {
		cta: String,
		new: Boolean,
		edit: Boolean,
		entryToEdit: Object
	},

	data: function() {
		return {
			loaded: false,
			groups: [],
			entry: {
				title: null,
				body: null,
				scope: [],
				gallery: null,
				type: 'meetup',
				event_date: null,
				recurring_frequency: null,
				url: null,
				location: null,
				location_url: null,
				time: null
			},
			loadingScreen: false,
			types: [
				'meetup',
				'rally',
				'drive',
				'other',
				'cars & coffee'
			]
		}
	},

	beforeMount: async function() {
		if(this.edit && this.entryToEdit) {
			this.entry = this.entryToEdit;
		}

		await fetch('/api/v1/group/userGroups', {
			headers: {
				authorization: `Bearer ${JSON.parse(localStorage.token).value}`
			}
		}).then(res => res.json())
			.then(groups => {
			var scope = this;
			 groups.forEach(function(group) {
					scope.groups.push(group);
				});
			});

		await fetch('/api/v1/group/userOwnedGroups', {
			headers: {
				authorization: `Bearer ${JSON.parse(localStorage.token).value}`
			}
		}).then(res => res.json())
			.then(groups => {
				var scope = this;
				groups.forEach(function(group) {
					scope.groups.push(group);
				});
			});

	},

	mounted: function() {
		this.loaded = true;
	},

	computed: {

		addable: function() {
			if(this.entry.title &&
					this.entry.body) {
				return true;
			} else {
				return false;
			}
		},

		btnText: function() {
			if(this.edit) {
				return 'Update ' + this.cta
			} else {
				return 'Add ' + this.cta
			}
		},

		title: function() {
			return smartTitle(this.entry.title)
		}
	},

	methods: {

		visibilityName(group) {
			return group.internal_id;
		},

		groupTitle(title) {
			return trimTitle(title);
		},

		isInGroup(group) {
			if(this.entry.scope.indexOf(group.internal_id) != -1) {
				return true;
			} else {
				return false;
			}
		},

		 onImageSelect(files) {
			this.entry.gallery = files;
		},

		formComplete() {
			this.loadingScreen = true;
			this.$refs.cta.classList.add('force-disabled');

			if(this.new) {
				gtag('event', 'event-add');
				this.addEntry();
			} else {
				gtag('event', 'event-update');
				this.updateEntry();
			}
		},

		async addEntry() {

			// Build common form data
			let formData = this.prepareFormData();

			// prepare file list
			let fileListArray = [];
			if(this.entry.gallery) {
				this.entry.gallery.forEach(function (item, index) {
					fileListArray.push(item.file);
				});

				for(var i = 0; i < fileListArray.length; i++) {
					formData.append('file', fileListArray[i]);
				}
			}

			// deal with the scope
			var checkboxInputs = this.$refs.scopes.querySelectorAll('input:checked');
			var scopes = this.entry.scope;
			checkboxInputs.forEach(function(item) {
				scopes.push(item.getAttribute('data-val'));
			});

			formData.append('scope', scopes);

			try {

				let submission = await axios.post(
					'/api/v1/event/add',
					formData,
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
							'Content-Type' : 'multipart/form-data'
						}
					});

				let res = submission.data;
				this.mountPane('created', res);
				this.reset();

			}

			catch(err) {
				alert('Something went wrong', err);
			}
		},

		async updateEntry() {

			// Build common form data
			let formData = this.prepareFormData();
			formData.append('internal_id', this.entry.internal_id);

			// Prep the images that will be sent to the
			// endpoint (new, edit, remove, etc)
			var imagesToAdd = this.entry.gallery.filter(el => el.new == true);
			var imagesToRemove = this.entry.gallery.filter(el => el.remove == true);


			// prepare file list
			let addArray = [];
			imagesToAdd.forEach(function (item, index) {
				addArray.push(item);
				formData.append('file', item.file);
			});

			let removeArray = [];
			imagesToRemove.forEach(function (item, index) {
				removeArray.push(item.filename);
			});

			// Finally, add an array of items to remove here
			formData.append('to_remove', removeArray);

			// deal with the scope
			var checkboxInputs = this.$refs.scopes.querySelectorAll('input:checked');
			var scopes = [];
			checkboxInputs.forEach(function(item) {
				scopes.push(item.getAttribute('data-val'));
			});

			formData.append('scope', scopes);

			try {

				let submission = await axios.post(
					'/api/v1/event/edit',
					formData,
					{
						headers: {
							authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
							'Content-Type' : 'multipart/form-data'
						}
					});

				let res = submission.data;
				this.mountPane('edited', res);
				this.reset();

			}

			catch(err) {
				console.log('Something went wrong', err);
			}
		},

		reset() {
			window.scrollTo(0,0);
			this.entry.title = null;
			this.entry.body = null;
			this.entry.gallery = null;
			this.entry.scope = [];
			this.entry.type = 'meetup';
			this.entry.event_date = null;
			this.entry.recurring_frequency = null;
			this.entry.url = null;
			this.entry.location = null;
			this.entry.location_url = null;
			this.entry.time = null;
			this.loadingScreen = false;
			this.$refs.imagePicker.reset();
			this.$refs.cta.classList.remove('force-disabled');
		},

		 // Prepare common formData elements
		prepareFormData() {
			var fd = new FormData();
			fd.append('title', this.$sanitize(this.entry.title));
			fd.append('type', this.entry.type);
			fd.append('body', this.$sanitize(this.entry.body));
			if(this.entry.event_date) {
				fd.append('event_date', this.entry.event_date);
			}
			fd.append('recurring_frequency', this.entry.recurring_frequency);
			fd.append('url', this.$sanitize(this.entry.url));
			fd.append('location', this.$sanitize(this.entry.location));
			fd.append('location_url', this.$sanitize(this.entry.location_url));
			fd.append('time', this.$sanitize(this.entry.time));
			return fd;
		},

		async mountPane(key, data) {
			if(key == 'created') {
				await mountOnBody(PaneCreate, { parent: this, propsData: this.getModalOptions(data) })
			}

			if(key == 'edited') {
				await mountOnBody(PaneEdit, { parent: this, propsData: this.getModalOptions(data) })
			}
		},

		getModalOptions(data) {
			return {
				title: trimTitle(data.title),
				viewLink: '/event/' + data.internal_id,
				backLink: '/garage',
				message: trimTitle(data.title) + " successfully created.",
				parentEl: this
			}
		}
	}
}

</script>

<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'
@import '~/src/styles/forms.styl'
@import '~/src/styles/listing-forms.styl'

.input-group.select
	input-style()


</style>
