var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("heading", {
        attrs: {
          heading: "New Group",
          "user-action": "/garage",
          "user-label": "< Garage",
        },
      }),
      _c("group-form", { attrs: { new: "" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }