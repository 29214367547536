var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mounted-pane",
    {
      ref: "pane",
      attrs: { title: "🗑 Confirm Delete", location: "top" },
      on: {
        close: function ($event) {
          return _vm.close()
        },
      },
    },
    [
      _vm.loaded
        ? _c("div", { staticClass: "content-rows" }, [
            _c("div", { staticClass: "content" }, [
              _c("p", [_vm._v(_vm._s(_vm.message))]),
              _c(
                "div",
                { staticClass: "btn-list" },
                [
                  _c("btn", {
                    attrs: { size: "small", label: "Confirm" },
                    on: {
                      click: function ($event) {
                        return _vm.confirm()
                      },
                    },
                  }),
                  _c("btn", {
                    attrs: { color: "grey", size: "small", label: "Cancel" },
                    on: {
                      click: function ($event) {
                        return _vm.cancel()
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }