var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row-search" },
    [
      _c("bookmark-icon", { staticClass: "icon" }),
      _c(
        "router-link",
        { staticClass: "label", attrs: { to: "/" + _vm.item.url } },
        [_vm._v(_vm._s(_vm.item.label))]
      ),
      _c("btn", {
        attrs: {
          color: "red",
          theme: "outline",
          size: "small",
          icon: "trash-2-icon",
        },
        on: {
          click: function ($event) {
            return _vm.removeSavedSearch()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }