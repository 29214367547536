import { render, staticRenderFns } from "./paginated-groups.vue?vue&type=template&id=425e9248&scoped=true&lang=pug&"
import script from "./paginated-groups.vue?vue&type=script&lang=js&"
export * from "./paginated-groups.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "425e9248",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/matthewaebersold/dev/psg/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('425e9248')) {
      api.createRecord('425e9248', component.options)
    } else {
      api.reload('425e9248', component.options)
    }
    module.hot.accept("./paginated-groups.vue?vue&type=template&id=425e9248&scoped=true&lang=pug&", function () {
      api.rerender('425e9248', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/garage/paginated-groups.vue"
export default component.exports