var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "form",
        {
          staticClass: "record-form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.formComplete.apply(null, arguments)
            },
          },
        },
        [
          _c("loader", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loadingScreen,
                expression: "loadingScreen",
              },
            ],
          }),
          _c(
            "segment",
            { attrs: { title: "Details" } },
            [
              _c("form-text", {
                attrs: { label: "Name", name: "title" },
                model: {
                  value: _vm.entry.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.entry, "title", $$v)
                  },
                  expression: "entry.title",
                },
              }),
            ],
            1
          ),
          _c("segment", { attrs: { title: "Type" } }, [
            _c(
              "div",
              { staticClass: "radio-wrap" },
              _vm._l(_vm.types, function (type, index) {
                return _c("label", { key: index, attrs: { for: type } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.entry.type,
                        expression: "entry.type",
                      },
                    ],
                    attrs: { type: "radio", name: "recordType", id: type },
                    domProps: {
                      value: type,
                      checked: _vm._q(_vm.entry.type, type),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.entry, "type", type)
                      },
                    },
                  }),
                  _c("span", { staticClass: "box" }, [
                    _c("span", [_vm._v(_vm._s(type))]),
                  ]),
                ])
              }),
              0
            ),
          ]),
          _c("segment", { attrs: { title: "Event Visibility" } }, [
            _c(
              "div",
              { ref: "scopes", staticClass: "radio-wrap" },
              [
                _c("label", { attrs: { for: "visiblityAll" } }, [
                  _c("input", {
                    ref: "visiblityAll",
                    attrs: {
                      type: "checkbox",
                      id: "visiblityAll",
                      name: "all",
                      "data-val": "all",
                      checked: "",
                    },
                  }),
                  _c("span", { staticClass: "box" }, [
                    _c("span", [_vm._v("Public")]),
                  ]),
                ]),
                _vm._l(_vm.groups, function (group, index) {
                  return _c(
                    "label",
                    { key: index, attrs: { for: group.internal_id } },
                    [
                      _c("input", {
                        ref: group.internal_id,
                        refInFor: true,
                        attrs: {
                          type: "checkbox",
                          id: group.internal_id,
                          "data-val": group.internal_id,
                          name: _vm.visibilityName(group),
                        },
                        domProps: { checked: _vm.isInGroup(group) },
                      }),
                      _c("span", { staticClass: "box" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.groupTitle(group.name))),
                        ]),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
          _c(
            "segment",
            { attrs: { title: "Images" } },
            [
              _c("uploader", {
                ref: "imagePicker",
                attrs: {
                  initial: _vm.entry.gallery,
                  edit: _vm.edit,
                  type: "group",
                },
                on: { "master-batch": _vm.onImageSelect },
              }),
            ],
            1
          ),
          _c(
            "segment",
            [
              _c("form-text", {
                attrs: { type: "date", label: "Event Date", name: "date" },
                model: {
                  value: _vm.entry.event_date,
                  callback: function ($$v) {
                    _vm.$set(_vm.entry, "event_date", $$v)
                  },
                  expression: "entry.event_date",
                },
              }),
              _c("div", [_vm._v("and/or")]),
              _c("form-text", {
                attrs: {
                  label: "Recurring Frequency",
                  name: "recurring_frequency",
                },
                model: {
                  value: _vm.entry.recurring_frequency,
                  callback: function ($$v) {
                    _vm.$set(_vm.entry, "recurring_frequency", $$v)
                  },
                  expression: "entry.recurring_frequency",
                },
              }),
              _c("form-text", {
                attrs: { label: "Optional Link", name: "url" },
                model: {
                  value: _vm.entry.url,
                  callback: function ($$v) {
                    _vm.$set(_vm.entry, "url", $$v)
                  },
                  expression: "entry.url",
                },
              }),
              _c("form-text", {
                attrs: { label: "Optional Locaiton", name: "location" },
                model: {
                  value: _vm.entry.location,
                  callback: function ($$v) {
                    _vm.$set(_vm.entry, "location", $$v)
                  },
                  expression: "entry.location",
                },
              }),
              _c("form-text", {
                attrs: {
                  label: "Optional Location Link",
                  name: "location_url",
                },
                model: {
                  value: _vm.entry.location_url,
                  callback: function ($$v) {
                    _vm.$set(_vm.entry, "location_url", $$v)
                  },
                  expression: "entry.location_url",
                },
              }),
              _c("form-text", {
                attrs: { label: "Event Time", name: "time" },
                model: {
                  value: _vm.entry.time,
                  callback: function ($$v) {
                    _vm.$set(_vm.entry, "time", $$v)
                  },
                  expression: "entry.time",
                },
              }),
              _c("form-textarea", {
                attrs: {
                  value: _vm.entry.body,
                  label: "Details",
                  name: "body",
                },
                model: {
                  value: _vm.entry.body,
                  callback: function ($$v) {
                    _vm.$set(_vm.entry, "body", $$v)
                  },
                  expression: "entry.body",
                },
              }),
            ],
            1
          ),
          _c("cta-wrap", [
            _c("button", {
              ref: "cta",
              staticClass: "btn green cta",
              attrs: { type: "submit", disabled: _vm.addable == false },
              domProps: { innerHTML: _vm._s(_vm.btnText) },
            }),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }