var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content-rows" }, [
    _c("div", { staticClass: "content" }, [
      _vm.btn && _vm.loggedInUser
        ? _c(
            "div",
            { staticClass: "action" },
            [
              _vm.path == "wants"
                ? _c("btn", {
                    attrs: { to: "/want/add", label: "New Want To Buy" },
                  })
                : _c("btn", {
                    attrs: { to: "/listing/add", label: "New Listing" },
                  }),
            ],
            1
          )
        : _vm._e(),
      _vm.data.length
        ? _c(
            "div",
            [
              _vm.row
                ? _c(
                    "div",
                    _vm._l(_vm.data, function (item, index) {
                      return _c("row", {
                        key: index,
                        attrs: { item: item, forceDelete: _vm.forceDelete },
                      })
                    }),
                    1
                  )
                : _c(
                    "grid",
                    _vm._l(_vm.data, function (item, index) {
                      return _c("card", { key: index, attrs: { item: item } })
                    }),
                    1
                  ),
            ],
            1
          )
        : _c("div", [_c("p", { staticClass: "none" }, [_vm._v("No Data")])]),
      _vm.canLoadMore
        ? _c(
            "div",
            { staticClass: "loads-more" },
            [
              _c("btn", {
                attrs: { label: "Load More" },
                on: { click: _vm.loadMore },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }