<template lang="pug">

feed

</template>

<script>

import Feed from '/src/components/feed/index';

export default {

  name: 'home',

  components: {
    Feed
  }
}
</script>


<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

</style>
