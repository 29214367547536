var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "listing",
    [
      _c("heading", {
        attrs: {
          heading: "User Cars",
          "user-action": "/",
          "user-label": "< Back",
        },
      }),
      _c("paginated-feed", { attrs: { type: "garage" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }