var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.componentLoaded
    ? _c("div", { staticClass: "car-picker" }, [
        _c(
          "div",
          { staticClass: "input-group select picker-select" },
          [
            _c(
              "select",
              { ref: "carSelect", on: { change: _vm.onCarSelectChange } },
              [
                _c("option", { attrs: { value: "" } }, [
                  _vm._v("Pick a car or add a new one"),
                ]),
                _vm._l(_vm.garage, function (car, index) {
                  return _c(
                    "option",
                    { domProps: { value: _vm.computedCar(car) } },
                    [
                      _vm._v(
                        _vm._s(car.name) +
                          " (" +
                          _vm._s(car.make) +
                          " " +
                          _vm._s(car.model) +
                          ")"
                      ),
                    ]
                  )
                }),
                _c("option", { attrs: { value: "" } }, [
                  _vm._v("-------------"),
                ]),
                _c("option", { attrs: { value: "new" } }, [
                  _vm._v("Add a new car"),
                ]),
                _c("option", { attrs: { value: "" } }, [
                  _vm._v("Not Associated with any specific car"),
                ]),
              ],
              2
            ),
            _c("chevron-down-icon", { staticClass: "icon" }),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.newCar,
                expression: "newCar",
              },
            ],
            staticClass: "new-car",
          },
          [
            _c("h3", [_vm._v("Pick a new car for this listing")]),
            _c("car-creator", {
              ref: "carCreator",
              on: {
                year: _vm.onYear,
                make: _vm.onMake,
                model: _vm.onModel,
                trim: _vm.onTrim,
              },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }