<template lang='pug'>

div

  h3 {{ label }}

  Redactor(
    v-model='state'
    :name='name'
    :config="redactorConfig")

</template>

<script>


export default {

  props: {
    label: String,
    name: String,
    value: [String, Number],
    required: {
      type: Boolean,
      default: false
    }
  },

  data: function() {
    return {
      redactorConfig: {
        buttons: ['bold', 'italic', 'link', 'lists']
      },
      state: '',
      processing: false
    }
  },

	mounted: function() {
		if(this.value) { this.state = this.value; }
	},

  watch: {
    value() {
      this.state = this.value;
    },
    state() {
      this.$emit('input', this.state);
    }
  }

}

</script>

<style lang='stylus' scoped>

div
  padding 15px 0
  margin 15px 0

label
  font-weight bold
  display block
  margin-bottom 5px


</style>
