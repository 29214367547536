<template lang='pug'>

form.record-form(@submit.prevent='formComplete' v-if='loaded')

  loader(v-show='loadingScreen')

  segment(title='Details')

    form-text(
      v-model='record.name'
      label='Title'
      name='name')

    .radio-wrap.checkmark

      label(for='is_private')

        input(
          type='checkbox'
          v-model="record.is_private"
          name='is_private'
          value='is_private'
          ref='is_private'
          id='is_private')

        span.box
          check-icon(class='icon')
          span Private?

  segment(title='Type')

    .radio-wrap

      //- label
      label(
        v-for='type, index in types'
        :key='index'
        :for='type')

        //- input
        input(
          type='radio'
          v-model="record.type"
          name='recordType'
          :value='type'
          :id='type')

        //- styled elements
        span.box
          span {{ type }}


  segment(title='Images')
    uploader(
      ref='imagePicker'
      v-on:master-batch='onImageSelect'
      :initial='record.gallery'
      :edit='edit'
			type='garage')

  segment
    form-textarea(
      v-model='record.body'
			:value='record.body'
      label='Details'
      name='body')

  //- CTA BAR------------------------------------------
  cta-wrap
    button.btn.green.cta(
      ref='cta'
      type='submit'
      :disabled='addable == false'
      v-html='btnText')


</template>

<script>
import axios from 'axios';
import { mountOnBody } from '/src/services/helpers';
import PaneCreate from '/src/components/panes/modal/create';
import PaneEdit from '/src/components/panes/modal/edit';

export default {

  props: {
    new: Boolean,
    edit: Boolean,
    car: Object,
		recordToEdit: Object
  },

  data: function() {
    return {
			loaded: false,
      record: {
        name: null,
        body: null,
        is_private: false,
        gallery: null,
        type: 'general'
      },
      loadingScreen: false,
      types: [
        'general',
        'mod',
        'issue',
        'service',
        'repair',
        'track',
        'other'
      ]
    }
  },

	beforeMount: function() {
		if(this.edit && this.recordToEdit) {
			this.record = this.recordToEdit;
		}
	},

	mounted: function() {
		this.loaded = true;
	},

  computed: {

    addable: function() {
      if(this.record.name &&
          this.record.body) {
        return true;
      } else {
        return false;
      }
    },

    btnText: function() {
      if(this.edit) {
        return 'Update Record'
      } else {
        return 'Add Record'
      }
    },
  },

  methods: {
     onImageSelect(files) {
      this.record.gallery = files;
    },

    formComplete() {
			this.loadingScreen = true;
			this.$refs.cta.classList.add('force-disabled');

      if(this.new) {
				gtag('event', 'record-add');
        this.addRecord();
      } else {
				gtag('event', 'record-update');
        this.updateRecord();
      }
    },

    async addRecord() {

      // Build common form data
      let formData = this.prepareFormData();

      // prepare file list
      let fileListArray = [];
      if(this.record.gallery) {
        this.record.gallery.forEach(function (item, index) {
          fileListArray.push(item.file);
        });

        for(var i = 0; i < fileListArray.length; i++) {
          formData.append('file', fileListArray[i]);
        }
      }

      try {

        let submission = await axios.post(
          '/api/v1/garage/record/add',
          formData,
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'multipart/form-data'
            }
          });

        let res = submission.data;
        this.mountPane('created', res);
        this.reset();

      }

      catch(err) {
        alert('Something went wrong', err);
      }
    },

    async updateRecord() {

      // Build common form data
      let formData = this.prepareFormData();
      formData.append('internal_id', this.record.internal_id);

      // Prep the images that will be sent to the
      // endpoint (new, edit, remove, etc)
      var imagesToAdd = this.record.gallery.filter(el => el.new == true);
      var imagesToRemove = this.record.gallery.filter(el => el.remove == true);


      // prepare file list
      let addArray = [];
      imagesToAdd.forEach(function (item, index) {
        addArray.push(item);
        formData.append('file', item.file);
      });

      let removeArray = [];
      imagesToRemove.forEach(function (item, index) {
        removeArray.push(item.filename);
      });

      // Finally, add an array of items to remove here
      formData.append('to_remove', removeArray);

      try {

        let submission = await axios.post(
          '/api/v1/garage/record/edit',
          formData,
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'multipart/form-data'
            }
          });

        let res = submission.data;
        this.mountPane('edited', res);
        this.reset();

      }

      catch(err) {
        console.log('Something went wrong', err);
      }
    },

    reset() {
      window.scrollTo(0,0);
      this.record.name = null;
      this.record.body = null;
      this.record.gallery = null;
      this.record.type = 'general';
      this.loadingScreen = false;
      this.$refs.imagePicker.reset();
      this.$refs.cta.classList.remove('force-disabled');
    },

     // Prepare common formData elements
    prepareFormData() {
      var fd = new FormData();
      fd.append('name', this.$sanitize(this.record.name));
      fd.append('type', this.record.type);
      fd.append('body', this.$sanitize(this.record.body));
      fd.append('car_id', this.car.internal_id);
			fd.append('is_private', this.record.is_private);
      return fd;
    },

    async mountPane(key, data) {
      if(key == 'created') {
        await mountOnBody(PaneCreate, { parent: this, propsData: {
          viewLink: '/garage/car/detail/' + this.car.internal_id + '/records/' + data.internal_id,
          backLink: '/garage/car/detail/' + this.car.internal_id,
          message: this.record.name + " successfully created.",
					parentEl: this
        } })
      }

      if(key == 'edited') {
        await mountOnBody(PaneEdit, { parent: this, propsData: {
          viewLink: '/garage/car/detail/' + this.car.internal_id + '/records/' + this.record.internal_id,
          backLink: '/garage/car/detail/' + this.car.internal_id,
          message: this.record.name + " successfully edited.",
					parentEl: this
        } })
      }
    }
  }
}

</script>

<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'
@import '~/src/styles/forms.styl'
@import '~/src/styles/listing-forms.styl'

.input-group.select
  input-style()


</style>
