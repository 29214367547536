<template lang='pug'>
.spacer

</template>

<script>

export default {

	data() {
		return {
			loaded: false
		}
	}
}
</script>

<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'


.spacer
	height 30px

</style>