<template lang="pug">
.contact-prefs(v-if='user')

  .btn-list

    btn(
      v-if='user.email && user.allowEmail' 
      :href='emailLink'
      label='Email')

    btn(
      v-if='user.phone && user.allowPhone' 
      :href='textLink' 
      label='Text Message')

    btn(
      v-if='user.phone && user.allowPhone' 
      :href='phoneLink'
      label='Call')


  .notice(v-if='user.preferText') Prefers Text Messages
    

</template>

<script>

export default {
  name: 'user-contact-prefs',
  props: {
    user: Object
  },
 
  data: function() {
    return {}
  },

  computed: {
    emailLink: function() {
      return 'mailto:' + this.user.email;
    },
    phoneLink: function() {
      return 'tel:' + this.user.phone;
    },
    textLink: function() {
      return 'sms:' + this.user.phone;
    }
  }
}
</script>
