<template lang='pug'>

mounted-pane(ref='pane' title='Sold Listings' @close='close()')
	.content-rows(v-if='loaded')
		.content
			settings-form(:user='user')

</template>

<script>
import SettingsForm from '/src/components/garage/settings-form';

export default {

	components: { SettingsForm },

	data: function() {
    return {
      loaded: false
    }
  },

	mounted: async function() {
		this.loaded = true;
	},

	computed: {
		user: function() {
			return this.$store.state.user;
		}
	}

}

</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

</style>
