<template lang="pug">

.feed-items

	div(v-if='feed.length')

		div(v-if='rows')
			row(:item='item' v-for="item, index in feed" :key='index')

		grid(v-else)
			card(:item='item' v-for="item, index in feed" :key='index' :small='small')

		.loads-more(v-if='canLoadMore')
			btn(@click='loadMoreListings' label='Load More')

	.no-listings(v-else)
		h2 Nothing to show

</template>

<script>

export default {
	name: 'paginated-feed',

	props: {
		type: {
			type: String,
			default: 'listing'
		},
		rows: {
			type: Boolean,
			deafult: false
		},
		scope: String,
		hide: String,
		noAds: {
			type: Boolean,
			default: false
		},
		small: {
			type: Boolean,
			default: false
		}
	},


	beforeMount: function () {
		this.fetchFeed();
	},

	data: function() {
		return {
			feed: [],
			index: 0,
			total: 0
		}
	},

	computed: {
		hidden: function() {
			if(this.hide) {
				return this.hide;
			} else {
				return this.internal_id
			}
		},
		canLoadMore: function() {
			return this.feed.length < this.total;
		},
		ads: function() {
			if(this.noAds) { return 'noAds' }
			else { return 'showAds' }
		}
	},

	methods: {

		fetchFeed: async function() {

			var endpoint = '/api/v1/all/' + this.type + '/' + this.index + '/' + this.hidden + '/' + this.ads;

			if(this.type == 'scoped-listings') {
				endpoint = '/api/v1/all/scoped-listings/' + this.scope + '/' + this.index + '/' + this.hidden + '/' + this.ads;
			}

			if(this.type == 'scoped-wants') {
				endpoint = '/api/v1/all/scoped-wants/' + this.scope + '/' + this.index + '/' + this.hidden + '/' + this.ads;
			}

			if(this.type == 'treasures') {
				endpoint = '/api/v1/all/treasures/' + this.index + '/' + this.ads;
			}

			if(this.type == 'scoped-events') {
				endpoint = '/api/v1/all/scoped-events/' + this.scope + '/' + this.index + '/' + this.ads;
			}

			if(this.type == 'scoped-member-cars') {
				endpoint = '/api/v1/all/scoped-member-cars/' + this.scope + '/' + this.index + '/' + this.ads;
			}

			if(this.type == 'deleted-listings') {
				endpoint = '/api/v1/user/listings/deleted/' + this.index;
			}

			if(this.type == 'garage') {
				endpoint = '/api/v1/garage/all/' + this.index + '/' + this.ads;
			}

			await fetch(endpoint)
			.then(res => res.json())
			.then(data => {
				this.total = data.total;
				this.feed.push(...data.data);
			});
		},

		loadMoreListings: function() {
			this.index++;
			this.fetchFeed();
		}
	}
}
</script>


<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'


.feed-items
	padding gutter 20px
	max-width 1200px
	margin 0 auto

	@media(max-width:mobile)
		padding gutter 10px


</style>
