var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "image-updater" },
        [
          _c("uploader", {
            attrs: {
              id: _vm.id,
              single: "",
              type: _vm.type,
              initial: _vm.initial,
            },
            on: { "master-batch": _vm.onSelect },
          }),
          _c("btn", {
            ref: "btn",
            attrs: { label: _vm.label },
            on: { click: _vm.update },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }