<template lang="pug">

.card-listing

	.image
		badges(:page='item' absolute)
			.badge.type(v-if='typeBadge') {{ item.entry_type }}
		router-link(:to='deepLink')
			base-image(:src='item.gallery[0].filename' v-if='item.gallery[0]')
			placeholder(v-else)
		price(:listing='item')
		profile-widget(:userId='item.user_id')

	.info
		router-link.card-info(:to='deepLink')
			smart-title(:title='item.title')
		car-info(:item='item')

		time-ago(:item='item')
		span.private(v-if='private') Private Listing for this group


		likes-and-comments-bar(
			v-if='!private'
			:item='item'
			type='listing')


</template>

<script>

export default {
	props: {
		item: Object,
		typeBadge: {
			type: Boolean,
			default: false
		}
	},

	data: function() {
		return {
			group: null
		}
	},

	beforeMount: async function() {
		if(this.item.scope.length == 1 && this.item.scope[0] != 'all') {
			await this.fetchGroupData();
		}
	},

	computed: {
		listing_date: function() {
			return new Date(this.item.created_at).toLocaleDateString("en-US");
		},
		deepLink: function() {
			return '/cascade-consulting/' + this.item.internal_id
		},
		private: function() {
			if(this.group) {
				if(this.group.internal_id == this.item.scope[0]) {
					return true;
				} else {
					return false;
				}
			}
		}
	},

	methods: {
		async fetchGroupData() {
			await fetch('/api/v1/group/detail/' + this.item.scope[0])
				.then(res => res.json())
				.then(group => {
					this.group = group;
				});
		}
	}
}
</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.private
	color pink
	display block
	font-size 13px
	margin-top 5px
	margin-bottom 10px

>>> .price
	position absolute
	bottom 10px
	right 10px

</style>
