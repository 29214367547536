<template lang="pug">

.icon
  flag-icon(class='icon' v-if='icon == "flag-icon"')

  trash-2-icon(class='icon' v-if='icon == "trash-2-icon"')

  image-icon(class='icon' v-if='icon == "image-icon"')

  check-icon(class='icon' v-if='icon == "check-icon"')

  chevron-left-icon(class='icon' v-if='icon == "chevron-left-icon"')

  chevron-right-icon(class='icon' v-if='icon == "chevron-right-icon"')

  dollar-sign-icon(class='icon' v-if='icon == "dollar-sign-icon"')

  x-icon(class='icon' v-if='icon == "x-icon"')



</template>

<script>


export default {
  name: 'btn-icon',

  props: {
    icon: String
  },
}

</script>

<style lang='stylus' scoped>


</style>