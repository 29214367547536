<template lang="pug">

.featured-listings(v-if='loaded && listings.length > 0')
  h2 Featured Partstash Listings
  ssr-carousel(:slides-per-page='4' :responsive='responsive' show-arrows)
    card(v-for="item, index in listings" :key='index' :item="item")

    template(#back-arrow='{ disabled }')
      chevron-down-icon(class='icon')
    template(#next-arrow='{ disabled }')
      chevron-down-icon(class='icon')

</template>

<script>
import SsrCarousel from 'vue-ssr-carousel';

export default {
  name: 'featured-listings',

  components: { SsrCarousel },

  beforeMount: async function () {
    await this.fetchFeaturedListings();
    this.loaded = true;
  },

  data: function() {
    return {
      listings: [],
      loaded: false,
      responsive: [
        {
          maxWidth: 1280,
          slidesPerPage: 4,
          peek: '50px'
        },
        {
          maxWidth: 1024,
          slidesPerPage: 3,
          peek: '40px'
        },
        {
          maxWidth: 767,
          slidesPerPage: 2,
          peek: '30px'
        },
        {
          maxWidth: 500,
          slidesPerPage: 1,
          peek: '20px'
        }
      ]
 
    }
  },

  methods: {

    fetchFeaturedListings: async function() {
      await fetch('/api/v1/listing/featured')
      .then(res => res.json())
      .then(listings => {
        this.listings.push(...listings.data);     
      });
    }

  }
}
</script>


<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.featured-listings
  background primary
  padding 30px 0
  margin 0 0 40px 0

  @media(max-width:mobile)
    padding 15px 0
    margin-bottom 20px

h2
  margin-left 20px

>>> .card
  background rgba(#111, .5)

@media(max-width:mobile)
  .card
    border-radius radius

  >>>
    .card-info
      padding 15px


>>> 
  .ssr-carousel-arrows
    position absolute
    top -23px
    right 10px

    @media(max-width:mobile)
      top -18px
      right 4px

  .ssr-carousel-back-button,
  .ssr-carousel-next-button
    transform translate(0, 0)
    position relative
    top inherit !important
    left inherit !important
    right inherit !important
    margin 0 6px

  .ssr-carousel-back-button svg
    transform rotate(90deg)

  .ssr-carousel-next-button svg
    transform rotate(-90deg)


</style>

<style lang='stylus'>
  @import '~/src/styles/carousel.styl'
</style>