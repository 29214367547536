<template lang='pug'>


div

  div(v-if='data.length')

    row(
      v-if='rows'
      v-for="item, index in data"
      :key="index"
      :item="item"
      :status='status')

    card(
      v-else
      v-for="item, index in data"
      :key="index"
      :item="item")

  div(v-else)
    p.none No Groups yet

  .loads-more(v-if='canLoadMore')
    btn(@click='loadMore' label='Load More')

</template>

<script>
import Card from '/src/components/globals/cards/card';
import Row from '/src/components/globals/rows/row';
import GroupCard from '/src/components/globals/cards/group';

export default {

  components: {
    Card,
    Row,
    GroupCard
  },

  props: {
    user: Object,
    public: Boolean,
    total: Number,
    type: String,
    rows: Boolean,
    status: String
  },

  beforeMount: async function () {
    await this.fetchData();
    this.loading = false;
  },

  destroyed: function() {
    this.data = [];
    this.index = 0;
    this.loading = true;
  },

  data: function() {
    return {
      data: [],
      index: 0,
      loading: true
    }
  },

  computed: {
    canLoadMore: function() {
      if(this.data.length < this.total) {
        return true;
      } else {
        return false;
      }
    }
  },

  methods: {
    fetchData: async function() {

      await fetch('/api/v1/group/' + this.type + '/' + this.index, {
        headers: {
            authorization: `Bearer ${JSON.parse(localStorage.token).value}`
          }
      }).then(res => res.json())
        .then(data => {
          this.data.push(...data.groups);
        });
    },

    loadMore: function() {
      this.index++;
      this.fetchData();
    }
  }
}

</script>

<style lang='stylus' scoped>

</style>
