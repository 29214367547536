import Vue from 'vue';
import Vuex from 'vuex'

Vue.use(Vuex);

// VueX Store
const store = new Vuex.Store({
  state: {
    menu: {
      open: false
    },
    search: {
      open: false
    },
    view: 'Home',
    user: false,
    notice: false,
    loggedIn: false
  }
});

export default store