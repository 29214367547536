<template lang="pug">

.metrics
  .metric.metric-listings
    .label Listings
    .count {{ listingCount }}

  .metric.metric-wants
    .label Wants
    .count {{ wantCount }}

  .metric.metric-members
    users-icon(class='icon')
    .count {{ memberCount }}

  .metric.metric-likes
    heart-icon(class='icon')
    .count {{ likeCount }}

  .metric.metric-comments
    message-circle-icon(class='icon')
    .count {{ commentCount }}

</template>

<script>

export default {
  props: {
    group: Object
  },

  data: function() {
    return {
      commentCount: 0,
      listingCount: 0,
      wantCount: 0
    }
  },

  mounted: async function() {
    await this.fetchCommentCount();
    await this.fetchListingCount();
    await this.fetchWantCount();
  },



  computed: {

    memberCount: function() {
      if(this.group.members.length > 100) {
        return '99+';
      } else {
        return this.group.members.length + 1;
      }
    },

    likeCount: function() {
      if(this.group.likes.length > 100) {
        return '99+';
      } else {
        return this.group.likes.length;
      }
    }
  },

  methods: {
    fetchCommentCount: function() {
      fetch('/api/v1/comment/getAll/' + this.group.internal_id)
      .then(res => res.json())
      .then(comments => {
        this.commentCount = comments.comments
      });
    },

    fetchListingCount: function() {
      fetch('/api/v1/group/getAllListings/' + this.group.internal_id)
      .then(res => res.json())
      .then(count => {
        if(count.count > 100) {
          this.listingCount = '99+';
        } else {
          this.listingCount = count.count;
        }
      });
    },

    fetchWantCount: function() {
      fetch('/api/v1/group/getAllWants/' + this.group.internal_id)
      .then(res => res.json())
      .then(count => {
        if(count.count > 100) {
          this.wantCount = '99+';
        } else {
          this.wantCount = count.count;
        }
      });
    }
  }
}
</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'
@import '~/src/styles/metrics.styl'

</style>
