var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal-simple" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.open,
              expression: "open",
            },
          ],
          staticClass: "overlay",
          on: { click: _vm.close },
        }),
      ]),
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.open,
                expression: "open",
              },
            ],
            ref: "scroll",
            staticClass: "content",
          },
          [
            !_vm.noClose
              ? _c(
                  "div",
                  { staticClass: "close", on: { click: _vm.close } },
                  [_c("x-icon", { staticClass: "icon" })],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "flex-wrap" },
              [
                _vm._t("default"),
                _c("div", { staticClass: "message" }, [
                  _c("div", { staticClass: "wrap" }, [
                    _vm._v(_vm._s(_vm.message)),
                  ]),
                ]),
                _vm.typeToProceed == true
                  ? _c("div", { staticClass: "string-match" }, [
                      _c("div", { staticClass: "input-group pad-top" }, [
                        _c("p", { staticClass: "red" }, [
                          _c("span", [
                            _vm._v("Type your email address to confirm"),
                          ]),
                        ]),
                        _c("input", {
                          ref: "matcher",
                          attrs: {
                            type: "text",
                            value: "",
                            placeholder: _vm.stringMatch,
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                !_vm.noChoices
                  ? _c(
                      "div",
                      { staticClass: "btn-list" },
                      [
                        _c("btn", {
                          attrs: {
                            size: "large",
                            color: "grey",
                            icon: "x-icon",
                          },
                          on: { click: _vm.close },
                        }),
                        _c("btn", {
                          attrs: {
                            size: "large",
                            color: "green",
                            icon: "check-icon",
                          },
                          on: { click: _vm.pass },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }