<template lang='pug'>

.badges(:class='positionClasses')

	div(v-if='type == "region"')
		slot
		.badge(:class='classes')
			span(v-html='region')

	div(v-else)
		slot

		.badge.new(v-if='isNew()')
			span Recently Added

		.badge(:class='classes')
			span(v-html='page.type')

</template>

<script>
import { differenceInDays } from 'date-fns'

export default {

	props: {
		page: Object,
		absolute: Boolean,
		region: String
	},

	computed: {

		type: function() {
			if(this.page.entry_type == 'listing') {
				return 'listing';
			} else if(this.page.entry_type == 'want') {
				return 'want';
			} else if(this.region) {
				return 'region';
			}
		},

		positionClasses: function() {
			if(this.absolute) {
				return 'absolute';
			}
		},

		classes: function() {
			var classes = [];

			classes.push(this.page.type);

			if(this.type == 'region') {
				classes.push("region-" + this.region);
			}

			return classes;

		},

		categoryLink: function() {
			if(this.type == 'listing') {
				return "/listings/category/" + this.type;
			}
		}
	},

	methods: {
		handelize: function(value) {
			if (!value) return '';
			value = value.toString();
			return value.toLowerCase().replace(' ', '-');
		},
		isNew: function() {
			if(this.type != 'listing') { return; }

			var diff = differenceInDays(
				new Date(),
				new Date(this.page.created_at));

			if(diff <= 2) {
				return true;
			} else {
				return false;
			}
		}
	}
}
</script>


<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'

.badges, .badges div
	display flex
	align-items stretch
	flex-wrap wrap

	&.absolute
		position absolute
		top 7px
		left 7px
		z-index 5

	// list of badges
	.badge
		margin-right 5px
		margin-bottom 5px

.badge
	font-size 10px
	color white
	text-transform uppercase
	border-radius 7px
	padding 4px 8px
	line-height 1em
	display block
	font-family font-bold
	letter-spacing .5px
	box-shadow()

	&.new
		border 1px solid darken(green, 15%)
		background green

		a
			color white

	&.want
		border 1px solid darken(lightgreen, 15%)
		background lightgreen
		color black

		a
			color black

	&.part
		border 1px solid darken(pink, 15%)
		background darken(pink, 10%)
		color black

		a
			color black

	&.trade
		border 1px solid darken(purple, 15%)
		background darken(purple, 10%)

		a
			color white

	&.part-out
		border 1px solid darken(cyan, 25%)
		background cyan
		color black

		a
			color black

	&.other
		border 1px solid darken(orange, 15%)
		background orange

		a
			color white

	&.wheels
		border 1px solid #222
		background #444

		a
			color black

	&.car
		border 1px solid darken(teal, 10%)
		background teal

		a
			color black

	&.region-NW, &.region-Northwest
		border 1px solid darken(cyan, 25%)
		background cyan
		color black

		a
			color black

	&.region-SW, &.region-Southwest
		border 1px solid darken(purple, 15%)
		background darken(purple, 10%)

		a
			color white


	&.region-MW, &.region-Midwest
		border 1px solid darken(teal, 10%)
		background teal

		a
			color black

	&.region-NE, &.region-Northeast
		border 1px solid darken(lightgreen, 10%)
		background lightgreen

		a
			color black

	&.region-SE, &.region-Southeast
		border 1px solid darken(orange, 10%)
		background orange

		a
			color black

	&.service

		border 1px solid darken(lightgreen, 10%)
		background lightgreen
		color black
		a
			color black

	&.general

		border 1px solid darken(orange, 10%)
		background orange
		color black
		a
			color black

	&.mod

		border 1px solid darken(purple, 15%)
		background darken(purple, 10%)

		a
			color white

	&.issue
		border 1px solid darken(teal, 10%)
		background teal

	&.repair

		border 1px solid darken(lightgreen, 10%)
		background lightgreen
		color black

		a
			color black

	&.track
		border 1px solid darken(red, 10%)
		background red


	&.other
		border 1px solid darken(cyan, 25%)
		background cyan
		color black

		a
			color black

	&.private
		border 1px solid darken(purple, 25%)
		background linear-gradient(52deg, rgba(124,52,209,1) 0%, rgba(204,43,43,1) 100%)

		color white

		a
			color white

	&.meetup

		border 1px solid darken(purple, 15%)
		background darken(purple, 10%)

		a
			color white

	&.rally

		border 1px solid darken(lightgreen, 10%)
		background lightgreen
		color black

		a
			color black

	&.drive
		border 1px solid darken(red, 10%)
		background red

	&.update

		border 1px solid darken(purple, 15%)
		background darken(purple, 10%)

		a
			color white

	&.issue

		border 1px solid darken(lightgreen, 10%)
		background lightgreen
		color black

		a
			color black

	&.type
		border 1px solid darken(pink, 15%)
		background pink
		color black

		a
			color black

	&.alert
		border 1px solid darken(red, 10%)
		background red



.tags

	.tag
		margin-right 5px
		margin-bottom 5px
		display inline-block
		background-color ui-grey
		padding 5px 10px
		font-size 12px
		border-radius radius-small


</style>
