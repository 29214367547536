var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row-want row-contents" },
    [
      _c("modal", {
        ref: "deleteModal",
        attrs: { message: "Confirm delete?" },
        on: { pass: _vm.proceedRemove },
      }),
      _c(
        "router-link",
        { staticClass: "img-area", attrs: { to: _vm.deepLink } },
        [
          _c("badges", { attrs: { page: _vm.item, absolute: "" } }),
          _vm.item.gallery[0]
            ? _c("base-image", { attrs: { src: _vm.item.gallery[0].filename } })
            : _c("image-placeholder"),
          _c("profile-widget", {
            attrs: { userId: _vm.item.user_id, short: "" },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "info-area" },
        [
          _c(
            "router-link",
            { staticClass: "card-info", attrs: { to: _vm.deepLink } },
            [
              _c("smart-title", { attrs: { title: _vm.item.title } }),
              _c("car-info", { attrs: { item: _vm.item } }),
            ],
            1
          ),
          _c("likes-and-comments-bar", {
            attrs: { item: _vm.item, theme: "small", type: "listing" },
          }),
          _vm.loggedInUser && _vm.mine
            ? _c("div", { staticClass: "actions" }, [
                _c(
                  "div",
                  { staticClass: "btn-list" },
                  [
                    _c("btn", {
                      attrs: {
                        color: "grey",
                        size: "small",
                        to: _vm.editLink,
                        label: "Edit",
                      },
                    }),
                    _c("btn", {
                      attrs: {
                        color: "red",
                        size: "small",
                        theme: "outline",
                        icon: "trash-2-icon",
                      },
                      on: {
                        clicked: function ($event) {
                          return _vm.removeWant()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }