<template lang="pug">

form.invite(v-if='loggedInUser' @submit.prevent='inviteUser()')
  .messages(v-if='messages.length')
    span(v-for='message, index in messages' :key='index') {{ message }}

  form-text(
    v-model='invite'
    label='Invite User via email'
    name='email'
    type='email'
    required)

  btn(size='small' type='submit' label='Invite')

</template>

<script>
import axios from 'axios';

export default {
  name: 'invite-user',

  data: function() {
    return {
      invite: '',
      messages: []
    }
  },

  computed: {

    loggedInUser: function() {
      return this.$store.state.user;
    },

  },
  
  methods: {
    
    inviteUser: async function() {
      if(this.invite.length > 0 && this.invite.indexOf('@') != -1) {

        this.messages = [];

        gtag('event', 'user-invited');

        var payload = {
          email: this.$sanitize(this.invite)
        }

        try {

          let submission = await axios.post(
            '/api/v1/user/invite', 
            payload,
            {
              headers: { 
                authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
                'Content-Type' : 'application/json'
              }
            });

          if(submission.data.message) {
            this.messages.push(submission.data.message);
          }
          this.invite = '';

        }

        catch(err) {
          alert('Something went wrong', err);
          console.log('Something went wrong', err);
        }

      } else {
        this.messages.push('enter a valid email address');
      }

    }
  }

}
</script>


<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.invite
  width 90%
  margin 30px 0
  max-width 350px

input, label
  margin-bottom 10px
  width 100%
  display block

input
  padding 10px
  border-radius 4px

.messages
  span
    display inline-block
    background primary
    color white
    padding 10px
    margin 10px 0
    font-size 13px
    letter-spacing .5px

</style>


