var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("div", { staticClass: "share" }, [
        _c("div", { staticClass: "iframe-wrap" }, [
          _c("iframe", {
            staticStyle: { border: "none", overflow: "hidden" },
            attrs: {
              src: _vm.src,
              width: "450",
              height: "35",
              scrolling: "no",
              frameborder: "0",
              allowfullscreen: "true",
              allow:
                "autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share",
            },
          }),
        ]),
        _vm._m(0),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tweet" }, [
      _c(
        "a",
        {
          staticClass: "twitter-share-button",
          attrs: {
            href: "https://twitter.com/share?ref_src=twsrc%5Etfw",
            "data-show-count": "false",
          },
        },
        [_vm._v("Tweet")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }