<template lang='pug'>

.car-picker(v-if='componentLoaded')
	.input-group.select.picker-select
		select(@change='onCarSelectChange' ref='carSelect')

			option(value='') Pick a car or add a new one

			option(
				v-for='car, index in garage'
				:value='computedCar(car)') {{ car.name }} ({{ car.make }} {{ car.model }})

			option(value='') -------------

			option(value='new') Add a new car

			option(value='') Not Associated with any specific car

		chevron-down-icon(class='icon')

	.new-car(v-show='newCar')
		h3 Pick a new car for this listing

		car-creator(
			ref='carCreator'
			@year='onYear'
			@make='onMake'
			@model='onModel'
			@trim='onTrim')

</template>

<script>
import CarCreator from '/src/components/ui/car-creator/index';

export default {
  components: {
    CarCreator
  },

  data() {
    return {
			newCar: false,
			componentLoaded: false,
			garage: []

		}
  },

  // Get the listing data on load for editing
  beforeMount: async function () {

    await fetch('/api/v1/garage', {
      headers: {
        authorization: `Bearer ${JSON.parse(localStorage.token).value}`
      }
    }).then(res => res.json())
      .then(garage => {
        this.garage = garage;
      });
  },

  mounted: function() {
    this.componentLoaded = true;
  },

  methods: {

    computedCar: function(car) {
      return car.year + ':' + car.make + ':' + car.model + ':' + car.trim + ':' + car.internal_id
    },

    onYear: function(year) {
      this.year = year;
    },

    onMake: function(make) {
      this.make = make;
    },

    onModel: function(model) {
      this.model = model;
    },

    onTrim: function(trim) {
      this.trim = trim;
    },

    onCarSelectChange: function() {



      var val = this.$refs.carSelect.value;
			if(val == 'new' || val == '') {
				this.reset()

				if(val == 'new') {
					this.newCar = true;
				}

			} else if(val != '') {

				var car = {
					year: val.split(':')[0],
					make: val.split(':')[1],
					model: val.split(':')[2],
					trim: val.split(':')[3],
					carId: val.split(':')[4]
				}

				this.$emit('onChange', car);

			}

    },

		reset: function() {
			this.newCar = false;

			this.$emit('onChange', {
				year: null,
				make: null,
				model: null,
				trim: null,
				carId: null
			});
		}

  }
}
</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'
@import '~/src/styles/forms.styl'

.picker-select
	display inline-flex
	align-items center
	border 1px solid white
	border-radius radius

	.icon
		margin-right 10px

	select
		padding 10px 20px
		appearance none
		border none
		background transparent
		color white

</style>
