<template lang='pug'>

mounted-pane(ref='pane' title='Watchlist' @close='close()')
	.content-rows(v-if='loaded')
		.content
			content-rows(:data='data' type='watches')

</template>

<script>
import { fetchDataWithOptions } from '/src/services/helpers'
import ContentRows from '/src/components/garage/content-rows';

export default {

	components: { ContentRows },

	props: {
		options: Object
	},

	data: function() {
    return {
      loaded: false,
			data: []
    }
  },

	mounted: async function() {
		this.data = await fetchDataWithOptions('user/watches', this.options.watches);
		this.loaded = true;
	}

}

</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

</style>
