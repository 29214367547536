var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        [
          _c("segment", { attrs: { full: "", light: "" } }, [
            _c("h4", [_vm._v("Pending Approval")]),
            _vm.pending.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.pending, function (user, index) {
                    return _c(
                      "user-row",
                      { key: index, attrs: { userId: user } },
                      [
                        _c("btn", {
                          attrs: {
                            color: "yellow",
                            size: "small",
                            label: "Reject",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.reject(user)
                            },
                          },
                        }),
                        _c("btn", {
                          attrs: {
                            color: "green",
                            size: "small",
                            label: "Approve",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.approve(user)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  1
                )
              : _c("div", [
                  _c("p", { staticClass: "none" }, [
                    _vm._v("No Pending Users"),
                  ]),
                ]),
          ]),
          _c("segment", { attrs: { full: "", light: "" } }, [
            _c("h4", [_vm._v("Current Admins")]),
            _vm.admins.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.admins, function (user, index) {
                    return _c(
                      "user-row",
                      { key: index, attrs: { userId: user, noFollowUI: "" } },
                      [
                        user != _vm.page.owner
                          ? _c("btn", {
                              attrs: {
                                color: "yellow",
                                theme: "outline",
                                size: "small",
                                label: "Demote",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.demote(user)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  1
                )
              : _vm._e(),
          ]),
          _c("segment", { attrs: { full: "", light: "" } }, [
            _c("h4", [_vm._v("Current Members")]),
            _vm.members.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.members, function (user, index) {
                    return _c(
                      "user-row",
                      { key: index, attrs: { userId: user, noFollowUI: "" } },
                      [
                        _c("btn", {
                          attrs: {
                            color: "green",
                            theme: "outline",
                            size: "small",
                            label: "Promote",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.promote(user)
                            },
                          },
                        }),
                        _c("btn", {
                          attrs: {
                            color: "red",
                            size: "small",
                            label: "Remove",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.remove(user)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  1
                )
              : _c("div", [
                  _c("p", { staticClass: "none" }, [_vm._v("No Members")]),
                ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }