<template lang="pug">


.row-search
	
	bookmark-icon(class='icon')
	
	router-link.label(:to='"/" + item.url') {{ item.label }}

	btn(color='red'
		theme='outline'
		size='small'
		icon='trash-2-icon'
		@click='removeSavedSearch()')

</template>

<script>
import axios from 'axios';

export default {
	props: {
		item: Object
	},

	methods: {
		removeSavedSearch: async function() {
			try {
        await axios.post(
          '/api/v1/user/search/delete', {
            label: this.item.label
          }, 
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          }
        );

        // make this a bit better
        window.location.reload();

      } catch(err) {
        console.log('Something went wrong', err);
      }
		}
	}
}
</script>

<style lang='stylus' scoped>

.row-search
	display flex
	align-items center
	padding 5px 10px
	color white

	.label
		display block
		margin-left 10px
		flex-grow 1

>>> a
	color white

</style>