<template lang="pug">

.content.narrow(v-if='processed')
  h2 Verifying email address
  p If you're seeing this message then it's likely you've opened the link in a different browser, where you're not logged in.
  ul
    li Either open the link in a browser you've logged in at
    li Or login here and then click the link in your email again

  p If that fails, please contact support

  btn(to='/login' label='Log In Again')

  p or
  
  btn(href='mailto:support@partstash.com' label='Contact Support')

</template>

<script>
import axios from 'axios';


export default {
  name: 'user-verify',

  data: function() {
    return {
      processed: false
    }
  },

  mounted: async function() {

    // wait a second and do the verifying
    var scope = this;
    setTimeout(function() {
      scope.verify()
    }, 1000);
  },

  methods: {

    verify: async function() {


      if(!this.$store.state.user) { 
        this.processed = true;
        return; 
      }
    
      try {
        var verification = await axios.post(
          '/auth/verify', 
          { key: this.$route.params.key }, 
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
              'Content-Type' : 'application/json'
            }
          }
        );

        if(verification.data.success) {
          alert('Thank you for verifying your email address')
          window.location.href='/#/garage';
        } else {
          this.processed = true;
        }
      } catch(err) {
        this.processed = true;
        alert(err);
        console.log('Something went wrong', err);
      }

    },

    resend: async function() {
      alert('Sending the verification email again');

      try {
        await axios.get(
          '/auth/verify/resend',
          {
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.token).value}`
            }
          }
        );
      } catch(err) {
        console.log('Something went wrong', err);
      }
    }
  }

}

</script>

<style lang='stylus' scoped>
</style>