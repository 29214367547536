<template lang='pug'>

.base-image(:style="style")
 
</template>

<script>


export default {

  props: {
    src: String,
    bucket: String
  },

  computed: {
    bucketString: function () {
      if (this.bucket) {
        return '-' + this.bucket;
      } else {
        return '';
      }
    },

    imgUrl: function () {
      return '//partstash' + this.bucketString + '.imgix.net/' + this.src + '?auto=format&q=100&width=500';
    },

    style: function () {
      return 'background-image: url(' + this.imgUrl + ');'
    }
  }
}
</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.base-image
  width 100%
  aspect-ratio 3/2.5
  background-position center center
  background-size cover
  background-repeat no-repeat

</style>

