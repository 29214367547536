<template lang="pug">

.segment-header
  .slot
    slot
  chevron-down-icon(class='icon' v-if='expandable')

</template>

<script>

export default {
  name: 'segment-header',

  props: {
    expandable: Boolean
  },
  
}

</script>

<style lang='stylus' scoped>

.segment-header
  display flex
  align-items center
  margin-bottom 12px

  .icon
    transition all .2s ease-out

  h1, h2
    flex-grow 1
    margin-bottom 0 !important

  .count
    background primary
    width 30px
    height 26px
    display flex
    align-items center
    justify-content center
    font-weight font-bold  
    margin-right gutter
    border-radius radius
    color white

.slot
  display flex
  align-items center
  flex-grow 1

.back
  font-weight bold
  font-size 12px
  color white
  text-decoration underline

</style>