<template lang="pug">

.profile
  .profile-image(:style='profile_styles')
  .notifications(v-if='user && notifications')

</template>

<script>

export default {
  name: 'profile-img',
  
  computed: {
    profile_image: function() {
      if(this.user.username) {
        return '//partstash-profiles.imgix.net/' + this.user.profile + '?auto=format&q=100&width=500'
      } else {
        return '/assets/img/default-dark.jpg';
      }
      
    },
    profile_styles: function() {
      return 'background-image: url(' + this.profile_image + ');'
    },

    notifications: function() {
      if(this.user.username) {
        let notices = this.$store.state.user.notices.filter(function(notice) {
          return notice.read == false;
        });

        if(notices.length > 0) { return true; }
        else { return false }
      }
    },

    user: function() {
      return this.$store.state.user;
    }
  },

  watch: {
    $route(to, from) {
      // possibly check for new notices here
      // this fires twice, possibly because there's 2 insances of the profile image
      // TODO: console.log('watch for new notices');
    }
  }
}

</script>

<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.profile
  position relative

.notifications
  position absolute
  background red
  width 15px
  height 15px
  border-radius 20px
  top 0
  right 0

</style>

