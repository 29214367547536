<template lang='pug'>

.content-rows
  .content

    .action(v-if='btn && loggedInUser')
      btn(to='/want/add' label='New Want To Buy' v-if='path == "wants"')
      btn(to='/listing/add' label='New Listing' v-else)

    div(v-if='data.length')

      div(v-if='row')
        row(
          v-for='item, index in data'
          :key='index'
          :item='item'
					:forceDelete='forceDelete')

      grid(v-else)
        card(
          v-for="item, index in data"
          :key="index"
          :item="item")

    div(v-else)
      p.none No Data

    .loads-more(v-if='canLoadMore')
      btn(@click='loadMore' label='Load More')

</template>

<script>

export default {

  props: {
    user: Object,
    path: String,
    btn: Boolean,
    total: Number,
    public: Boolean,
    row: Boolean,
		forceDelete: {
			type: Boolean,
			default: false
		}
  },

  beforeMount: async function () {
    await this.fetchData();
    this.loading = false;
  },

  data: function () {
    return {
      data: [],
      index: 0,
      loading: true
    }
  },

  computed: {
    canLoadMore: function () {
      if (this.data.length < this.total) {
        return true;
      } else {
        return false;
      }
    },

    loggedInUser: function () {
      return this.$store.state.user.user_id == this.user.user_id
    }
  },

  methods: {
    fetchData: async function () {
      if (this.public) {
        fetch('/api/v1/profile/' + this.path + '/' + this.user.username + '/' + this.index)
          .then(res => res.json())
          .then(data => {
            if(this.path == "listings") {
              this.data.push(...data.listings);
            } else {
              this.data.push(...data.wants);
            }

          });
      } else {
        fetch('/api/v1/user/' + this.path + '/' + this.index, {
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.token).value}`
          }
        }).then(res => res.json())
          .then(data => {
						this.data.push(...data.data);
          });
      }
    },

    loadMore: function () {
      this.index++;
      this.fetchData();
    }
  }
}

</script>

<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.content-rows
  text-align left

.action
  margin-top 10px
  margin-bottom 10px

</style>
