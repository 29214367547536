var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "profile-card" }, [
    _c("div", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))]),
    _c("div", { staticClass: "val" }, [_vm._v(_vm._s(_vm.value))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }