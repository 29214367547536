<template lang="pug">

span

	router-link.btn(:to='to' v-if='to && !outside' :class='classes')
		btn-icon(:icon='icon' v-if='icon')
		slot
		span.label(v-if='label') {{ label }}

	a.btn(v-else-if='to && outside' :href='to' :disabled='disabled' @click='click' :class='classes' :type='type' target='blank')
		btn-icon(:icon='icon' v-if='icon')
		slot
		span.label(v-if='label') {{ label }}


	.btn(v-else-if='!to && !href' :disabled='disabled' @click='click' :class='classes')
		btn-icon(:icon='icon' v-if='icon')
		slot
		span.label(v-if='label') {{ label }}

	a.btn(v-else-if='href' target='blank' :href='href' :disabled='disabled' @click='click' :class='classes' :type='type')
		btn-icon(:icon='icon' v-if='icon')
		slot
		span.label(v-if='label') {{ label }}

	button.btn(v-else :disabled='disabled' @click='click' :class='classes' :type='type')
		btn-icon(:icon='icon' v-if='icon')
		slot
		span.label(v-if='label') {{ label }}

</template>

<script>

import BtnIcon from './btn-icon';

export default {
  name: 'btn',

	components: { BtnIcon },

	data() {
    return {
      loading: false
    }
  },

  props: {
    href: String,
    type: String,
    theme: String,
    color: String,
    size: String,
    to: String,
    disabled: Boolean,
    label: String,
		icon: String,
		outside: {
			type: Boolean,
			default: false
		}
  },

	computed: {
		classes: function() {
			var classList = [];
			if(this.color) { classList.push(this.color) }
			if(this.size) { classList.push(this.size) }
			if(this.theme) { classList.push(this.theme) }
			if(this.loading) { classList.push('loading') }
			return classList;
		}

	},

	methods: {
		click: function(e) {
			this.$emit('click'); // TODO: deprecate this
			this.$emit('clicked');
		},

		setLoading: function() {
			this.loading = true;
		},

		removeLoading: function() {
			this.loading = false;
		}

	}
}
</script>

<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

a.btn, .btn, input[type=submit], button
	appearance none
	border none
	background primary
	border-radius radius-small
	padding 10px 17px 8px 17px
	color white
	font-size 12px
	display inline-flex
	align-items center
	height btn-height
	cursor pointer
	display inline-flex
	align-items center
	transition all .2s ease-out
	font-family font-bold
	text-transform uppercase
	position relative

	&.loading
		opacity .4
		pointer-events none

	&.square
		padding 0
		width btn-height
		display inline-flex
		align-items center
		justify-content center

	&:hover
		background lighten(#1538D6, 20%)

	span
		display inline-block

	>>> .icon
		height 15px
		width auto
		display inline-block

	@media(max-width: mobile)
		padding 9px 16px
		font-size 13px

	&.large
		padding 22px 30px
		font-size 16px

	&.light, &.grey
		border 1px solid color-text
		color color-text
		border 1px solid #999
		background transparent


		.detail-admin-bar &
			color #eee
			border 1px solid #eee

			&:hover
				border 1px solid #eee

	&.green
		background green
		color #fff

		&.outline
			background transparent
			border 1px solid green
			color green

	&.red
		background red
		color #fff

		&.outline
			background transparent
			border 1px solid red
			color red

	&.yellow
		background yellow
		color black

		&.outline
			background transparent
			border 1px solid yellow
			color yellow

	&.white
		background white
		color black

	&.white-inverted
		border 1px solid white
		color white
		background transparent

	&.inverted
		background white
		color black

	&.small
		padding 8px 10px
		font-size 12px
		border-radius radius-small
		height btn-height-small
		font-family font-semibold

	&.disabled,
	&.force-disabled,
	&:disabled,
	&[disabled]
		opacity .3
		pointer-events none


// pill styles
.pill
	font-size 10px
	font-family font-bold
	padding 4px 10px 5px 10px
	border-radius 1000px
	margin 0 4px
	display inline-flex
	align-items center

	&.blue
		background rgba(blue, .5)

	&.large
		font-size 15px
		padding 7px 20px

	&.xl
		font-size 22px
		padding 20px 50px

		@media(max-width:mobile)
			padding 10px 40px

	.remove
		width 15px
		height 15px
		display inline-block
		margin-left 10px
		border-radius 100px
		display flex
		align-items center
		justify-content center
		margin-right -5px


.label
	position relative
	letter-spacing 1px

</style>
