var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded && _vm.listings.length > 0
    ? _c(
        "div",
        { staticClass: "featured-listings" },
        [
          _c("h2", [_vm._v("Featured Partstash Listings")]),
          _c(
            "ssr-carousel",
            {
              attrs: {
                "slides-per-page": 4,
                responsive: _vm.responsive,
                "show-arrows": "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "back-arrow",
                    fn: function ({ disabled }) {
                      return [_c("chevron-down-icon", { staticClass: "icon" })]
                    },
                  },
                  {
                    key: "next-arrow",
                    fn: function ({ disabled }) {
                      return [_c("chevron-down-icon", { staticClass: "icon" })]
                    },
                  },
                ],
                null,
                false,
                2841333605
              ),
            },
            _vm._l(_vm.listings, function (item, index) {
              return _c("card", { key: index, attrs: { item: item } })
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }