import Vue from 'vue';
import axios from 'axios';
import { format, formatDistance } from 'date-fns';

export async function loadPageData(section, id) {

  // TODO Try and get this to work
  await fetch('/api/v1/' + section + '/' + id)
    .then(res => res.json())
    .then(page => {
      return page;
    });
}


export function processTags(tags) {

  let processedTags = tags.replace(/\s+/g, '').replace(/[.\/#!$%\^&\*;:{}=\-_`~()]/g, "").toLowerCase().split(',');

  let flattenedTags = processedTags.sort().filter(function (item, pos, ary) {
    return !pos || item != ary[pos - 1];
  });

  return flattenedTags;

}

export function trimTitle(title, len) {

  // first replace amp;
  let newTitle = title.replace('&amp;', '&');

  // then shorten it if it's long
  if(title.length > len) {
    newTitle = newTitle.substring(0, len);
    newTitle += '...';
  }

  return newTitle;
}

export function smartTitle(title) {
	return title.replace('&amp;', '&');
}


// Mount a component within an element
let mountInside = function(element, component, options = {}, append = true) {

  new Promise(function(resolve) {

    var attachMethod, mount, ref;

    // Set default options
    if (!options.parent) {
      options.parent = (ref = window) != null ? ref.$root : void 0;
    }

    // pass the parent as a prop for communication
    options.propsData.parent = options.parent;

    // Mount the component
    attachMethod = append ? 'appendChild' : 'prepend';
    mount = function() {
      var vm = new (Vue.extend(component))(options);
      vm.$mount();
      element[attachMethod](vm.$el);
      return resolve(vm);
    };

    return setTimeout(mount, 50);

  });

}

export function mountOnBody(component, options = {}) {
  appendMount(document.body, component, options);
}

export function appendMount(element, component, options = {}) {
  mountInside(element, component, options, true);
}

export function timeAgo(date) {
	var distance = formatDistance(
		new Date(),
		new Date(date),
	{
		includeSeconds: true,
	});

	return distance + ' ago.'
}

export function date(date) {
	return format(new Date(date), 'MM/dd/yyyy');
}


// standard bearer fetch (just to get data with no options)
export async function fetchData(path) {
	let dataToReturn = null;

	let headers = {};
	if(localStorage.token) {
		headers = {
			authorization: `Bearer ${JSON.parse(localStorage.token).value}`
		}
	}

	// await the query
	await fetch(('/api/v1/' + path), {
		headers: headers
	}).then(res => res.json())
		.then(data => {
			dataToReturn = data;
		});

	// return the data
	return dataToReturn;
}


// this if I need to get some data with some params as an object
export async function fetchDataWithOptions(path, options = {}) {

	try {
		let data = await axios.post(
			'/api/v1/' + path,
			options,
			{
				headers: {
					authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
					'Content-Type' : 'application/json'
				}
			}
		);

		return data.data.docs

	} catch(err) {
		console.log('Something went wrong', err);
	}

}


// common post
export async function post(endpoint, options) {
	try {
		let submission = await axios.post(
			endpoint,
			options,
			{
				headers: {
					authorization: `Bearer ${JSON.parse(localStorage.token).value}`,
					'Content-Type' : 'application/json'
				}
			}
		);

		if(submission.data) {
			return submission.data;
		}

	} catch(err) {
		gtag('event', 'member approval failed');
		console.log('Something went wrong', err);
		// this.$refs.btnApprove.removeLoading();
	}
}
