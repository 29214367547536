<template lang="pug">

.content.narrow
    
  br
  .card.single.pad.centered
    h3 Not Found
    p The page your looking for cannout be found.
    br
    btn(label='Go Home' to='/')

</template>

<script>
export default {
  name: 'not-found-view',
  props: {

  },
  data: function() {
    return {
      notFound: true
    }
  }
}
</script>

