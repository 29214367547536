<template lang="pug">

.listings-container(v-if='loaded')

  .picker
    .picker-item.selected(ref='sale' @click='toggle("sale")')
      span.title For Sale
    .picker-item(ref='want' @click='toggle("want")')
      span.title Want To Buy

  .listings.listings(v-if='section == "sale"')

    .listing-wrap(v-if='listings.length')
      grid
        card(v-for="item, index in listings" :key='index' :item="item")

      .loads-more(v-if='canLoadMoreListings')
        btn(@click='loadMoreListings' label='Load More')

    .no-listings(v-else)
      h2 No Listings

  .listings.wants(v-if='section == "want"')
    .listing-wrap(v-if='wants.length')
      grid
        card(:item='want' v-for="want, index in wants" :key='index')

      .loads-more(v-if='canLoadMoreWants')
        btn(@click='loadMoreWants' label='Load More')

    .no-listings(v-else)
      h2 No Want to buys



</template>

<script>

export default {
  name: 'listings-all',

  beforeMount: async function () {
    await this.fetchListings();
    await this.fetchWants();
    this.loaded = true;
  },
  data: function() {
    return {
      url: '/filter/any/any/any/any',
      listings: [],
      listingsIndex: 0,
      listingsTotal: 0,
      wants: [],
      wantsIndex: 0,
      wantsTotal: 0,
      loaded: false,
      section: 'sale'
    }
  },

  computed: {
    canLoadMoreListings: function() {
      if(this.listings.length < this.listingsTotal) {
        return true;
      } else {
        return false;
      }
    },
    canLoadMoreWants: function() {
      if(this.wants.length < this.wantsTotal) {
        return true;
      } else {
        return false;
      }
    }
  },

  methods: {
    setListings: function(url) {

      // reset
      this.listings = [];
      this.listingsIndex = 0;
      this.listingsTotal = 0;
      this.wants = [];
      this.wantsIndex = 0;
      this.wantsTotal = 0;
      this.section = 'sale';

      // call for new data
      this.url = url;

      this.fetchListings();
      this.fetchWants();

    },

    fetchListings: async function() {
      await fetch('/api/v1/listing/' + this.url + '/' + this.listingsIndex + '/showAds')
      .then(res => res.json())
      .then(listings => {
        this.listingsTotal = listings.total;
        this.listings.push(...listings.listings);
      });
    },

    fetchWants: async function() {
      await fetch('/api/v1/want/' + this.url + '/' + this.wantsIndex + '/showAds')
      .then(res => res.json())
      .then(wants => {
        this.wantsTotal = wants.total;
        this.wants.push(...wants.wants);
      });
    },

    toggle: function(section) {
      if(section == this.section) {
        return;
      } else {
        this.section = section;
        this.$refs['sale'].classList.remove('selected');
        this.$refs['want'].classList.remove('selected');
        this.$refs[section].classList.add('selected');
      }
    },

    loadMoreListings: function() {
      this.listingsIndex++;
      this.fetchListings();
    },

    loadMoreWants: function() {
      this.wantsIndex++;
      this.fetchWants();
    }


  }
}
</script>


<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

</style>
