<template lang="pug">

.card-event
	.image
		badges(:page='item' absolute)
			.badge.type(v-if='typeBadge') {{ item.entry_type }}
		router-link(:to='deepLink')
			base-image(:src='item.gallery[0].filename' v-if='item.gallery[0]' bucket='groups')
			placeholder(v-else)

		profile-widget(:userId='item.user_id' label='Organizer')

	.info
		router-link(:to='deepLink')
			smart-title(:title='item.title')
			event-info(:item='item')

		likes-and-comments-bar(
			:item='item'
			theme='small'
			type='listing')

</template>

<script>

export default {
	props: {
		item: Object,
		typeBadge: {
			type: Boolean,
			default: false
		}
	},

	data: function() {
		return {
			group: null
		}
	},

	beforeMount: async function() {
		if(this.item.scope.length == 1 && this.item.scope[0] != 'all') {
			await this.fetchGroupData();
		}
	},

	computed: {
		listing_date: function() {
			return new Date(this.item.created_at).toLocaleDateString("en-US");
		},
		deepLink: function() {
			return '/event/' + this.item.internal_id
		}
	},

	methods: {
		async fetchGroupData() {
			await fetch('/api/v1/group/detail/' + this.item.scope[0])
				.then(res => res.json())
				.then(group => {
					this.group = group;
				});
		}
	}
}
</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.card-info
	font-size 12px
	line-height 1.3em

</style>
