var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.car && _vm.loaded
    ? _c(
        "div",
        [
          _c("heading", {
            attrs: {
              heading: _vm.heading,
              "user-action": _vm.action,
              "user-label": "< Back",
            },
          }),
          _c("car-record-form", { attrs: { new: "", car: _vm.car } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }