var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("div", { staticClass: "comments" }, [
        _c("h2", [
          _vm._v(
            "Discussion (" +
              _vm._s(_vm.comments.length + _vm.newComments.length) +
              ")"
          ),
        ]),
        _vm.user.username
          ? _c(
              "div",
              [
                _vm.user.username
                  ? _c(
                      "div",
                      { staticClass: "add" },
                      [
                        _c("form-text", {
                          attrs: { label: "Add Comment", name: "comment" },
                          model: {
                            value: _vm.comment,
                            callback: function ($$v) {
                              _vm.comment = $$v
                            },
                            expression: "comment",
                          },
                        }),
                        _c("btn", {
                          attrs: { size: "small", label: "comment" },
                          on: { click: _vm.addComment },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._l(_vm.newComments, function (comment, index) {
                  return _c("comment", { key: index, attrs: { item: comment } })
                }),
                _vm._l(_vm.comments, function (comment) {
                  return _c("comment", {
                    key: comment.internal_id,
                    attrs: { item: comment },
                  })
                }),
              ],
              2
            )
          : _c("div", [
              _c("h3", [_vm._v("Login or Sign Up to see the comments")]),
              _c(
                "div",
                { staticClass: "btn-list" },
                [
                  _c("btn", {
                    attrs: { size: "small", to: "/login", label: "Login" },
                  }),
                  _c("btn", {
                    attrs: {
                      size: "small",
                      to: "/register",
                      label: "Register",
                    },
                  }),
                ],
                1
              ),
            ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }