var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row-garage" },
    [
      _c("modal", {
        ref: "deleteModal",
        attrs: { message: "Confirm delete?" },
        on: { pass: _vm.proceedRemove },
      }),
      _c(
        "div",
        { staticClass: "row-contents" },
        [
          _c(
            "router-link",
            {
              staticClass: "img-area",
              attrs: { to: "/garage/car/detail/" + _vm.item.internal_id },
            },
            [
              _vm.item.gallery.length > 0
                ? _c("base-image", {
                    attrs: {
                      src: _vm.item.gallery[0].filename,
                      bucket: "cars",
                    },
                  })
                : _c("placeholder"),
              _c("profile-widget", {
                attrs: { userId: _vm.item.owner, short: "" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "info-area" },
            [
              _c(
                "router-link",
                { attrs: { to: "/garage/car/detail/" + _vm.item.internal_id } },
                [_c("smart-title", { attrs: { title: _vm.item.name } })],
                1
              ),
              _c("car-info", { attrs: { item: _vm.item } }),
              _vm.item.is_private
                ? _c("span", { staticClass: "private" }, [_vm._v("PRIVATE")])
                : _vm._e(),
              _c("likes-and-comments-bar", {
                attrs: { item: _vm.item, type: "car" },
              }),
              _vm.loggedInUser && _vm.mine
                ? _c("div", { staticClass: "actions" }, [
                    _c(
                      "div",
                      { staticClass: "btn-list" },
                      [
                        _vm.admin && _vm.item.flagged
                          ? _c("btn", {
                              attrs: {
                                color: "yellow",
                                theme: "outline",
                                size: "small",
                                icon: "flag-icon",
                                label: "Remove Flag",
                              },
                              on: {
                                clicked: function ($event) {
                                  return _vm.removeFlag()
                                },
                              },
                            })
                          : _vm._e(),
                        _c("btn", {
                          attrs: {
                            color: "grey",
                            size: "small",
                            to: _vm.editLink,
                            label: "Edit",
                          },
                        }),
                        _c("btn", {
                          attrs: {
                            color: "red",
                            size: "small",
                            theme: "outline",
                            icon: "trash-2-icon",
                          },
                          on: {
                            clicked: function ($event) {
                              return _vm.removeCar()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }