var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content-rows" }, [
    _vm.type == "listings"
      ? _c("div", { staticClass: "content" }, [
          _vm.data.length
            ? _c(
                "div",
                { staticClass: "action" },
                [
                  _c("btn", {
                    attrs: { to: "/listing/add", label: "New Listing" },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "action" },
                [
                  _c("btn", {
                    attrs: {
                      to: "/listing/add",
                      label: "Create Your First Listing",
                    },
                  }),
                ],
                1
              ),
          _vm.data.length
            ? _c(
                "div",
                _vm._l(_vm.data, function (item, index) {
                  return _c("row", { key: index, attrs: { item: item } })
                }),
                1
              )
            : _c("div", [
                _c("p", { staticClass: "none" }, [_vm._v("No listings yet")]),
              ]),
        ])
      : _vm._e(),
    _vm.type == "wants"
      ? _c("div", { staticClass: "content" }, [
          _vm.data.length
            ? _c(
                "div",
                { staticClass: "action" },
                [_c("btn", { attrs: { to: "/want/add", label: "New Want" } })],
                1
              )
            : _c(
                "div",
                { staticClass: "action" },
                [
                  _c("btn", {
                    attrs: { to: "/want/add", label: "Create Your First Want" },
                  }),
                ],
                1
              ),
          _vm.data.length
            ? _c(
                "div",
                _vm._l(_vm.data, function (item, index) {
                  return _c("row", { key: index, attrs: { item: item } })
                }),
                1
              )
            : _c("div", [
                _c("p", { staticClass: "none" }, [_vm._v("No wants yet")]),
              ]),
        ])
      : _vm._e(),
    _vm.type == "garage"
      ? _c("div", { staticClass: "content" }, [
          !_vm.noAdd
            ? _c(
                "div",
                { staticClass: "action" },
                [
                  _c("btn", {
                    attrs: { to: "/garage/car/add", label: "New Car" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.data.length
            ? _c(
                "div",
                _vm._l(_vm.data, function (item, index) {
                  return _c("row", {
                    key: index,
                    attrs: { item: item, customEntryType: "garage" },
                  })
                }),
                1
              )
            : _c("div", [
                _c("p", { staticClass: "none" }, [
                  _vm._v("No cars in your garage"),
                ]),
              ]),
        ])
      : _vm._e(),
    _vm.type == "watches"
      ? _c("div", { staticClass: "content" }, [
          _vm.data.length
            ? _c(
                "div",
                _vm._l(_vm.data, function (item, index) {
                  return _c("row", {
                    key: index,
                    attrs: { item: item, type: "watch" },
                    on: { "watch-remove": _vm.removeWatch },
                  })
                }),
                1
              )
            : _c("div", [
                _c("p", { staticClass: "none" }, [
                  _vm._v("no items in your watch list"),
                ]),
              ]),
        ])
      : _vm._e(),
    _vm.type == "searches"
      ? _c("div", { staticClass: "content" }, [
          _vm.data.length
            ? _c(
                "div",
                _vm._l(_vm.data, function (item, index) {
                  return _c("row", {
                    key: index,
                    attrs: { item: item, customEntryType: "search" },
                  })
                }),
                1
              )
            : _c("div", [
                _c("p", { staticClass: "none" }, [
                  _vm._v("no items in your saved searches"),
                ]),
              ]),
        ])
      : _vm._e(),
    _vm.type == "followers"
      ? _c("div", { staticClass: "content" }, [
          _vm.data.length
            ? _c(
                "div",
                _vm._l(_vm.data, function (item, index) {
                  return _c("UserRow", { key: index, attrs: { user: item } })
                }),
                1
              )
            : _c("div", [
                _c("p", { staticClass: "none" }, [_vm._v("No followers")]),
              ]),
        ])
      : _vm._e(),
    _vm.type == "following"
      ? _c("div", { staticClass: "content" }, [
          _vm.data.length
            ? _c(
                "div",
                _vm._l(_vm.data, function (item, index) {
                  return _c("UserRow", { key: index, attrs: { user: item } })
                }),
                1
              )
            : _c("div", [
                _c("p", { staticClass: "none" }, [
                  _vm._v("You're not following anyone"),
                ]),
              ]),
        ])
      : _vm._e(),
    _vm.type == "deleted"
      ? _c("div", { staticClass: "content" }, [
          _vm.data.length
            ? _c(
                "div",
                _vm._l(_vm.data, function (item, index) {
                  return _c("card", {
                    key: index,
                    attrs: { layout: "horizontal", item: item, deleted: "" },
                  })
                }),
                1
              )
            : _c("div", [
                _c("p", { staticClass: "none" }, [_vm._v("Nothing Deleted")]),
              ]),
        ])
      : _vm._e(),
    _vm.type == "notices"
      ? _c("div", { staticClass: "content" }, [
          _vm.data.length
            ? _c(
                "div",
                _vm._l(_vm.data, function (item, index) {
                  return _c("notice-row", {
                    key: index,
                    attrs: { index: index, item: item },
                  })
                }),
                1
              )
            : _c("div", [
                _c("p", { staticClass: "none" }, [_vm._v("No Notifications")]),
              ]),
        ])
      : _vm._e(),
    _vm.type == "events"
      ? _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "action" },
            [
              !_vm.noAdd
                ? _c("btn", { attrs: { to: "/event/add", label: "New Event" } })
                : _vm._e(),
            ],
            1
          ),
          _vm.data.length
            ? _c(
                "div",
                _vm._l(_vm.data, function (item, index) {
                  return _c("row", {
                    key: index,
                    attrs: { index: index, item: item },
                  })
                }),
                1
              )
            : _c("div", [
                _c("p", { staticClass: "none" }, [_vm._v("No Events")]),
              ]),
        ])
      : _vm._e(),
    _vm.type == "timeline"
      ? _c("div", { staticClass: "content" }, [
          !_vm.noAdd
            ? _c(
                "div",
                { staticClass: "action" },
                [
                  _c("btn", {
                    attrs: { to: "/timeline/add", label: "New Status" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.data.length
            ? _c(
                "div",
                _vm._l(_vm.data, function (item, index) {
                  return _c("row", {
                    key: index,
                    attrs: { index: index, item: item },
                  })
                }),
                1
              )
            : _c("div", [
                _c("p", { staticClass: "none" }, [
                  _vm._v("No Timeline Statuses"),
                ]),
              ]),
        ])
      : _vm._e(),
    _vm.type == "treasures"
      ? _c("div", { staticClass: "content" }, [
          !_vm.noAdd
            ? _c(
                "div",
                { staticClass: "action" },
                [
                  _c("btn", {
                    attrs: { to: "/treasure/add", label: "New Treasure" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.data.length
            ? _c(
                "div",
                _vm._l(_vm.data, function (item, index) {
                  return _c("row", {
                    key: index,
                    attrs: { index: index, item: item },
                  })
                }),
                1
              )
            : _c("div", [
                _c("p", { staticClass: "none" }, [
                  _vm._v("No Cascade Consulting Listings"),
                ]),
              ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }