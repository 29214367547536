<template lang='pug'>

mounted-pane(ref='pane' title='Members' @close='close()')
	.content-rows(v-if='loaded')
		.content
			group-members(:page='page')

</template>

<script>
import GroupMembers from '/src/views/group/members';

export default {

	components: { GroupMembers },

  props: {
    page: Object
  },

	data: function() {
    return {
      loaded: false
    }
  },

	mounted: async function() {
		this.loaded = true;
	}
  
}

</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

</style>