var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "event-info" }, [
    _vm.item.event_date && !_vm.item.recurring_frequency
      ? _c("div", { staticClass: "info-badge" }, [
          _c("span", [_vm._v("When:")]),
          _c("span", [_vm._v(_vm._s(_vm.niceDate))]),
        ])
      : _vm._e(),
    _vm.item.recurring_frequency
      ? _c("div", { staticClass: "info-badge" }, [
          _c("span", [_vm._v("When:")]),
          _c("span", [_vm._v(_vm._s(_vm.item.recurring_frequency))]),
        ])
      : _vm._e(),
    _vm.item.location
      ? _c("div", { staticClass: "info-badge" }, [
          _c("span", [_vm._v("Location:")]),
          _c("span", [_vm._v(_vm._s(_vm.item.location))]),
        ])
      : _vm._e(),
    _vm.item.attendees
      ? _c("div", { staticClass: "info-badge" }, [
          _c("span", [_vm._v("Attending:")]),
          _c("span", [_vm._v(_vm._s(_vm.item.attendees.length))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }