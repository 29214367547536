<template lang="pug">

.card-advert(:style='adStyle' :class='classes')
  .shade
  .height-prop
  .content
    a.item(v-if='item.url' :href='item.url' target='blank')
      h2(v-if='item.title') {{ item.title }}
      h3(v-if='item.headline') {{ item.headline }}
      btn(v-if='item.btn_text' :label='item.btn_text')

    .item(v-if='!item.url')
      h2(v-if='item.title') {{ item.title }}
      h3(v-if='item.headline') {{ item.headline }}


</template>

<script>

export default {
  props: {
    item: Object
  },

  computed: {
    adStyle: function() {
      return "background-image: url(//partstash-images.imgix.net/" + this.item.image[0].filename + "?auto=format&q=100&width=500)";
    },
    classes: function() {
      if(this.item.dark_text == 'yes') {
        return 'dark-text';
      }
    }
  }
}
</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'


.item
  display block
  padding 20px

h2, h3
  margin 0 0 10px 0
  text-shadow 0 0 10px rgba(black, .5)

.card-advert
  background-size cover
  background-position center center
  position relative
  height 100%

.height-prop
  padding-top 100%

.shade
  expand()
  z-index 1
  background rgba(black, .35)

.content
  expand()
  z-index 2

a.item
  expand()

a
  h2, h3
    color #fff

.dark-text
  h2, h3, a h2, a h3
    color #000
    text-shadow 0 0 20px rgba(white, .3)

</style>
