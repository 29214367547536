var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.listing.price
    ? _c("div", { staticClass: "price" }, [
        _vm._v("$" + _vm._s(_vm.numberWithCommas(_vm.listing.price))),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }