var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "admin-bar" },
    [
      _c("modal", {
        ref: "modalDelete",
        attrs: { message: "Confirm delete?" },
        on: { pass: _vm.proceedRemove },
      }),
      _c("modal", {
        ref: "modalForceDelete",
        attrs: { message: "Confirm fully delete?" },
        on: { pass: _vm.proceedForceRemove },
      }),
      _c("modal", {
        ref: "modalSold",
        attrs: { message: "Mark as sold?" },
        on: { pass: _vm.proceedSold },
      }),
      _c(
        "div",
        { staticClass: "btn-list" },
        [
          !_vm.item.deleted
            ? _c("btn", {
                attrs: {
                  color: "grey",
                  size: "small",
                  to: _vm.editLink,
                  label: "Edit",
                },
              })
            : _vm._e(),
          _vm.canRestore
            ? _c("btn", {
                attrs: { color: "grey", size: "small", label: "Restore" },
                on: { click: _vm.restoreListing },
              })
            : _vm._e(),
          _vm.canBeSold && _vm.type == "listing"
            ? _c("btn", {
                attrs: {
                  size: "small",
                  color: "green",
                  theme: "outline",
                  icon: "dollar-sign-icon",
                  label: "Mark Sold",
                },
                on: {
                  click: function ($event) {
                    return _vm.sellListing()
                  },
                },
              })
            : _vm._e(),
          _vm.item.sold && _vm.type == "listing"
            ? _c("btn", {
                attrs: {
                  color: "yellow",
                  theme: "outline",
                  size: "small",
                  icon: "dollar-sign-icon",
                  label: "Remove Sold",
                },
                on: {
                  click: function ($event) {
                    return _vm.unsellListing()
                  },
                },
              })
            : _vm._e(),
          !_vm.item.deleted
            ? _c("btn", {
                attrs: {
                  color: "red",
                  theme: "outline",
                  size: "small",
                  icon: "trash-2-icon",
                },
                on: {
                  click: function ($event) {
                    return _vm.removeListing()
                  },
                },
              })
            : _vm._e(),
          _vm.item.deleted
            ? _c("btn", {
                attrs: {
                  color: "red",
                  size: "small",
                  theme: "outline",
                  label: "Force Delete",
                },
                on: {
                  click: function ($event) {
                    return _vm.forceRemoveListing()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }