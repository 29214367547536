<template lang='pug'>

mounted-pane(ref='pane' title='Event Attendees' @close='close()')
	.content-rows(v-if='loaded')
		.content
			div(v-if='page.attendees.length > 0')
				user-row(
					v-for='user, index in page.attendees'
					:key='index'
					:userId='user')

			div(v-else)
				p.none No Attendees

</template>

<script>
import UserRow from '/src/components/globals/rows/user';

export default {

	components: {
		UserRow
	},

  props: {
    page: Object
  },

	data: function() {
    return {
      loaded: false
    }
  },

	mounted: async function() {
		this.loaded = true;
	}

}

</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

</style>
