<template lang='pug'>

select(v-model='state' @change='onChange')
	option(value='undefined') {{ fullLabel }}
	car-creator-option(
		v-for='item, index in items'
		:item='item'
		:key='index'
		:typeID='typeID'
		:index='index')

</template>

<script>
import CarCreatorOption from './option';

export default {

	components: {
		CarCreatorOption
	},

	props: {
		items: Array,
		label: String,
		value: String,
		typeID: String
	},

	data() {
		return {
			state: this.value
		}
	},

	computed: {
		fullLabel() {
			return `Select ${this.label}`;
		}
	},

	methods: {
		onChange(val) {
			this.$emit('onChange', val);
		},
		reset() {
			this.state = 'undefined';
		}
	},

	watch: {
		value (to, from) {
			this.state = this.value;
		},

		state (to, from) {
			this.$emit('input', this.state);
		}
	}

}
</script>

<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

select {
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
	width: 100%;
  max-width: 20rem;
  padding: 1rem 4rem 1rem 1rem;
  background: url('https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg') no-repeat right 0.8em center / 1.4em,
    linear-gradient(to left, rgba(255, 255, 255, 0.3) 3em, rgba(255, 255, 255, 0.2) 3em);
  color: white;
  border-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
	display: block;
	margin-bottom: 10px;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
  }

  option {
    color: inherit;
    background-color: white;
  }

}



</style>
