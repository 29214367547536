<template lang="pug">

.segment(:class='classes')
  h3(v-if='title') {{ title }}

  div
    slot

</template>

<script>


export default {
  name: 'segment',

  props: {
    title: String,
    danger: Boolean,
    light: Boolean,
    full: Boolean,
    transparent: Boolean
  },

  computed: {
    classes: function() {
      var classes = [];
      if(this.danger) { classes.push('danger'); }
      if(this.light) { classes.push('light'); }
      if(this.full) { classes.push('full'); }
      if(this.transparent) { classes.push('transparent'); }
      return classes;
    }
  }
}
</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.segment
  padding 20px
  border-radius 20px
  background #121212
  margin 20px auto
  width calc(100% - 40px)
  max-width 900px
  border 1px solid #121212
  position relative

  &.danger
    border 1px solid red !important

    h1, h2, h3, h4, h5
      color red !important

  &.light
    background-color #2c2c2c
    border 1px solid #2c2c2c

  &.full
    width 100%

  &.transparent
    background transparent !important
    border none !important
    padding 0px !important

  @media(max-width:mobile)
    padding 12px
    border-radius 7px
    width calc(100% - 10px)
    margin 13px auto

  &.collapsable
    .card
      display none

    &.open

      .card
        display block

  &.top-0
    margin-top gutter

  h2
    margin 0 0 gutter 0
</style>