<template lang='pug'>

div
  heading(
    heading='Edit Car'
    user-action='/garage'
    user-label='< Garage')

  garage-form(edit)

</template>

<script>
import GarageForm from './form';

export default {
  components: {
    GarageForm,
  }
}

</script>