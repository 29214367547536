var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "delete-account" },
    [
      _c("modal", {
        ref: "modalDelete",
        attrs: {
          typeToProceed: "",
          stringMatch: _vm.user.email,
          message: _vm.deleteMessage,
        },
        on: {
          pass: function ($event) {
            return _vm.proceed()
          },
        },
      }),
      _c("p", [
        _vm._v(
          "Warning: Deleting account will remove all user information, listings, etc."
        ),
      ]),
      _c("br"),
      _c("btn", {
        attrs: { color: "red", type: "outline", label: "Delete Account" },
        on: {
          click: function ($event) {
            return _vm.deleteAccount()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }