var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded && _vm.visible
    ? _c(
        "div",
        [
          _c("heading", {
            attrs: {
              heading: _vm.heading,
              "user-action": _vm.action,
              "user-label": "< Back",
            },
          }),
          _c(
            "segment",
            [
              _c("div", { staticClass: "car" }, [
                _vm._v(_vm._s(_vm.carHeading)),
              ]),
              _c("badges", { attrs: { page: _vm.record } }, [
                _vm.record.is_private
                  ? _c("div", { staticClass: "badge private" }, [
                      _vm._v("PRIVATE"),
                    ])
                  : _vm._e(),
              ]),
              _c("time-ago", { attrs: { item: _vm.record } }),
              !_vm.record.is_private
                ? _c("likes-and-comments-bar", {
                    attrs: { item: _vm.record, type: "record", scroll: "" },
                    on: { "scroll-to-comments": _vm.scrollToComments },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.hasGallery
            ? _c(
                "segment",
                { attrs: { transparent: "" } },
                [
                  _c("listing-gallery", {
                    attrs: { item: _vm.record, bucket: "cars" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("segment", { attrs: { title: "Details" } }, [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.record.body) } }),
          ]),
          !_vm.record.is_private
            ? _c(
                "segment",
                { ref: "comments", attrs: { id: "comments" } },
                [
                  _c("comments", {
                    attrs: { item: _vm.record, type: "record" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }