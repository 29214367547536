var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.searchOpen,
              expression: "searchOpen",
            },
          ],
          staticClass: "search-overlay",
          on: { click: _vm.closeSearch },
        }),
      ]),
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.searchOpen,
                expression: "searchOpen",
              },
            ],
            staticClass: "search-content",
            attrs: { id: "searchContent" },
          },
          [
            _c(
              "div",
              { staticClass: "close", on: { click: _vm.closeSearch } },
              [_c("img", { attrs: { src: "/assets/close.svg" } })]
            ),
            _c("search-form", {
              ref: "search",
              on: { closeSearch: _vm.closeSearch },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }