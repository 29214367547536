<template lang="pug">

.condition
	.condition-label
		span.rating(:class='conditionClasses') {{ condition }}
		span {{ conditionLabel }}

	.legend 1 = total restoration, 5 = like new

</template>

<script>

export default {
	name: 'condition-label',

	props: {
		condition: String
	},

	computed: {
		conditionClasses: function() {
			return 'condition-' + this.condition;
		},

		conditionLabel: function() {
			var label = '';
			if(this.condition == 1) {
				label = 'Total Restoration'
			} else if(this.condition == 2) {
				label = 'Okay, but needs love'
			} else if(this.condition == 3) {
				label = 'Acceptable'
			} else if(this.condition == 4) {
				label = 'Works and looks great'
			} else if(this.condition == 5) {
				label = 'New Old Stock'
			}

			return label;
			
		}
	}
}
</script>

<style lang='stylus' scoped>

@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.condition-label
	display flex
	align-items center
	margin 10px 0

	span
		display flex
		align-items center

.condition-1
	background condition1

.condition-2
	background condition2

.condition-3
	background condition3

.condition-4
	background condition4
	color black

.condition-5
	background condition5

w = 30px
.rating
	width w
	height w
	border-radius w
	display flex
	align-items center
	justify-content center
	margin-right 10px

.legend
	font-size 12px
	color grey
	font-style italic



</style>