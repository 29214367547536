var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-group" }, [
    _c(
      "div",
      { staticClass: "image" },
      [
        _c(
          "badges",
          { attrs: { page: _vm.item, region: _vm.region, absolute: "" } },
          [
            _vm.typeBadge
              ? _c("div", { staticClass: "badge type" }, [
                  _vm._v(_vm._s(_vm.item.entry_type)),
                ])
              : _vm._e(),
          ]
        ),
        _c(
          "router-link",
          { attrs: { to: _vm.deepLink } },
          [
            _vm.item.profile
              ? _c("base-image", {
                  attrs: { src: _vm.item.profile, bucket: "groups" },
                })
              : _c("placeholder"),
          ],
          1
        ),
        _c("profile-widget", {
          attrs: { userId: _vm.item.owner, label: "Admin" },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "info" },
      [
        _c(
          "router-link",
          { attrs: { to: _vm.deepLink } },
          [_c("smart-title", { attrs: { title: _vm.item.name } })],
          1
        ),
        _c("group-info", { attrs: { item: _vm.item } }),
        _c("group-metrics", { attrs: { group: _vm.item } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }