var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("div", { staticClass: "search-results" }, [
        _c("div", { staticClass: "search-header" }, [
          _c(
            "div",
            { staticClass: "search-content" },
            [_c("search-form", { attrs: { initial: _vm.query } })],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "content narrow" },
          [
            _vm.results.length
              ? _c(
                  "grid",
                  _vm._l(_vm.results, function (item, index) {
                    return _c("card", {
                      key: index,
                      attrs: { search: "", noProfile: "", item: item },
                    })
                  }),
                  1
                )
              : _c("div", { staticClass: "card single narrow" }, [
                  _c("div", { staticClass: "card-heading" }, [
                    _c("div", { staticClass: "flex-container stretch" }, [
                      _c("div", { staticClass: "pad flex-container grow" }, [
                        _c("h2", [_vm._v("No Search Results")]),
                      ]),
                    ]),
                  ]),
                ]),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }