<template lang="pug">

.card(:class='classes')
	card-listing(:item='item' v-if='entryType == "listing"' :typeBadge='typeBadge')
	card-want(:item='item' v-if='entryType == "want"' :typeBadge='typeBadge')
	card-group(:item='item' v-if='entryType == "group"' :typeBadge='typeBadge')
	card-ad(:item='item' v-if='entryType == "advert"' :typeBadge='typeBadge')
	card-garage(:item='item' v-if='entryType == "garage"' :typeBadge='typeBadge')
	card-event(:item='item' v-if='entryType == "event"' :typeBadge='typeBadge')
	card-timeline(:item='item' v-if='entryType == "timeline"' :typeBadge='typeBadge')
	card-treasure(:item='item' v-if='entryType == "treasure"' :typeBadge='typeBadge')

</template>

<script>
import CardListing from './listing';
import CardWant from './want';
import CardGroup from './group';
import CardGarage from './garage';
import CardAd from './ad';
import CardEvent from './event';
import CardTimeline from './timeline';
import CardTreasure from './treasure';

export default {
	name: 'card',

	components: {
		CardListing,
		CardWant,
		CardAd,
		CardGarage,
		CardGroup,
		CardEvent,
		CardTimeline,
		CardTreasure
	},

	props: {
		item: Object,
		customEntryType: String,
		typeBadge: {
			type: Boolean,
			default: false
		},
		layout: {
			type: String,
			default: 'card'
		},
		small: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		entryType: function() {
			return this.customEntryType || this.item.entry_type;
		},
		classes: function() {
			var classes = [];
			classes.push('layout-' + this.layout)
			if(this.small) { classes.push('small') }
			return classes;
		}
	}
}
</script>

<style lang='stylus' scoped>
@import '~/src/styles/variables.styl'
@import '~/src/styles/mixins.styl'

.card
	position relative
	overflow hidden
	border-radius 14px
	color color-text
	background #161616
	border 1px solid #232323
	margin-bottom 20px
	color white

	>>> a
		color white

.badge
	position absolute
	z-index 10
	top 10px
	left 10px
	font-size 10px
	font-family font-bold
	padding 5px 10px
	border-radius 4px

.card-badge-event
	background purple

.card-badge-listing
	background yellow
	color #000

.card-badge-want
	background green
	color #000

.card-badge-group
	background cyan
	color #000

.card-badge-garage
	background pink
	color #000

.card-badge-advert
	background blue

>>> .info
	padding 15px

>>> .likes-and-comments-bar
	padding 0 20px 20px 20px

>>> .placeholder
	aspect-ratio 12/9 !important

>>> .base-image
	aspect-ratio 12/9 !important

>>> .image
	position relative

	&:after
		content ''
		height 90px
		width 100%
		position absolute
		bottom 0
		left 0
		display block
		pointer-events none
		background linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%)


>>> .profile-widget
	position absolute !important
	bottom 4px
	left 0
	padding 5px 10px
	z-index 1

>>> .price
	position absolute
	z-index 2

.card.small
	>>> h3
		font-size 17px

	>>> .info-badge
		display none

	>>>
		.metric-listings, .metric-wants, .metric-comments
			display none


@media(max-width:500px)
	.card
		>>> .profile-widget
			.name
				display none

		>>> .metrics
			flex-wrap nowrap

			.metric-listings,
			.metric-wants,
			.metric-members
				display none

			svg
				transform scale(.6)

			.count
				border-radius 5px
				padding 3px 5px
				margin-left -4px

			.metric
				padding-right 3px

		>>> .info
			padding 8px

		>>>
			.event-info, .info-badge.established
				display none

		>>> h3
			font-size 13px

</style>
